import React, { useCallback, useEffect } from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { OrderAgainRail } from '@order/features/orders';

import { ReorderOrderLineItem } from '../ReorderOrderLineItem';
import { useRecentOrders } from './hooks';

// ─────────────────────────────────────────────────────────────────────

/**
 * Renders user's most recent orders.
 *
 * NOTE: The component does not check for states like logged-in and instead
 *       relies on external render condition/s.
 */
export const RecentOrdersRail = (props: RecentOrderAgainRailProps) => {
  const { shouldRefetchOnFocus } = props;

  const {
    fetchInitialOrders,
    refetchOrders,
    mostRecentLineItems,
    isFetchingInitialOrders,
    mostRecentOrder,
  } = useRecentOrders();

  // ─── Effects ─────────────────────────────────────────────────────────

  // Fetch orders on component mount
  useEffect(() => {
    fetchInitialOrders();
  }, [fetchInitialOrders]);

  // Refetch orders on screen focus
  useFocusEffect(
    useCallback(() => {
      if (!shouldRefetchOnFocus) return;

      refetchOrders();
    }, [refetchOrders, shouldRefetchOnFocus]),
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <RecentOrderAgainRailReady
      lineItems={mostRecentLineItems}
      mostRecentOrder={mostRecentOrder}
      isFetching={isFetchingInitialOrders}
    />
  );
};

// ─────────────────────────────────────────────────────────────────────

export const RecentOrderAgainRailReady = (
  props: RecentOrderAgainRailReadyProps,
) => {
  const { lineItems, mostRecentOrder, isFetching } = props;

  const { navigate } = useNavigation();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const navigateToAllOrdersModal = useCallback(() => {
    navigate('Modal', { screen: 'Reorder' });
  }, [navigate]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <OrderAgainRail.Container>
      <OrderAgainRail.Header>
        <OrderAgainRail.Heading />
        <OrderAgainRail.ViewAllOrdersCTA
          onPress={navigateToAllOrdersModal}
          disabled={isFetching}
        />
      </OrderAgainRail.Header>

      {isFetching ? (
        <OrderAgainRail.LoadingPlaceholder />
      ) : (
        <OrderAgainRail.List>
          {lineItems.map((lineItem) => (
            <ReorderOrderLineItem
              key={lineItem.id}
              variation="vertical"
              lineItem={lineItem}
              orderDetails={lineItem.order}
              mostRecentOrder={mostRecentOrder}
              shouldShowSuccessBanner={true}
            />
          ))}
        </OrderAgainRail.List>
      )}
    </OrderAgainRail.Container>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type RecentOrderAgainRailProps = {
  shouldRefetchOnFocus?: boolean;
};

type RecentOrderAgainRailReadyProps = {
  lineItems: ReturnType<typeof useRecentOrders>['mostRecentLineItems'];
  mostRecentOrder: ReturnType<typeof useRecentOrders>['mostRecentOrder'];
  isFetching: boolean;
};
