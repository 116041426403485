import type { ComponentProps } from 'react';
import React from 'react';
import { LoadingAnimation as GarnishLoadingAnimation } from '@sg/garnish';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { NAVBAR_HEADER_HEIGHT } from '@order/navigation';

import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_NAVBAR_HEIGHT,
} from '../Header/constants';

export const LoadingAnimation = (
  props: ComponentProps<typeof GarnishLoadingAnimation>,
) => {
  // ─────────────────────────────────────────────────────────────────

  const shouldUseNavbar = useFeatureFlag('CELS-3459-desktop-header-v2-enabled');
  const desktopHeaderHeight = shouldUseNavbar
    ? NAVBAR_HEADER_HEIGHT
    : DESKTOP_HEADER_HEIGHT;

  // ─────────────────────────────────────────────────────────────────

  return (
    <GarnishLoadingAnimation
      {...props}
      topOffset={desktopHeaderHeight}
      bottomOffset={MOBILE_NAVBAR_HEIGHT}
    />
  );
};
