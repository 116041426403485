/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChatTokenQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ChatTokenQuery = {
  readonly __typename?: 'Query';
  readonly chatToken: {
    readonly __typename?: 'ChatToken';
    readonly token: string;
  } | null;
};

export const ChatTokenDocument = gql`
  query ChatToken {
    chatToken {
      token
    }
  }
`;

export function useChatTokenQuery(
  options?: Omit<Urql.UseQueryArgs<ChatTokenQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ChatTokenQuery, ChatTokenQueryVariables>({
    query: ChatTokenDocument,
    ...options,
  });
}
