const BASE_TRANSITION = 240;
const SHORT_TRANSITION = 140;

// ─────────────────────────────────────────────────────────────────────────────

export const TRANSITIONS = {
  short: SHORT_TRANSITION,
  base: BASE_TRANSITION,

  /**
   * @see {https://docs.expo.dev/versions/latest/sdk/image/#transition Expo Image | Transition}
   */
  image: 120,

  /**
   * Web-only transition presets
   * @see {https://developer.mozilla.org/en-US/docs/Web/CSS/transition}
   */
  presets: {
    colors: `background-color ${BASE_TRANSITION}ms ease-in-out, color ${BASE_TRANSITION}ms ease-in-out, border-color ${BASE_TRANSITION}ms ease-in-out`,
    opacity: `opacity ${BASE_TRANSITION}ms ease-in-out`,
    colorsAndOpacity: `background-color ${BASE_TRANSITION}ms ease-in-out, color ${BASE_TRANSITION}ms ease-in-out, border-color ${BASE_TRANSITION}ms ease-in-out, opacity ${BASE_TRANSITION}ms ease-in-out`,
  },
};
