import React, {
  type ComponentProps,
  type PropsWithChildren,
  useCallback,
} from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { theme } from '@garnish/constants';

import { Icon } from '../../../../../Icon';
import { DisplayText } from '../../../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

const PinPromptKeypadButtonBase = (props: PinPromptKeypadButtonBaseProps) => {
  const { children, palette = 'default', accessibilityLabel, onPress } = props;

  const pressableStyle = [
    styles.pressable,
    palette === 'default' && styles.pressableDefault,
    palette === 'dark' && styles.pressableDark,
    palette === 'caution' && styles.pressableCaution,
  ];

  return (
    <TouchableOpacity
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      style={pressableStyle}
      onPress={onPress}
    >
      <DisplayText sizeMatch={['32']}>{children}</DisplayText>
    </TouchableOpacity>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

export const PinPromptKeypadValueButton = (
  props: PinPromptKeypadNumButtonProps,
) => {
  const { onPress, value, accessibilityLabel } = props;

  const handleOnPress = useCallback(() => {
    onPress(value);
  }, [onPress, value]);

  return (
    <PinPromptKeypadButtonBase
      onPress={handleOnPress}
      accessibilityLabel={accessibilityLabel}
    >
      <PinPromptKeypadButtonLabel>{value}</PinPromptKeypadButtonLabel>
    </PinPromptKeypadButtonBase>
  );
};

export const PinPromptKeypadActionButton = PinPromptKeypadButtonBase;

export const PinPromptKeypadButtonLabel = (
  props: PinPromptKeypadButtonLabelProps,
) => {
  const { children, palette = 'dark' } = props;

  const labelStyles = [
    palette === 'light' && styles.labelLight,
    palette === 'dark' && styles.labelDark,
  ];

  return (
    <DisplayText sizeMatch={['40']} style={labelStyles}>
      {children}
    </DisplayText>
  );
};

export const PinPromptKeypadButtonIcon = (
  props: PinPromptKeypadButtonIconProps,
) => {
  const { name, palette } = props;

  return (
    <Icon
      name={name}
      width={40}
      height={40}
      color={palette === 'light' ? theme.colors.WHITE : theme.colors.DARK_KALE}
      testID="pin-prompt-keypad-button-icon"
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    height: 80,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.medium,
  },
  pressableDefault: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
  pressableDark: {
    backgroundColor: theme.colors.OLIVE,
  },
  pressableCaution: {
    backgroundColor: theme.colors.CAUTION,
  },

  labelDark: {
    color: theme.colors.DARK_KALE,
  },
  labelLight: {
    color: theme.colors.WHITE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────\

type PinPromptKeypadButtonBaseProps = PropsWithChildren<{
  onPress: () => void;
  palette?: 'default' | 'dark' | 'caution';
  accessibilityLabel?: string;
}>;

type PinPromptKeypadButtonLabelProps = PropsWithChildren<{
  palette?: 'light' | 'dark';
}>;

type PinPromptKeypadButtonIconProps = {
  name: ComponentProps<typeof Icon>['name'];
  palette?: 'light' | 'dark';
};

type PinPromptKeypadNumButtonProps = {
  onPress: (value: string) => void;
  value: string;
  accessibilityLabel?: string;
};
