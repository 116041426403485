import React from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';
import { Image, theme } from '@sg/garnish';

export const HomeHeroImageSM = (props: HomeHeroImageSMProps) => {
  const { width, height } = useHomeHeroImageSize();

  const dynamicImageSize = useStyle(() => ({ width, height }), [width, height]);

  const imageStyle = [styles.heroImage, dynamicImageSize];

  return (
    <View style={styles.heroImageWrapper}>
      <Image
        contentFit="cover"
        style={imageStyle}
        contentPosition={{ right: 0 }}
        aria-label={props.imageA11yLabel}
        source={props.imageUrl}
      />
    </View>
  );
};

const HomeHeroImagePlaceholderSM = () => {
  const { height } = useHomeHeroImageSize();
  const dynamicHeight = useStyle(() => ({ height }), [height]);

  return <View style={[styles.heroImagePlaceholder, dynamicHeight]} />;
};

const useHomeHeroImageSize = () => {
  const { width: viewPortWidth } = useWindowDimensions();

  const width = viewPortWidth / 2;
  const height = viewPortWidth / 3;

  return { width, height };
};

const styles = StyleSheet.create({
  heroImageWrapper: {
    flex: 0,
    position: 'relative',
    borderLeftWidth: 1,
    borderLeftColor: theme.colors.DARK_KALE,
  },
  heroImage: {
    position: 'absolute',
    left: 0,
  },
  heroImagePlaceholder: {
    flex: 1,
  },
});

type HomeHeroImageSMProps = {
  imageUrl: string;
  imageA11yLabel: string;
};

// ─── Compound Components ─────────────────────────────────────────────────────
// eslint-disable-next-line functional/immutable-data
HomeHeroImageSM.Placeholder = HomeHeroImagePlaceholderSM;
