/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CustomerAddressesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CustomerAddressesQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly addresses: ReadonlyArray<{
      readonly __typename: 'Address';
      readonly id: string;
      readonly street: string;
      readonly secondaryStreet: string | null;
      readonly city: string;
      readonly state: string;
      readonly country: string;
      readonly zipCode: string;
      readonly deliveryPreference: Types.DeliveryPreferenceType;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly name: string | null;
      readonly notes: string | null;
    }>;
  } | null;
};

export const CustomerAddressesDocument = gql`
  query CustomerAddresses {
    currentCustomer {
      id
      __typename
      addresses {
        id
        __typename
        street
        secondaryStreet
        city
        state
        country
        zipCode
        deliveryPreference
        googlePlaceId
        latitude
        longitude
        name
        notes
      }
    }
  }
`;

export function useCustomerAddressesQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerAddressesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerAddressesQuery, CustomerAddressesQueryVariables>(
    { query: CustomerAddressesDocument, ...options },
  );
}
