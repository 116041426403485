import React from 'react';

import { BodyText } from '../../../../Text';

export const IngredientCardQuantityStepperLabel = (
  props: IngredientCardQuantityStepperLabelProps,
) => {
  const { quantity } = props;

  return <BodyText sizeMatch={['22']}>{quantity}</BodyText>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardQuantityStepperLabelProps = {
  quantity: number;
};
