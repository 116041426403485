/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPreferencesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPreferencesQuery = {
  readonly __typename?: 'Query';
  readonly getPreferences: {
    readonly __typename: 'CustomerPreference';
    readonly id: string;
    readonly dietaryRestrictions: ReadonlyArray<Types.DietaryRestriction>;
    readonly hasSyncedRestrictions: boolean;
  };
};

export type UpdatePreferencesMutationVariables = Types.Exact<{
  input: Types.CustomerPreferencesInput;
}>;

export type UpdatePreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePreferences:
    | {
        readonly __typename: 'CustomerPreference';
        readonly id: string;
        readonly dietaryRestrictions: ReadonlyArray<Types.DietaryRestriction>;
        readonly hasSyncedRestrictions: boolean;
      }
    | {
        readonly __typename: 'UpdateCustomerPreferencesError';
        readonly errorMessage: string;
      };
};

export const GetPreferencesDocument = gql`
  query GetPreferences {
    getPreferences {
      id
      dietaryRestrictions
      hasSyncedRestrictions
      __typename
    }
  }
`;

export function useGetPreferencesQuery(
  options?: Omit<Urql.UseQueryArgs<GetPreferencesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetPreferencesQuery, GetPreferencesQueryVariables>({
    query: GetPreferencesDocument,
    ...options,
  });
}
export const UpdatePreferencesDocument = gql`
  mutation UpdatePreferences($input: CustomerPreferencesInput!) {
    updatePreferences(input: $input) {
      __typename
      ... on CustomerPreference {
        id
        dietaryRestrictions
        hasSyncedRestrictions
      }
      ... on UpdateCustomerPreferencesError {
        errorMessage
      }
    }
  }
`;

export function useUpdatePreferencesMutation() {
  return Urql.useMutation<
    UpdatePreferencesMutation,
    UpdatePreferencesMutationVariables
  >(UpdatePreferencesDocument);
}
