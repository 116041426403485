import { useCallback, useRef, useState } from 'react';
import { logger } from '@garnish/logger';
import StringCacheMap from 'string-cache-map';

import { useDebounceFn } from '../useDebounceFn';
import type {
  FetchGeocodeProps,
  Predictions,
  PredictionsCacheItem,
  UseGooglePlacesSearchProps,
} from './utils';
import { fetchPredictions, PLACES_GEOCODE_URL } from './utils';

export const usePlacesAutocomplete = (props: UseGooglePlacesSearchProps) => {
  const { apiKey, minChars = 3 } = props ?? {};

  // ───── state ───────────────────────────────────────────────
  const [value, setValue] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [networkError, setNetworkError] = useState<unknown>();
  const [predictions, setPredictions] = useState<Predictions>([]);

  // ───── cache ───────────────────────────────────────────────
  const predictionsCache = useRef(new StringCacheMap(10));

  // ───── google predictions ──────────────────────────────────
  const getPredictions = useCallback(
    async (input = '') => {
      const validInput = input.trim().length >= minChars;
      let fetchedPredictions: Predictions = [];

      try {
        if (validInput) {
          const { predictions: predictionsList } = await fetchPredictions({
            input,
            apiKey,
          });

          // Enforces results to be valid street addresses, not just locations.
          const validPredictions = predictionsList.filter((prediction) =>
            prediction.types.some(isValidType),
          );

          // add predictions to cache
          predictionsCache.current.set(input, {
            predictions: validPredictions,
          });

          fetchedPredictions = validPredictions;
        }
      } catch (error: unknown) {
        logger.error('[usePlacesAutocomplete]', error);
        setNetworkError(error as Error);
      } finally {
        setPredictions(fetchedPredictions);
        setLoading(false);
      }
    },
    [apiKey, minChars],
  );

  const getPredictionsDebounced = useDebounceFn(getPredictions);

  const setSearchString = useCallback(
    (query: string) => {
      const isSearchable = query.length >= minChars;
      const cachedPredictions =
        predictionsCache.current.get<PredictionsCacheItem>(query);

      setValue(query);
      setNetworkError(undefined);

      if (cachedPredictions) {
        setPredictions(cachedPredictions.predictions);

        return;
      }

      setLoading(isSearchable);

      if (isSearchable) getPredictionsDebounced(query);
    },
    [getPredictionsDebounced, minChars],
  );

  // ───── reset results ───────────────────────────────────────

  const clearAutoCompleteResults = useCallback(() => {
    setValue(undefined);
    setLoading(false);
    setNetworkError(undefined);
    setPredictions([]);
  }, []);

  // ───────────────────────────────────────────────────────────

  const validInput = (value ?? '').length >= minChars;
  const noResults = !loading && validInput && predictions.length === 0;

  return {
    searchString: value ?? '',
    predictions,
    loading,
    error: networkError,
    noResults,
    setSearchString,
    clearAutoCompleteResults,
  };
};

// ──── Geocode results from google places id ──────────────────
export async function fetchGeocode(props: FetchGeocodeProps) {
  const fetchUrl = `${PLACES_GEOCODE_URL}?place_id=${props.placeId}&key=${props.apiKey}`;
  const apiResponse = await fetch(fetchUrl);
  const data: google.maps.GeocoderResponse = await apiResponse.json();

  return data ?? { results: [] };
}

// ──── Full address from google places id ─────────────────────
export const convertGooglePlacesAddress = async (
  props: FetchGeocodeProps,
): Promise<GooglePlacesAddress> => {
  const response = await fetchGeocode(props);
  const findAddressComponent = (component: string) => {
    const match = response.results[0]?.address_components.find(
      (e: Readonly<{ types: readonly string[] }>) =>
        e.types.includes(component),
    );

    return match;
  };

  const cityKey =
    [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'sublocality_level_5',
      'neighborhood',
    ].find(findAddressComponent) ?? 'locality';

  const place = response?.results?.[0] ?? {};
  const { place_id: googlePlaceId, geometry } = place;

  const streetLongName = findAddressComponent('street_number')?.long_name ?? '';
  const routeLongName = findAddressComponent('route')?.long_name ?? '';
  const street = [streetLongName, routeLongName].join(' ').trim();
  const zipCode = findAddressComponent('postal_code')?.long_name ?? '';
  const city = findAddressComponent(cityKey)?.long_name ?? '';
  const state =
    findAddressComponent('administrative_area_level_1')?.short_name ?? '';
  const country = findAddressComponent('country')?.long_name ?? '';
  const { lat = 0, lng = 0 } = geometry?.location ?? {};
  const latitude = typeof lat === 'function' ? 0 : lat;
  const longitude = typeof lng === 'function' ? 0 : lng;
  const formattedAddress = response.results[0]?.formatted_address ?? '';

  return {
    googlePlaceId,
    latitude,
    longitude,
    street,
    zipCode,
    city,
    state,
    country,
    formattedAddress,
  };
};

// ──── Human readable address ─────────────────────────────────
export const formatGoogleAddress = (
  address: Partial<GooglePlacesAddress> & Readonly<{ name?: string | null }>,
  withName?: boolean,
  streetOnly?: boolean,
) => {
  const {
    name = '',
    street = '',
    city = '',
    state = '',
    zipCode = '',
  } = address ?? {};

  const stateAndZipCode = `${state} ${zipCode}`.trim();
  const addressPartials = streetOnly
    ? [street]
    : [street, city, stateAndZipCode];
  const formattedAddress = addressPartials.filter(Boolean).join(', ');
  const shouldAddName = Boolean(withName && name);

  if (!formattedAddress) return '';

  return shouldAddName ? `${name} · ${formattedAddress}` : formattedAddress;
};

const isValidType = (type: string) => {
  return validTypes.has(type);
};

// ─── Constants ───────────────────────────────────────────────────────────────

// @see https://developers.google.com/maps/documentation/places/web-service/supported_types
const validTypes = new Set(['street_address', 'premise', 'subpremise']);

// ──── Types ──────────────────────────────────────────────────

export type GooglePlacesAddress = Readonly<{
  googlePlaceId: string;
  latitude: number;
  longitude: number;
  street: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  formattedAddress: string;
}>;
