import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { IngredientCardV2 } from '@sg/garnish';
import { type IngredientModification } from '@sg/graphql-schema';

import { useDietaryRestrictions } from '@order/components';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductDetailsIngredientCard = (
  props: ProductDetailsIngredientCardProps,
) => {
  const { ingredientModification, quantity, onIncrement, onDecrement } = props;

  const { formatMessage } = useIntl();
  const getRestrictionNotice = useDietaryRestrictions();

  // ─────────────────────────────────────────────────────────────────────

  const dietaryRestrictionNote = getRestrictionNotice(
    ingredientModification.ingredient.dietaryProperties,
  );
  const accessibilityLabel = formatMessage(messages.a11yCardLabel, {
    quantity,
    ingredient_name: ingredientModification.ingredient.name,
  });

  // ─────────────────────────────────────────────────────────────────────

  const handleIncrement = useCallback(() => {
    onIncrement(ingredientModification);
  }, [onIncrement, ingredientModification]);

  const handleDecrement = useCallback(() => {
    onDecrement(ingredientModification);
  }, [onDecrement, ingredientModification]);

  return (
    <IngredientCardV2.Pressable
      disabled={ingredientModification.outOfStock}
      onPress={handleIncrement}
      accessibilityLabel={accessibilityLabel}
    >
      <IngredientCardV2.ImageContainer>
        <IngredientCardV2.Image
          source={ingredientModification.ingredient.asset.url}
          accessibilityLabel={ingredientModification.ingredient.name}
        />

        {quantity && !ingredientModification.outOfStock ? (
          <IngredientCardV2.QuantityStepper.Container>
            <IngredientCardV2.QuantityStepper.StepperButton
              variation="decrease"
              onPress={handleDecrement}
              accessibilityLabel={formatMessage(messages.a11yDecrementLabel)}
            />
            <IngredientCardV2.QuantityStepper.Label quantity={quantity} />
            <IngredientCardV2.QuantityStepper.StepperButton
              variation="increase"
              onPress={handleIncrement}
              accessibilityLabel={formatMessage(messages.a11yIncrementLabel)}
            />
          </IngredientCardV2.QuantityStepper.Container>
        ) : null}
      </IngredientCardV2.ImageContainer>

      <IngredientCardV2.Label>
        {ingredientModification.ingredient.name}
      </IngredientCardV2.Label>

      {ingredientModification.outOfStock ? (
        <IngredientCardV2.NoticeLabel>
          <FormattedMessage {...messages.unavailableLabel} />
        </IngredientCardV2.NoticeLabel>
      ) : null}

      {dietaryRestrictionNote && !ingredientModification.outOfStock ? (
        <IngredientCardV2.NoticeLabel>
          {dietaryRestrictionNote}
        </IngredientCardV2.NoticeLabel>
      ) : null}
    </IngredientCardV2.Pressable>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  a11yCardLabel: {
    defaultMessage: `{quantity, select,
      0 {Add {ingredient_name}}
      other {Increase quantity of {ingredient_name} (current quantity: {quantity})}
    }`,
    description: 'Customization | Ingredient card | a11y label',
  },
  a11yIncrementLabel: {
    defaultMessage: 'Increase product quantity',
    description:
      'Customization | Ingredient card increment button | a11y label',
  },
  a11yDecrementLabel: {
    defaultMessage: 'Decrease product quantity',
    description:
      'Customization | Ingredient card decrement button | a11y label',
  },
  unavailableLabel: {
    defaultMessage: 'Unavailable',
    description: 'Customization | Ingredient card | Unavailable label',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ProductDetailsIngredientCardProps = {
  ingredientModification: IngredientModification;
  onIncrement: (ingredientModification: IngredientModification) => void;
  onDecrement: (ingredientModification: IngredientModification) => void;
  quantity: number;
  baseIngredientsCount: number;
};
