import { useCallback, useEffect } from 'react';
import { logger as LOG } from '@garnish/logger';
import { useActor } from '@xstate/react';
import { DietaryRestriction } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  dietaryRestrictionsModel,
  type DietaryRestrictionsTypes,
} from '@order/components';
import { useGlobalAppState } from '@order/GlobalAppState';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  useCustomerPreferences,
  useUpdateCustomerPreferences,
} from '../useCustomerPreferences';
import { getLocalDietaryRestrictions } from './dietary-restrictions-local-storage';

/**
 * Historically we have stored the customer's dietary restrictions in local storage.
 * This hook updates the remote database with the local dietary restrictions.
 * Once there are dietary restrictions in the database, this will no longer overwrite the remote data.
 * Locally we have a state machine for the dietary restrictions, this hook updates it with remote data once per app load.
 */
export const useSyncDietaryRestrictions = () => {
  const isLoggedIn = useIsLoggedIn();

  const isRemoteDietaryRestrictionsEnabled = useFeatureFlag(
    'CELS-3623-remote-dietary-restrictions-enabled',
  );

  // ─── Machine State ─────────────────────────────────────────────────────────

  const { dietaryRestrictionsMachineRef } = useGlobalAppState();
  const [, send] = useActor(dietaryRestrictionsMachineRef);

  const setRestrictions = useCallback(
    (updatedRestrictions: Partial<DietaryRestrictionsTypes>) => {
      send(
        dietaryRestrictionsModel.events.SET_RESTRICTIONS(updatedRestrictions),
      );
    },
    [send],
  );

  const loadInitialRestrictions = useCallback(
    (updatedRestrictions: Partial<DietaryRestrictionsTypes>) => {
      send(
        dietaryRestrictionsModel.events.LOAD_INITIAL_RESTRICTIONS(
          updatedRestrictions,
        ),
      );
    },
    [send],
  );

  // ─── Remote Data ───────────────────────────────────────────────────────────

  const { updateCustomerPreferences } = useUpdateCustomerPreferences();
  const { preferences, isLoadingPreferences } = useCustomerPreferences({
    requestPolicy: 'network-only',
    pause: !isRemoteDietaryRestrictionsEnabled,
  });

  const dietaryRestrictions = preferences?.dietaryRestrictions;
  const hasSyncedRestrictions = Boolean(preferences?.hasSyncedRestrictions);

  // ─── Synchronization ───────────────────────────────────────────────────────

  const synchronizeDietaryRestrictions = useCallback(async () => {
    // Exit when feature flag is disabled.
    if (!isRemoteDietaryRestrictionsEnabled) return;

    // Use local restrictions if the customer is logged out.
    if (!isLoggedIn) {
      const localRestrictions = await getLocalDietaryRestrictions();

      setRestrictions(getRestrictionsAsMap(localRestrictions));

      return;
    }

    // Wait until remote restrictions have been loaded.
    if (isLoadingPreferences) {
      return;
    }

    // Always use remote restrictions in machine state if they have been synced before.
    if (hasSyncedRestrictions) {
      loadInitialRestrictions(getRestrictionsAsMap(dietaryRestrictions ?? []));

      return;
    }

    const localRestrictions = await getLocalDietaryRestrictions();

    // If the local restrictions have not been synced, use them in the machine.
    loadInitialRestrictions(getRestrictionsAsMap(localRestrictions));

    // Update the remote database with the local restrictions.
    await updateCustomerPreferences({ dietaryRestrictions: localRestrictions });

    logger.info('Updated remote dietary restrictions', { localRestrictions });
  }, [
    dietaryRestrictions,
    hasSyncedRestrictions,
    isLoadingPreferences,
    isLoggedIn,
    isRemoteDietaryRestrictionsEnabled,
    setRestrictions,
    loadInitialRestrictions,
    updateCustomerPreferences,
  ]);

  // ─── Synchronization Trigger ───────────────────────────────────────────────

  useEffect(() => {
    void synchronizeDietaryRestrictions();
  }, [synchronizeDietaryRestrictions]);
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Converts the list of restrictions to a map of every restrictions,
 * It uses the {DietaryRestriction} enum to build a map with true/false values.
 */
const getRestrictionsAsMap = (restrictions: readonly DietaryRestriction[]) => {
  const enabledSet = new Set(restrictions);

  return Object.values(DietaryRestriction)
    .map((restriction) => ({ [restriction]: enabledSet.has(restriction) }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
};

// ─── Constants ───────────────────────────────────────────────────────────────

LOG.enable('RemoteDietaryRestrictions');
const logger = LOG.extend('RemoteDietaryRestrictions');
