import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@sg/garnish';

import { Modal } from '../Modal';
import { DebugMenu } from './DebugMenu';

// ─────────────────────────────────────────────────────────────────────────────

export const DebugMenuModal = (props: DebugMenuModalProps) => {
  const { visible, onRequestClose } = props;

  const { formatMessage } = useIntl();
  const { match, currentBreakpoint } = useResponsive();

  return (
    <Modal
      visible={visible}
      accessibilityLabel={formatMessage(messages.title)}
      fitHeight={currentBreakpoint.isXS}
      size={match(['full', 'medium'])}
      onRequestClose={onRequestClose}
    >
      <Modal.Header onClose={onRequestClose}>
        <FormattedMessage {...messages.title} />
      </Modal.Header>

      <DebugMenu />
    </Modal>
  );
};

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Open debug menu',
    description: 'Opens the debug menu',
  },
  title: {
    defaultMessage: 'Debug menu',
    description: 'Debug menu title',
  },
});

type DebugMenuModalProps = Readonly<{
  visible: boolean;
  onRequestClose: () => void;
}>;
