import React, { useCallback } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useInaccessibleElement } from '@sg/garnish';

import { useBagCount, useLoyaltyAvailablePoints } from '@order/hooks';
import { useNavigateToLastInteractedStore } from '@order/LastInteractedStore';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { Navbar as NavbarComponents } from '@order/navigation';
import { useTelemetry } from '@order/Telemetry';

export const Navbar = () => {
  const { track } = useTelemetry();
  const { navigate } = useNavigation();
  const navigateLastStore = useNavigateToLastInteractedStore();

  // ─── Accessibility ─────────────────────────────────────────────────────────
  // Here we add the "accessibilityHidden" prop when the navbar isn't focused.
  // This is because with react-navigation we'll render this navbar multiple times.

  const isVisible = useIsFocused();
  const a11yProps = useInaccessibleElement(!isVisible);

  // ─── Flags ─────────────────────────────────────────────────────────────────

  const showGift = useFeatureFlag('CELS-1449-in-app-gift-cards-enabled');

  // ─── Remote Data ───────────────────────────────────────────────────────────

  const points = useLoyaltyAvailablePoints();
  const { bagCount } = useBagCount();

  // ─── Callbacks ─────────────────────────────────────────────────────────────

  const navigateHome = useCallback(() => {
    track('home.jump_nav');
    navigate('MainTabs', { screen: 'HomeTab', params: { screen: 'Home' } });
  }, [track, navigate]);

  const navigateLoyalty = useCallback(() => {
    track('loyalty.jump_nav');
    navigate('MainTabs', { screen: 'LoyaltyTab', params: { screen: 'LoyaltyHome' } }); // prettier-ignore
  }, [track, navigate]);

  const navigateGift = useCallback(() => {
    track('gift.jump_nav');
    navigate('MainTabs', { screen: 'GiftCardsTab', params: { screen: 'GiftCards' } }); // prettier-ignore
  }, [track, navigate]);

  const navigateMenu = useCallback(() => {
    track('menu.jump_nav', {});
    navigateLastStore();
  }, [track, navigateLastStore]);

  const navigateOrder = useCallback(() => {
    track('order.jump_nav');
    navigateLastStore();
  }, [track, navigateLastStore]);

  const navigateBag = useCallback(() => {
    track('bag.jump_nav');
    navigate('Modal', { screen: 'Bag' });
  }, [navigate, track]);

  const navigateAccount = useCallback(() => {
    track('account.jump_nav');
    navigate('MainTabs', { screen: 'AccountTab', params: undefined as never }); // prettier-ignore
  }, [track, navigate]);

  // ───────────────────────────────────────────────────────────────────────────

  return (
    <NavbarComponents.Containers.Main {...a11yProps}>
      <NavbarComponents.Containers.Left>
        {showGift ? <NavbarComponents.Gift onPress={navigateGift} /> : null}
        <NavbarComponents.Loyalty points={points} onPress={navigateLoyalty} />
        <NavbarComponents.Menu onPress={navigateMenu} />
      </NavbarComponents.Containers.Left>

      <NavbarComponents.Home onPress={navigateHome} />

      <NavbarComponents.Containers.Right>
        <NavbarComponents.Order onPress={navigateOrder} />
        <NavbarComponents.Bag count={bagCount} onPress={navigateBag} />
        <NavbarComponents.Account onPress={navigateAccount} />
      </NavbarComponents.Containers.Right>
    </NavbarComponents.Containers.Main>
  );
};
