/* istanbul ignore file */

import type { ComponentProps } from 'react';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { type LayoutChangeEvent } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { Region } from '@sg/garnish';
import { findCenterRegion, useResponsive } from '@sg/garnish';

import { useCustomer } from '@order/Customer';

import { LocationsMap } from '../../../LocationsMap';
import { useNewLocationsLayout } from '../../hooks';
import type { Location, LocationSearchMachineContext } from '../../machine';
import type { LocationNavigationEntryPoint } from '../../machine';
import {
  generateMapPinsForLocations,
  getActiveLocationsCoordinates,
} from './LocationSearchMap.utils';

// ─────────────────────────────────────────────────────────────────────────────

export const LocationSearchMap = memo((props: LocationSearchMapProps) => {
  const {
    variation = 'standard',
    locationSearchType,
    locations,
    deliveryLocation,
    currentPosition,
    focusedPinId,
    isLoadingLocations,
    onSearchAreaPress,
    onGetCurrentPosition,
    onLocationsSearchAreaChange,
    onMapPinPress,
    onLocationPinPress,
    onDeliveryPinPress,
    onRecentOrNearbyLocationPinPress,
  } = props;

  const isScreenFocused = useIsFocused();
  const { customer } = useCustomer();

  const customerName = customer?.firstName ?? '';
  const isDeliveryLocationSearchType = locationSearchType === 'delivery';

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldUseNewLocationsLayout = useNewLocationsLayout();

  // ─── State ───────────────────────────────────────────────────────────

  const [mapDimensions, setMapDimensions] = useState({ width: 0, height: 0 });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const { match } = useResponsive();

  const US_REGION = match([US_REGION_XS, US_REGION_SM]);

  const updateMapDimensions = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;

    setMapDimensions({ width, height });
  }, []);

  const clearFocusedPinId = useCallback(() => {
    onMapPinPress(undefined);
  }, [onMapPinPress]);

  const handleOnCalloutPress = useCallback<OnPinCalloutPress>(
    ({ restaurantSlug }) => {
      if (!restaurantSlug) return;

      onLocationPinPress({ restaurantSlug }, { entryPoint: 'pin' });
    },
    [onLocationPinPress],
  );

  const handleOnDeliveryCalloutPress = useCallback<OnPinCalloutPress>(
    ({ restaurantSlug }) => {
      if (!restaurantSlug) return;

      onDeliveryPinPress?.({ restaurantSlug }, { entryPoint: 'pin' });
    },
    [onDeliveryPinPress],
  );

  const handleRecentOrNearbyLocationCalloutPress =
    useCallback<OnPinCalloutPress>(
      (locationDetails) => {
        const { restaurantId } = locationDetails;

        if (!restaurantId) return;

        const pickupLocations = locations?.pickup ?? [];
        const outpostLocations = locations?.outpost ?? [];

        const allLocations = [...pickupLocations, ...outpostLocations];
        const targetLocation = allLocations.find(
          (location) => location.restaurantId === restaurantId,
        );

        if (!targetLocation) return;

        onRecentOrNearbyLocationPinPress?.({
          location: targetLocation,
          options: { entryPoint: 'pin' },
        });
      },
      [locations?.outpost, locations?.pickup, onRecentOrNearbyLocationPinPress],
    );

  const onCalloutPress = useCallback<OnPinCalloutPress>(
    (locationDetails) => {
      if (variation === 'recent-and-nearby') {
        handleRecentOrNearbyLocationCalloutPress(locationDetails);

        return;
      }

      if (isDeliveryLocationSearchType) {
        handleOnDeliveryCalloutPress(locationDetails);

        return;
      }

      handleOnCalloutPress(locationDetails);
    },
    [
      handleOnCalloutPress,
      handleOnDeliveryCalloutPress,
      handleRecentOrNearbyLocationCalloutPress,
      isDeliveryLocationSearchType,
      variation,
    ],
  );

  const handleOnSearchAreaPress = isDeliveryLocationSearchType
    ? undefined
    : onSearchAreaPress;

  // ─── Derived Data ────────────────────────────────────────────────────

  const activeLocationsCoordinates = useMemo(() => {
    const activeLocations = getActiveLocationsCoordinates({
      locationSearchType,
      locations,
      deliveryLocation,
    });

    // include customer's current position (if available)
    if (currentPosition) {
      const customerCurrentPosition = {
        lat: currentPosition.latitude,
        lng: currentPosition.longitude,
      };

      return [...activeLocations, customerCurrentPosition];
    }

    return activeLocations;
  }, [currentPosition, deliveryLocation, locationSearchType, locations]);

  const pins = useMemo(() => {
    return generateMapPinsForLocations({
      currentPosition,
      locationSearchType,
      customerName,
      locations,
      deliveryLocation,
      focusedPinId,
    });
  }, [
    currentPosition,
    customerName,
    deliveryLocation,
    focusedPinId,
    locationSearchType,
    locations,
  ]);

  const region = useMemo(() => {
    if (activeLocationsCoordinates.length === 0) return;

    const pinsRegion = findCenterRegion(
      activeLocationsCoordinates,
      mapDimensions,
    );

    const isValidRegion =
      !Number.isNaN(pinsRegion.lat) && !Number.isNaN(pinsRegion.lng);

    return isValidRegion ? pinsRegion : US_REGION;
  }, [activeLocationsCoordinates, mapDimensions, US_REGION]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container} onLayout={updateMapDimensions}>
      {isScreenFocused ? (
        <LocationsMap
          pins={pins}
          isAutoFitBoundsEnabled={shouldUseNewLocationsLayout}
          isOffsetEnabled={shouldUseNewLocationsLayout}
          edgePadding={MAP_EDGE_PADDING}
          region={region ?? US_REGION}
          isLoadingMapLocations={isLoadingLocations}
          isSearchAreaBtnEnabled={locationSearchType !== 'delivery'}
          onRegionChange={onLocationsSearchAreaChange}
          onSearchAreaPress={handleOnSearchAreaPress}
          onGeolocateUserBtnPress={onGetCurrentPosition}
          onMapPress={clearFocusedPinId}
          onCalloutPress={onCalloutPress}
          onPinPress={onMapPinPress}
        />
      ) : null}
    </View>
  );
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const US_REGION_XS = { lat: 37.0902, lng: -95.7129, zoom: 3.2 }; // for mobile
const US_REGION_SM = { lat: 37.0902, lng: -95.7129, zoom: 4.8 }; // for desktop

const MAP_EDGE_PADDING: ComponentProps<typeof LocationsMap>['edgePadding'] = {
  top: 100,
  bottom: 220,
};

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationSearchMapProps = Readonly<{
  variation?: 'recent-and-nearby' | 'standard';
  locationSearchType: LocationSearchMachineContext['locationSearchType'];
  locations: LocationSearchMachineContext['locations'];
  deliveryLocation: LocationSearchMachineContext['deliveryLocation'];
  currentPosition: LocationSearchMachineContext['currentPosition'];
  focusedPinId: string | undefined;
  isLoadingLocations: boolean;
  onLocationsSearchAreaChange: (region: Region) => void;
  onSearchAreaPress: () => void;
  onGetCurrentPosition: () => void;
  onMapPinPress: (id: string | undefined) => void;

  // NOTE: Handles recent or nearby location pin press.
  //
  //       The logic differs from standard locations in that we no longer rely
  //       on the user-selected order channel and bypass several extra phases
  //       such as outpost warning, which are required for standard locations.
  onRecentOrNearbyLocationPinPress?: (params: {
    location: Location;
    options?: {
      entryPoint?: LocationNavigationEntryPoint;
    };
  }) => void;
  onLocationPinPress: (
    location: { restaurantSlug: string },
    options?: {
      entryPoint?: LocationNavigationEntryPoint;
    },
  ) => void;
  onDeliveryPinPress: (
    location: { restaurantSlug: string },
    options?: {
      entryPoint?: LocationNavigationEntryPoint;
    },
  ) => void;
}>;

type OnPinCalloutPress = NonNullable<
  ComponentProps<typeof LocationsMap>['onCalloutPress']
>;
