/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { ValidationErrorFieldsFragmentDoc } from '../../../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderFieldsFragment = {
  readonly __typename: 'Order';
  readonly id: string;
  readonly orderType: Types.OrderType;
  readonly wantedTime: string;
  readonly description: string;
  readonly total: number | null;
  readonly lineItems: ReadonlyArray<{
    readonly __typename: 'LineItem';
    readonly id: string;
    readonly customName: string | null;
    readonly isCustom: boolean;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly name: string;
      readonly isCustom: boolean;
      readonly categoryName: string;
    };
  }>;
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
  readonly restaurant: {
    readonly __typename: 'Restaurant';
    readonly id: string;
    readonly name: string;
    readonly isOutpost: boolean;
  };
  readonly deliveryOrderDetail: {
    readonly __typename: 'DeliveryOrderDetail';
    readonly id: string;
    readonly address: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};

export type OrderHistoryQueryVariables = Types.Exact<{
  input: Types.OrdersInput;
}>;

export type OrderHistoryQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly pagination: {
          readonly __typename?: 'Pagination';
          readonly total: number;
          readonly last: number;
        };
        readonly orders: ReadonlyArray<{
          readonly __typename: 'Order';
          readonly canCancel: boolean;
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly wantedTime: string;
          readonly description: string;
          readonly total: number | null;
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly customName: string | null;
            readonly isCustom: boolean;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
            };
          }>;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type OrdersV2QueryVariables = Types.Exact<{
  input: Types.OrdersInputV2;
}>;

export type OrdersV2Query = {
  readonly __typename?: 'Query';
  readonly ordersV2:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly pagination: {
          readonly __typename?: 'Pagination';
          readonly total: number;
          readonly last: number;
        };
        readonly orders: ReadonlyArray<{
          readonly __typename: 'Order';
          readonly isCanceled: boolean;
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly wantedTime: string;
          readonly description: string;
          readonly total: number | null;
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly customName: string | null;
            readonly isCustom: boolean;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
            };
          }>;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly name: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type CanCancelOrdersQueryVariables = Types.Exact<{
  input: ReadonlyArray<Types.CanCancelInput> | Types.CanCancelInput;
}>;

export type CanCancelOrdersQuery = {
  readonly __typename?: 'Query';
  readonly canCancel: ReadonlyArray<
    | {
        readonly __typename: 'CanCancel';
        readonly orderId: number;
        readonly canCancel: boolean;
      }
    | { readonly __typename?: 'CanCancelAlreadySent' }
    | { readonly __typename?: 'CanCancelFailed' }
    | { readonly __typename?: 'CanCancelNotFound' }
    | { readonly __typename?: 'CanCancelUnauthorized' }
    | { readonly __typename?: 'CantCancelAlreadyCanceled' }
    | { readonly __typename?: 'CantCancelLimitExcceeded' }
    | { readonly __typename?: 'CantCancelOldOrders' }
  >;
};

export type CanCancelOrdersV2QueryVariables = Types.Exact<{
  input: ReadonlyArray<Types.CanCancelInput> | Types.CanCancelInput;
}>;

export type CanCancelOrdersV2Query = {
  readonly __typename?: 'Query';
  readonly canCancelV2: ReadonlyArray<
    | {
        readonly __typename: 'CanCancel';
        readonly orderId: number;
        readonly canCancel: boolean;
      }
    | { readonly __typename?: 'CanCancelAlreadySent' }
    | { readonly __typename?: 'CanCancelFailed' }
    | { readonly __typename?: 'CanCancelNotFound' }
    | { readonly __typename?: 'CanCancelUnauthorized' }
    | { readonly __typename?: 'CantCancelAlreadyCanceled' }
    | { readonly __typename?: 'CantCancelLimitExcceeded' }
    | { readonly __typename?: 'CantCancelOldOrders' }
  >;
};

export const OrderFieldsFragmentDoc = gql`
  fragment OrderFields on Order {
    id
    __typename
    orderType
    wantedTime
    description
    total
    lineItems {
      id
      __typename
      customName
      isCustom
      product {
        id
        __typename
        name
        isCustom
        categoryName
      }
    }
    asset {
      url
    }
    restaurant {
      id
      __typename
      name
      isOutpost
    }
    deliveryOrderDetail {
      id
      __typename
      address {
        id
        __typename
        name
      }
    }
  }
`;
export const OrderHistoryDocument = gql`
  query orderHistory($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        pagination {
          total
          last
        }
        orders {
          ...OrderFields
          canCancel
        }
      }
      ...ValidationErrorFields
    }
  }
  ${OrderFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useOrderHistoryQuery(
  options: Omit<Urql.UseQueryArgs<OrderHistoryQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrderHistoryQuery, OrderHistoryQueryVariables>({
    query: OrderHistoryDocument,
    ...options,
  });
}
export const OrdersV2Document = gql`
  query ordersV2($input: OrdersInputV2!) {
    ordersV2(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        pagination {
          total
          last
        }
        orders {
          ...OrderFields
          isCanceled
        }
      }
      ... on ValidationError {
        fieldErrors {
          field
          message
        }
      }
    }
  }
  ${OrderFieldsFragmentDoc}
`;

export function useOrdersV2Query(
  options: Omit<Urql.UseQueryArgs<OrdersV2QueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrdersV2Query, OrdersV2QueryVariables>({
    query: OrdersV2Document,
    ...options,
  });
}
export const CanCancelOrdersDocument = gql`
  query CanCancelOrders($input: [CanCancelInput!]!) {
    canCancel(input: $input) {
      ... on CanCancel {
        __typename
        orderId
        canCancel
      }
    }
  }
`;

export function useCanCancelOrdersQuery(
  options: Omit<Urql.UseQueryArgs<CanCancelOrdersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CanCancelOrdersQuery, CanCancelOrdersQueryVariables>({
    query: CanCancelOrdersDocument,
    ...options,
  });
}
export const CanCancelOrdersV2Document = gql`
  query CanCancelOrdersV2($input: [CanCancelInput!]!) {
    canCancelV2(input: $input) {
      ... on CanCancel {
        __typename
        orderId
        canCancel
      }
    }
  }
`;

export function useCanCancelOrdersV2Query(
  options: Omit<Urql.UseQueryArgs<CanCancelOrdersV2QueryVariables>, 'query'>,
) {
  return Urql.useQuery<CanCancelOrdersV2Query, CanCancelOrdersV2QueryVariables>(
    { query: CanCancelOrdersV2Document, ...options },
  );
}
