import type {
  RailItemsMeasurementsSV,
  RailMeasurementsSV,
  TargetItemIndexSV,
} from '../../../HorizontalScrollRail.types';

// ─────────────────────────────────────────────────────────────────────────────

export function getNextItemScrollOffset(params: Params) {
  'worklet';

  const {
    nextItemIndexSV,
    outerOffset,
    railItemsMeasurementsSV,
    railMeasurementsSV,
  } = params;

  const targetItemMeasurements =
    railItemsMeasurementsSV.value[nextItemIndexSV.value];

  if (!targetItemMeasurements) return;

  const targetItemX = targetItemMeasurements.x;
  const targetItemWidth = targetItemMeasurements.width;
  const targetItemEndX = targetItemX + targetItemWidth;
  const railWidth = railMeasurementsSV.value?.width ?? 0;

  return targetItemEndX - railWidth - outerOffset;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Params = {
  nextItemIndexSV: TargetItemIndexSV;
  railItemsMeasurementsSV: RailItemsMeasurementsSV;
  railMeasurementsSV: RailMeasurementsSV;
  outerOffset: number;
};
