import React, { useCallback, useRef } from 'react';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type View,
  type ViewStyle,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { theme } from '@garnish/constants';
import { ImpactFeedbackStyle } from 'expo-haptics';

import { useUniqueNativeID } from '../../../../hooks';
import { triggerHapticFeedback, webOnlyStyles } from '../../../../utils';
import { BodyText } from '../../../Text';
import { type RadioSize, sizeStyles } from './RadioItem.config';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Renders a `Pressable` component with the `'radio'` role and multiple
 * customization options.
 */

export const RadioItem = <Value extends string>(
  props: RadioContainerItemProps<Value>,
) => {
  const {
    value,
    size = 'md',
    label,
    isSelected,
    isDisabled,
    accessibilityLabel,
    'aria-label': ariaLabel,
    onPress,
  } = props;

  const pressableContainerRef = useRef<View>(null);
  const isHovered = useHover(pressableContainerRef);
  const labelNativeId = useUniqueNativeID();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    onPress(value);
    void triggerHapticFeedback(ImpactFeedbackStyle.Light);
  }, [onPress, value]);

  const conditionalOnPress = isSelected || isDisabled ? null : handleOnPress;

  // ─── Styles ──────────────────────────────────────────────────────────

  const labelStyles = [
    styles.label,
    labelWebStyles,
    isSelected ? styles.labelSelected : undefined,
    isDisabled ? styles.labelDisabled : undefined,
  ];

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        sizeStyles[size],
        pressableWebStyles,
        isSelected ? styles.pressableChecked : undefined,
        isHovered && !isSelected ? styles.pressableHover : undefined,
        isDisabled ? styles.pressableDisabled : undefined,
        pressed ? styles.pressablePressed : undefined,
      ];
    },
    [size, isSelected, isHovered, isDisabled],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={pressableContainerRef}
      accessibilityRole="radio"
      accessibilityState={{
        selected: !isDisabled && isSelected,
        disabled: isDisabled,
      }}
      accessibilityLabel={accessibilityLabel}
      aria-label={ariaLabel}
      aria-selected={!isDisabled && isSelected}
      disabled={isDisabled}
      style={pressableStyles}
      onPress={conditionalOnPress}
    >
      <BodyText nativeID={labelNativeId} style={labelStyles} sizeMatch={['18']}>
        {label}
      </BodyText>
    </Pressable>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    padding: theme.spacing['4'],
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
    minHeight: 72,
    userSelect: 'none',
  },
  pressableHover: {
    backgroundColor: theme.colors.OPACITY.KALE.LIGHTER,
  },
  pressableChecked: {
    backgroundColor: theme.colors.KALE,
  },
  pressablePressed: {
    opacity: 0.7,
  },
  pressableDisabled: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },

  label: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.colors.TEXT_COLOR,
  },
  labelSelected: {
    color: theme.colors.CREAM,
  },
  labelDisabled: {
    color: theme.colors.OPACITY.DARK_KALE.DARK,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: theme.transitions.presets.colorsAndOpacity,
});
const labelWebStyles = webOnlyStyles({
  transition: theme.transitions.presets.colors,
});

type RadioContainerItemProps<Value extends string> = {
  label: string;
  accessibilityLabel?: string;
  'aria-label'?: string;
  value: Value;
  isSelected: boolean;
  isDisabled?: boolean;
  onPress: (value: Value) => void;
  size?: RadioSize;
};
