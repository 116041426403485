import React, { memo, type PropsWithChildren } from 'react';
import { useStyle } from 'react-native-style-utilities';

import { HStack } from '../../../Stack';

// ─────────────────────────────────────────────────────────────────────────────

export const LoadingPlaceholderRow = memo(
  (props: LoadingPlaceholderRowProps) => {
    const { index, gridGap, rowHeight, columnsNumber, children } = props;

    const currentRowHeight = getRowHeight(rowHeight, index);
    const rowStyles = useStyle(
      () => ({ height: currentRowHeight }),
      [rowHeight],
    );

    return (
      <HStack
        key={index}
        style={rowStyles}
        itemsPerRow={columnsNumber === 'auto' ? undefined : columnsNumber}
        testID="sg-loading-placeholder-row"
        gap={gridGap}
      >
        {children}
      </HStack>
    );
  },
);

// ─── Utils ───────────────────────────────────────────────────────────────────

function getRowHeight(rowHeight: RowHeight | undefined, rowIndex: number) {
  if (!rowHeight) {
    return ROW_DEFAULT_HEIGHT;
  }

  // Variable heights case.
  if (Array.isArray(rowHeight)) {
    return rowHeight[rowIndex] ?? rowHeight.at(-1) ?? ROW_DEFAULT_HEIGHT;
  }

  // Standard case.
  return rowHeight;
}

// ─── Constants ───────────────────────────────────────────────────────────────

const ROW_DEFAULT_HEIGHT = 100;

// ─── Types ───────────────────────────────────────────────────────────────────

type LoadingPlaceholderRowProps = PropsWithChildren<{
  index: number;
  gridGap: number;
  rowHeight?: RowHeight;
  columnsNumber?: number | 'auto';
}>;

type RowHeight = number | number[];
