import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { ScrollViewWithHeaderTitle, useResponsive } from '@sg/garnish';

export const ContainerWrapperLayout = (props: PropsWithChildren) => {
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return (
      <ScrollViewWithHeaderTitle contentContainerStyle={styles.wrapperXS}>
        {props.children}
      </ScrollViewWithHeaderTitle>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.wrapperSM}>
      {props.children}
    </ScrollView>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapperXS: {
    flexGrow: 1,
  },
  wrapperSM: {
    height: '100%',
  },
});
