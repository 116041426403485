/* eslint-disable react/style-prop-object */

import React, {
  type ComponentProps,
  type PropsWithChildren,
  useCallback,
} from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { Platform, StyleSheet, View } from 'react-native';
import { type To } from '@react-navigation/native/lib/typescript/src/useLinkTo';
import { IconLink, ProductCardV2 } from '@sg/garnish';

import { useDietaryRestrictions } from '@order/components';
import { MenuCategoriesGrid } from '@order/components/menu';
import { telemetryProductFromInput, useTelemetry } from '@order/Telemetry';

import { useOneClickAddToBag } from '../../hooks';
import {
  type MenuContentDeliveryDetailsData,
  type MenuContentProduct,
} from '../../MenuContent.types';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuContentProductCard = (props: MenuContentProductCardProps) => {
  const {
    product,
    restaurantSlug,
    deliveryDetails,
    to,
    imageFetchingPriority,
  } = props;

  const {
    dietaryProperties,
    calories,
    isModifiable,
    name,
    description,
    outOfStock,
    asset,
    label,
    cost,
  } = product;

  const getRestrictionNotice = useDietaryRestrictions();
  const { formatMessage } = useIntl();
  const { trackEvent } = useTelemetry();

  // ─── Derived Data ────────────────────────────────────────────────────

  const restrictionNotice = getRestrictionNotice(dietaryProperties);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const {
    addProductToBag,
    isAddingProductToBag,
    checkIfSupportsOneClickAddToBag,
  } = useOneClickAddToBag({ restaurantSlug, deliveryDetails });

  const handleOneClickAddToBag = useCallback(async () => {
    await addProductToBag?.(product, 1);
  }, [addProductToBag, product]);

  const trackCardPress = useCallback(() => {
    const selectedProduct = telemetryProductFromInput({
      ...product,
      location: restaurantSlug,
    });

    trackEvent({
      name: 'pdp.view',
      payload: { entryPoint: 'menu', selectedProduct },
    });
  }, [product, restaurantSlug, trackEvent]);

  // ─── Flags ───────────────────────────────────────────────────────────

  const isProductAvailable = !outOfStock;
  const isProductSupportsOneClickAddToBag =
    checkIfSupportsOneClickAddToBag(isModifiable);
  const isNavigationEnabled =
    isProductAvailable && !isProductSupportsOneClickAddToBag;

  // On the web platform, customers can add products to their bags with a
  // single click by pressing the entire product card or the standard 1-click
  // CTA button.
  const shouldUsePressableVariation =
    isProductAvailable &&
    Platform.OS === 'web' &&
    isProductSupportsOneClickAddToBag;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.cardContainer}>
      <MenuContentProductCardContainer
        to={to}
        onPress={trackCardPress}
        onQuickAddToBag={handleOneClickAddToBag}
        isLoading={isAddingProductToBag}
        isNavigationEnabled={isNavigationEnabled}
        productName={name}
        variation={shouldUsePressableVariation ? 'pressable' : 'link'}
      >
        <ProductCardV2.Container
          canHover={isNavigationEnabled || shouldUsePressableVariation}
          style={styles.container}
        >
          {asset.url ? (
            <ProductCardV2.Image
              source={asset.url}
              alt={name}
              isUnavailable={outOfStock}
              priority={imageFetchingPriority}
            />
          ) : null}

          <ProductCardV2.LabelContainer>
            {isProductAvailable && label?.name ? (
              <ProductCardV2.Label>{label.name}</ProductCardV2.Label>
            ) : null}

            {isProductAvailable ? null : (
              <ProductCardV2.UnavailableLabel>
                <FormattedMessage {...messages.unavailableProductLabel} />
              </ProductCardV2.UnavailableLabel>
            )}
          </ProductCardV2.LabelContainer>

          <ProductCardV2.Heading>{name}</ProductCardV2.Heading>
          <ProductCardV2.Description>{description}</ProductCardV2.Description>

          <ProductCardV2.Footer>
            <ProductCardV2.ProductDetailsContainer>
              <ProductCardV2.ProductDetail palette="dark-kale">
                <FormattedNumber
                  value={cost / 100}
                  style="currency"
                  currency="USD"
                />
              </ProductCardV2.ProductDetail>

              {calories === undefined ? null : (
                <ProductCardV2.ProductDetail>
                  <FormattedMessage
                    {...messages.caloriesText}
                    values={{ calories }}
                  />
                </ProductCardV2.ProductDetail>
              )}
            </ProductCardV2.ProductDetailsContainer>

            {restrictionNotice ? (
              <ProductCardV2.AllergenNotice>
                {restrictionNotice}
              </ProductCardV2.AllergenNotice>
            ) : null}
          </ProductCardV2.Footer>
        </ProductCardV2.Container>
      </MenuContentProductCardContainer>

      {isProductSupportsOneClickAddToBag ? (
        <ProductCardV2.AddToBagContainer>
          <IconLink
            name="IconPlus"
            width={40}
            height={40}
            iconSize={30}
            palette="muted"
            onPress={handleOneClickAddToBag}
            isLoading={isAddingProductToBag}
            disabled={!isProductAvailable}
            accessibilityLabel={formatMessage(messages.quickAddToBagA11yLabel, {
              product: name,
            })}
          />
        </ProductCardV2.AddToBagContainer>
      ) : null}
    </View>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const MenuContentProductCardContainer = (
  props: MenuContentProductCardContainerProps,
) => {
  const {
    variation,
    to,
    productName,
    isNavigationEnabled,
    isLoading,
    children,
    onPress,
    onQuickAddToBag,
  } = props;

  if (variation === 'pressable') {
    return (
      <MenuCategoriesGrid.ProductCardPressable
        onPress={onQuickAddToBag}
        isDisabled={isLoading}
        productName={productName}
      >
        {children}
      </MenuCategoriesGrid.ProductCardPressable>
    );
  }

  return (
    <MenuCategoriesGrid.ProductCardLink
      to={to}
      onPress={onPress}
      isNavigationEnabled={isNavigationEnabled}
      productName={productName}
    >
      {children}
    </MenuCategoriesGrid.ProductCardLink>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  caloriesText: {
    defaultMessage: '{calories} cals',
    description: 'Menu | Product card | Calories',
  },
  unavailableProductLabel: {
    defaultMessage: 'Currently unavailable',
    description: 'Menu | Product card | Unavailable product label',
  },
  quickAddToBagA11yLabel: {
    defaultMessage: 'Add 1 {product} to your bag',
    description: 'Menu | Product card | 1-click add to bag | a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cardContainer: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuContentProductCardProps = {
  to: To;
  product: MenuContentProduct;
  restaurantSlug: string;
  deliveryDetails?: MenuContentDeliveryDetailsData;
  imageFetchingPriority: ComponentProps<typeof ProductCardV2.Image>['priority'];
};

type MenuContentProductCardContainerProps = PropsWithChildren<{
  variation: 'link' | 'pressable';
  to: To;
  onQuickAddToBag: () => void;
  onPress?: () => void;
  isLoading: boolean;
  isNavigationEnabled: boolean;
  productName: string;
}>;
