import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Pressable, type PressableProps, StyleSheet } from 'react-native';
import {
  BodyText,
  Icon,
  type IconName,
  theme,
  usePressableState,
} from '@sg/garnish';

import { messages } from '../messages';

export const NavbarBagCta = (props: Props) => {
  const { count, onPress } = props;

  const ref = useRef(null);
  const { isHovered } = usePressableState(ref);
  const { formatMessage } = useIntl();

  const icon: IconName = count > 0 ? 'IconBagFillLime' : 'IconBagStroke';

  return (
    <Pressable
      ref={ref}
      testID="nav-bar.bag"
      accessibilityRole="button"
      aria-label={formatMessage(messages.bagA11y)}
      onPress={onPress}
    >
      <Icon width={24} height={24} name={isHovered ? 'IconBagHover' : icon} />

      {count > 0 ? (
        <BodyText size={5} style={styles.label}>
          {count > 9 ? '9+' : count}
        </BodyText>
      ) : null}
    </Pressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress'> & { count: number };

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  label: {
    position: 'absolute',
    right: 0,
    bottom: 1,
    left: 0,
    textAlign: 'center',
    color: theme.colors.DARK_KALE,
  },
});
