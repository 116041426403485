import React, { useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { COLORS } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { Icon } from '../../Icon';

export const CollapseIndicatorIcon = (props: IconProps) => {
  const {
    collapsed,
    iconName,
    iconColor = COLORS.CHARCOAL,
    iconStrokeWidth = 1,
    style,
    ...rest
  } = props;

  const { currentBreakpoint } = useResponsive();
  const expandedAnimatedValue = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    const rotateDegree = interpolate(
      expandedAnimatedValue.value,
      [0, 1],
      [0, 180],
    );

    return { transform: [{ rotate: `${rotateDegree}deg` }] };
  }, []);

  // rotate icon on {collapsed} prop change
  useLayoutEffect(() => {
    // eslint-disable-next-line functional/immutable-data
    expandedAnimatedValue.value = withTiming(Number(!collapsed), {
      duration: 200,
    });
  }, [collapsed, expandedAnimatedValue]);

  const dynamicIcon = currentBreakpoint.isXS
    ? 'IconCaretRight'
    : 'IconCaretDown';

  return (
    <Animated.View
      style={[styles.container, animatedStyles, style]}
      testID="sg-collapse-indicator-icon"
    >
      <Icon
        name={iconName === undefined ? dynamicIcon : iconName}
        color={iconColor}
        strokeWidth={iconStrokeWidth}
        width={24}
        height={24}
        {...rest}
      />
    </Animated.View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type IconProps = Readonly<{
  style?: React.ComponentProps<typeof Animated.View>['style'];
  collapsed?: boolean;
  iconName?: React.ComponentProps<typeof Icon>['name'];
  iconColor?: React.ComponentProps<typeof Icon>['color'];
  iconStrokeWidth?: React.ComponentProps<typeof Icon>['strokeWidth'];
}> &
  Omit<React.ComponentProps<typeof Icon>, 'name' | 'color' | 'strokeWidth'>;
