import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from '@xstate5/react';
import { parse, useURL } from 'expo-linking';
import { type Challenge } from '@sg/graphql-schema';

import { useHasLoggedOut, useIsLoggedIn } from '@order/AuthMachine';
import { useLoyaltyOffersModalMachine } from '@order/features/loyalty';
import { useGlobalAppState } from '@order/GlobalAppState';
import { useFeatureFlag } from '@order/LaunchDarkly';

import {
  type LoyaltyOffersQuery,
  useLoyaltyOffersQuery,
} from '../graphql/LoyaltyOffers.generated';

// ─────────────────────────────────────────────────────────────────────────────

const useWasOpenedFromHome = () => {
  const appOpenURL = useURL();
  const appOpenPath = appOpenURL && parse(appOpenURL).path;

  return appOpenPath === '' || appOpenPath === '/';
};

export const useLoyaltyOffers = () => {
  const {
    loyaltyOffersModalMachineRef: actorRef,
    postPurchaseModalMachineRef,
  } = useGlobalAppState();

  const { helpers, state, context } = useLoyaltyOffersModalMachine({
    actorRef,
  });

  const { alreadySeenOfferIds } = context;
  const { isReady, isShowingModal, hasAlreadyShowedModal } = state;
  const { showModal, hideModal, resetState } = helpers;

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();

  const isLoyaltyOffersModalEnabled = useFeatureFlag(
    'CELS-2957-home-offers-modal-enabled',
  );
  const shouldIncludeInProgressOffers = useFeatureFlag(
    'CELS-2957-home-in-progress-offers-modal-enabled',
  );

  const isOffersModalEnabled = isLoyaltyOffersModalEnabled && isLoggedIn;

  const wasOpenedFromHome = useWasOpenedFromHome();

  // ─── Remote Data ─────────────────────────────────────────────────────

  const [response] = useLoyaltyOffersQuery({
    requestPolicy: 'network-only',
    pause: !isOffersModalEnabled || hasAlreadyShowedModal,
  });

  // ─── Post Purchase Modal Dependency ──────────────────────────────────

  const isShowingPostPurchaseModal = useSelector(
    postPurchaseModalMachineRef,
    (snapshot) => snapshot.matches('showing'),
  );

  // ─── Derived Data ────────────────────────────────────────────────────

  const offers = useMemo(
    () => response.data?.challenges ?? OFFERS_FALLBACK,
    [response.data?.challenges],
  );

  const filteredOffers = useMemo(() => {
    const newChallenges = offers.filter(checkIfOfferIsNew);
    const inProgressChallenges = offers.filter(checkIfOfferIsInProgress);
    const allOffers = [
      ...newChallenges,
      ...(shouldIncludeInProgressOffers ? inProgressChallenges : []),
    ];

    const alreadySeenOfferIdsSet = new Set(alreadySeenOfferIds);

    return allOffers.filter((offer) => !alreadySeenOfferIdsSet.has(offer.id));
  }, [alreadySeenOfferIds, offers, shouldIncludeInProgressOffers]);

  // ─────────────────────────────────────────────────────────────────────

  const shouldShowModal =
    isOffersModalEnabled &&
    filteredOffers.length > 0 &&
    !hasAlreadyShowedModal &&
    wasOpenedFromHome &&
    !isShowingPostPurchaseModal;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showOffersModal = useCallback(() => {
    if (!isReady || !shouldShowModal) return;

    showModal();
  }, [isReady, shouldShowModal, showModal]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!isShowingPostPurchaseModal) return;

    hideModal();
  }, [hideModal, isShowingPostPurchaseModal]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    isOffersModalEnabled,
    isShowingOffersModal: isShowingModal,
    filteredOffers,
    showOffersModal,
    hideOffersModal: hideModal,
    resetState,
  };
};

/**
 * Custom hook that resets loyalty offers when a user logs out.
 *
 * NOTE: Should be called at the application level.
 */
export const useResetLoyaltyOffersOnLogout = () => {
  const hasLoggedOut = useHasLoggedOut();
  const { loyaltyOffersModalMachineRef: actorRef } = useGlobalAppState();

  const { helpers } = useLoyaltyOffersModalMachine({ actorRef });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetSeenOffersOnLogout = useCallback(() => {
    if (!hasLoggedOut) return;

    helpers.resetState();
  }, [hasLoggedOut, helpers]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(resetSeenOffersOnLogout);
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function checkIfOfferIsNew(offer: Pick<Challenge, 'status'>) {
  return offer.status === 'NOT_STARTED' || offer.status === 'STARTED';
}

function checkIfOfferIsInProgress(offer: Pick<Challenge, 'status'>) {
  return offer.status === 'IN_PROGRESS';
}

// ─── Constants ───────────────────────────────────────────────────────────────

const OFFERS_FALLBACK: LoyaltyOffersQuery['challenges'] = [];
