import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Button, theme } from '@sg/garnish';
import { type Ingredient } from '@sg/graphql-schema';

import { orderAgainConflictReviewCTAsMessages as messages } from './OrderAgainConflictReviewCTAs.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderAgainConflictReviewCTAs = (
  props: OrderAgainConflictReviewCTAsProps,
) => {
  const { isReordering, addToBag } = props;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <Button
        onPress={addToBag}
        size="large"
        palette="primary"
        isLoading={isReordering}
      >
        <FormattedMessage {...messages.addToBagAnyway} />
      </Button>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    rowGap: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderAgainConflictReviewCTAsProps = {
  productIngredients: readonly UnavailableIngredient[] | undefined;
  unavailableIngredients: readonly UnavailableIngredient[] | undefined;
  isReordering: boolean;
  addToBag: () => void;
};

type UnavailableIngredient = Pick<Ingredient, 'id' | 'name' | 'kind'>;
