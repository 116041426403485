/* eslint-disable @typescript-eslint/consistent-type-imports,@typescript-eslint/consistent-type-assertions,object-shorthand */
/* istanbul ignore file */

import { logger as LOG, logger } from '@garnish/logger';
import type { Client } from 'urql';
import { actions, assign, createMachine, raise } from 'xstate';

import {
  getActiveLocation,
  getActiveRecentDeliveryLocation,
  getActiveRecentLocation,
  getSearchAreaBasedOnCurrentPosition,
  setLocationWarningStorageRecord,
} from './actions';
import {
  LocationSearchMachineContext,
  LocationSearchMachineEvents,
  LocationSearchMachineServices,
} from './LocationSearch.types';
import {
  addNewDeliveryAddress,
  checkIfShouldShowLocationWarning,
  getCurrentPosition,
  getDefaultDeliveryLocation,
  getDeliveryAddressPredictions,
  getDeliveryLocationByPlaceId,
  getLastPlacedOrderLocation,
  getNearbyLocations,
  getRecentAndNearbyLocations,
  searchLocationsByBoundingBox,
  searchLocationsByString,
} from './services';

// ─────────────────────────────────────────────────────────────────────────────

export const createLocationSearchMachine = (
  params: CreateLocationSearchMachineParams,
) => {
  const {
    client,
    lastInteractedStoreAddressId,
    googlePlacesApiKey,
    shouldUseCurrentPosition = true,
    minimalCharactersForSearch = 3,
    shouldUseDeliveryDisclosureFields,
    shouldUseOutpostDisclosureFields,
  } = params;

  return createMachine(
    {
      tsTypes: {} as import('./LocationSearchMachine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as LocationSearchMachineContext,
        events: {} as LocationSearchMachineEvents,
        services: {} as LocationSearchMachineServices,
      },
      context: {
        // ─── Search Type ─────────────────────────────

        locationSearchType: undefined,

        // ─── Locations ───────────────────────────────

        searchString: '',
        searchArea: undefined,
        currentPosition: undefined,
        locations: undefined,
        activeLocation: undefined,

        // ─── Recent And Nearby Locations ─────────────

        recentLocations: undefined,
        nearbyLocations: undefined,

        // ─── Delivery Location ───────────────────────

        deliverySearchString: '',
        deliveryLocation: undefined,
        deliveryAddressPredictions: [],
        selectedDeliveryAddressPredictionId: undefined,

        // ─────────────────────────────────────────────

        focusedPinId: undefined,
        focusedLocationId: undefined,
      },
      type: 'parallel',
      states: {
        searchType: {
          initial: 'undetermined',
          states: {
            undetermined: {
              id: 'search-type-undetermined',
              on: {
                SET_LOCATION_SEARCH_TYPE_PICKUP: {
                  target: '#search-type-pickup',
                },
                SET_LOCATION_SEARCH_TYPE_DELIVERY: {
                  target: '#search-type-delivery',
                },
                SET_LOCATION_SEARCH_TYPE_OUTPOST: {
                  target: '#search-type-outpost',
                },
              },
            },
            pickup: {
              id: 'search-type-pickup',
              entry: [
                'onPickup',
                'onLocationTypeChangeEvent',
                'clearFocusedLocations',
                raise('SEARCH_FOR_INITIAL_LOCATIONS_BY_CURRENT_POSITION'),
              ],
              on: {
                SET_LOCATION_SEARCH_TYPE_DELIVERY: {
                  target: '#search-type-delivery',
                },
                SET_LOCATION_SEARCH_TYPE_OUTPOST: {
                  target: '#search-type-outpost',
                },
                SEARCH_BY_STRING: {
                  actions: ['setSearchString'],
                  cond: 'checkIfEnteredMinChars',
                  target: '#search-by-string',
                },
                SEARCH_BY_AREA: {
                  target: '#search-by-area',
                },
                SEARCH_BY_CURRENT_POSITION: {
                  target: '#search-by-area',
                  actions: [
                    'setSearchAreaToCurrentPosition',
                    'onUserLocationChanged',
                  ],
                  cond: 'checkIfShouldFetchLocationsByCurrentPosition',
                },
                SET_SEARCH_AREA: {
                  actions: ['setSearchArea'],
                },
                SET_FOCUSED_PIN_ID: {
                  actions: ['setFocusedPinId', 'onFocusedPinIdChangeEvent'],
                },
                SET_FOCUSED_CARD_ID: {
                  actions: ['setFocusedCardId'],
                },
                NAVIGATE_TO_LOCATION: {
                  actions: ['setActiveLocation', 'onNavigateToLocationEvent'],
                  target: '#check-for-pickup-location-warning',
                },
              },
            },
            delivery: {
              id: 'search-type-delivery',
              entry: [
                'onDelivery',
                'onLocationTypeChangeEvent',
                'clearFocusedLocations',
                raise('SET_DEFAULT_DELIVERY_LOCATION'),
              ],
              on: {
                SET_LOCATION_SEARCH_TYPE_PICKUP: {
                  target: '#search-type-pickup',
                },
                SET_LOCATION_SEARCH_TYPE_OUTPOST: {
                  target: '#search-type-outpost',
                },
                SEARCH_BY_DELIVERY_ADDRESS: {
                  target: '#get-default-delivery-location',
                },
                SET_DELIVERY_SEARCH_STRING: [
                  {
                    target: '#search-delivery-addresses',
                    actions: [
                      'setDeliverySearchString',
                      'clearDeliveryLocation',
                      'clearFocusedLocations',
                    ],
                    cond: 'checkIfEnteredMinChars',
                  },
                  {
                    actions: [
                      'setDeliverySearchString',
                      'clearDeliveryLocation',
                      'clearFocusedLocations',
                    ],
                  },
                ],
                SET_DELIVERY_PREDICTION_PLACE_ID: {
                  target: '#search-by-delivery-place-id',
                  actions: ['setSelectedDeliveryAddressPredictionPlaceId'],
                },
                NAVIGATE_TO_DELIVERY_LOCATION: [
                  {
                    target: '#showing-delivery-address-form',
                    cond: 'checkIfShouldShowDeliveryAddressForm',
                  },
                  {
                    target: '#add-new-delivery-address',
                    cond: 'checkIfShouldAddNewDeliveryLocation',
                  },
                  {
                    target: '#navigating-to-delivery',
                    actions: 'onNavigateToDeliveryLocationEvent',
                  },
                ],
              },
            },
            outpost: {
              id: 'search-type-outpost',
              entry: [
                'onOutpost',
                'onLocationTypeChangeEvent',
                'clearFocusedLocations',
                raise('SEARCH_FOR_INITIAL_LOCATIONS_BY_CURRENT_POSITION'),
              ],
              on: {
                SET_LOCATION_SEARCH_TYPE_PICKUP: {
                  target: '#search-type-pickup',
                },
                SET_LOCATION_SEARCH_TYPE_DELIVERY: {
                  target: '#search-type-delivery',
                },
                SEARCH_BY_STRING: {
                  actions: ['setSearchString'],
                  cond: 'checkIfEnteredMinChars',
                  target: '#search-by-string',
                },
                SEARCH_BY_AREA: {
                  target: '#search-by-area',
                },
                SEARCH_BY_CURRENT_POSITION: {
                  target: '#search-by-area',
                  actions: [
                    'setSearchAreaToCurrentPosition',
                    'onUserLocationChanged',
                  ],
                  cond: 'checkIfShouldFetchLocationsByCurrentPosition',
                },
                SET_SEARCH_AREA: {
                  actions: ['setSearchArea'],
                },
                SET_FOCUSED_PIN_ID: {
                  actions: ['setFocusedPinId'],
                },
                SET_FOCUSED_CARD_ID: {
                  actions: ['setFocusedCardId'],
                },
                NAVIGATE_TO_LOCATION: {
                  actions: ['setActiveLocation', 'onNavigateToLocationEvent'],
                  target: '#check-for-outpost-location-warning',
                },
              },
            },
          },
        },
        search: {
          initial: 'setup',
          states: {
            setup: {
              initial: 'waiting',
              states: {
                waiting: {
                  always: [
                    {
                      target: '#setup-nearby-locations-legacy',
                      cond: 'checkIfUsingLegacyFlow',
                    },
                    {
                      target: [
                        '#search-type-pickup',
                        '#setup-recent-and-nearby-locations',
                      ],
                      cond: 'checkIfSignedIn',
                    },
                    {
                      target: [
                        '#search-type-pickup',
                        '#setup-nearby-locations',
                      ],
                    },
                  ],
                },
                setupRecentAndNearbyLocations: {
                  id: 'setup-recent-and-nearby-locations',
                  invoke: {
                    src: 'getRecentAndNearbyLocations',
                    onDone: {
                      target: '#finish-setup',
                      actions: [
                        'onRecentAndNearbyLocationsDone',
                        'onCurrentPositionChanged',
                      ],
                    },
                    onError: {
                      target: '#idle',
                    },
                  },
                  after: {
                    10_000: '#idle',
                  },
                },
                setupNearbyLocations: {
                  id: 'setup-nearby-locations',
                  invoke: {
                    src: 'getNearbyLocations',
                    onDone: {
                      target: '#finish-setup',
                      actions: [
                        'onNearbyLocationsDone',
                        'onCurrentPositionChanged',
                      ],
                    },
                    onError: {
                      target: '#idle',
                    },
                  },
                  after: {
                    100_000: '#idle',
                  },
                },
                setupNearbyLocationsLegacy: {
                  id: 'setup-nearby-locations-legacy',
                  invoke: {
                    src: 'getCurrentPosition',
                    onDone: {
                      target: ['#search-type-pickup', '#search-by-area'],
                      actions: [
                        'setCurrentPosition',
                        'onGetCurrentPositionDone',
                        'onUserLocationChanged',
                      ],
                    },
                    onError: {
                      target: '#search-by-last-placed-order-location',
                    },
                  },
                },
                finishSetup: {
                  id: 'finish-setup',
                  always: [
                    {
                      target: '#recent-and-nearby-locations',
                      cond: 'checkIfHaveRecentOrNearbyLocations',
                    },
                    {
                      target: '#idle',
                    },
                  ],
                  type: 'final',
                },
              },
            },
            idle: {
              id: 'idle',
              on: {
                SET_DEFAULT_DELIVERY_LOCATION: {
                  target: '#get-default-delivery-location',
                  cond: 'checkIfShouldGetDefaultDeliveryLocationOnEntry',
                },
                SEARCH_FOR_INITIAL_LOCATIONS_BY_CURRENT_POSITION: {
                  target: '#search-by-area',
                  cond: 'checkIfShouldFetchInitialLocationsByCurrentPosition',
                },
                SHOW_RECENT_AND_NEARBY_LOCATIONS: {
                  target: '#recent-and-nearby-locations',
                  cond: 'checkIfHaveRecentOrNearbyLocations',
                },
              },
            },
            showingDeliveryAddressForm: {
              id: 'showing-delivery-address-form',
              on: {
                CANCEL_DELIVERY_ADDRESS_FORM: 'idle',
                SUBMIT_DELIVERY_ADDRESS_FORM: {
                  actions: ['onDeliveryFormFilled'],
                  target: '#add-new-delivery-address',
                },
              },
            },
            recentAndNearbyLocations: {
              id: 'recent-and-nearby-locations',
              initial: 'idle',
              states: {
                idle: {
                  id: 'recent-and-nearby-locations.idle',
                  on: {
                    NAVIGATE_TO_RECENT_PICKUP_LOCATION: {
                      actions: [
                        'setActiveRecentLocation',
                        'onNavigateToLocationEvent',
                      ],
                      target:
                        '#recent-and-nearby-locations.navigating-to-pickup',
                    },
                    NAVIGATE_TO_RECENT_OUTPOST_LOCATION: {
                      actions: [
                        'setActiveRecentLocation',
                        'onNavigateToLocationEvent',
                      ],
                      target:
                        '#recent-and-nearby-locations.navigating-to-outpost',
                    },
                    NAVIGATE_TO_RECENT_DELIVERY_LOCATION: [
                      {
                        actions: [
                          'setActiveRecentDeliveryLocation',
                          'onNavigateToDeliveryLocationEvent',
                        ],
                        target:
                          '#recent-and-nearby-locations.navigating-to-delivery',
                      },
                    ],
                  },
                },
                warning: {
                  on: {
                    LOCATION_WARNING_CANCEL: {
                      target: '#recent-and-nearby-locations.idle',
                      actions: ['clearActiveLocation'],
                    },
                  },
                  initial: 'waiting',
                  states: {
                    waiting: {
                      description: 'A placeholder state',
                    },
                    pickupLocation: {
                      id: 'recent-and-nearby-locations.check-pickup-location-warning',
                      on: {
                        LOCATION_WARNING_CONFIRM: {
                          target:
                            '#recent-and-nearby-locations.navigating-to-pickup',
                          actions: [
                            'setLocationWarningStorageRecord',
                            'onLocationWarningConfirmEvent',
                          ],
                        },
                      },
                    },
                    outpostLocation: {
                      id: 'recent-and-nearby-locations.check-outpost-location-warning',
                      on: {
                        LOCATION_WARNING_CONFIRM: {
                          target:
                            '#recent-and-nearby-locations.navigating-to-outpost',
                          actions: [
                            'setLocationWarningStorageRecord',
                            'onLocationWarningConfirmEvent',
                          ],
                        },
                      },
                    },
                  },
                },
                navigating: {
                  initial: 'waiting',
                  states: {
                    waiting: {
                      description: 'A placeholder state',
                    },
                    toPickup: {
                      id: 'recent-and-nearby-locations.navigating-to-pickup',
                      entry: ['navigateToLocation'],
                      always: { target: '#recent-and-nearby-locations.idle' },
                    },
                    toOutpost: {
                      id: 'recent-and-nearby-locations.navigating-to-outpost',
                      entry: ['navigateToLocation'],
                      always: { target: '#recent-and-nearby-locations.idle' },
                    },
                    toDelivery: {
                      id: 'recent-and-nearby-locations.navigating-to-delivery',
                      entry: ['navigateToDeliveryLocation'],
                      always: { target: '#recent-and-nearby-locations.idle' },
                    },
                  },
                },
              },
            },
            loading: {
              states: {
                //
                // ─── Locations ───────────────────────────────

                searchByArea: {
                  id: 'search-by-area',
                  entry: ['clearSearchString'],
                  invoke: {
                    src: 'searchByArea',
                    onDone: {
                      target: '#idle',
                      actions: [
                        'onSearchByAreaDone',
                        'onSearchByAreaEvent',
                        'clearFocusedLocations',
                      ],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['onSearchByAreaError'],
                    },
                  },
                },
                searchByString: {
                  id: 'search-by-string',
                  invoke: {
                    src: 'searchByString',
                    onDone: {
                      target: '#idle',
                      actions: [
                        'onSearchByStringDone',
                        'onSearchByStringEvent',
                        'clearFocusedLocations',
                      ],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['onSearchByStringError'],
                    },
                  },
                },
                searchByLastPlacedOrderLocation: {
                  id: 'search-by-last-placed-order-location',
                  invoke: {
                    src: 'getLastPlacedOrderLocation',
                    onDone: {
                      target: '#idle',
                      actions: [
                        'onSearchByLastPlacedOrderLocationDone',
                        'selectLocationSearchType',
                        'clearFocusedLocations',
                      ],
                    },
                    onError: {
                      target: ['#idle', '#search-type-pickup'],
                    },
                  },
                },

                // ─── Locations Warnings ──────────────────────

                checkForPickupLocationWarning: {
                  id: 'check-for-pickup-location-warning',
                  invoke: {
                    src: 'checkIfShouldShowPickupLocationWarning',
                    onDone: [
                      {
                        target: '#warning-pickup-location',
                        cond: 'checkIfShouldShowLocationWarning',
                      },
                      {
                        target: '#navigating-to-pickup',
                      },
                    ],
                    onError: {
                      target: '#idle',
                      actions: ['onCheckForLocationWarningError'],
                    },
                  },
                },
                checkForOutpostLocationWarning: {
                  id: 'check-for-outpost-location-warning',
                  invoke: {
                    src: 'checkIfShouldShowOutpostLocationWarning',
                    onDone: [
                      {
                        target: '#warning-outpost-location',
                        cond: 'checkIfShouldShowLocationWarning',
                      },
                      {
                        target: '#navigating-to-outpost',
                      },
                    ],
                    onError: {
                      target: '#idle',
                      actions: ['onCheckForLocationWarningError'],
                    },
                  },
                },

                // ─── Delivery Location ───────────────────────

                searchByDeliveryLocationPlaceId: {
                  id: 'search-by-delivery-place-id',
                  invoke: {
                    src: 'searchByDeliveryLocationPlaceId',
                    onDone: {
                      target: '#idle',
                      actions: [
                        'clearFocusedLocations',
                        'onSearchByDeliveryPlaceIdDone',
                        'onSearchByDeliveryPlaceIdEvent',
                      ],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['onSearchByDeliveryPlaceIdError'],
                    },
                  },
                },
                getDefaultDeliveryLocation: {
                  id: 'get-default-delivery-location',
                  invoke: {
                    src: 'getDefaultDeliveryLocation',
                    onDone: {
                      target: '#idle',
                      actions: [
                        'clearFocusedLocations',
                        'onSearchByDeliveryPlaceIdDone',
                        'onSearchByDeliveryPlaceIdEvent',
                      ],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['onSearchByDeliveryPlaceIdError'],
                    },
                  },
                },
                searchDeliveryAddresses: {
                  id: 'search-delivery-addresses',
                  invoke: {
                    src: 'searchDeliveryAddresses',
                    onDone: {
                      target: '#idle',
                      actions: ['onSearchDeliveryAddressesDone'],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['onSearchDeliveryAddressesError'],
                    },
                  },
                },
                addNewDeliveryAddress: {
                  id: 'add-new-delivery-address',
                  invoke: {
                    src: 'addNewDeliveryAddress',
                    onDone: {
                      target: '#navigating-to-delivery',
                      actions: ['onAddDeliveryAddressIdDone'],
                    },
                    onError: {
                      target: '#idle',
                      actions: ['onAddDeliveryAddressIdError'],
                    },
                  },
                },
              },
            },
            warning: {
              initial: 'waiting',
              states: {
                waiting: {
                  description: 'A placeholder state',
                },
                pickupLocation: {
                  id: 'warning-pickup-location',
                  on: {
                    LOCATION_WARNING_CANCEL: {
                      target: '#idle',
                      actions: ['clearActiveLocation'],
                    },
                    LOCATION_WARNING_CONFIRM: {
                      target: '#navigating-to-pickup',
                      actions: [
                        'setLocationWarningStorageRecord',
                        'onLocationWarningConfirmEvent',
                      ],
                    },
                  },
                },
                outpostLocation: {
                  id: 'warning-outpost-location',
                  on: {
                    LOCATION_WARNING_CANCEL: {
                      target: '#idle',
                      actions: ['clearActiveLocation'],
                    },
                    LOCATION_WARNING_CONFIRM: {
                      target: '#navigating-to-outpost',
                      actions: [
                        'setLocationWarningStorageRecord',
                        'onLocationWarningConfirmEvent',
                      ],
                    },
                  },
                },
              },
            },
            navigating: {
              initial: 'waiting',
              states: {
                waiting: {
                  description: 'A placeholder state',
                },
                toPickup: {
                  id: 'navigating-to-pickup',
                  entry: ['navigateToLocation'],
                  always: { target: '#idle' },
                },
                toOutpost: {
                  id: 'navigating-to-outpost',
                  entry: ['navigateToLocation'],
                  always: { target: '#idle' },
                },
                toDelivery: {
                  id: 'navigating-to-delivery',
                  entry: ['navigateToDeliveryLocation'],
                  always: { target: '#idle' },
                },
              },
            },
          },
        },
      },
    },
    {
      services: {
        //
        // ─── Locations ───────────────────────────────

        getCurrentPosition: async () => getCurrentPosition(),

        getRecentAndNearbyLocations: async () =>
          getRecentAndNearbyLocations({
            client,
            shouldUseOutpostDisclosureFields,
            shouldUseDeliveryDisclosureFields,
          }),
        getNearbyLocations: async () =>
          getNearbyLocations({
            client,
            shouldUseOutpostDisclosureFields,
            shouldUseDeliveryDisclosureFields,
          }),

        searchByString: async (context) => {
          return searchLocationsByString({
            client,
            context,
            shouldUseDisclosureFields: shouldUseOutpostDisclosureFields,
          });
        },
        searchByArea: async (context) => {
          return searchLocationsByBoundingBox({
            client,
            context,
            shouldUseDisclosureFields: shouldUseOutpostDisclosureFields,
          });
        },
        getLastPlacedOrderLocation: async (context) => {
          return getLastPlacedOrderLocation({
            client,
            context,
            shouldUseDisclosureFields: shouldUseOutpostDisclosureFields,
          });
        },

        // ─── Location Warning ────────────────────────

        checkIfShouldShowPickupLocationWarning: async (context) =>
          checkIfShouldShowLocationWarning(context.activeLocation),

        checkIfShouldShowOutpostLocationWarning: async (context) =>
          checkIfShouldShowLocationWarning(context.activeLocation),

        // ─── Delivery Location ───────────────────────

        searchByDeliveryLocationPlaceId: async (context) => {
          return getDeliveryLocationByPlaceId({
            client,
            googlePlacesApiKey,
            googlePlaceId: context.selectedDeliveryAddressPredictionId,
            shouldUseDisclosureFields: shouldUseDeliveryDisclosureFields,
          });
        },
        getDefaultDeliveryLocation: async () => {
          return getDefaultDeliveryLocation({
            client,
            googlePlacesApiKey,
            lastInteractedStoreAddressId,
            shouldUseDisclosureFields: shouldUseDeliveryDisclosureFields,
          });
        },
        searchDeliveryAddresses: async (context) =>
          getDeliveryAddressPredictions(client, context.deliverySearchString),
        addNewDeliveryAddress: async (context) =>
          addNewDeliveryAddress(client, context.deliveryLocation),
      },
      actions: {
        // ─── Locations Search Type ───────────────────

        onPickup: assign({
          locationSearchType: (_) => 'pickup' as const,
        }),
        onDelivery: assign({
          locationSearchType: (_) => 'delivery' as const,
        }),
        onOutpost: assign({
          locationSearchType: (_) => 'outpost' as const,
        }),
        selectLocationSearchType: actions.choose([
          {
            cond: (_context, event) => event.data.locationType === 'pickup',
            actions: [raise('SET_LOCATION_SEARCH_TYPE_PICKUP')],
          },
          {
            cond: (_context, event) => event.data.locationType === 'delivery',
            actions: [raise('SET_LOCATION_SEARCH_TYPE_DELIVERY')],
          },
          {
            cond: (_context, event) => event.data.locationType === 'outpost',
            actions: [raise('SET_LOCATION_SEARCH_TYPE_OUTPOST')],
          },
        ]),

        // ─── Current Position ────────────────────────

        onGetCurrentPositionDone: assign({
          searchArea: (_, { data }) =>
            getSearchAreaBasedOnCurrentPosition({
              currentPositionCoordinates: data.coords,
              radiusInMiles: 5,
            }),
        }),
        setCurrentPosition: assign({
          currentPosition: (_, { data }) => ({
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
          }),
        }),

        // ─── Locations ───────────────────────────────

        onSearchByAreaDone: assign({
          locations: (_context, event) => event.data,
        }),
        setSearchArea: assign({
          searchArea: (_, { searchArea }) => searchArea,
        }),
        setSearchAreaToCurrentPosition: assign({
          searchArea: (context) => {
            if (!context.currentPosition) return;

            return getSearchAreaBasedOnCurrentPosition({
              currentPositionCoordinates: context.currentPosition,
              radiusInMiles: 5,
            });
          },
        }),
        onSearchByStringDone: assign({
          locations: (_context, event) => event.data,
        }),
        setSearchString: assign({
          searchString: (_, event) => event.searchString,
        }),
        clearSearchString: assign({
          searchString: (_) => '',
        }),
        setActiveLocation: assign({
          activeLocation: (context, event) =>
            getActiveLocation(context.locations, event),
        }),
        clearActiveLocation: assign({
          activeLocation: (_) => undefined,
        }),
        onSearchByLastPlacedOrderLocationDone: assign({
          locations: (_context, event) => event.data.locations,
        }),

        // ─── Location Warning ────────────────────────

        setLocationWarningStorageRecord: (context) => {
          setLocationWarningStorageRecord(context.activeLocation);
        },

        // ─── Delivery Location ───────────────────────

        onSearchByDeliveryPlaceIdDone: assign({
          focusedLocationId: (_, event) => event.data?.id,
          focusedPinId: (_, event) => event.data?.id,
          deliveryLocation: (_, event) => event.data,
          deliverySearchString: (context, { data }) =>
            data?.address ?? context.deliverySearchString,
        }),

        onSearchDeliveryAddressesDone: assign({
          deliveryAddressPredictions: (_, event) => event.data,
        }),

        onDeliveryFormFilled: assign({
          deliveryLocation: (_, event) => event.address,
        }),

        onAddDeliveryAddressIdDone: assign({
          deliveryLocation: (context, event) =>
            context.deliveryLocation
              ? { ...context.deliveryLocation, addressId: event.data }
              : undefined,
        }),

        setActiveRecentLocation: assign({
          activeLocation: (context, event) =>
            getActiveRecentLocation(context, event),
        }),
        setActiveRecentDeliveryLocation: assign({
          deliveryLocation: (context, event) =>
            getActiveRecentDeliveryLocation(context, event),
        }),
        clearDeliveryLocation: assign({
          deliveryLocation: (_) => undefined,
        }),
        setDeliverySearchString: assign({
          deliverySearchString: (_, { searchString }) => searchString,
        }),
        setSelectedDeliveryAddressPredictionPlaceId: assign({
          selectedDeliveryAddressPredictionId: (_, { placeId }) => placeId,
        }),

        // ─── Recent Locations ────────────────────────

        onRecentAndNearbyLocationsDone: assign({
          currentPosition: (_context, event) =>
            event.data.currentPositionCoordinates,
          recentLocations: (_context, event) => event.data.recentLocations,
          nearbyLocations: (_context, event) => event.data.nearbyLocations,
        }),
        onNearbyLocationsDone: assign({
          currentPosition: (_context, event) =>
            event.data.currentPositionCoordinates,
          nearbyLocations: (_context, event) => event.data.nearbyLocations,
        }),

        // ─── Focused Pin & Card ──────────────────────

        setFocusedPinId: assign({
          focusedPinId: (_, event) => event.id,
        }),
        setFocusedCardId: assign({
          focusedLocationId: (_, event) => event.id,
        }),
        clearFocusedLocations: assign({
          focusedPinId: (_) => undefined,
          focusedLocationId: (_) => undefined,
        }),

        // ─── Navigation ──────────────────────────────

        navigateToLocation: createLogger(
          'Provide `navigateToLocation` action to handle location navigation event',
        ),
        navigateToDeliveryLocation: createLogger(
          'Provide `navigateToLocation` action to handle delivery location navigation event',
        ),

        // ─── Optional Error Handlers ─────────────────

        onSearchByAreaError: createErrorLogger(
          'onSearchByAreaError',
          'Failed to fetch locations by search area',
        ),
        onSearchByStringError: createErrorLogger(
          'onSearchByStringError',
          'Failed to fetch locations by search string',
        ),
        onCheckForLocationWarningError: createErrorLogger(
          'onCheckForLocationWarningError',
          'Failed to check location warning',
        ),
        onSearchByDeliveryPlaceIdError: createErrorLogger(
          'onSearchByDeliveryPlaceIdError',
          'Failed to fetch delivery location by placed ID',
        ),
        onSearchDeliveryAddressesError: createErrorLogger(
          'onSearchDeliveryAddressesError',
          'Failed to fetch delivery addresses',
        ),
        onAddDeliveryAddressIdError: createErrorLogger(
          'onAddDeliveryAddressIdError',
          'Failed to add new delivery address',
        ),

        // ─── Optional Event Listeners ────────────────

        onUserLocationChanged: createLogger(
          'Provide `onUserLocationChanged` action to handle user location changed event',
        ),
        onLocationTypeChangeEvent: createLogger(
          'Provide `onLocationTypeChangeEvent` action to handle location type change event',
        ),
        onSearchByAreaEvent: createLogger(
          'Provide `onSearchByAreaEvent` action to listen for "search by locations" event',
        ),
        onSearchByStringEvent: createLogger(
          'Provide `onSearchByStringEvent` action to listen for "search by locations" event',
        ),
        onSearchByDeliveryPlaceIdEvent: createLogger(
          'Provide `onSearchByDeliveryPlaceIdEvent` action to listen for "search by delivery place ID" event',
        ),
        onLocationWarningConfirmEvent: createLogger(
          'Provide `onLocationWarningConfirmEvent` action to listen for "confirm location" event',
        ),
        onFocusedPinIdChangeEvent: createLogger(
          'Provide `onFocusedPinIdChangeEvent` action to listen for "focused pin ID change" event',
        ),
        onCurrentPositionChanged: createLogger(
          'Provide `onCurrentPositionChanged` action to handle user location changed event',
        ),
        onNavigateToLocationEvent: createLogger(
          'Provide `onNavigateToLocationEvent` action to listen for "navigate to location" event',
        ),
        onNavigateToDeliveryLocationEvent: createLogger(
          'Provide `onNavigateToDeliveryLocationEvent` action to listen for "navigate to delivery location" event',
        ),
      },
      guards: {
        // ─── Locations ───────────────────────────────

        checkIfEnteredMinChars: (_, { searchString }) =>
          searchString.length >= minimalCharactersForSearch,
        checkIfShouldFetchInitialLocationsByCurrentPosition: (context) =>
          shouldUseCurrentPosition &&
          context.currentPosition !== undefined &&
          !context.locations,
        checkIfShouldFetchLocationsByCurrentPosition: (context) =>
          shouldUseCurrentPosition && context.currentPosition !== undefined,
        checkIfShouldShowLocationWarning: (_, event) => event.data === true,

        // ─── Recent And Nearby Locations ─────────────

        checkIfHaveRecentOrNearbyLocations: (context) => {
          const { locationSearchType, nearbyLocations } = context;

          const recentLocations = context.recentLocations ?? [];
          const nearbyPickupLocations =
            locationSearchType === 'pickup'
              ? nearbyLocations?.pickup ?? []
              : [];
          const nearbyOutpostLocations =
            locationSearchType === 'outpost'
              ? nearbyLocations?.outpost ?? []
              : [];

          return (
            recentLocations.length > 0 ||
            nearbyPickupLocations.length + nearbyOutpostLocations.length > 0
          );
        },

        // ─── Delivery Location ───────────────────────

        checkIfShouldGetDefaultDeliveryLocationOnEntry: ({
          deliveryLocation,
        }) => !deliveryLocation || !deliveryLocation.restaurantSlug,
        checkIfShouldAddNewDeliveryLocation: ({ deliveryLocation }) =>
          !deliveryLocation?.addressId,

        // ─── External Guards ─────────────────────────

        checkIfSignedIn: () => {
          createLogger('`checkIfSignedIn` guard is not provided');

          return false;
        },

        /**
         * A flag that indicates the use of the legacy location search flow.
         */
        checkIfUsingLegacyFlow: () => {
          createLogger('`checkIfUsingLegacyFlow` guard is not provided');

          return true;
        },

        /**
         * A flag that indicates the need to show the delivery address form.
         */
        checkIfShouldShowDeliveryAddressForm: () => {
          createLogger(
            '`checkIfShouldShowDeliveryAddressForm` guard is not provided',
          );

          return false;
        },
      },
    },
  );
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Handles rejected service promises by logging the corresponding error message
 * and action name, which can be supplied to externally handle the error.
 */
function createErrorLogger(missingActionName: string, errorMessage: string) {
  const message = `${errorMessage} (NOTE: Provide "${missingActionName}" action while setting up the machine to handle this error externally.)`;

  return createLogger(message, 'error');
}

/**
 * Returns a function to log messages in the location machine scope.
 */
function createLogger(message: string, messageType: 'error' | 'info' = 'info') {
  return function () {
    if (messageType === 'error') {
      messageLogger.error(message);

      return;
    }

    messageLogger.debug(message);
  };
}

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('LOCATION STATE MACHINE');

const messageLogger = logger.extend('LOCATION STATE MACHINE');

// ─── Types ───────────────────────────────────────────────────────────────────

type CreateLocationSearchMachineParams = Readonly<{
  client: Client;
  googlePlacesApiKey: string;
  defaultLocationSearchType?: LocationSearchMachineContext['locationSearchType'];
  lastInteractedStoreAddressId?: string;
  minimalCharactersForSearch?: number;
  shouldUseCurrentPosition?: boolean;

  /**
   * Tells machine to fetch extra upcharge disclosure fields from BE (delivery).
   */
  shouldUseDeliveryDisclosureFields: boolean;

  /**
   * Tells machine to fetch extra upcharge disclosure fields from BE (outpost).
   */
  shouldUseOutpostDisclosureFields: boolean;
}>;
