import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type PressableProps } from 'react-native';

import { messages } from '../messages';
import { NavbarTextLinkCta } from './NavbarTextLinkCta';

export const NavbarMenuCta = (props: Props) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();

  return (
    <NavbarTextLinkCta
      testID="nav-bar.menu"
      aria-label={formatMessage(messages.menuA11y)}
      onPress={onPress}
    >
      <FormattedMessage {...messages.menu} />
    </NavbarTextLinkCta>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress'>;
