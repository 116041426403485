import { useContentfulContentTypeEntryFactory } from '@sg/shared-fe/integrations/contentful';

import { useContentfulClient } from '../useContentfulClient';
import { useIsPreviewModeEnabled } from '../useIsPreviewModeEnabled';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a single Contentful entry for the provided content type.
 */
export const useContentfulContentTypeEntry = <ContentTypeFields>(
  params: UseContentfulContentTypeEntryParams,
) => {
  const contentfulClient = useContentfulClient();
  const isPreviewModeEnabled = useIsPreviewModeEnabled();

  return useContentfulContentTypeEntryFactory<ContentTypeFields>({
    contentfulClient,
    isPreviewModeEnabled,
    ...params,
  });
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseContentfulContentTypeEntryParams = Omit<
  Parameters<typeof useContentfulContentTypeEntryFactory>[0],
  'contentfulClient' | 'isPreviewModeEnabled'
>;
