import {
  Containers,
  NavbarAccountCta,
  NavbarBagCta,
  NavbarGiftCta,
  NavbarHomeCta,
  NavbarLoyaltyCta,
  NavbarMenuCta,
  NavbarOrderCta,
} from './components';
export { NAVBAR_HEADER_HEIGHT } from './constants';

export const Navbar = {
  Containers,
  Home: NavbarHomeCta,
  Gift: NavbarGiftCta,
  Loyalty: NavbarLoyaltyCta,
  Menu: NavbarMenuCta,
  Order: NavbarOrderCta,
  Bag: NavbarBagCta,
  Account: NavbarAccountCta,
};
