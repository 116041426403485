import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { BagWarning } from '../BagWarning';

/**
 * Warning message that the date selected is in the future.
 */
export const BagFutureDateWarning = (props: BagFutureDateWarningProps) => {
  const { formatMessage } = useIntl();

  return (
    <BagWarning
      message={formatMessage(messages.fallback)}
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      cta={formatMessage(messages.cta)}
      onClose={props.onClose}
      onCtaPress={props.onClose}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  fallback: {
    defaultMessage: 'Heads up! This order is scheduled for a future date.',
    description: 'Bag | Future date warning | Message',
  },
  cta: {
    defaultMessage: 'Got it',
    description: 'Bag | Future date warning | CTA',
  },
  accessibilityLabel: {
    defaultMessage: 'Confirm future time',
    description: 'Bag | Future date warning | a11y',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagFutureDateWarningProps = {
  onClose: () => void;
};
