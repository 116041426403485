import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { getIosModalPresentationConfig, Modal } from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useTrackEventEffect } from '@order/Telemetry';

import { useLoyaltyHowItWorksContent } from './useLoyaltyHowItWorksContent';

export const LoyaltyHowItWorksModal = (props: LoyaltyHowItWorksModalProps) => {
  const { visible, onRequestClose } = props;
  const { howItWorks, termsAndConditionsUrl, disclaimer } =
    useLoyaltyHowItWorksContent();

  useTrackEventEffect({ name: 'loyalty.how_it_works.view', skip: !visible });

  // ─── Styles ──────────────────────────────────────────────────────────

  const safeAreaInsets = useSafeAreaInsets();
  const containerSafeInsetStyle = useStyle(
    () => ({
      paddingTop: Platform.OS === 'android' ? safeAreaInsets.top : 0,
    }),
    [safeAreaInsets],
  );

  return (
    <Modal
      fitHeight
      size="full"
      visible={visible}
      onRequestClose={onRequestClose}
      {...getIosModalPresentationConfig()}
    >
      <Loyalty.HowItWorks.Container style={containerSafeInsetStyle}>
        <Loyalty.HowItWorks.Header>
          <Loyalty.HowItWorks.HeaderTitle>
            <FormattedMessage {...messages.header} />
          </Loyalty.HowItWorks.HeaderTitle>

          <Loyalty.HowItWorks.HeaderClose onPress={onRequestClose} />
        </Loyalty.HowItWorks.Header>

        <Loyalty.HowItWorks.CardsContainer>
          {howItWorks.map((item) => (
            <Loyalty.HowItWorks.Card.Container
              key={item.id}
              containerColor={item.backgroundColor}
            >
              <Loyalty.HowItWorks.Card.Title style={{ color: item?.textColor }}>
                {item.title}
              </Loyalty.HowItWorks.Card.Title>

              <Loyalty.HowItWorks.Card.Text style={{ color: item.textColor }}>
                {item.description}
              </Loyalty.HowItWorks.Card.Text>
            </Loyalty.HowItWorks.Card.Container>
          ))}
        </Loyalty.HowItWorks.CardsContainer>

        <Loyalty.HowItWorks.Footer
          disclaimer={disclaimer}
          termsAndConditionsUrl={termsAndConditionsUrl}
        />
      </Loyalty.HowItWorks.Container>
    </Modal>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  header: {
    defaultMessage: 'How it works',
    description: 'Loyalty | How it works | Header',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHowItWorksModalProps = {
  visible: boolean;
  onRequestClose: () => void;
};
