/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagCreditsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BagCreditsQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly availableCredit: number;
  } | null;
};

export const BagCreditsDocument = gql`
  query BagCredits {
    currentCustomer {
      id
      __typename
      availableCredit
    }
  }
`;

export function useBagCreditsQuery(
  options?: Omit<Urql.UseQueryArgs<BagCreditsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BagCreditsQuery, BagCreditsQueryVariables>({
    query: BagCreditsDocument,
    ...options,
  });
}
