import { useCallback } from 'react';
import { type AddressType } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns a utility function for logging a placed order to Braze.
 *
 * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/react_native/analytics/#logging-purchases Logging purchases | React Native}
 * @see {@link https://www.braze.com/docs/developer_guide/platform_integration_guides/web/analytics/logging_purchases/ Logging purchases | Web}
 */
export const useCheckoutBrazeLogPurchase = (props: BrazeLogPurchaseProps) => {
  const { logPurchase } = props;

  return useCallback(
    (params: BrazeLogPurchaseParams) => {
      const {
        restaurantId,
        subtotal,
        total,
        discountTotal,
        lineItems = [],
        orderChannel,
      } = params;

      const currency = 'usd';
      const quantity = 1;
      const lineItemsData = getLineItems(lineItems);

      const otherDetails = {
        restaurant_id: restaurantId,
        discount: formatAmountInDollars(discountTotal),
        order_net: formatAmountInDollars(subtotal),
        ...(lineItemsData
          ? {
              item_count: lineItemsData.count,
              item_names: lineItemsData.names,
            }
          : {}),
      };

      logPurchase?.(
        orderChannel,
        formatAmountInDollars(total),
        currency,
        quantity,
        otherDetails,
      );
    },
    [logPurchase],
  );
};

// ─── Utils ───────────────────────────────────────────────────────────────────

function formatAmountInDollars(amount: number) {
  return amount / 100;
}

function getLineItems(lineItems: readonly LineItem[] | undefined | null) {
  if (!Array.isArray(lineItems)) {
    return;
  }

  return {
    count: lineItems.length,
    names: lineItems.map(extractProductNames).filter(Boolean).join(','),
  };
}

function extractProductNames(lineItem: LineItem) {
  return lineItem?.product?.name?.toLowerCase();
}

// ─── Types ───────────────────────────────────────────────────────────────────

type BrazeLogPurchaseProps = {
  logPurchase: (
    productId: string,
    price: number,
    currencyCode?: string,
    quantity?: number,
    purchaseProperties?: Record<string, string | number | boolean | Date>,
  ) => void;
};

type BrazeLogPurchaseParams = Readonly<{
  restaurantId: string;
  subtotal: number;
  total: number;
  discountTotal: number;
  lineItems: readonly LineItem[] | undefined | null;
  orderChannel: AddressType;
}>;

type LineItem = { product?: { name?: string } };
