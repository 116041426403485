import React from 'react';
import { StyleSheet, type ViewProps } from 'react-native';
import { DisplayText } from '@sg/garnish';

/**
 * This is the text line for the header.
 * The specific request is that the first line should contain the channel and the restaurant name.
 * While everything else should be on the second line.
 *
 * Pickup from Sweetgreen Brink Demo
 * today at 1:00pm, top floor
 *
 * Meaning the location name should not break into multiple lines.
 * The text size for it should shrink from `32` to `24` if needed.
 * And if even then it doesn't fit, it should have an ellipsis at the end.
 */
export const BagHeaderLine = (props: BagHeaderLineProps) => {
  const { children, sizeMatch, numberOfLines = 1 } = props;

  return (
    <DisplayText
      style={styles.container}
      numberOfLines={numberOfLines}
      sizeMatch={sizeMatch}
    >
      {children}
    </DisplayText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagHeaderLineProps = ViewProps & {
  sizeMatch: Array<'24' | '32'>;
  numberOfLines?: number;
};
