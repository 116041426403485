import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductCardV2AddToBagContainer = (props: PropsWithChildren) => {
  return (
    <View style={styles.container} pointerEvents="box-none">
      {props.children}
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: theme.spacing['4'],
    bottom: theme.spacing['6'],
  },
});
