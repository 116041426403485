import React from 'react';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useNavigateToMainTabs } from '../../navigation';
import type {
  AppStackParamList,
  MenuScreenReferrer,
} from '../../navigation/AppNavigation.props';

export const useMenuNavigation = (
  passedNavigation?: NativeStackNavigationProp<AppStackParamList>,
) => {
  const fallback =
    useNavigation<NativeStackNavigationProp<AppStackParamList>>();
  const navigation = passedNavigation ?? fallback;
  const navigateToMainTabs = useNavigateToMainTabs(navigation);

  const handleGoToMenu = React.useCallback(
    async (restaurantSlug: string, referrer?: MenuScreenReferrer) => {
      return navigateToMainTabs('MenuTab', {
        screen: 'Menu',
        params: { restaurantSlug, referrer },
      });
    },
    [navigateToMainTabs],
  );

  const handleGoToDeliveryMenu = React.useCallback(
    async (addressId: string, referrer?: MenuScreenReferrer) => {
      return navigateToMainTabs('MenuTab', {
        screen: 'DeliveryMenu',
        params: { addressId, referrer },
      });
    },
    [navigateToMainTabs],
  );

  const handleGoToProductDetails = React.useCallback(
    (productSlug: string, restaurantSlug: string) => {
      navigation.navigate('Modal', {
        screen: 'ProductDetails',
        params: { productSlug, restaurantSlug },
      });
    },
    [navigation],
  );

  const handleGoToDeliveryProductDetails = React.useCallback(
    (productSlug: string, addressId: string) => {
      navigation.navigate('Modal', {
        screen: 'DeliveryProductDetails',
        params: { productSlug, addressId },
      });
    },
    [navigation],
  );

  const handleGoToLocations = React.useCallback(() => {
    navigation.navigate('MainTabs', {
      screen: 'MenuTab',
      params: {
        screen: 'Locations',
      },
    });
  }, [navigation]);

  return {
    handleGoToMenu,
    handleGoToDeliveryMenu,
    handleGoToProductDetails,
    handleGoToDeliveryProductDetails,
    handleGoToLocations,
  };
};
