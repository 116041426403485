import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { format, isValid } from 'date-fns';

import { ModalDialogue, ModalRow } from '../../../Modal';
import { VStack } from '../../../Stack';
import { BodyText, DisplayText, LabelText, TextLinkify } from '../../../Text';
import { TextLink } from '../../../TextLink';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyOfferCardDetailsModal = (
  props: LoyaltyOfferCardDetailsModalProps,
) => {
  const {
    isVisible,
    title,
    expirationDate,
    text,
    expirationDatePrefix,
    accessibilityLabel,
    onRequestClose,
  } = props;

  const maybeExpirationDate = formattedExpirationDate(expirationDate);

  // ─────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      visible={isVisible}
      size="small"
      accessibilityLabel={accessibilityLabel}
      onRequestClose={onRequestClose}
      withBottomPadding
    >
      <ModalRow>
        <View style={styles.container}>
          <DisplayText sizeMatch={['24']}>{title}</DisplayText>

          {maybeExpirationDate ? (
            <LabelText sizeMatch={['12']}>
              {[expirationDatePrefix, maybeExpirationDate]
                .filter(Boolean)
                .join(' ')}
            </LabelText>
          ) : null}

          <BodyText sizeMatch={['16']} style={styles.text}>
            <TextLinkify>{text}</TextLinkify>
          </BodyText>

          <VStack gap={0} style={styles.linksContainer}>
            <TextLink href={TERMS_LINK} sizeMatch={['16']}>
              {TERMS_LINK}
            </TextLink>

            <TextLink href={REWARDS_TERMS_LINK} sizeMatch={['16']}>
              {REWARDS_TERMS_LINK}
            </TextLink>
          </VStack>
        </View>
      </ModalRow>
    </ModalDialogue>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    gap: theme.spacing['2'],
    paddingTop: theme.spacing['6'],
    paddingBottom: theme.spacing['10'],
  },
  text: {
    marginTop: theme.spacing['2'],
  },
  linksContainer: {
    marginTop: theme.spacing['6'],
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

function formattedExpirationDate(expirationDate: string) {
  const date = new Date(expirationDate);

  if (!isValid(date)) return;

  return format(date, 'MM/dd');
}

// ─── Constants ───────────────────────────────────────────────────────────────

const TERMS_LINK = 'https://www.sweetgreen.com/terms';
const REWARDS_TERMS_LINK = 'https://www.sweetgreen.com/sweet-rewards';

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyOfferCardDetailsModalProps = {
  isVisible: boolean;
  onRequestClose: () => void;

  title: string;
  text: string;
  expirationDate: string;

  /**
   * @example "Until"
   */
  expirationDatePrefix: string;
  accessibilityLabel: string;
};
