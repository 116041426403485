import { useMemo } from 'react';
import {
  Easing,
  useAnimatedStyle,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
  type WithTimingConfig,
} from 'react-native-reanimated';

export const useLoyaltyHomeProgressBarAnimation = (
  props: ProgressBarAnimationProps,
) => {
  const { availablePoints, totalPoints, pointsRewards, progressBarWidth } =
    props;

  const availableBarWidth = useProgressBarsCalculations({
    points: availablePoints,
    pointsRewards,
    progressBarWidth,
  });

  const pendingBarWidth = useProgressBarsCalculations({
    points: totalPoints,
    pointsRewards,
    progressBarWidth,
  });

  const availableBarStyle = useAnimatedStyle(
    () => ({
      minWidth: availablePoints === 0 ? 0 : '3%',
      width: withTiming(availableBarWidth, PROGRESS_BAR_ANIMATION),
    }),
    [availableBarWidth],
  );

  const pendingBarStyle = useAnimatedStyle(
    () => ({
      width: withDelay(
        1200,
        withTiming(pendingBarWidth, PROGRESS_BAR_ANIMATION),
      ),
      opacity: withRepeat(
        withSequence(
          withTiming(0.5, { duration: 1800 }),
          withTiming(1, { duration: 1200 }),
        ),
        -1,
        true,
      ),
    }),
    [pendingBarWidth],
  );

  return {
    availableBarStyle,
    pendingBarStyle,
  };
};

/**
 * Given that the nodes are equally distanced regardless of the points value,
 * we can assume all milestones have the same width, and calculate based on that.
 */
const useProgressBarsCalculations = (props: ProgressBarCalculationProps) => {
  return useMemo(() => {
    const { progressBarWidth, points, pointsRewards } = props;

    // How much a single milestone contributes to the total size.
    const milestoneCount = pointsRewards.length + 1; // for the 0 milestone.
    const milestoneWidth = progressBarWidth / milestoneCount;

    // Finding the last completed milestone.
    const lastCompletedMilestoneIndex = pointsRewards.findLastIndex(
      (reward) => points >= (reward.points ?? 0),
    );

    // If all milestones are completed, return full bar width.
    if (lastCompletedMilestoneIndex === pointsRewards.length - 1) {
      return progressBarWidth;
    }

    // Empty state for this progress bar is for it to be empty.
    if (points === 0) {
      return 0;
    }

    // Points for the this milestone.
    const thisMilestone = pointsRewards[lastCompletedMilestoneIndex];
    const thisMilestonePoints = thisMilestone?.points ?? 0;

    // Points for the next milestone.
    const nextMilestone = pointsRewards[lastCompletedMilestoneIndex + 1];
    const nextMilestonePoints = nextMilestone.points ?? 0;

    // Points left over from completing this milestone.
    const milestonePointsDifference = nextMilestonePoints - thisMilestonePoints;
    const leftOverPoints = points - thisMilestonePoints;
    const leftOverCompletion = leftOverPoints / milestonePointsDifference;

    // How much is completed in between milestones (+1 for the 0 milestone).
    const completedMilestones = lastCompletedMilestoneIndex + 1;
    const totalCompletion = completedMilestones + leftOverCompletion + 1;
    const gapAdjustment = completedMilestones;

    // How many milestones are completed (in decimal) times the milestone width.
    // This is deducted half of the milestone width so the bar is closer to the dot.
    // This is increased the amount of completed milestones to adjust for the gap.
    return Math.min(
      totalCompletion * milestoneWidth - milestoneWidth / 2 + gapAdjustment,
      progressBarWidth,
    );
  }, [props]);
};

// ─── Constants ──────────────────────────────────────────────────────────────

const PROGRESS_BAR_ANIMATION: WithTimingConfig = {
  duration: 1200,
  easing: Easing.bezier(0.5, 0.5, 0.25, 1),
};

// ─── Types ──────────────────────────────────────────────────────────────────

type ProgressBarAnimationProps = {
  progressBarWidth: number;
  availablePoints: number;
  totalPoints: number;
  pointsRewards: ReadonlyArray<{ points?: number | null }>;
};

type ProgressBarCalculationProps = {
  progressBarWidth: number;
  points: number;
  pointsRewards: ReadonlyArray<{ points?: number | null }>;
};
