import React, { useEffect } from 'react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import { useRedirectAfterAuthStateChange } from '../../navigation';
import {
  JoinOrSignInScreenContentEmbeddedFrameFlow,
  JoinOrSignInScreenContentHybridFlow,
} from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const JoinOrSignInScreen = () => {
  const { customer } = useCustomer();
  const isLoggedIn = useIsLoggedIn();
  const redirectAfterAuthStateChange = useRedirectAfterAuthStateChange();
  const { track } = useTelemetry();

  const isHybridAuthModeEnabled = useFeatureFlag('cels-2041-auth-hybrid-flow', {
    listenForChanges: true,
  });

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!isLoggedIn || !customer.id) return;

    track('sign-in.success', { userId: customer.id });
    redirectAfterAuthStateChange();
  }, [customer.id, isLoggedIn, redirectAfterAuthStateChange, track]);

  // ─────────────────────────────────────────────────────────────────────

  if (isHybridAuthModeEnabled) {
    return <JoinOrSignInScreenContentHybridFlow />;
  }

  return <JoinOrSignInScreenContentEmbeddedFrameFlow />;
};
