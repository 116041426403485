import React, {
  forwardRef,
  type RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Pressable, View } from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../utils';
import { focusOnElement } from '../../utils/accessibility/focus/focusOnElement.web';
import { CollapseIndicatorIcon } from '../Collapsible';
import { FadeView } from '../FadeView';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { BodyText } from '../Text';
import { styles, TRIGGER_BOX_HEIGHT } from './Select.styles';
import type { TriggerProps, TriggerSelectedOptionProps } from './Select.types';

export const Trigger = forwardRef<View, TriggerProps>((props, ref) => {
  const {
    selectedOption,
    opened,
    accessibilityLabel,
    selectId,
    variation,
    isInteracting,
    isLoading,
    onLayout,
    onPress,
  } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const [isFirstPress, setFirstPress] = useState(true);

  // ─── Styles ──────────────────────────────────────────────────────────

  const triggerStyles = [
    styles.row,
    styles.trigger,
    variation === 'box' ? styles.triggerBox : undefined,
  ];
  const hoverContainerStyles =
    variation === 'box' ? styles.triggerHoverBox : styles.triggerHover;

  const iconSize = variation === 'box' ? 16 : 24;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleTrigger = useCallback(() => {
    setFirstPress(false);
    onPress();
  }, [setFirstPress, onPress]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (opened || isFirstPress) return;
    focusOnElement(ref as RefObject<View>);
  }, [ref, isFirstPress, opened]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.triggerWrapper}>
      <FadeView show={isInteracting} style={hoverContainerStyles} />

      {isLoading ? (
        <LoadingPlaceholder
          palette="cream"
          borderRadius={theme.radius.small}
          rowHeight={TRIGGER_BOX_HEIGHT}
          floating
        />
      ) : null}

      <Pressable
        ref={ref}
        style={triggerStyles}
        accessibilityRole="button"
        accessibilityLabel={accessibilityLabel}
        onLayout={onLayout}
        onPress={handleTrigger}
        {...{
          accessibilityExpanded: opened,
          accessibilityControls: selectId,
        }}
      >
        <TriggerSelectedOption
          selectedOption={selectedOption}
          bold={variation !== 'box'}
        />

        <CollapseIndicatorIcon
          style={styles.collapseIcon}
          iconColor={theme.colors.TEXT_COLOR}
          collapsed={!opened}
          width={iconSize}
          height={iconSize}
        />
      </Pressable>
    </View>
  );
});

// ─── Sub Components ──────────────────────────────────────────────────────────

const TriggerSelectedOption = (props: TriggerSelectedOptionProps) => {
  const { bold, selectedOption = '' } = props;

  return (
    <BodyText
      size={4}
      bold={bold}
      style={webOnlyStyles({ userSelect: 'none' })}
    >
      {selectedOption}
    </BodyText>
  );
};
