import { type ComponentProps, useCallback } from 'react';
import { useResponsive } from '@sg/garnish';
import { FlattenedOrderStatuses } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { type Home } from '@order/features/home';
import { useTelemetry } from '@order/Telemetry';

import { useHomeBannerContent, useHomeLastOrderStatus } from '../../hooks';
import { useHomeNavigation } from '../useHomeNavigation';
import {
  type BannerOrder,
  getActiveOrderBanner,
  getLateOrderBanner,
  getLoggedOutXsBanner,
  getOrderNotTrackableBanner,
  getPendingFeedbackBanner,
  getPromoBanner,
} from './homeBanners';

/**
 * The priority order for the home banner is as follows.
 * 1. Logged out.
 * 2. Rate last order.
 * 3. Late order.
 * 4. Un-trackable order.
 * 5. Active order.
 * 7. Promo / marketing.
 * 8. No banner.
 */
export const useHomeBannerInfo = (): BannerProps | null | undefined => {
  const bannerContent = useHomeBannerContent();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const lastOrder = useHomeLastOrderStatus('cache-only');

  const navigationOptions = useHomeNavigation(lastOrder.orderId);

  // ─── Telemetry ────────────────────────────────────────────────────────────

  const { track } = useTelemetry();
  const navigateWithDeeplink = useCallback(() => {
    const isPromoBannerCTA = bannerContent.bannerPromoDeepLink !== undefined;

    track('home.banner.cta_tapped', {
      destination: bannerContent.bannerPromoDeepLink ?? 'missing',
    });

    if (isPromoBannerCTA) {
      track('home.promo.cta_tapped');

      return;
    }

    track('home.orderstatus.cta_tapped');
  }, [bannerContent.bannerPromoDeepLink, track]);

  // ─── State ────────────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const { isXS } = useResponsive().currentBreakpoint;
  const isLoading =
    lastOrder.isLoadingLastOrder || bannerContent.isFetchingPromoBanner;

  const wasOrderCompleted =
    lastOrder.orderStatus === FlattenedOrderStatuses.Completed;
  const wasOrderRated = wasOrderCompleted && lastOrder.isRated;
  const isOrderActive = lastOrder.isRecent && !wasOrderRated;

  // ─── Loading ──────────────────────────────────────────────────────────────

  if (isLoading) return null;

  // ─── Logged Out ───────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return isXS
      ? getLoggedOutXsBanner(bannerContent, navigationOptions)
      : undefined;
  }

  // ─── Pending Feedback ─────────────────────────────────────────────────────

  if (lastOrder.isPendingFeedback) {
    return getPendingFeedbackBanner(bannerContent, navigationOptions);
  }

  // ─── Late Order ───────────────────────────────────────────────────────────

  if (lastOrder.isLate) {
    return getLateOrderBanner(bannerContent, navigationOptions);
  }

  // ─── Order Not Trackable ──────────────────────────────────────────────────

  if (lastOrder.isRecent && !lastOrder.isTrackable && lastOrder.orderTime) {
    return getOrderNotTrackableBanner(
      lastOrder as BannerOrder,
      bannerContent,
      navigationOptions,
    );
  }

  // ─── Active Order ─────────────────────────────────────────────────────────

  if (isOrderActive && lastOrder.orderTime && lastOrder.orderStatus) {
    return getActiveOrderBanner(
      lastOrder as BannerOrder,
      bannerContent,
      navigationOptions,
    );
  }

  // ─── Promo Banner ─────────────────────────────────────────────────────────

  if (
    bannerContent.bannerPromoMessage &&
    bannerContent.bannerPromoCta &&
    bannerContent.bannerPromoDeepLink
  ) {
    return getPromoBanner(
      bannerContent.bannerPromoMessage,
      bannerContent.bannerPromoCta,
      bannerContent.bannerPromoDeepLink,
      navigateWithDeeplink,
    );
  }
};

// ─── Types ──────────────────────────────────────────────────────────────────

type BannerProps = ComponentProps<typeof Home.Banner>;
