import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  gift: {
    defaultMessage: 'Gift',
    description: 'Navbar | Gift | Title',
  },
  giftA11y: {
    defaultMessage: 'Header | Gift',
    description: 'Navbar | Gift | Accessibility Label',
  },
  loyalty: {
    defaultMessage: 'Rewards',
    description: 'Navbar | Loyalty | Title',
  },
  loyaltyWithPoints: {
    defaultMessage: 'Rewards • {points} points',
    description: 'Navbar | Loyalty | Title With Points',
  },
  loyaltyA11y: {
    defaultMessage: 'Header | Loyalty',
    description: 'Navbar | Loyalty | Accessibility Label',
  },
  menu: {
    defaultMessage: 'Menu',
    description: 'Navbar | Menu | Title',
  },
  menuA11y: {
    defaultMessage: 'Header | Menu',
    description: 'Navbar | Menu | Accessibility Label',
  },
  homeA11y: {
    defaultMessage: 'Header | Home',
    description: 'Navbar | Home | Accessibility Label',
  },
  order: {
    defaultMessage: 'Order',
    description: 'Navbar | Order | Title',
  },
  orderA11y: {
    defaultMessage: 'Header | Order',
    description: 'Navbar | Order | Accessibility Label',
  },
  bagA11y: {
    defaultMessage: 'Header | Bag',
    description: 'Navbar | Bag | Accessibility Label',
  },
  accountA11y: {
    defaultMessage: 'Header | Account',
    description: 'Navbar | Account | Accessibility Label',
  },
});
