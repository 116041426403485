import type { UpdatesConfig } from '@urql/exchange-graphcache';

import type {
  AddLineItemToCartMutation,
  ApplyRewardMutation,
  EditLineItemInCartMutation,
  RemoveFromCartMutation,
  RemoveRewardMutation,
  ReorderLineItemMutation,
  ReorderOrderMutation,
  SubmitOrderUsingPaymentMethodMutation,
} from '@order/graphql';

import {
  deletePaymentMethodCard,
  invalidateCartCacheEntry,
  updateCartCache,
  updateOrderPostSubmission,
  updatePaymentMethod,
} from './utils';
import { addAddress, deleteAddress, updateAddress } from './utils/addresses';

/**
 * Graphcache | Updates
 * A nested mapping of updater functions for mutation (and subscription) fields,
 * which may be used to add side-effects that update other parts of the cache
 * when the given subscription or mutation field is written to the cache.
 *
 * @see {@link: https://formidable.com/open-source/urql/docs/graphcache/cache-updates/}
 */

export const updates: UpdatesConfig = {
  Mutation: {
    // this force invalidates all the cache entries on logout
    logout(_result, _args, cache, _info) {
      cache.invalidate('Query');
      cache.invalidate('Mutation');
    },

    //
    // ─── CART UPDATES ────────────────────────────────────────────────
    //

    /*
      The following helpers modifies the 'cart' cache entry for the respective mutations.

      urql uses the `__typename` and `id` fields to update the cache entries,
      but sometimes we need to override the cart entry when the ID is different;
      therefore, we must manually handle these updates.

      Add here any cart-related update handler.
     */

    addLineItemToCart(result: AddLineItemToCartMutation, _args, cache) {
      updateCartCache(cache, result.addLineItemToCart);
    },
    editLineItemInCart(result: EditLineItemInCartMutation, _args, cache) {
      updateCartCache(cache, result.editLineItemInCart);
    },
    removeFromCart(result: RemoveFromCartMutation, _args, cache) {
      updateCartCache(cache, result.removeFromCart);
    },
    applyReward(result: ApplyRewardMutation, _args, cache) {
      updateCartCache(cache, result.applyReward);
    },
    removeReward(result: RemoveRewardMutation, _args, cache) {
      updateCartCache(cache, result.removeReward);
    },
    reorderOrder(result: ReorderOrderMutation, _args, cache) {
      updateCartCache(cache, result.reorderOrder);
    },
    reorderLineItem(result: ReorderLineItemMutation, _args, cache) {
      updateCartCache(cache, result.reorderLineItem);
    },
    submitOrderUsingPaymentMethod(
      result: SubmitOrderUsingPaymentMethodMutation,
      _args,
      cache,
    ) {
      invalidateCartCacheEntry(cache, result.submitOrderUsingPaymentMethod);
      updateOrderPostSubmission(cache, result.submitOrderUsingPaymentMethod);
    },

    // ─── Payment Methods Cache ─────────────────────────────────────────

    updatePaymentMethod,
    deletePaymentMethodCard,

    // ─── Addresses Cache ───────────────────────────────────────────────

    addAddress,
    updateAddress,
    deleteAddress,
  },
};
