/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GiftCardPurchaseHistoryQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GiftCardPurchaseHistoryQuery = {
  readonly __typename?: 'Query';
  readonly giftCardPurchaseHistory: {
    readonly __typename: 'GiftCardPurchaseHistory';
    readonly giftCardOrders: ReadonlyArray<{
      readonly __typename: 'GiftCardOrderDetail';
      readonly id: string;
      readonly purchasedAt: string;
      readonly deliveryDetail: {
        readonly __typename?: 'GiftCardDeliveryDetail';
        readonly deliveryMethods: ReadonlyArray<string>;
      };
      readonly userGiftCards: ReadonlyArray<{
        readonly __typename: 'UserGiftCard';
        readonly id: string;
        readonly balance: number;
        readonly recipientEmail: string | null;
        readonly recipientName: string;
      }>;
    }>;
  };
};

export const GiftCardPurchaseHistoryDocument = gql`
  query GiftCardPurchaseHistory {
    giftCardPurchaseHistory {
      __typename
      giftCardOrders {
        id
        __typename
        purchasedAt
        deliveryDetail {
          deliveryMethods
        }
        userGiftCards {
          id
          __typename
          balance
          recipientEmail
          recipientName
        }
      }
    }
  }
`;

export function useGiftCardPurchaseHistoryQuery(
  options?: Omit<
    Urql.UseQueryArgs<GiftCardPurchaseHistoryQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GiftCardPurchaseHistoryQuery,
    GiftCardPurchaseHistoryQueryVariables
  >({ query: GiftCardPurchaseHistoryDocument, ...options });
}
