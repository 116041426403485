/* eslint-disable @typescript-eslint/no-var-requires */

import { type ContentfulClientApi, type CreateClientParams } from 'contentful';

/**
 * Creates a new Contentful client instance.
 */
export function createContentfulClient(
  params: CreateClientParams,
): ContentfulClientApi {
  // NOTE: We use `require` instead of `import`, since it  causes issues
  //       with Metro resolver.
  const contentful = require('contentful/dist/contentful.browser.min.js');

  return contentful.createClient(params);
}
