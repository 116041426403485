import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { OrderToHealthKit } from '@order/components';

/**
 * Wrapper to the {OrderToHealthKit} for order status styling.
 */
export const OrderStatusHealthKit = (props: Props) => {
  return (
    <View style={styles.orderToHealthKitContainer}>
      <OrderToHealthKit {...props} />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  orderToHealthKitContainer: {
    borderTopWidth: 1,
    borderTopColor: theme.colors.GRAY,
    marginTop: theme.spacing['10'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = ComponentProps<typeof OrderToHealthKit>;
