import type { Location, LocationsSearchType } from '../../machine';

/**
 * A small helper that returns location's order channel (aka location type).
 */
export function getLocationOrderChannel(
  location: LocationWithChannelAttributes,
): LocationsSearchType {
  const isDeliveryOrder = Boolean(location?.isDelivery);
  const isOutpostOrder = Boolean(location?.isOutpost);

  if (isDeliveryOrder) return 'delivery';

  if (isOutpostOrder) return 'outpost';

  return 'pickup';
}

// ─── Types ───────────────────────────────────────────────────────────────────

type LocationWithChannelAttributes = Pick<Location, 'isOutpost' | 'isDelivery'>;
