import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Dimensions } from 'react-native';
import { type Asset } from 'contentful';
import { getContentfulImageUrl, theme } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useContentfulContentTypeEntry } from '@order/Contentful';

import { useHomeIsPostOrder } from '../useHomeIsPostOrder';
import { mainHomeHeroImageSM, mainHomeHeroImageXS } from './assets';

export const useHomeContent = () => {
  const { data, fetching } =
    useContentfulContentTypeEntry<HomeContainerContent>({
      contentType: HOME_CONTENT_TYPE,
    });

  const fallbackHomeContent = useFallbackHomeContent();

  const homeLoggedOutContent = useHomeContentWithFallback(
    fallbackHomeContent,
    data?.fields?.loggedOut?.fields,
  );

  const homePreOrderContent = useHomeContentWithFallback(
    fallbackHomeContent,
    data?.fields?.preOrder?.fields,
  );

  const homePostOrderContent = useHomeContentWithFallback(
    fallbackHomeContent,
    data?.fields?.postOrder?.fields,
  );

  const isLoggedIn = useIsLoggedIn();
  const { hasPlacedOrder, isPostOrder, isLoadingPostOrder } =
    useHomeIsPostOrder('cache-first');

  const isFetchingHomeContent = fetching || isLoadingPostOrder;

  if (!isLoggedIn) {
    return {
      homeContent: homeLoggedOutContent,
      isFetchingHomeContent,
      hasPlacedOrder,
    };
  }

  if (isPostOrder) {
    return {
      homeContent: homePostOrderContent,
      isFetchingHomeContent,
      hasPlacedOrder,
    };
  }

  return {
    homeContent: homePreOrderContent,
    isFetchingHomeContent,
    hasPlacedOrder,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const useHomeContentWithFallback = (
  fallbackHomeContent: ReturnType<typeof useFallbackHomeContent>,
  content?: HomeContent,
) => {
  return useMemo(() => {
    if (!content) return fallbackHomeContent;

    const {
      eyebrowText = fallbackHomeContent.eyebrowText,
      heroText = fallbackHomeContent.heroText,
      ctaText = fallbackHomeContent.ctaText,
      ctaLoyalty = fallbackHomeContent.ctaLoyalty,
      ctaDeepLink = fallbackHomeContent.ctaDeepLink,
      themeXS,
      mainImageXs,
      mainImageSm,
    } = content;

    return {
      eyebrowText,
      heroText,
      ctaText,
      ctaLoyalty,
      ctaDeepLink,
      themeXS,

      eyebrowTextColorXS: themeXS
        ? FONT_COLORS_XS[themeXS].eyebrowTextColor
        : fallbackHomeContent.eyebrowTextColorXS,

      heroTextColorXS: themeXS
        ? FONT_COLORS_XS[themeXS].heroTextColor
        : fallbackHomeContent.heroTextColorXS,

      eyebrowTextColorSM: fallbackHomeContent.eyebrowTextColorSM,
      heroTextColorSM: fallbackHomeContent.heroTextColorSM,

      mainImageXS: mainImageXs
        ? getContentfulImageUrl(
            mainImageXs.fields.file.url,
            CONTENTFUL_IMAGE_CONFIG_XS,
          )
        : mainHomeHeroImageXS,

      mainImageA11yXS:
        mainImageXs?.fields.description ?? fallbackHomeContent.mainImageA11yXS,

      mainImageSM: mainImageSm
        ? getContentfulImageUrl(
            mainImageSm.fields.file.url,
            CONTENTFUL_IMAGE_CONFIG_SM,
          )
        : mainHomeHeroImageSM,

      mainImageA11ySM:
        mainImageSm?.fields.description ?? fallbackHomeContent.mainImageA11ySM,
    };
  }, [content, fallbackHomeContent]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type HomeContainerContent = {
  loggedOut: { fields: HomeContent };
  preOrder: { fields: HomeContent };
  postOrder: { fields: HomeContent };
};

type HomeContent = {
  eyebrowText: string;
  heroText: string;
  ctaText: string;
  ctaDeepLink: string;
  ctaLoyalty: string;
  themeXS: 'light' | 'dark';
  mainImageXs: Asset;
  mainImageSm: Asset;
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  eyebrowText: {
    defaultMessage: 'Our newest protein',
    description: 'Home | Eyebrow | Fallback home eyebrow text',
  },
  heroText: {
    defaultMessage: 'Say hello to steak',
    description: 'Home | Hero | Fallback home hero text',
  },
  ctaText: {
    defaultMessage: 'Order now',
    description: 'Home | Hero CTA | Fallback home CTA',
  },
  ctaLoyalty: {
    defaultMessage: 'New! SG Rewards',
    description: 'Home | Loyalty CTA | Fallback loyalty CTA',
  },
  mainImageA11yXS: {
    defaultMessage: `A photo of the garlic steak on a plate placed on top of a wooden table.`,
    description: `Home | Main Image | a11y message for home image on XS viewport`,
  },
  mainImageA11ySM: {
    defaultMessage: `A photo of the garlic steak on a plate placed on top of a grass field.`,
    description: `Home | Main Image | a11y message for home image on SM+ viewport`,
  },
});

// ─── Fallback ────────────────────────────────────────────────────────────────

const useFallbackHomeContent = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      ...FALLBACK_HOME_CONTENT,
      eyebrowText: formatMessage(messages.eyebrowText),
      heroText: formatMessage(messages.heroText),
      ctaText: formatMessage(messages.ctaText),
      ctaLoyalty: formatMessage(messages.ctaLoyalty),
      mainImageA11yXS: formatMessage(messages.mainImageA11yXS),
      mainImageA11ySM: formatMessage(messages.mainImageA11ySM),
    }),
    [formatMessage],
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const [AVERAGE_WIDTH_XS, AVERAGE_HEIGHT_XS] = [383, 852];
const [AVERAGE_WIDTH_LG, AVERAGE_HEIGHT_LG] = [1920, 1080];
const DIMENSION_SCALE = Math.min(Dimensions.get('window').scale, 3);

const CONTENTFUL_IMAGE_CONFIG_XS = {
  fm: 'webp',
  q: 90,
  w: AVERAGE_WIDTH_XS * DIMENSION_SCALE,
  h: AVERAGE_HEIGHT_XS * DIMENSION_SCALE,
} as const;

const CONTENTFUL_IMAGE_CONFIG_SM = {
  fm: 'webp',
  q: 90,
  w: AVERAGE_WIDTH_LG,
  h: AVERAGE_HEIGHT_LG,
} as const;

const FONT_COLORS_XS = {
  light: {
    eyebrowTextColor: theme.colors.CREAM,
    heroTextColor: theme.colors.CREAM,
  },
  dark: {
    eyebrowTextColor: theme.colors.DARK_KALE,
    heroTextColor: theme.colors.DARK_KALE,
  },
};

const HOME_CONTENT_TYPE = 'orderHomeV2Container';
const FALLBACK_HOME_CONTENT = {
  ctaDeepLink: '/menu',
  eyebrowTextColorXS: theme.colors.CREAM,
  heroTextColorXS: theme.colors.CREAM,
  eyebrowTextColorSM: theme.colors.DARK_KALE,
  heroTextColorSM: theme.colors.DARK_KALE,
  mainImageXS: mainHomeHeroImageXS,
  mainImageSM: mainHomeHeroImageSM,
  themeXS: 'light',
};
