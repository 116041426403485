import { useEffect } from 'react';
import { useIsFocused } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useChallenges } from '@order/ChallengesAndRewards';

import { useChallengeRewardsWithRedemptionChannelQuery } from './graphql/ChallengeRewards.generated';

/**
 * Refreshes challenges and rewards when the screen gains/regains focus.
 * This necessary because submitting orders can change the status of challenges and rewards.
 * Since the submit order mutation doesn't update the cache, we update it upon re-entering the screen.
 */
export const useSweetpassChallengesAndRewards = () => {
  const isLoggedIn = useIsLoggedIn();
  const isScreenFocused = useIsFocused();

  const { challenges, isFetchingChallenges, fetchChallenges } = useChallenges();

  // ─── Queries ─────────────────────────────────────────────────────────

  const [challengeRewardsWithRedemptionChannel] =
    useChallengeRewardsWithRedemptionChannelQuery({
      requestPolicy: 'network-only',
      pause: !isLoggedIn || !isScreenFocused,
    });

  // ─── Derived Data ────────────────────────────────────────────────────

  const challengeRewards =
    challengeRewardsWithRedemptionChannel.data?.challengeRewards;
  const isFetchingChallengeRewards =
    challengeRewardsWithRedemptionChannel.fetching;

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!isLoggedIn || !isScreenFocused) return;

    fetchChallenges();
  }, [fetchChallenges, isLoggedIn, isScreenFocused]);

  return {
    challenges,
    challengeRewards,
    isFetchingChallenges,
    isFetchingChallengeRewards,
  };
};
