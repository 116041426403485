/* istanbul ignore file */ /* not actual functions */

import type { GraphCacheConfig } from '@order/graphql';

type GraphCacheKeys = GraphCacheConfig['keys'];

export const keys: GraphCacheKeys = {
  // No Identifiers
  AvailableWantedTime: () => null,
  EstimateRange: () => null,
  IngredientModification: () => null,
  IngredientModifications: () => null,
  IngredientModificationKind: () => null,
  InvalidInput: () => null,
  HasUnseenChallenges: () => null,
  Ledger: () => null,
  LedgerItem: () => null,
  Location: () => null,
  LocationSearchResult: () => null,
  Menu: () => null,
  NoValidRestaurants: () => null,
  OrderStatus: () => null,
  OrdersResponseSuccess: () => null,
  DeliveryOrderDetail: () => null,
  RestaurantHours: () => null,
  Vendor: () => null,
  CustomerPass: () => null,
  UserPointsStatus: () => null,
  Kustomer: () => null,
  ChatToken: () => null,

  Session: (data) => data.csrf ?? null,
  Restaurant: (data) => data.slug ?? data.id ?? null,
  Asset: (data) => data.url ?? null,
  LoyaltyProfile: (data) => data.customerId ?? null,
  GiftCardBalance: (data) => data.customerId ?? null,
  CanCancel: (data) => (data.orderId ? `${data.orderId}` : null),
};
