/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeliveryMenuContentAddressQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;

export type DeliveryMenuContentAddressQuery = {
  readonly __typename?: 'Query';
  readonly address: {
    readonly __typename: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly zipCode: string;
    readonly deliveryPreference: Types.DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string | null;
    readonly notes: string | null;
  } | null;
};

export type DeliveryMenuContentRestaurantQueryVariables = Types.Exact<{
  input: Types.LocationByAddressInput;
  costChannel?: Types.InputMaybe<Types.CostChannel>;
}>;

export type DeliveryMenuContentRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: Types.LocationStatus;
        readonly restaurant: {
          readonly __typename: 'Restaurant';
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly address: string;
          readonly phone: string;
          readonly isOutpost: boolean;
          readonly showDeliveryFeeDisclosure: boolean;
          readonly isAcceptingOrders: boolean;
          readonly notAcceptingOrdersReason: string;
          readonly deliveryFee: number;
          readonly flexMessage: string | null;
          readonly hours: {
            readonly __typename?: 'RestaurantHours';
            readonly formatted: string | null;
            readonly permanent: ReadonlyArray<{
              readonly __typename?: 'RestaurantStoreHours';
              readonly start: string;
              readonly end: string;
              readonly day: string;
            }>;
            readonly store: ReadonlyArray<{
              readonly __typename?: 'RestaurantStoreHours';
              readonly start: string;
              readonly end: string;
              readonly day: string;
            }>;
          };
          readonly menu: {
            readonly __typename: 'Menu';
            readonly id: string;
            readonly categories: ReadonlyArray<{
              readonly __typename: 'MenuCategory';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly description: string | null;
              readonly products: ReadonlyArray<{
                readonly __typename: 'Product';
                readonly id: string;
                readonly calories: number;
                readonly categoryId: string;
                readonly description: string;
                readonly isModifiable: boolean;
                readonly isCustom: boolean;
                readonly customType: Types.CustomProductType | null;
                readonly isSalad: boolean;
                readonly name: string;
                readonly outOfStock: boolean;
                readonly restaurantId: string;
                readonly slug: string;
                readonly throttleItem: boolean;
                readonly cost: number;
                readonly asset: {
                  readonly __typename?: 'Asset';
                  readonly url: string;
                };
                readonly ingredients: ReadonlyArray<{
                  readonly __typename: 'Ingredient';
                  readonly id: string;
                  readonly name: string;
                  readonly asset: {
                    readonly __typename?: 'Asset';
                    readonly url: string;
                  };
                }>;
                readonly label: {
                  readonly __typename: 'ProductLabel';
                  readonly id: string;
                  readonly name: string;
                } | null;
                readonly dietaryProperties: ReadonlyArray<{
                  readonly __typename: 'DietaryProperty';
                  readonly id: string;
                  readonly name: Types.DietaryPropertyKind;
                }>;
              }>;
            }>;
          };
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export const DeliveryMenuContentAddressDocument = gql`
  query DeliveryMenuContentAddress($addressId: ID!) {
    address(addressId: $addressId) {
      __typename
      id
      street
      secondaryStreet
      city
      state
      country
      zipCode
      deliveryPreference
      googlePlaceId
      latitude
      longitude
      name
      notes
    }
  }
`;

export function useDeliveryMenuContentAddressQuery(
  options: Omit<
    Urql.UseQueryArgs<DeliveryMenuContentAddressQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeliveryMenuContentAddressQuery,
    DeliveryMenuContentAddressQueryVariables
  >({ query: DeliveryMenuContentAddressDocument, ...options });
}
export const DeliveryMenuContentRestaurantDocument = gql`
  query DeliveryMenuContentRestaurant(
    $input: LocationByAddressInput!
    $costChannel: CostChannel
  ) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          id
          __typename
          slug
          name
          city
          state
          zipCode
          address
          phone
          isOutpost
          showDeliveryFeeDisclosure
          hours {
            formatted
            permanent {
              start
              end
              day
            }
            store {
              start
              end
              day
            }
          }
          isAcceptingOrders
          notAcceptingOrdersReason
          deliveryFee
          flexMessage
          menu {
            id
            __typename
            categories {
              id
              __typename
              name
              isCustom
              description
              products {
                id
                __typename
                asset {
                  url
                }
                calories
                categoryId
                cost: channelCost(costChannel: $costChannel)
                description
                ingredients {
                  id
                  __typename
                  name
                  asset {
                    url
                  }
                }
                isModifiable
                isCustom
                customType
                isSalad
                label {
                  id
                  __typename
                  name
                }
                dietaryProperties {
                  id
                  __typename
                  name
                }
                name
                outOfStock
                restaurantId
                slug
                throttleItem
              }
            }
          }
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
`;

export function useDeliveryMenuContentRestaurantQuery(
  options: Omit<
    Urql.UseQueryArgs<DeliveryMenuContentRestaurantQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    DeliveryMenuContentRestaurantQuery,
    DeliveryMenuContentRestaurantQueryVariables
  >({ query: DeliveryMenuContentRestaurantDocument, ...options });
}
