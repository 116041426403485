/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PickupOutpostRestaurantNameQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type PickupOutpostRestaurantNameQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename: 'Restaurant';
    readonly id: string;
    readonly name: string;
  } | null;
};

export const PickupOutpostRestaurantNameDocument = gql`
  query PickupOutpostRestaurantName($id: ID!) {
    restaurant(id: $id) {
      id
      __typename
      name
    }
  }
`;

export function usePickupOutpostRestaurantNameQuery(
  options: Omit<
    Urql.UseQueryArgs<PickupOutpostRestaurantNameQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    PickupOutpostRestaurantNameQuery,
    PickupOutpostRestaurantNameQueryVariables
  >({ query: PickupOutpostRestaurantNameDocument, ...options });
}
