import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { openBrowserAsync } from 'expo-web-browser';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import {
  useKustomer,
  useKustomerAvailability,
} from '../../integrations/kustomer';

/**
 * Featured flagged contact us handler.
 * Will open Sierra if its enabled.
 * Otherwise will open Kustomer if its enabled.
 * Fallback to opening https://www.sweetgreen.com/contact.
 */
export const useContactUsHandler = (
  source: 'account' | 'order-history' | 'org-links' | 'order-status' | 'scan',
) => {
  const { track } = useTelemetry();

  const isKustomerAvailable = useKustomerAvailability();
  const isSierraEnabled = useFeatureFlag('permanent-sierra-enabled');

  const { open: openKustomer, openingKustomer } = useKustomer();
  const { navigate } = useNavigation();

  const handleContactUs = useCallback(async () => {
    track('contact_us', {
      source,
      destination: getDestination(isKustomerAvailable, isSierraEnabled),
    });

    if (isSierraEnabled) {
      navigate('Modal', { screen: 'Chat' });

      return;
    }

    if (isKustomerAvailable) {
      await openKustomer();

      return;
    }

    await openBrowserAsync('https://www.sweetgreen.com/contact', {
      windowFeatures: { target: '_blank' },
    });
  }, [
    source,
    isKustomerAvailable,
    isSierraEnabled,
    openKustomer,
    navigate,
    track,
  ]);

  return {
    handleContactUs,
    handlingContactUs: openingKustomer,
    hasDynamicChat: isKustomerAvailable || isSierraEnabled,
  };
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

const getDestination = (
  isKustomerAvailable: boolean,
  isSierraEnabled: boolean,
) => {
  if (isSierraEnabled) return 'sierra';

  if (isKustomerAvailable) return 'kustomer';

  return 'website';
};
