import type { RequestPolicy } from 'urql';

import { useIsLoggedIn } from '@order/AuthMachine';
import { TierStatusName } from '@order/graphql';
import { DateDurationUnit, useLocalizationContext } from '@order/Localization';

import { useSweetpassTierQuery } from './graphql/SweetpassTier.generated';
import { getSweetpassTierNameLocalizationId } from './utils';

/**
 * Returns the sweetpass tier information.
 * This will create a loyalty profile if it doesn't already exists.
 * Due to complications with Postgres, we would like to avoid over-fetching this for new accounts.
 * So most use cases should keep the default request policy of "cache-first".
 * While {refetchSweetpassTier} must be called every time that tier changes:
 * - optIn (changing from opted out to sweetpass)
 * - subscribe (changing from sweetpass to sweetpass+)
 * - cancel free trial (changing from sweetpass+ to sweetpass)
 */
export const useSweetpassTier = (
  requestPolicy: RequestPolicy = 'cache-first',
) => {
  const isLoggedIn = useIsLoggedIn();
  const { t, formatDurationFromUnit } = useLocalizationContext();

  const [sweetpassTierResponse, refetchSweetpassTier] = useSweetpassTierQuery({
    requestPolicy,
    pause: !isLoggedIn,
  });

  const loyaltyProfile = sweetpassTierResponse?.data?.loyaltyProfile;
  const loyaltyId = loyaltyProfile?.loyaltyId;
  const joinDate = loyaltyProfile?.joinDate;
  const sweetpassTier = loyaltyProfile?.tier;
  const sweetpassTierId = loyaltyProfile?.tier?.id;
  const sweetpassTierName = sweetpassTier?.name;
  const sweetpassTierStatus = loyaltyProfile?.tierStatus;

  const sweetpassNextTier = loyaltyProfile?.tier?.nextTier;
  const sweetpassNextTierId = sweetpassNextTier?.id;
  const sweetpassNextTierName = sweetpassNextTier?.name;

  const hasTrialMembership =
    sweetpassTierStatus?.name === TierStatusName.FreeTrial;
  const duration = 0;
  const durationUnit = DateDurationUnit.Day;
  const trialDuration = formatDurationFromUnit({ duration, durationUnit });
  const trialDurationSingular = formatDurationFromUnit({
    duration,
    durationUnit,
    singular: true,
  });
  const isEligibleForFreeTrial = false;

  const formattedSweetpassTierName = t(
    getSweetpassTierNameLocalizationId(sweetpassTierName, hasTrialMembership),
  );

  const isSweetpassTierLoading = sweetpassTierResponse?.fetching;
  const hasUnknownTierError =
    !loyaltyId && Boolean(sweetpassTierResponse?.error);

  return {
    joinDate,
    loyaltyId,
    hasTrialMembership,
    sweetpassTierId,
    sweetpassTierName,
    formattedSweetpassTierName,
    sweetpassNextTierId,
    sweetpassNextTierName,
    isSweetpassTierLoading,
    isEligibleForFreeTrial,
    trialDuration,
    trialDurationSingular,
    hasUnknownTierError,
    refetchSweetpassTier,
  };
};
