import React, { useCallback, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import { logger as LOG } from '@garnish/logger';
import {
  HostedFrame,
  type HostedFrameMessageEvent,
  type HostedFrameRef,
  IconLink,
  LoadingAnimation,
  theme,
  useKeyboardAvoiderPadding,
  useResponsive,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useCustomer } from '@order/Customer';
import { getEnvVars } from '@order/utils';

import { NotFoundView } from '../NotFoundView';
import { useChatTokenQuery } from './GraphQL/ChatToken.generated';

export const SierraHostedFrame = (props: HostedFrameProps) => {
  const { onClose } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const isLoggedIn = useIsLoggedIn();
  const [isReady, setIsReady] = useState(false);
  const sierraHostedFrameRef = useRef<HostedFrameRef>(null);

  // ─── Remote Data ─────────────────────────────────────────────────────

  const { customer } = useCustomer();
  const [tokenResponse] = useChatTokenQuery({ pause: !isLoggedIn });

  const customerId = customer.id;
  const authToken = tokenResponse.data?.chatToken?.token;
  const isFetchingToken = tokenResponse.fetching;
  const hasError = tokenResponse.error;

  // ─── Styling ─────────────────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const { match } = useResponsive();
  const containerStyle = match([styles.containerXS, styles.containerSM]);

  const { bottom } = useSafeAreaInsets();
  const fullHeight = useWindowDimensions().height;
  const frameHeight = match([fullHeight - bottom, FRAME_HEIGHT]);
  const minHeight = useStyle(() => ({ minHeight: frameHeight }), [frameHeight]);

  const { keyboardPadding } = useKeyboardAvoiderPadding();
  const paddingBottom = useStyle(
    () => ({
      paddingBottom: (keyboardPadding?.paddingBottom ?? 0) + bottom,
    }),
    [bottom, keyboardPadding],
  );

  // ─── Messaging ───────────────────────────────────────────────────────

  const handleOnMessage = useCallback(
    (message: HostedFrameMessageEvent) => {
      const { log, error, ready, initialized, opened, closed } =
        parseMessage(message);

      if (log) {
        logger.info(log);
      }

      if (error) {
        logger.error(error);
      }

      if (closed) onClose();

      if (opened) {
        setIsReady(true);
      }

      if (ready) {
        sierraHostedFrameRef.current?.postMessage?.(
          JSON.stringify({ type: 'INITIALIZE', customerId, authToken }),
        );
      }

      if (initialized) {
        sierraHostedFrameRef.current?.postMessage?.(
          JSON.stringify({ type: 'OPEN' }),
        );
      }
    },
    [sierraHostedFrameRef, customerId, authToken, onClose],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={[containerStyle, minHeight, paddingBottom]}>
      {isReady && !hasError ? null : <Header onClose={onClose} />}

      {hasError ? <ErrorMessage /> : null}

      {isReady || hasError ? null : <LoadingIndicator />}

      {isFetchingToken || hasError ? null : (
        <HostedFrame
          title={formatMessage(messages.title)}
          ref={sierraHostedFrameRef}
          frameId={FRAME_ID}
          frameHeight={frameHeight}
          source={SOURCE}
          containerStyle={isReady ? null : styles.hideContent}
          onMessage={handleOnMessage}
        />
      )}
    </View>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const Header = (props: Pick<HostedFrameProps, 'onClose'>) => {
  const { onClose } = props;
  const { formatMessage } = useIntl();

  return (
    <View style={styles.header}>
      <IconLink
        name="IconClose"
        accessibilityLabel={formatMessage(messages.close)}
        width={24}
        height={24}
        onPress={onClose}
      />
    </View>
  );
};

const LoadingIndicator = () => {
  return (
    <View style={styles.loadingContainer}>
      <LoadingAnimation />
    </View>
  );
};

const ErrorMessage = () => {
  return (
    <View style={styles.errorContainer}>
      <NotFoundView.Title sizeMatch={['32']} />
      <NotFoundView.Image />
      <NotFoundView.Description />
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

LOG.enable('Sierra');
const logger = LOG.extend('Sierra');
const FRAME_ID = 'sierra';
const FRAME_HEIGHT = 480;
const URL = getEnvVars().SIERRA_EMBEDDED_CHATBOT_URL;
const SOURCE = { uri: URL };

// ─── Helpers ─────────────────────────────────────────────────────────────────

const parseMessage = (event: HostedFrameMessageEvent) => {
  try {
    const dataString = event.nativeEvent.data ?? '{}';
    const parsedData = JSON.parse(dataString) as HostedFieldsPayload;

    return parsedData;
  } catch {
    return { error: 'Failed to parse JSON message.' };
  }
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Sierra Chat',
    description: 'Sierra | Chat | Title',
  },
  close: {
    defaultMessage: 'Close Chat',
    description: 'Sierra | Chat | Close',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.colors.WHITE,
  },
  containerSM: {
    position: 'absolute',
    minWidth: 300,
    bottom: theme.spacing['4'],
    right: theme.spacing['4'],
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
    borderRadius: theme.radius.medium,
    shadowColor: theme.colors.BLACK,
    shadowOpacity: 0.25,
    shadowOffset: { width: 4, height: 4 },
    shadowRadius: theme.radius.medium,
    overflow: 'hidden',
  },
  loadingContainer: {
    ...StyleSheet.absoluteFillObject,
    zIndex: theme.zIndex.popover,
    backgroundColor: theme.colors.WHITE,
  },
  hideContent: {
    opacity: 0,
  },
  header: {
    position: 'absolute',
    top: theme.spacing['4'],
    right: theme.spacing['4'],
    zIndex: theme.zIndex.tooltip,
  },
  errorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['4'],
    paddingHorizontal: theme.spacing['8'],
    backgroundColor: theme.colors.WHITE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HostedFieldsPayload = {
  log?: string;
  error?: string;
  ready?: boolean;
  opened?: boolean;
  closed?: boolean;
  initialized?: boolean;
};

type HostedFrameProps = {
  onClose: () => void;
};
