import {
  PinPromptKeypadActionButton,
  PinPromptKeypadButtonIcon,
  PinPromptKeypadButtonLabel,
  PinPromptKeypadContainer,
  PinPromptKeypadValueButton,
} from './components';

export const PinPromptKeypad = {
  Container: PinPromptKeypadContainer,
  Button: {
    Value: PinPromptKeypadValueButton,
    Action: PinPromptKeypadActionButton,
  },
  ButtonLabel: PinPromptKeypadButtonLabel,
  ButtonIcon: PinPromptKeypadButtonIcon,
};
