import React, {
  type MutableRefObject,
  type PropsWithChildren,
  useEffect,
} from 'react';
import Animated, { useAnimatedRef } from 'react-native-reanimated';

import { type RailItemsRefsMap } from '../HorizontalScrollRail.types';

// ─────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRailItem = (props: Props) => {
  const { index, railItemsRef, children } = props;

  // ─── Refs ────────────────────────────────────────────────────────────

  const animatedRef = useAnimatedRef<Animated.View>();

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    railItemsRef.current.set(index, animatedRef);
  }, [animatedRef, index, railItemsRef]);

  // ─────────────────────────────────────────────────────────────────────

  return <Animated.View ref={animatedRef}>{children}</Animated.View>;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = PropsWithChildren<{
  index: number;
  railItemsRef: MutableRefObject<RailItemsRefsMap>;
}>;
