import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  type NativeScrollEvent,
  type NativeSyntheticEvent,
  type ScrollView,
  View,
} from 'react-native';
import { useSharedValue } from 'react-native-reanimated';
import { useResponsive, useScrollspy } from '@sg/garnish';

import {
  CustomizationContainer,
  CustomizationCTA,
  CustomizationDetailsContainer,
  CustomizationIngredientsGrid,
  CustomizationSelectedIngredients,
} from '@order/features/customization';

import { useFilteredIngredientsModifications } from '../../../../hooks';
import { useProductDetailsScreenContext } from '../../../../ProductDetailsScreen.provider';
import {
  checkIfBaseKind,
  checkIfDressingKind,
  getActiveIngredientsModifications,
} from '../../../../state';
import type { IngredientModificationWithQuantity } from '../../../../types';
import { ProductDetailsIngredientCard } from '../ProductDetailsIngredientCard';
import { ProductDetailsContentNavigationHeader } from '../ProductDetailsNavigationHeader';
import { useAddedIngredientId } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ProductDetailsIngredientsView = () => {
  const {
    addressId,
    restaurantSlug,
    product,
    modifications,
    addIngredientModification,
    removeIngredientModification,
    completelyRemoveIngredientModification,
    quantity,
    activeIngredientsModificationsMap,
    finishCustomization,
  } = useProductDetailsScreenContext();
  const addedIngredientId = useAddedIngredientId();

  const ingredientModifications = product?.ingredientModifications;
  const { calories, netPriceChange, active } = modifications;

  const selectedBaseIngredientsCount = active.filter(checkIfBaseKind).length;

  const { currentBreakpoint, minWidth } = useResponsive();

  // ─── Refs ────────────────────────────────────────────────────────────

  const detailsContainerRef = useRef<ScrollView>(null);

  // ─── State ───────────────────────────────────────────────────────────

  const [activeIngredientId, setActiveIngredientId] = useState<string>();

  const clearActiveIngredientId = useCallback(() => {
    setActiveIngredientId(undefined);
  }, []);

  // ─── Data ────────────────────────────────────────────────────────────

  const productCost = (product?.cost ?? 0) + netPriceChange;

  const activeIngredientModifications = useMemo(
    () => getActiveIngredientsModifications(modifications),
    [modifications],
  );

  const { ingredientsModificationsKinds, ingredientsModifications } =
    useFilteredIngredientsModifications(ingredientModifications);

  const { helpers, activeTargetId, setActiveTargetId } = useScrollspy({
    defaultActiveTargetId: ingredientsModificationsKinds[0],
  });

  // ─── Styles ──────────────────────────────────────────────────────────

  const selectedIngredientsScrollSV = useSharedValue(0);

  const updateIngredientsScrollSV = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      // eslint-disable-next-line functional/immutable-data
      selectedIngredientsScrollSV.value = event.nativeEvent.contentOffset.y;
    },
    [selectedIngredientsScrollSV],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (!product) return null;

  return (
    <CustomizationContainer>
      <CustomizationDetailsContainer
        ref={detailsContainerRef}
        stickyHeaderIndices={[0]}
        onScroll={updateIngredientsScrollSV}
        scrollEventThrottle={16}
        bounces={false}
      >
        <ProductDetailsContentNavigationHeader
          scrollOffsetSV={selectedIngredientsScrollSV}
          restaurantSlug={restaurantSlug}
          addressId={addressId}
          text={product.name}
          spacing="narrow"
        />

        <CustomizationSelectedIngredients.Container>
          <CustomizationSelectedIngredients.List>
            {activeIngredientModifications.map((ingredientModification) => {
              const isActive =
                activeIngredientId === ingredientModification.ingredient.id;
              const isDressing = checkIfDressingKind(
                ingredientModification.kind,
              );

              const shouldScrollToSelf =
                addedIngredientId === ingredientModification.ingredient.id;

              return (
                <CustomizationSelectedIngredients.ListItem
                  key={ingredientModification.ingredient.id}
                  isActive={isActive}
                  shouldScrollToSelf={shouldScrollToSelf}
                  scrollViewRef={detailsContainerRef}
                >
                  <CustomizationSelectedIngredients.Ingredient<IngredientModificationWithQuantity>
                    ingredientModification={ingredientModification}
                    isActive={isActive}
                    isDisabled={isDressing}
                    setActive={setActiveIngredientId}
                    setInactive={clearActiveIngredientId}
                    onRemove={completelyRemoveIngredientModification}
                    onIncreaseQuantity={addIngredientModification}
                    onDecreaseQuantity={removeIngredientModification}
                  />
                </CustomizationSelectedIngredients.ListItem>
              );
            })}
          </CustomizationSelectedIngredients.List>
        </CustomizationSelectedIngredients.Container>

        {minWidth.isSM ? (
          <CustomizationCTA
            variation="continue"
            cost={productCost}
            quantity={quantity}
            calories={calories}
            onPress={finishCustomization}
          />
        ) : null}
      </CustomizationDetailsContainer>

      {ingredientsModificationsKinds.length > 0 ? (
        <View style={styles.ingredientsGridContainer}>
          {minWidth.isSM ? (
            <CustomizationIngredientsGrid.Nav
              register={helpers.navScrollView.register}
              deregister={helpers.navScrollView.deregister}
              trackScroll={helpers.navScrollView.trackScroll}
              storeSize={helpers.navScrollView.storeSize}
            >
              {ingredientsModificationsKinds.map((kind) => (
                <CustomizationIngredientsGrid.NavItem
                  key={kind}
                  isActive={activeTargetId === kind}
                  onPress={setActiveTargetId}
                  targetId={kind}
                  ingredientKindName={kind}
                  register={helpers.navItem.register}
                  deregister={helpers.navItem.deregister}
                />
              ))}
            </CustomizationIngredientsGrid.Nav>
          ) : null}

          <CustomizationIngredientsGrid.Container
            register={helpers.scrollView.register}
            deregister={helpers.scrollView.deregister}
            storeContentSize={helpers.scrollView.storeContentSize}
            storeSize={helpers.scrollView.storeSize}
            trackScroll={helpers.scrollView.trackScroll}
          >
            {Object.entries(ingredientsModifications).map(
              ([ingredientKindName, ingredientKind]) => (
                <CustomizationIngredientsGrid.Section
                  key={ingredientKindName}
                  ingredientKindName={ingredientKindName}
                  register={helpers.target.register}
                  deregister={helpers.target.deregister}
                >
                  <CustomizationIngredientsGrid.SectionStack>
                    {ingredientKind.modifications.map((modification) => {
                      const { ingredient } = modification;

                      const maybeActiveIngredient =
                        activeIngredientsModificationsMap.get(ingredient.id);
                      const activeIngredientQuantity =
                        maybeActiveIngredient?.quantity ?? 0;

                      return (
                        <ProductDetailsIngredientCard
                          key={ingredient.id}
                          ingredientModification={modification}
                          quantity={activeIngredientQuantity}
                          baseIngredientsCount={selectedBaseIngredientsCount}
                          onIncrement={addIngredientModification}
                          onDecrement={removeIngredientModification}
                        />
                      );
                    })}
                  </CustomizationIngredientsGrid.SectionStack>
                </CustomizationIngredientsGrid.Section>
              ),
            )}
          </CustomizationIngredientsGrid.Container>
        </View>
      ) : null}

      {currentBreakpoint.isXS ? (
        <CustomizationCTA
          variation="continue"
          cost={productCost}
          quantity={quantity}
          calories={calories}
          onPress={finishCustomization}
        />
      ) : null}
    </CustomizationContainer>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const styles = {
  ingredientsGridContainer: {
    flex: 1,
  },
};
