import { useMemo } from 'react';
import {
  parseIncompletePhoneNumber,
  parsePhoneNumber as parseCompletePhoneNumber,
} from 'libphonenumber-js';
import * as yup from 'yup';

import { useLocalizationContext } from '@order/Localization';

import { US_COUNTRY_CODE, US_PHONE_NUMBER_MAX_LENGTH } from './constants';

// ────────────────────────────────────────────────────────────────────────────────
export const useFormValidation = (hasZipCode: boolean) => {
  const i18n = useYupValidationMessages();
  const validations = getValidations(i18n, hasZipCode);

  return validations;
};

const useYupValidationMessages = () => {
  const { t } = useLocalizationContext();
  const i18n = useMemo(() => {
    return {
      firstNameRequired: t('form.first-name-required'),
      firstNameValidChars: t('form.invalid-characters', {
        fieldName: 'First',
      }),
      firstNameMaxLength: t('form.max-name-length', { fieldName: 'First' }),
      lastNameRequired: t('form.last-name-required'),
      lastNameValidChars: t('form.invalid-characters', {
        fieldName: 'Last',
      }),
      lastNameMaxLength: t('form.max-name-length', { fieldName: 'Last' }),
      phoneNumberRequired: t('form.phone-number-required'),
      phoneNumberValid: t('form.validation-phone-number'),
      zipCodeRequired: t('form.zip-code-required'),
      zipCodeValid: t('form.validation-zip-code'),
      passwordMinLength: t('form.min-password-length'),
      passwordsMatch: t('form.confirm-password-match'),
    };
  }, [t]);

  return i18n;
};

type I18nMessages = ReturnType<typeof useYupValidationMessages>;
const getValidations = (i18n: I18nMessages, hasZipCode: boolean) =>
  yup.object({
    firstName: yup
      .string()
      .required(i18n.firstNameRequired)
      .matches(/^[\sA-Za-z]+$/, i18n.firstNameValidChars)
      .max(50, i18n.firstNameMaxLength),
    lastName: yup
      .string()
      .required(i18n.lastNameRequired)
      .matches(/^[\sA-Za-z]+$/, i18n.lastNameValidChars)
      .max(50, i18n.lastNameMaxLength),
    phoneNumber: yup
      .string()
      .required(i18n.phoneNumberRequired)
      .test('test-valid-phone-number', function (phoneNumber) {
        const { path, createError } = this;
        const parsedPhoneNumber = parseIncompletePhoneNumber(phoneNumber ?? '');
        const isValidNumber =
          parsedPhoneNumber.length === US_PHONE_NUMBER_MAX_LENGTH
            ? parseCompletePhoneNumber(
                parsedPhoneNumber,
                US_COUNTRY_CODE,
              ).isValid()
            : true;

        return (
          isValidNumber || createError({ path, message: i18n.phoneNumberValid })
        );
      })
      .matches(/^\d+$/, i18n.phoneNumberValid)
      .min(10, i18n.phoneNumberValid)
      .max(10, i18n.phoneNumberValid),
    zipCode: hasZipCode
      ? yup
          .string()
          .required(i18n.zipCodeRequired)
          .min(3, i18n.zipCodeValid)
          .max(8, i18n.zipCodeValid)
          .matches(/^[\dA-Z]*$/, i18n.zipCodeValid)
      : yup.string(),
    password: yup.lazy((val: string) => {
      if (val.length > 0) {
        return yup.string().min(8, i18n.passwordMinLength);
      }

      return yup.string();
    }),
    confirmPassword: yup.string().when('password', {
      is: (val: string) => val !== '',
      /* eslint-disable unicorn/no-thenable */
      then: yup
        .string()
        .oneOf([yup.ref('password'), null], i18n.passwordsMatch),
    }),
  });
