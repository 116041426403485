import React, { type ComponentProps, useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Platform,
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type TouchableOpacity,
  type ViewStyle,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ImpactFeedbackStyle } from 'expo-haptics';
import {
  BodyText,
  LoadingPlaceholder,
  theme,
  triggerHapticFeedback,
  webOnlyStyles,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesButton = <RestrictionKind extends string>(
  props: MenuDietaryPreferencesButtonProps<RestrictionKind>,
) => {
  const {
    restriction,
    isChecked,
    isDisabled,
    isLoading,
    style,
    toggleRestriction,
    ...restProps
  } = props;

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { formatMessage } = useIntl();

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleToggleRestriction = useCallback(() => {
    if (isDisabled) return;

    toggleRestriction(restriction);
    void triggerHapticFeedback(ImpactFeedbackStyle.Light);
  }, [isDisabled, restriction, toggleRestriction]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const labelStyles = [
    styles.label,
    labelWebStyles,
    isHovered || isChecked ? styles.labelHover : styles.labelIdle,
  ];

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        pressableWebStyles,
        isChecked ? styles.pressableChecked : undefined,
        isHovered && !isChecked ? styles.pressableHover : undefined,
        isHovered && isChecked ? styles.pressableCheckedHover : undefined,
        pressed ? styles.pressablePressed : undefined,
        style,
      ];
    },
    [isChecked, isHovered, style],
  );

  // ─────────────────────────────────────────────────────────────────────

  if (isLoading) {
    return <LoadingPlaceholder palette="cream" rowHeight={72} />;
  }

  // ─────────────────────────────────────────────────────────────────────

  const accessibilityLabel = isChecked
    ? formatMessage(messages.deselectRestrictionA11yLabel, { restriction })
    : formatMessage(messages.selectRestrictionA11yLabel, { restriction });

  return (
    <Pressable
      ref={ref}
      style={pressableStyles}
      accessibilityState={{ checked: isChecked, disabled: isDisabled }}
      accessibilityRole="checkbox"
      accessibilityLabel={accessibilityLabel}
      onPress={handleToggleRestriction}
      {...Platform.select({ web: { accessibilityChecked: isChecked } })}
      {...restProps}
    >
      <BodyText style={labelStyles} sizeMatch={['18']} numberOfLines={1}>
        {restriction.toLowerCase()}
      </BodyText>
    </Pressable>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  selectRestrictionA11yLabel: {
    defaultMessage: 'Select {restriction}',
    description: 'Menu | Dietary preferences | Select restriction a11y label',
  },
  deselectRestrictionA11yLabel: {
    defaultMessage: 'Deselect {restriction}',
    description: 'Menu | Dietary preferences | Deselect restriction a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    padding: theme.spacing['4'],
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
    minHeight: 72,
    userSelect: 'none',
  },
  pressableHover: {
    backgroundColor: theme.colors.KALE,
  },
  pressableChecked: {
    backgroundColor: theme.colors.KALE,
  },
  pressableCheckedHover: {
    backgroundColor: theme.colors.KALE_HOVER,
  },
  pressablePressed: {
    opacity: 0.7,
  },

  label: {
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  labelIdle: {
    color: theme.colors.TEXT_COLOR,
  },
  labelHover: {
    color: theme.colors.CREAM,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms, opacity ${theme.transitions.base}ms`,
});
const labelWebStyles = webOnlyStyles({
  transition: `color ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuDietaryPreferencesButtonProps<RestrictionKind> = {
  restriction: RestrictionKind;
  isDisabled: boolean;
  isLoading: boolean;
  isChecked: boolean;
  toggleRestriction: (restriction: RestrictionKind) => void;
} & Omit<
  ComponentProps<typeof TouchableOpacity>,
  | 'children'
  | 'ref'
  | 'accessibilityLabel'
  | 'aria-label'
  | 'aria-checked'
  | 'accessibilityState'
  | 'accessibilityRole'
  | 'onPress'
>;
