import { defineMessages, useIntl } from 'react-intl';

import { usePermanentNoticeBanner } from '@order/hooks';

/**
 * Shows a permanent notice banner if the order is late.
 */
export const useLateOrderNoticeBanner = (props: Props) => {
  const { isLate = false } = props;

  const { formatMessage } = useIntl();
  const notice = formatMessage(messages.lateOrderNotice);

  usePermanentNoticeBanner(isLate, notice, 'late-notice');
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  lateOrderNotice: {
    defaultMessage: "Sorry we're running a little behind with your order",
    description: 'Order Status | Late Order | Notice',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = {
  isLate: boolean;
};
