import { useCallback } from 'react';
import { type RequestPolicy } from 'urql';
import {
  type CustomerPreference,
  type CustomerPreferencesInput,
} from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';

import {
  useGetPreferencesQuery,
  useUpdatePreferencesMutation,
} from './GraphQL/CustomerPreferences.generated';

export const useCustomerPreferences = (props?: CustomerPreferencesProps) => {
  const { pause = false, requestPolicy = 'cache-and-network' } = props ?? {};

  const isLoggedIn = useIsLoggedIn();

  const [response] = useGetPreferencesQuery({
    pause: pause || !isLoggedIn,
    requestPolicy,
  });

  const preferences = response.data?.getPreferences;
  const isLoadingPreferences = response.fetching;

  return { preferences, isLoadingPreferences };
};

export const useUpdateCustomerPreferences = () => {
  const [response, executeMutation] = useUpdatePreferencesMutation();

  const preferences = response.data?.updatePreferences;
  const isUpdatingPreferences = response.fetching;

  const updateCustomerPreferences = useCallback(
    async (input: CustomerPreferencesInput) => {
      const { data } = await executeMutation({ input });
      const typename = data?.updatePreferences.__typename;

      const isSuccess = typename === 'CustomerPreference';

      if (isSuccess) {
        return { preferences: data?.updatePreferences as CustomerPreference };
      }

      return { error: data?.updatePreferences.errorMessage };
    },
    [executeMutation],
  );

  return {
    preferences,
    isUpdatingPreferences,
    updateCustomerPreferences,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomerPreferencesProps = {
  pause?: boolean;
  requestPolicy?: RequestPolicy;
};
