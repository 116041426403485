import React, { type PropsWithChildren, useRef } from 'react';
import { Pressable, type PressableProps } from 'react-native';
import { BodyText, usePressableState } from '@sg/garnish';

export const NavbarTextLinkCta = (props: Props) => {
  const { testID, children, onPress } = props;

  const ref = useRef(null);
  const { isHovered } = usePressableState(ref);

  return (
    <Pressable
      testID={testID}
      ref={ref}
      accessibilityRole="button"
      aria-label={props['aria-label']}
      onPress={onPress}
    >
      <BodyText underline={isHovered} sizeMatch={['16']}>
        {children}
      </BodyText>
    </Pressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress' | 'aria-label' | 'testID'> &
  PropsWithChildren;
