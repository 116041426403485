import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Icon } from '@sg/garnish';

export const LoyaltyPointsExpirationRow = (
  props: LoyaltyPointsExpirationRowProps,
) => {
  const { amountExpiring, numOfDaysUntilExpiration } = props;

  return (
    <View style={styles.container}>
      <View>
        <BodyText sizeMatch={['18']}>
          <FormattedMessage
            {...messages.expiration}
            values={{ days: numOfDaysUntilExpiration }}
          />
        </BodyText>

        <BodyText sizeMatch={['14']} style={styles.charcoalText}>
          <FormattedMessage {...messages.hint} />
        </BodyText>
      </View>

      <View style={styles.amountWrapper}>
        <BodyText sizeMatch={['24']}>{amountExpiring}</BodyText>

        <Icon width={16} height={16} name="IconSgRewardBlackFill" />
      </View>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  expiration: {
    defaultMessage: 'Expiring within {days} days',
    description: 'Loyalty | Points Expiration | Expiration',
  },
  hint: {
    defaultMessage: 'Points expire 180 days after deposit',
    description: 'Loyalty | Points Expiration | Hint',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
  },
  amountWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['1'],
  },
  charcoalText: {
    color: theme.colors.CHARCOAL,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointsExpirationRowProps = {
  amountExpiring: number;
  numOfDaysUntilExpiration: number;
};
