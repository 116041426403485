import React, { type ComponentProps, useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type TouchableOpacity,
  View,
  type ViewStyle,
} from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { ImpactFeedbackStyle } from 'expo-haptics';
import {
  BodyText,
  LoadingDots,
  theme,
  triggerHapticFeedback,
  webOnlyStyles,
} from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuDietaryPreferencesSubmitButton = (
  props: MenuDietaryPreferencesSubmitButtonProps,
) => {
  const { style, isLoading, onPress, ...restProps } = props;

  const ref = useRef(null);
  const isHovered = useHover(ref);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    if (isLoading || !onPress) return;

    void triggerHapticFeedback(ImpactFeedbackStyle.Medium);
    onPress();
  }, [isLoading, onPress]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed } = state;

      return [
        styles.pressable,
        pressableWebStyles,
        isHovered ? styles.pressableHover : undefined,
        pressed ? styles.pressablePressed : undefined,
        style,
      ];
    },
    [isHovered, style],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <Pressable
        ref={ref}
        aria-busy={isLoading}
        style={pressableStyles}
        onPress={handleOnPress}
        {...restProps}
      >
        {isLoading ? (
          <LoadingDots />
        ) : (
          <BodyText style={styles.label} sizeMatch={['18']} numberOfLines={1}>
            <FormattedMessage {...messages.buttonLabel} />
          </BodyText>
        )}
      </Pressable>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  buttonLabel: {
    defaultMessage: 'Confirm preferences',
    description:
      'Menu | Dietary preferences | Confirm preferences button label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: theme.spacing['6'],
  },
  pressable: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: theme.spacing['2'],
    paddingHorizontal: theme.spacing['8'],
    borderWidth: 1,
    borderColor: theme.colors.KALE,
    borderRadius: 64,
    minHeight: 64,
    minWidth: 230,
    userSelect: 'none',
  },
  pressableHover: {
    backgroundColor: theme.colors.OPACITY.SPINACH.NEAR_LIGHTEST,
  },
  pressablePressed: {
    opacity: 0.7,
  },
  label: {
    textAlign: 'center',
    color: theme.colors.KALE,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: `background-color ${theme.transitions.base}ms, border-color ${theme.transitions.base}ms, opacity ${theme.transitions.base}ms`,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuDietaryPreferencesSubmitButtonProps = {
  isLoading: boolean;
  onPress: () => void;
} & Omit<
  ComponentProps<typeof TouchableOpacity>,
  'children' | 'ref' | 'onPress'
>;
