import React from 'react';
import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';
import { BodyText, DisplayText, theme, useResponsive } from '@sg/garnish';

import { LoyaltyHomeChallengesRewardsContainer } from '../LoyaltyHomeChallengesRewardsContainer';

export const LoyaltyHomeRewardsEmptyState = (props: EmptyStateProps) => {
  const { title, body, style } = props;
  const { match, minWidth } = useResponsive();

  if (!minWidth.isSM) return null;

  return (
    <LoyaltyHomeChallengesRewardsContainer style={style}>
      <DisplayText style={styles.creamLabel} sizeMatch={['24']}>
        {title}
      </DisplayText>

      <BodyText
        style={match([styles.centerLabel, styles.creamLabel])}
        sizeMatch={['18']}
      >
        {body}
      </BodyText>
    </LoyaltyHomeChallengesRewardsContainer>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  centerLabel: {
    textAlign: 'center',
  },
  creamLabel: {
    color: theme.colors.CREAM,
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type EmptyStateProps = {
  title: string;
  body: string;
  style: StyleProp<ViewStyle>;
};
