import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { MenuHeaderDetailsTextItem } from '../MenuHeaderDetailsTextItem';
import { getClosestStoreHoursInfo } from './utils/get-closest-store-hours-info';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuHeaderDetailsStoreHoursText = (
  props: MenuHeaderDetailsStoreHoursTextProps,
) => {
  const { permanentHours, storeHours, orderChannel } = props;

  const { formatMessage } = useIntl();

  const message = useMemo(() => {
    const closestStoreHoursInfo = getClosestStoreHoursInfo({
      storeHours,
      permanentHours,
    });

    if (!closestStoreHoursInfo) {
      return;
    }

    // ─────────────────────────────────────────────────────────────────────

    const isOutpost = orderChannel === 'outpost';

    const { weekDay, storeHours: hours, state } = closestStoreHoursInfo;
    const { isOpen, isToday, isNotYetOpen } = state;

    const { hour: startHour, isTemporary: isStartHourTemporary } = hours.start;
    const { hour: endHour, isTemporary: isEndHourTemporary } = hours.end;

    const weekDayFormatted = formatMessage(messages.weekDay, { day: weekDay });

    // NOTE: For outpost locations we show only next working day info.
    //       Working hours information is displayed as a separate message.
    if (!isToday && isOutpost) {
      return formatMessage(messages.opensAtDay, { day: weekDayFormatted });
    }

    if (isOutpost) return;

    // ─────────────────────────────────────────────────────────────────────

    if (isToday && isNotYetOpen && isStartHourTemporary) {
      return formatMessage(messages.opensAtTemporaryHours, { hour: startHour });
    }

    if (isToday && isNotYetOpen) {
      return formatMessage(messages.opensAt, { hour: startHour });
    }

    // ─────────────────────────────────────────────────────────────────────

    if (isToday && isOpen && isEndHourTemporary) {
      return formatMessage(messages.openUntilTemporaryHours, { hour: endHour });
    }

    if (isToday && isOpen) {
      return formatMessage(messages.openUntil, { hour: endHour });
    }

    // ─────────────────────────────────────────────────────────────────────

    if (!isToday && isStartHourTemporary) {
      return formatMessage(messages.opensTemporaryHours, {
        day: weekDayFormatted,
        hour: startHour,
      });
    }

    if (!isToday) {
      return formatMessage(messages.opens, {
        day: weekDayFormatted,
        hour: startHour,
      });
    }

    // ─────────────────────────────────────────────────────────────────────

    return null;
  }, [formatMessage, orderChannel, permanentHours, storeHours]);

  // ─────────────────────────────────────────────────────────────────────

  if (!message) {
    return null;
  }

  return (
    <MenuHeaderDetailsTextItem withSeparator>
      {message}
    </MenuHeaderDetailsTextItem>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  opensAt: {
    defaultMessage: 'Opens at {hour}',
    description: 'Menu | Header | Store hours text | "Opens at..." text',
  },
  opensAtTemporaryHours: {
    defaultMessage: 'Opens at {hour} (temporary hours)',
    description:
      'Menu | Header | Store hours text | "Opens at (temporary hours)..." text',
  },
  openUntil: {
    defaultMessage: 'Open until {hour}',
    description: 'Menu | Header | Store hours text | "Open until..." text',
  },
  openUntilTemporaryHours: {
    defaultMessage: 'Open until {hour} (temporary hours)',
    description:
      'Menu | Header | Store hours text | "Open until (temporary hours)..." text',
  },
  opens: {
    defaultMessage: 'Opens {day} at {hour}',
    description: 'Menu | Header | Store hours text | "Open on..." text',
  },
  opensAtDay: {
    defaultMessage: 'Opens {day}',
    description: 'Menu | Header | Store hours text | "Opens <day>" text',
  },
  opensTemporaryHours: {
    defaultMessage: 'Opens {day} at {hour} (temporary hours)',
    description:
      'Menu | Header | Store hours text | "Open on (temporary hours)..." text',
  },
  weekDay: {
    defaultMessage: `{day, select,
      sun {Sunday}
      mon {Monday}
      tue {Tuesday}
      wed {Wednesday}
      thu {Thursday}
      fri {Friday}
      sat {Saturday}
      other {{fallback}}
    }`,
    description: 'Menu | Header | Store hours text | Weekday',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type MenuHeaderDetailsStoreHoursTextProps = {
  permanentHours: readonly HoursGroup[];
  storeHours: readonly HoursGroup[];
  orderChannel?: 'pickup' | 'outpost' | 'delivery';
};

type HoursGroup = {
  day: string;
  start: string;
  end: string;
};
