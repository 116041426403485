/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagAvailablePointsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type BagAvailablePointsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly points: {
      readonly __typename?: 'UserPointsStatus';
      readonly total: number;
      readonly available: number;
      readonly pending: number;
    } | null;
    readonly tier: {
      readonly __typename: 'Tier';
      readonly id: string;
      readonly nameV2: Types.TierName;
    } | null;
  } | null;
};

export const BagAvailablePointsDocument = gql`
  query BagAvailablePoints {
    loyaltyProfile {
      customerId
      points {
        total
        available
        pending
      }
      tier {
        id
        __typename
        nameV2
      }
    }
  }
`;

export function useBagAvailablePointsQuery(
  options?: Omit<Urql.UseQueryArgs<BagAvailablePointsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    BagAvailablePointsQuery,
    BagAvailablePointsQueryVariables
  >({ query: BagAvailablePointsDocument, ...options });
}
