import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useResponsive } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  FloatingButtons,
  NotFoundView,
  TransparentModal,
} from '@order/components';
import { ORDER_AGAIN_TABS, OrderAgain } from '@order/features/orders';
import { useCart } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';

import type { ModalStackParamList } from '../../navigation/AppNavigation.props';
import { useReorderOrders } from './hooks';
import {
  ReorderScreenFavoritesTabPanel,
  ReorderScreenOrdersTabPanel,
} from './tabs';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A modal-screen that provides a simple user interface to reorder previous
 * orders and favorites.
 */
export const ReorderModal = () => {
  const { goBack } = useNavigation();

  return (
    <TransparentModal onClose={goBack} size="small" dialogMaxHeight={844}>
      <ReorderScreenContent
        canCloseScreen={true}
        showBagButtonV2={false}
        shouldUseModalNavigation={true}
      />
    </TransparentModal>
  );
};

/**
 * A tab-screen that provides a simple user interface to reorder previous
 * orders and favorites.
 */
export const ReorderTab = () => {
  return (
    <SafeAreaView style={styles.container} edges={['top']}>
      <ReorderScreenContent
        canCloseScreen={false}
        showBagButtonV2={true}
        shouldUseModalNavigation={false}
      />
    </SafeAreaView>
  );
};

// ─────────────────────────────────────────────────────────────────────────────

const ReorderScreenContent = (props: ReorderScreenReadyProps) => {
  const isReorderModalEnabled = useFeatureFlag(
    'CELS-2534-reorder-modal-enabled',
    { listenForChanges: true },
  );
  const isLoggedIn = useIsLoggedIn();
  const shouldRenderNotFoundView = !isLoggedIn || !isReorderModalEnabled;

  if (shouldRenderNotFoundView) {
    return <NotFoundView withoutFooter />;
  }

  return <ReorderScreenReady {...props} />;
};

const ReorderScreenReady = (props: ReorderScreenReadyProps) => {
  const { canCloseScreen, showBagButtonV2, shouldUseModalNavigation } = props;

  const { lineItemsCount } = useCart();
  const { goBack } = useNavigation();
  const { match } = useResponsive();
  const { track } = useTelemetry();

  // ─── Navigation ──────────────────────────────────────────────────────

  const { navigate, replace } = useNavigation<ModalStackParams>();

  // NOTE: Reorder modal is represented as a small modal on large breakpoints
  // and a fullscreen one on the smallest breakpoint which introduces some issues
  // We use different methods of navigation per breakpoint to avoid unexpected
  // issues.
  const navigationMethod = match([navigate, replace]);

  const navigateToBag = useCallback(() => {
    navigationMethod('Bag');
  }, [navigationMethod]);

  // ─── Remote Data ─────────────────────────────────────────────────────

  const reorderOrders = useReorderOrders();

  // ─── State ───────────────────────────────────────────────────────────

  const [activeTabId, setActiveTabId] = useState(ORDER_AGAIN_TABS.ORDERS.ID);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onTabChange = useCallback(
    (tabId: string) => {
      if (tabId === ORDER_AGAIN_TABS.ORDERS.ID) {
        track('reorder.orders.tab.tapped');
      }

      if (tabId === ORDER_AGAIN_TABS.FAVORITES.ID) {
        track('reorder.favorites.tab.tapped');
      }

      setActiveTabId(tabId);
    },
    [track],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <OrderAgain.Header shouldUseSafeArea={shouldUseModalNavigation}>
        <OrderAgain.HeaderTitle />
        {canCloseScreen ? <OrderAgain.CloseButton onPress={goBack} /> : null}
      </OrderAgain.Header>

      <OrderAgain.Tabs activeTabId={activeTabId} setActiveTabId={onTabChange} />

      <ReorderScreenOrdersTabPanel
        activeTabId={activeTabId}
        reorderOrders={reorderOrders}
      />

      <ReorderScreenFavoritesTabPanel
        activeTabId={activeTabId}
        mostRecentOrder={reorderOrders.mostRecentOrder}
      />

      {lineItemsCount > 0 && showBagButtonV2 ? (
        <FloatingButtons.Container anchor="right">
          <FloatingButtons.Bag showMessage={false} onPress={navigateToBag} />
        </FloatingButtons.Container>
      ) : null}

      {lineItemsCount > 0 && !showBagButtonV2 ? (
        <OrderAgain.BagButton
          lineItemsCount={lineItemsCount}
          onPress={navigateToBag}
        />
      ) : null}
    </>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ModalStackParams = NativeStackNavigationProp<ModalStackParamList>;

type ReorderScreenReadyProps = {
  canCloseScreen: boolean;
  showBagButtonV2: boolean;
  shouldUseModalNavigation: boolean;
};
