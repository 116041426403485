import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, TextLinkifyTags, useResponsive } from '@sg/garnish';

export const JoinSgRewardsTerms = (props: JoinSgRewardsTermsProps) => {
  const { termsAndConditionsUrl, onLinkPress } = props;

  const { formatMessage } = useIntl();
  const { match } = useResponsive();

  const links = useMemo(
    () => [
      {
        tag: 'terms',
        description: formatMessage(messages.termsTag),
        href: termsAndConditionsUrl,
        underline: true,
        onPress: onLinkPress,
      },
    ],
    [termsAndConditionsUrl, formatMessage, onLinkPress],
  );

  return (
    <View style={match([styles.textContainerXS, styles.textContainerSM])}>
      <BodyText style={styles.label} sizeMatch={['12', '22']}>
        <TextLinkifyTags
          palette="sweet-corn"
          sizeMatch={['12', '22']}
          links={links}
        >
          {formatMessage(messages.terms, { terms: '{terms}' })}
        </TextLinkifyTags>
      </BodyText>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  termsTag: {
    defaultMessage: 'terms',
    description: 'SG Rewards | Join screen | Opted Out | subtitle',
  },
  terms: {
    defaultMessage: 'See full {terms} for more details',
    description: 'SG Rewards | Join screen | Opted Out | subtitle',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  label: {
    paddingVertical: theme.spacing['4'],
    color: theme.colors.CREAM,
  },
  textContainerXS: {},
  textContainerSM: {
    alignItems: 'center',
    marginTop: theme.spacing['20'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type JoinSgRewardsTermsProps = {
  termsAndConditionsUrl: string;
  onLinkPress?: () => void;
};
