import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { BagWarning } from '../BagWarning';

/**
 * Warning message that the reward was not applied.
 */
export const BagRewardWarning = (props: BagRewardWarningProps) => {
  const { message, isRewardMissingSku, onClose, onViewMenu } = props;

  const { formatMessage } = useIntl();

  const cta = isRewardMissingSku
    ? formatMessage(messages.viewMenuCta)
    : formatMessage(messages.cta);

  const a11yLabel = isRewardMissingSku
    ? formatMessage(messages.accessibilityLabelViewMenu)
    : formatMessage(messages.accessibilityLabel);

  return (
    <BagWarning
      message={message ?? formatMessage(messages.fallback)}
      accessibilityLabel={a11yLabel}
      closeAccessibilityLabel={formatMessage(messages.closeAccessibilityLabel)}
      cta={cta}
      onClose={onClose}
      onCtaPress={isRewardMissingSku ? onViewMenu : onClose}
    />
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  fallback: {
    defaultMessage: 'Something went wrong, please try again later.',
    description: 'Bag | Reward warning | Fallback message',
  },
  cta: {
    defaultMessage: 'Got it',
    description: 'Bag | Reward warning | CTA',
  },
  viewMenuCta: {
    defaultMessage: 'View menu',
    description: 'Bag | Reward warning | View Menu CTA',
  },
  accessibilityLabel: {
    defaultMessage: 'Confirm reward not applied',
    description: 'Bag | Reward warning | a11y',
  },
  accessibilityLabelViewMenu: {
    defaultMessage: 'Confirm reward not applied and view menu',
    description: 'Bag | Reward warning | a11y',
  },
  closeAccessibilityLabel: {
    defaultMessage: 'Close',
    description: 'Bag | Reward warning | Close a11y',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRewardWarningProps = {
  message?: string;
  isRewardMissingSku?: boolean;
  onClose: () => void;
  onViewMenu: () => void;
};
