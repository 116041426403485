import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type OrderingLedger } from '../../machines/ordering-machine.types';
import { sumLedgerTotal } from './sumLedgerTotal';

/**
 * Extracts the ledger items from the ledger.
 */
export const useLedgerItems = (
  params: UseLedgerItemsParams,
): readonly LedgerItem[] => {
  const { ledger, shouldUseCredit, discountInfo, availableCredit } = params;
  const tip = Math.min(params.tip, ledger?.subtotal ?? 0);

  const { formatMessage, formatNumber } = useIntl();

  return useMemo(() => {
    const formatPrice = (value: string | number) =>
      formatNumber(Number(value) / 100, {
        style: 'currency',
        currency: 'USD',
      });

    const { total, credit } = sumLedgerTotal(
      ledger,
      tip,
      availableCredit,
      shouldUseCredit,
    );

    const subtotalItem: LedgerItem = {
      amount: formatPrice(ledger?.subtotal ?? 0),
      label: formatMessage(messages.subTotal),
    };

    const taxesItem: LedgerItem = {
      amount: `${formatPrice(ledger?.tax ?? 0)}`,
      label: formatMessage(messages.tax),
    };

    const feesItems =
      ledger?.fees
        ?.filter((fee) => fee.amount)
        .map((fee) => ({
          label: fee.name,
          amount: formatPrice(fee.amount ?? 0),
          helperText: fee.description ?? undefined,
        })) ?? [];

    const tipItem: LedgerItem = {
      label: 'Tip',
      amount: formatPrice(tip),
      helperText: undefined,
    };

    const creditsItems =
      shouldUseCredit && credit > 0
        ? [
            {
              label: formatMessage(messages.credit),
              amount: `-${formatPrice(credit)}`,
              palette: 'success',
            },
          ]
        : [];

    const discountsItems =
      ledger?.discounts
        ?.filter((discount) => discount.amount)
        .map((discount) => ({
          label: discount.name,
          amount: `-${formatPrice(discount.amount ?? 0)}`,
          helperText: discountInfo ?? discount.description,
          palette: 'success',
        })) ?? [];

    const totalItem: LedgerItem = {
      label: formatMessage(messages.total),
      amount: `${formatPrice(total ?? 0)}`,
      bold: true,
      testID: 'ledger.total',
    };

    return [
      subtotalItem,
      taxesItem,
      ...feesItems,
      ...(tip ? [tipItem] : []),
      ...discountsItems,
      ...creditsItems,
      totalItem,
    ].filter(Boolean) as readonly LedgerItem[];
  }, [
    ledger,
    tip,
    shouldUseCredit,
    availableCredit,
    discountInfo,
    formatMessage,
    formatNumber,
  ]);
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  subTotal: {
    defaultMessage: 'Subtotal',
    description: 'Bag | Ledger | Subtotal',
  },
  tax: {
    defaultMessage: 'Tax',
    description: 'Bag | Ledger | Tax',
  },
  credit: {
    defaultMessage: 'Credit',
    description: 'Bag | Ledger | Credit',
  },
  total: {
    defaultMessage: 'Total',
    description: 'Bag | Ledger | Total',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type UseLedgerItemsParams = {
  tip: number;
  availableCredit: number;
  discountInfo?: string; // information shown on a discount tooltip.
  shouldUseCredit: boolean;
  ledger?: OrderingLedger;
};

type LedgerItem = Readonly<{
  label: string;

  /**
   * Amount can be represented as a simple string
   * or a collection of strings joined by " + " sign.
   */
  amount: string | readonly string[];
  bold?: boolean;
  palette?: 'standard' | 'success';

  /**
   * Optional helper text to be shown in tooltip/modal.
   */
  helperText?: string;
  testID?: string;
}>;
