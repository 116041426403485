import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export const useLoyaltyContentFallbacks = (availablePoints: number) => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      bagHeaderLoggedOut: formatMessage(messages.bagHeaderLoggedOut),
      bagHeaderNewUser: formatMessage(messages.bagHeaderNewUser),
      bagHeaderRecurringUser: formatMessage(messages.bagHeaderRecurringUser, {
        points: availablePoints,
      }),
      bagHeaderSubtitle: formatMessage(messages.bagHeaderSubtitle),
      bagHeaderLoggedOutImageMessage: formatMessage(
        messages.bagHeaderLoggedOutImageMessage,
      ),
      bagDiscountBody: formatMessage(messages.bagDiscountBody),
      earnPointsTitle: formatMessage(messages.earnPointsTitle),
      earnPointsDescription: formatMessage(messages.earnPointsDescription),
      redeemRewardsTitle: formatMessage(messages.redeemRewardsTitle),
      redeemRewardsDescription: formatMessage(
        messages.redeemRewardsDescription,
      ),
      lookOutForOffersTitle: formatMessage(messages.lookOutForOffersTitle),
      lookOutForOffersDescription: formatMessage(
        messages.lookOutForOffersDescription,
      ),
      homeChallengesEmptyStateTitle: formatMessage(
        messages.homeChallengesEmptyStateTitle,
      ),
      homeChallengesEmptyStateBody: formatMessage(
        messages.homeChallengesEmptyStateBody,
      ),
      homeChallengesEmptyStateCtaXS: formatMessage(
        messages.homeChallengesEmptyStateCtaXS,
      ),
      homeChallengesEmptyStateCtaSM: formatMessage(
        messages.homeChallengesEmptyStateCtaSM,
      ),
      homeRewardsEmptyStateTitle: formatMessage(
        messages.homeRewardsEmptyStateTitle,
      ),
      homeRewardsEmptyStateBody: formatMessage(
        messages.homeRewardsEmptyStateBody,
      ),
    }),
    [availablePoints, formatMessage],
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  bagHeaderLoggedOut: {
    defaultMessage: 'Complete your order + earn',
    description: 'Loyalty Content | Bag | Title Logged Out',
  },
  bagHeaderNewUser: {
    defaultMessage: 'You’re about to get rewarded',
    description: 'Loyalty Content | Bag | Title New User',
  },
  bagHeaderRecurringUser: {
    defaultMessage: 'You have {points} points',
    description: 'Loyalty Content | Bag | Title Recurring User',
  },
  bagHeaderSubtitle: {
    defaultMessage:
      'Apply one available reward to this order. Required items must be in your bag to apply a reward.',
    description: 'Loyalty Content | Bag | Subtitle',
  },
  bagHeaderLoggedOutImageMessage: {
    defaultMessage:
      'Get a free side of Ripple Fries when you create an account',
    description: 'Loyalty Content | Bag | Image Text',
  },
  bagDiscountBody: {
    defaultMessage: 'Reward discount applied before taxes and fees.',
    description: 'Loyalty Content | Bag | Discount body',
  },
  earnPointsTitle: {
    defaultMessage: 'Earn points',
    description: 'Loyalty Content | Earn points | Title',
  },
  earnPointsDescription: {
    defaultMessage:
      'Scan your QR code at checkout to earn 10 points for every $1 spent',
    description: 'Loyalty Content | Earn points | Description',
  },
  redeemRewardsTitle: {
    defaultMessage: 'Redeem rewards',
    description: 'Loyalty Content | Redeem rewards | Title',
  },
  redeemRewardsDescription: {
    defaultMessage: 'Use points to unlock rewards and free Sweetgreen',
    description: 'Loyalty Content | Redeem rewards | Description',
  },
  lookOutForOffersTitle: {
    defaultMessage: 'Look out for offers',
    description: 'Loyalty Content | Look out for offers | Title',
  },
  lookOutForOffersDescription: {
    defaultMessage:
      'Look out for offers to earn more points and special rewards',
    description: 'Loyalty Content | Look out for offers | Description',
  },
  homeChallengesEmptyStateTitle: {
    defaultMessage: 'Free delicious deals ahead',
    description: 'Loyalty Content | Home | Challenges | Empty State | Title',
  },
  homeChallengesEmptyStateBody: {
    defaultMessage: 'Earn 10 points for every $1',
    description: 'Loyalty Content | Home | Challenges | Empty State | Body',
  },
  homeChallengesEmptyStateCtaXS: {
    defaultMessage: 'Start order',
    description: 'Loyalty Content | Home | Challenges | Empty State | CTA XS',
  },
  homeChallengesEmptyStateCtaSM: {
    defaultMessage: 'Start an order',
    description: 'Loyalty Content | Home | Challenges | Empty State | CTA SM',
  },
  homeRewardsEmptyStateTitle: {
    defaultMessage: 'Rewards',
    description: 'Loyalty Content | Home | Rewards | Empty State | Title',
  },
  homeRewardsEmptyStateBody: {
    defaultMessage: 'No bonus rewards available at the moment.',
    description: 'Loyalty Content | Home | Rewards | Empty State | Body',
  },
});
