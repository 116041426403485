/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyInfoQueryVariables = Types.Exact<{ [key: string]: never }>;

export type LoyaltyInfoQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly firstName: string | null;
  } | null;
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly points: {
      readonly __typename?: 'UserPointsStatus';
      readonly total: number;
      readonly available: number;
      readonly pending: number;
    } | null;
    readonly rewards: ReadonlyArray<{
      readonly __typename: 'Reward';
      readonly id: string;
      readonly name: string;
      readonly expirationDate: string | null;
      readonly rewardType: Types.RewardType;
      readonly redeemable: boolean;
      readonly redeemableAt: string | null;
      readonly redemptionChannel: Types.RedemptionChannel;
      readonly terms: string;
      readonly assetUrl: string | null;
    }> | null;
    readonly pointsRewards: ReadonlyArray<{
      readonly __typename: 'PointsReward';
      readonly id: string;
      readonly points: number;
      readonly redeemable: boolean;
      readonly assetUrl: string | null;
      readonly name: string;
    }> | null;
    readonly tier: {
      readonly __typename: 'Tier';
      readonly id: string;
      readonly nameV2: Types.TierName;
    } | null;
  } | null;
  readonly challenges: ReadonlyArray<{
    readonly __typename: 'Challenge';
    readonly achieved: number;
    readonly celebrationCopy: string;
    readonly descriptiveTitle: string;
    readonly expirationDate: string;
    readonly goal: number;
    readonly id: string;
    readonly legalTerms: string;
    readonly percentageCompleted: number;
    readonly permalink: string;
    readonly progressType: Types.ChallengeProgressType;
    readonly progress: number;
    readonly progressCopy: string;
    readonly status: Types.ChallengeStatus;
    readonly title: string;
    readonly themeV2: Types.ChallengeThemeV2;
    readonly assetUrl: string | null;
    readonly multipleBehaviorDetails: {
      readonly __typename?: 'HurdleBehaviorDetails';
      readonly hurdleBehaviors: ReadonlyArray<{
        readonly __typename?: 'HurdleBehavior';
        readonly goal: number;
        readonly progress: number;
        readonly achieved: number;
        readonly outcome: number;
      }>;
    } | null;
  }>;
};

export const LoyaltyInfoDocument = gql`
  query LoyaltyInfo {
    currentCustomer {
      id
      __typename
      firstName
    }
    loyaltyProfile {
      customerId
      points {
        total
        available
        pending
      }
      rewards {
        id
        __typename
        name
        expirationDate
        rewardType
        redeemable
        redeemableAt
        redemptionChannel
        terms
        assetUrl
      }
      pointsRewards {
        id
        __typename
        points
        name: displayName
        redeemable
        assetUrl
      }
      tier {
        id
        __typename
        nameV2
      }
    }
    challenges {
      __typename
      achieved
      celebrationCopy
      descriptiveTitle
      expirationDate
      goal
      id
      legalTerms
      percentageCompleted
      permalink
      progressType
      progress
      progressCopy
      status
      title
      themeV2
      assetUrl
      multipleBehaviorDetails {
        ... on HurdleBehaviorDetails {
          hurdleBehaviors {
            goal
            progress
            achieved
            outcome
          }
        }
      }
    }
  }
`;

export function useLoyaltyInfoQuery(
  options?: Omit<Urql.UseQueryArgs<LoyaltyInfoQueryVariables>, 'query'>,
) {
  return Urql.useQuery<LoyaltyInfoQuery, LoyaltyInfoQueryVariables>({
    query: LoyaltyInfoDocument,
    ...options,
  });
}
