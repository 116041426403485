import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type PressableProps } from 'react-native';
import { useResponsive } from '@sg/garnish';

import { messages } from '../messages';
import { NavbarTextLinkCta } from './NavbarTextLinkCta';

export const NavbarLoyaltyCta = (props: Props) => {
  const { points, onPress } = props;

  const { formatMessage, formatNumber } = useIntl();
  const { minWidth } = useResponsive();

  return (
    <NavbarTextLinkCta
      testID="nav-bar.loyalty"
      aria-label={formatMessage(messages.loyaltyA11y)}
      onPress={onPress}
    >
      {minWidth.isMD ? (
        <FormattedMessage {...messages.loyaltyWithPoints} values={{ points: formatNumber(points) }} /> // prettier-ignore
      ) : (
        <FormattedMessage {...messages.loyalty} />
      )}
    </NavbarTextLinkCta>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress'> & {
  points: number;
};
