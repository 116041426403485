import React from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import { useStyle } from 'react-native-style-utilities';

// ─────────────────────────────────────────────────────────────────────────────

export const LoadingDot = (props: LoadingDotProps) => {
  const { color, animatedValue } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const containerAnimatedStyle = useStyle(() => {
    return { transform: [{ translateY: animatedValue }] };
  }, [animatedValue]);

  const dotDynamicStyles = useStyle(() => {
    return { backgroundColor: color };
  }, [color]);

  const dotStyles = [styles.dot, dotDynamicStyles];

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={containerAnimatedStyle}>
      <View style={dotStyles} />
    </Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DOT_SIZE = 5;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoadingDotProps = {
  color: string;
  animatedValue: Animated.AnimatedInterpolation<number>;
};
