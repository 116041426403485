import React, { type PropsWithChildren } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { theme } from '@garnish/constants';

import { DisplayText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const PinPromptConfirmButton = (props: PinPromptConfirmButtonProps) => {
  return (
    <TouchableOpacity
      accessibilityRole="button"
      onPress={props.onPress}
      style={styles.pressable}
    >
      <DisplayText sizeMatch={['32']} style={styles.label}>
        {props.children}
      </DisplayText>
    </TouchableOpacity>
  );
};

//  ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomStartRadius: theme.radius.large,
    borderBottomEndRadius: theme.radius.large,
    backgroundColor: theme.colors.KALE,
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['4'],
  },
  label: {
    color: theme.colors.WHITE,
  },
});

//  ─── Types ──────────────────────────────────────────────────────────────────

type PinPromptConfirmButtonProps = PropsWithChildren<{
  onPress: () => void;
}>;
