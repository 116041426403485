import React, { type ComponentProps } from 'react';
import { StyleSheet, View } from 'react-native';
import { Scrollspy, theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationIngredientsGridContainer = (
  props: CustomizationIngredientsGridSectionProps,
) => {
  const { children, ...restProps } = props;

  const { match } = useResponsive();

  const contentContainerStyles = [
    styles.contentContainer,
    match([styles.contentContainerXS]),
  ];

  return (
    <View style={styles.outerContainer}>
      <Scrollspy.ScrollView
        contentContainerStyle={contentContainerStyles}
        {...restProps}
      >
        {children}
      </Scrollspy.ScrollView>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: theme.colors.OATMEAL,
  },
  contentContainer: {
    maxWidth: 1040,
  },
  contentContainerXS: {
    paddingBottom: theme.spacing['30'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationIngredientsGridSectionProps = ComponentProps<
  typeof Scrollspy.ScrollView
>;
