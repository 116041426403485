/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagCountQueryVariables = Types.Exact<{ [key: string]: never }>;

export type BagCountQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename: 'Order';
    readonly id: string;
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'LineItem';
      readonly id: string;
    }>;
  } | null;
};

export const BagCountDocument = gql`
  query BagCount {
    cart {
      id
      __typename
      lineItems {
        id
        __typename
      }
    }
  }
`;

export function useBagCountQuery(
  options?: Omit<Urql.UseQueryArgs<BagCountQueryVariables>, 'query'>,
) {
  return Urql.useQuery<BagCountQuery, BagCountQueryVariables>({
    query: BagCountDocument,
    ...options,
  });
}
