import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, IngredientCardV2, Radio, theme } from '@sg/garnish';
import {
  DressingWeight,
  type IngredientModification,
} from '@sg/graphql-schema';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsDressingWeight = (
  props: CustomizationDetailsDressingModeProps,
) => {
  const {
    ingredientModification,
    activeDressingsDetails,
    setDressingWeight,
    isDisabled,
  } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const { id, asset, name } = ingredientModification.ingredient;

  const selectedWeight = useMemo(() => {
    const maybeActiveDressingWeight = activeDressingsDetails.find(
      (dressing) => dressing.id === id,
    )?.weight;

    return maybeActiveDressingWeight ?? DressingWeight.Medium;
  }, [activeDressingsDetails, id]);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onWeightChange = useCallback(
    (weight: DressingWeight) => {
      setDressingWeight(ingredientModification, weight);
    },
    [ingredientModification, setDressingWeight],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      <View style={styles.header}>
        <View style={styles.imgContainer}>
          <IngredientCardV2.Image
            source={asset.url}
            imageWidth={64}
            accessibilityLabel={name}
          />
        </View>

        <BodyText sizeMatch={['24']} style={styles.heading}>
          {name}
        </BodyText>
      </View>

      <Radio.Group itemsPerRow={3} gap={theme.spacing['2']}>
        <Radio.Item<DressingWeight>
          value={DressingWeight.Light}
          isSelected={selectedWeight === DressingWeight.Light}
          onPress={onWeightChange}
          label={formatMessage(messages.label, {
            weight: DressingWeight.Light,
          })}
          accessibilityLabel={formatMessage(messages.a11yLabel, {
            weight: DressingWeight.Light,
          })}
          isDisabled={isDisabled}
          size="sm"
        />

        <Radio.Item<DressingWeight>
          value={DressingWeight.Medium}
          isSelected={selectedWeight === DressingWeight.Medium}
          onPress={onWeightChange}
          label={formatMessage(messages.label, {
            weight: DressingWeight.Medium,
          })}
          accessibilityLabel={formatMessage(messages.a11yLabel, {
            weight: DressingWeight.Medium,
          })}
          isDisabled={isDisabled}
          size="sm"
        />

        <Radio.Item<DressingWeight>
          value={DressingWeight.Heavy}
          isSelected={selectedWeight === DressingWeight.Heavy}
          onPress={onWeightChange}
          label={formatMessage(messages.label, {
            weight: DressingWeight.Heavy,
          })}
          accessibilityLabel={formatMessage(messages.a11yLabel, {
            weight: DressingWeight.Heavy,
          })}
          isDisabled={isDisabled}
          size="sm"
        />
      </Radio.Group>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  label: {
    defaultMessage: `{weight, select,
      LIGHT {Light}
      MEDIUM {Medium}
      HEAVY {Heavy}
      other {}
    }`,
    description: 'Customization | Dressing details | Dressing weight label',
  },
  a11yLabel: {
    defaultMessage: `{weight, select,
      LIGHT {Select light weight}
      MEDIUM {Select medium weight}
      HEAVY {Select heavy weight}
      other {}
    }`,
    description:
      'Customization | Dressing details | Dressing weight a11y label',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.spacing['2'],
    marginBottom: theme.spacing['2'],
  },
  imgContainer: {
    width: 64,
  },
  heading: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationDetailsDressingModeProps = {
  ingredientModification: IngredientModification;
  activeDressingsDetails: readonly {
    id: string;
    weight?: DressingWeight;
  }[];
  setDressingWeight: (
    ingredientModification: IngredientModification,
    dressingWeight: DressingWeight,
  ) => void;
  isDisabled?: boolean;
};
