/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FeedbackQuestionsQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;

export type FeedbackQuestionsQuery = {
  readonly __typename?: 'Query';
  readonly feedbackQuestions: ReadonlyArray<{
    readonly __typename: 'FeedbackQuestion';
    readonly id: string;
    readonly text: string | null;
    readonly slug: string | null;
    readonly responses: ReadonlyArray<{
      readonly __typename: 'FeedbackResponse';
      readonly id: string;
      readonly text: string;
    }> | null;
  }>;
};

export type InStoreFeedbackQuestionsQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;

export type InStoreFeedbackQuestionsQuery = {
  readonly __typename?: 'Query';
  readonly inStoreFeedbackQuestions: ReadonlyArray<{
    readonly __typename: 'FeedbackQuestion';
    readonly id: string;
    readonly text: string | null;
    readonly slug: string | null;
    readonly responses: ReadonlyArray<{
      readonly __typename: 'FeedbackResponse';
      readonly id: string;
      readonly text: string;
    }> | null;
  }>;
};

export type FeedbackOrderStatusQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID']['input'];
}>;

export type FeedbackOrderStatusQuery = {
  readonly __typename?: 'Query';
  readonly orderStatus:
    | { readonly __typename: 'InvalidOrder' }
    | {
        readonly __typename: 'OrderStatus';
        readonly flattenedOrderStatus: Types.FlattenedOrderStatuses;
        readonly order: {
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly wantedTime: string;
          readonly description: string;
          readonly isPendingFeedback: boolean;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly quantity: number;
            readonly customName: string | null;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
            };
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly estimatedDeliveryTime: string | null;
          } | null;
        };
      }
    | null;
};

export type SubmitFeedbackMutationVariables = Types.Exact<{
  input: Types.FeedbackInput;
}>;

export type SubmitFeedbackMutation = {
  readonly __typename?: 'Mutation';
  readonly submitFeedback: {
    readonly __typename: 'FeedbackSuccess';
    readonly success: boolean;
  };
};

export type SubmitInStoreFeedbackMutationVariables = Types.Exact<{
  input: Types.FeedbackInput;
}>;

export type SubmitInStoreFeedbackMutation = {
  readonly __typename?: 'Mutation';
  readonly submitInStoreFeedback: {
    readonly __typename: 'FeedbackSuccess';
    readonly success: boolean;
  };
};

export const FeedbackQuestionsDocument = gql`
  query feedbackQuestions($orderId: ID!) {
    feedbackQuestions(orderId: $orderId) {
      id
      __typename
      text
      slug
      responses {
        id
        __typename
        text
      }
    }
  }
`;

export function useFeedbackQuestionsQuery(
  options: Omit<Urql.UseQueryArgs<FeedbackQuestionsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FeedbackQuestionsQuery, FeedbackQuestionsQueryVariables>(
    { query: FeedbackQuestionsDocument, ...options },
  );
}
export const InStoreFeedbackQuestionsDocument = gql`
  query inStoreFeedbackQuestions($orderId: ID!) {
    inStoreFeedbackQuestions(orderId: $orderId) {
      id
      __typename
      text
      slug
      responses {
        id
        __typename
        text
      }
    }
  }
`;

export function useInStoreFeedbackQuestionsQuery(
  options: Omit<
    Urql.UseQueryArgs<InStoreFeedbackQuestionsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    InStoreFeedbackQuestionsQuery,
    InStoreFeedbackQuestionsQueryVariables
  >({ query: InStoreFeedbackQuestionsDocument, ...options });
}
export const FeedbackOrderStatusDocument = gql`
  query feedbackOrderStatus($orderId: ID!) {
    orderStatus(orderId: $orderId) {
      __typename
      ... on OrderStatus {
        flattenedOrderStatus
        order {
          id
          __typename
          orderType
          wantedTime
          description
          isPendingFeedback
          restaurant {
            id
            __typename
            slug
            name
          }
          lineItems {
            id
            __typename
            quantity
            customName
            product {
              id
              __typename
              name
              slug
            }
          }
          deliveryOrderDetail {
            id
            __typename
            estimatedDeliveryTime
          }
        }
      }
    }
  }
`;

export function useFeedbackOrderStatusQuery(
  options: Omit<Urql.UseQueryArgs<FeedbackOrderStatusQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    FeedbackOrderStatusQuery,
    FeedbackOrderStatusQueryVariables
  >({ query: FeedbackOrderStatusDocument, ...options });
}
export const SubmitFeedbackDocument = gql`
  mutation submitFeedback($input: FeedbackInput!) {
    submitFeedback(input: $input) {
      __typename
      success
    }
  }
`;

export function useSubmitFeedbackMutation() {
  return Urql.useMutation<
    SubmitFeedbackMutation,
    SubmitFeedbackMutationVariables
  >(SubmitFeedbackDocument);
}
export const SubmitInStoreFeedbackDocument = gql`
  mutation submitInStoreFeedback($input: FeedbackInput!) {
    submitInStoreFeedback(input: $input) {
      __typename
      success
    }
  }
`;

export function useSubmitInStoreFeedbackMutation() {
  return Urql.useMutation<
    SubmitInStoreFeedbackMutation,
    SubmitInStoreFeedbackMutationVariables
  >(SubmitInStoreFeedbackDocument);
}
