import React, { type ComponentProps, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNoticeBannersStackContext, useToggleState } from '@sg/garnish';

import { ReorderConflictModal } from '@order/components';
import { OrderLineItem } from '@order/features/orders';
import { useReorderOrderLineItem } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { reorderOrderLineItemMessages as messages } from './ReorderOrderLineItem.messages';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderOrderLineItem = (props: ReorderOrderLineItemProps) => {
  const {
    variation,
    lineItem,
    orderDetails,
    mostRecentOrder,
    shouldShowSuccessBanner,
  } = props;

  const { push: addBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();
  const { track } = useTelemetry();

  // ─── State ───────────────────────────────────────────────────────────

  const {
    value: isConflictReviewModalVisible,
    toggleOn: showConflictReviewModal,
    toggleOff: hideConflictReviewModal,
  } = useToggleState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onSuccessfulReorder = useCallback(() => {
    hideConflictReviewModal();

    if (!shouldShowSuccessBanner) return;

    addBanner({
      palette: 'success',
      text: formatMessage(messages.successfulReorderMessage, {
        quantity: 1,
        product: lineItem.product.name,
      }),
      autoHideTimeout: 2500,
    });
  }, [
    addBanner,
    formatMessage,
    lineItem.product.name,
    shouldShowSuccessBanner,
    hideConflictReviewModal,
  ]);

  const {
    reorderLineItem,
    isFetching: isReordering,
    unavailableIngredients,
    targetLocation,
  } = useReorderOrderLineItem({
    variation: 'order',
    mostRecentOrder,
    productName: lineItem.product.name,
    onUnavailableIngredientConflict: showConflictReviewModal,
    onSuccess: onSuccessfulReorder,
  });

  const onLineItemCardPress = useCallback(async () => {
    track('reorder.line_item.tapped');
    await reorderLineItem(lineItem.id);
  }, [reorderLineItem, lineItem.id, track]);

  // ─── Derived Data ────────────────────────────────────────────────────

  const reorderLineItemForce = useCallback(async () => {
    await reorderLineItem(lineItem.id, true);
  }, [lineItem.id, reorderLineItem]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <OrderLineItem
        variation={variation}
        lineItem={lineItem}
        orderDetails={orderDetails}
        isReordering={isReordering}
        onLineItemCardPress={onLineItemCardPress}
        onAddToBagButtonPress={reorderLineItem}
      />

      <ReorderConflictModal
        visible={isConflictReviewModalVisible}
        lineItem={lineItem}
        isReordering={isReordering}
        onRequestClose={hideConflictReviewModal}
        addToBagAnyway={reorderLineItemForce}
        targetLocation={targetLocation}
        unavailableIngredients={unavailableIngredients}
      />
    </>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderOrderLineItemProps = {
  lineItem: OrderLineItemProps['lineItem'];
  orderDetails: OrderLineItemProps['orderDetails'];
  mostRecentOrder: UseReorderOrderLineItemParams['mostRecentOrder'];
  variation?: OrderLineItemProps['variation'];
  shouldShowSuccessBanner?: boolean;
};

type OrderLineItemProps = ComponentProps<typeof OrderLineItem>;

type UseReorderOrderLineItemParams = Parameters<
  typeof useReorderOrderLineItem
>[0];
