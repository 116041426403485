import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { BodyText } from '@sg/garnish';

import { type BagReward } from '../../../machines/ordering-machine.types';

// ─────────────────────────────────────────────────────────────────────────────

export const BagRewardsEmptyState = (props: BagRewardsEmptyStateProps) => {
  const { availablePoints, pointsRewards } = props;

  const { formatMessage } = useIntl();

  const message = useMemo(() => {
    const nextPointsReward = pointsRewards.find(
      (reward) => availablePoints < (reward.points ?? 0),
    );

    if (nextPointsReward) {
      return formatMessage(messages.dynamicMessaging, {
        name: sanitizeRewardName(nextPointsReward.name),
        points: (nextPointsReward.points ?? 0) - availablePoints,
      });
    }

    return formatMessage(messages.defaultMessaging);
  }, [availablePoints, pointsRewards, formatMessage]);

  return <BodyText sizeMatch={['14']}>{message}</BodyText>;
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  dynamicMessaging: {
    defaultMessage: "You're {points} pts from a Free {name}!",
    description: 'Loyalty | Bag | Empty State | Dynamic',
  },
  defaultMessaging: {
    defaultMessage: 'No rewards are available at the moment.',
    description: 'Loyalty | Bag | Empty State | Default',
  },
});

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * In here we are interpolating the reward name with the following string:
 * - You're {points} pts from a Free {name}!
 * If the reward name begin with "Free" such as in "Free entree up to $18",
 * Then it becomes "You're 300 pts from a Free Free entree up to $18".
 * To avoid this behavior we remove the "Free" prefix from the reward name.
 */
const sanitizeRewardName = (name: string) => {
  if (name?.startsWith('Free ')) return name.replace('Free ', '');
  return name;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type BagRewardsEmptyStateProps = {
  availablePoints: number;
  pointsRewards: readonly BagReward[];
};
