/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FavoritesQueryVariables = Types.Exact<{
  input: Types.FavoritedLineItemsInput;
}>;

export type FavoritesQuery = {
  readonly __typename?: 'Query';
  readonly favoritedLineItems: ReadonlyArray<{
    readonly __typename: 'LineItem';
    readonly id: string;
    readonly slug: string;
    readonly customName: string | null;
    readonly quantity: number;
    readonly cost: number;
    readonly perItemCost: number;
    readonly favorited: boolean;
    readonly dressingMode: Types.DressingMode;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly isCustom: boolean;
      readonly description: string;
      readonly label: {
        readonly __typename: 'ProductLabel';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly restaurant: {
        readonly __typename: 'Restaurant';
        readonly id: string;
      };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    };
    readonly ingredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: Types.DressingWeight;
    }>;
  }>;
};

export const FavoritesDocument = gql`
  query favorites($input: FavoritedLineItemsInput!) {
    favoritedLineItems(input: $input) {
      id
      __typename
      slug
      customName
      quantity
      cost
      perItemCost
      favorited
      product {
        id
        __typename
        name
        calories
        cost
        isCustom
        label {
          id
          __typename
          name
        }
        description
        asset {
          url
        }
        restaurant {
          id
          __typename
        }
        dietaryProperties {
          id
          __typename
          name
        }
      }
      ingredients {
        id
        __typename
        name
      }
      addedIngredients {
        id
        __typename
        name
      }
      removedIngredients {
        id
        __typename
        name
      }
      mixedDressingDetails {
        ingredientId
        weight
      }
      dressingMode
    }
  }
`;

export function useFavoritesQuery(
  options: Omit<Urql.UseQueryArgs<FavoritesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<FavoritesQuery, FavoritesQueryVariables>({
    query: FavoritesDocument,
    ...options,
  });
}
