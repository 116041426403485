import type { ImageURISource } from 'react-native';

import { MAX_DPR_SETTING } from './ContentfulImage.constants';
import type { ContentfulImageTransformOptions } from './ContentfulImageTransformOptions';
import { getContentfulImageUrl } from './getContentfulImageUrl';

/**
 * Returns Contentful image URLs with the specified
 * transform options applied at specified scales.
 *
 * If no options are specified or if the options do not contain
 * `w`, `h`, or `r` properties, a single source is returned
 *
 * @param baseUrl The base image URL returned from a Contentful query.
 * @param options The image transform options.
 * @param maxScale The maximum scale factor (default is 2).
 * @returns An `ImageURISource` or an array of `ImageURISource`.
 */
export const getContentfulImageSources = (
  baseUrl: string,
  options?: Partial<ContentfulImageTransformOptions>,
  maxScale?: number,
): ImageURISource | ImageURISource[] => {
  if (!options) {
    return { uri: baseUrl };
  }

  if (
    options.w === undefined &&
    options.h === undefined &&
    options.r === undefined
  ) {
    return {
      uri: getContentfulImageUrl(baseUrl, options),
    };
  }

  const scaleWithLimit = Math.min(maxScale ?? 2, MAX_DPR_SETTING);

  // eslint-disable-next-line unicorn/no-new-array
  return new Array(scaleWithLimit).fill(0).map((_, index) => {
    const scale = index + 1;

    return {
      uri: getContentfulImageUrl(baseUrl, options, scale),
      scale,
    };
  });
};
