import React, { type PropsWithChildren } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, type TextStyle, View, type ViewStyle } from 'react-native';
import {
  BodyText,
  Container,
  DisplayText,
  theme,
  TitleText,
  useResponsive,
} from '@sg/garnish';

const LoyaltyHomeHowItWorksContainer = (props: ContainerProps) => {
  const { palette, children } = props;
  const { match } = useResponsive();

  return (
    <View style={[styles.background, backgroundPalettes[palette]]}>
      <Container
        style={match([
          styles.containerSM,
          styles.containerSM,
          styles.containerLG,
        ])}
      >
        <DisplayText
          style={[textPalettes[palette], styles.centerText]}
          sizeMatch={['48']}
        >
          <FormattedMessage {...messages.title} />
        </DisplayText>

        {children}
      </Container>
    </View>
  );
};

const LoyaltyHomeHowItWorksContent = (props: PropsWithChildren) => {
  return <View style={styles.content}>{props.children}</View>;
};

const LoyaltyHomeHowItWorksCard = (props: CardProps) => {
  const { index, title, description, palette } = props;

  return (
    <View style={styles.card}>
      <TitleText
        sizeMatch={['24']}
        style={[styles.index, textPalettes[palette]]}
      >
        {index}
      </TitleText>

      <TitleText
        style={[textPalettes[palette], styles.centerText]}
        sizeMatch={['32', '32', '48']}
      >
        {title}
      </TitleText>

      <BodyText
        style={[descriptionPalettes[palette], styles.centerText]}
        sizeMatch={['14', '14', '22']}
      >
        {description}
      </BodyText>
    </View>
  );
};

const LoyaltyHomeHowItWorksDisclaimer = (props: ContainerProps) => {
  return (
    <BodyText
      sizeMatch={['12']}
      style={[styles.disclaimer, disclaimerPalettes[props.palette]]}
    >
      {props.children}
    </BodyText>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'How it works',
    description: 'Loyalty Home | How it works | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  background: {
    flexGrow: 1,
    height: 'auto',
  },
  lightBackground: {
    backgroundColor: theme.colors.CREAM,
  },
  darkBackground: {
    backgroundColor: theme.colors.DARK_KALE,
    borderColor: theme.colors.CREAM,
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  kaleText: {
    color: theme.colors.KALE,
    borderColor: theme.colors.KALE,
  },
  charcoalText: {
    color: theme.colors.CHARCOAL,
  },
  darkText: {
    color: theme.colors.CREAM,
    borderColor: theme.colors.CREAM,
  },
  containerSM: {
    paddingHorizontal: theme.spacing['6'],
    paddingVertical: theme.spacing['20'],
    gap: theme.spacing['10'],
  },
  containerLG: {
    paddingHorizontal: theme.spacing['30'],
    paddingVertical: theme.spacing['20'],
    gap: theme.spacing['10'],
  },
  content: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  card: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: theme.spacing['6'],
    paddingHorizontal: theme.spacing['16'],
    gap: theme.spacing['4'],
  },
  index: {
    width: 32,
    height: 32,
    lineHeight: 32,
    borderRadius: 32,
    borderWidth: 2,
    textAlign: 'center',
  },
  centerText: {
    textAlign: 'center',
  },
  disclaimer: {
    color: theme.colors.CHARCOAL,
    alignSelf: 'center',
    textAlign: 'center',
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const backgroundPalettes: Record<HowItWorksPalette, ViewStyle> = {
  dark: styles.darkBackground,
  light: styles.lightBackground,
};

const textPalettes: Record<HowItWorksPalette, TextStyle> = {
  dark: styles.darkText,
  light: styles.kaleText,
};

const disclaimerPalettes: Record<HowItWorksPalette, TextStyle> = {
  dark: styles.darkText,
  light: styles.charcoalText,
};

const descriptionPalettes: Record<HowItWorksPalette, TextStyle> = {
  dark: styles.darkText,
  light: {},
};

// ─── Types ──────────────────────────────────────────────────────────────────

type HowItWorksPalette = 'dark' | 'light';

type ContainerProps = {
  children: PropsWithChildren['children'];
  palette: HowItWorksPalette;
};

type CardProps = {
  index: number;
  title: string;
  description: string;
  palette: HowItWorksPalette;
};

// ─── Compound Components ────────────────────────────────────────────────────

export const LoyaltyHomeHowItWorks = {
  Container: LoyaltyHomeHowItWorksContainer,
  Content: LoyaltyHomeHowItWorksContent,
  Card: LoyaltyHomeHowItWorksCard,
  Disclaimer: LoyaltyHomeHowItWorksDisclaimer,
};
