import { View } from 'react-native';

import {
  IngredientCardImage,
  IngredientCardLabel,
  IngredientCardNoticeLabel,
  IngredientCardPressable,
  IngredientCardQuantity,
  IngredientCardQuantityStepper,
} from './components';

export const IngredientCardV2 = {
  Pressable: IngredientCardPressable,
  Image: IngredientCardImage,
  ImageContainer: View,
  Label: IngredientCardLabel,
  NoticeLabel: IngredientCardNoticeLabel,
  Quantity: IngredientCardQuantity,
  QuantityStepper: IngredientCardQuantityStepper,
};
