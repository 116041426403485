import React from 'react';
import { type StyleProp, StyleSheet, type TextStyle } from 'react-native';
import { BodyText, theme } from '@sg/garnish';

export const LoyaltyHomeLegalDisclaimer = (props: Props) => {
  const { disclaimer, style } = props;

  return (
    <BodyText sizeMatch={['12']} style={[styles.disclaimer, style]}>
      {disclaimer}
    </BodyText>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  disclaimer: {
    marginTop: theme.spacing['8'],
    alignSelf: 'center',
    textAlign: 'center',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = {
  disclaimer: string;
  style: StyleProp<TextStyle>;
};
