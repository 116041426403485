/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type InStoreOrderLastPendingFeedbackQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type InStoreOrderLastPendingFeedbackQuery = {
  readonly __typename?: 'Query';
  readonly inStoreOrderLastPendingFeedback: {
    readonly __typename: 'InStoreOrder';
    readonly id: string;
    readonly wantedTime: string;
  } | null;
};

export const InStoreOrderLastPendingFeedbackDocument = gql`
  query InStoreOrderLastPendingFeedback {
    inStoreOrderLastPendingFeedback {
      id
      __typename
      wantedTime
    }
  }
`;

export function useInStoreOrderLastPendingFeedbackQuery(
  options?: Omit<
    Urql.UseQueryArgs<InStoreOrderLastPendingFeedbackQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    InStoreOrderLastPendingFeedbackQuery,
    InStoreOrderLastPendingFeedbackQueryVariables
  >({ query: InStoreOrderLastPendingFeedbackDocument, ...options });
}
