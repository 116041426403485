import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { DisplayText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const PinPromptHeading = (props: PropsWithChildren) => {
  return (
    <View style={styles.container}>
      <DisplayText sizeMatch={['40']}>{props.children}</DisplayText>
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingVertical: theme.spacing['5'],
    paddingHorizontal: theme.spacing['4'],
  },
});
