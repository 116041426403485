/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReferralShareUrlQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ReferralShareUrlQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly referralShareUrlEmail: string;
  } | null;
};

export const ReferralShareUrlDocument = gql`
  query referralShareUrl {
    currentCustomer {
      id
      __typename
      referralShareUrlEmail
    }
  }
`;

export function useReferralShareUrlQuery(
  options?: Omit<Urql.UseQueryArgs<ReferralShareUrlQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReferralShareUrlQuery, ReferralShareUrlQueryVariables>({
    query: ReferralShareUrlDocument,
    ...options,
  });
}
