import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Platform, StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  BodyText,
  CustomNavigationHeader,
  LoadingAnimation,
  Tabs,
  theme,
  useToggleState,
} from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { OrderStatusBar, SignedOutView } from '@order/components';
import { Loyalty, ScanAtCheckout } from '@order/features/loyalty';
import {
  useLoyaltyContent,
  useLoyaltyPointsRewards,
  useLoyaltyPremiumStatus,
} from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { useNavigateToMainTabs } from '../../navigation';
import {
  ScanAtCheckoutOptedInContent,
  ScanAtCheckoutOptedOutContent,
} from './components';
import { useOptInLoyaltyMutation } from './graphql/OptIn.generated';
import {
  useScanInStoreGiftCards,
  useScanInStoreTabs,
  useUserRewardsPoints,
} from './hooks';
import {
  containerPaletteStyles,
  textPaletteStyles,
} from './ScanAtCheckout.palettes';
import { ScanInStorePay } from './tabs';

// ─────────────────────────────────────────────────────────────────────────────

export const ScanAtCheckoutScreen = () => {
  const isLoggedIn = useIsLoggedIn();

  const {
    fetching: isLoadingUserRewardsPoints,
    loyaltyId,
    availablePoints,
    isOptedOut,
    refetch,
  } = useUserRewardsPoints();

  const { isLoadingGiftCards, hasGiftCardBalance } =
    useScanInStoreGiftCards('cache-and-network');

  const [data, optIn] = useOptInLoyaltyMutation();
  const isOptingIn = data.fetching;

  const handleOnOptIn = useCallback(async () => {
    await optIn({});
    refetch();
  }, [optIn, refetch]);

  // ─────────────────────────────────────────────────────────────────────

  if (!isLoggedIn) {
    return (
      <View style={styles.container}>
        <SignedOutView />
      </View>
    );
  }

  if (isLoadingUserRewardsPoints || isLoadingGiftCards) {
    return <LoadingAnimation />;
  }

  return (
    <ScanAtCheckoutScreenContentReady
      loyaltyId={loyaltyId}
      availablePoints={availablePoints}
      isOptingIn={isOptingIn}
      isOptedOut={isOptedOut}
      hasGiftCardBalance={hasGiftCardBalance}
      handleOnOptIn={handleOnOptIn}
    />
  );
};

// ─── Content ────────────────────────────────────────────────────────────────

const ScanAtCheckoutScreenContentReady = (props: ScanScreenProps) => {
  const {
    loyaltyId,
    availablePoints,
    isOptedOut,
    isOptingIn,
    hasGiftCardBalance,
    handleOnOptIn,
  } = props;

  const { formatMessage } = useIntl();
  const { track } = useTelemetry();

  // Special case in which the starting tab should be the pay tab.
  const shouldStartInPayTab = isOptedOut && hasGiftCardBalance;

  const {
    activeTabItemId,
    earnAndRedeemTab,
    payTab,
    tabs,
    setActiveTabItemId,
  } = useScanInStoreTabs(shouldStartInPayTab);

  // ─── Benefits ──────────────────────────────────────────────────────────────

  const { pointsRewards, isFetchingPointsRewards, refetchPointsRewards } =
    useLoyaltyPointsRewards();

  const hasBenefits = pointsRewards.length > 0;

  // ─── Scroll ────────────────────────────────────────────────────────────────

  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffsetSV = useScrollViewOffset(animatedRef);

  // ─── Contentful ────────────────────────────────────────────────────────────

  const { homeProgressBenefitsDisclaimer: benefitsDisclaimer } =
    useLoyaltyContent({ availablePoints: 0 });

  // ─── Rewards Modal ─────────────────────────────────────────────────────────

  const {
    value: isShowingRewardsModal,
    toggleOn: showRewardsModal,
    toggleOff: hideRewardsModal,
  } = useToggleState(false);

  const handleSeeRewardOptionsPress = useCallback(() => {
    showRewardsModal();
    track('scan.seerewards.cta_tapped');
  }, [showRewardsModal, track]);

  // ─── Navigation Helpers ────────────────────────────────────────────────────

  const isScreenFocused = useIsFocused();
  const navigation = useNavigation();
  const navigateToMainTabs = useNavigateToMainTabs();

  const navigateToLoyalty = useCallback(() => {
    track('seerewards.sgrewards.cta_tapped');
    void navigateToMainTabs('LoyaltyTab', {
      screen: 'SweetpassHome',
    });
  }, [navigateToMainTabs, track]);

  // ─── Styles ────────────────────────────────────────────────────────────────

  const { isPremium } = useLoyaltyPremiumStatus();
  const palette = isPremium ? 'premium' : 'primary';

  const containerStyles = [styles.container, containerPaletteStyles[palette]];
  const textStyles = textPaletteStyles[palette];

  // ───────────────────────────────────────────────────────────────────────────

  return (
    <Animated.ScrollView
      testID="scan-at-checkout-container"
      style={containerStyles}
      contentContainerStyle={styles.container}
      ref={animatedRef}
      stickyHeaderIndices={[0]}
    >
      {isScreenFocused && isPremium ? (
        <OrderStatusBar barStyle="light-content" />
      ) : null}

      <CustomNavigationHeader.Container
        palette={isPremium ? 'dark-kale' : 'cream'}
        safeAreaEdges={Platform.OS === 'android' ? ['top'] : []}
        scrollOffsetSV={scrollOffsetSV}
      >
        <CustomNavigationHeader.Content scrollOffsetSV={scrollOffsetSV}>
          <BodyText style={[styles.headerText, textStyles]} sizeMatch={['16']}>
            <FormattedMessage {...messages.header} />
          </BodyText>
        </CustomNavigationHeader.Content>

        <CustomNavigationHeader.Button.Right
          name="IconClose"
          palette={isPremium ? 'premium' : 'muted'}
          color={isPremium ? theme.colors.CREAM : theme.colors.BLACK}
          accessibilityLabel={formatMessage(messages.close)}
          onPress={navigation.goBack}
        />
      </CustomNavigationHeader.Container>

      <ScanAtCheckout.HeaderTitle style={[styles.title, textStyles]}>
        <FormattedMessage {...messages.header} />
      </ScanAtCheckout.HeaderTitle>

      <Tabs.Bar
        tabs={tabs}
        activeTabId={activeTabItemId}
        palette={palette}
        onChange={setActiveTabItemId}
      />

      <Tabs.Panel
        isActive={activeTabItemId === earnAndRedeemTab.id}
        tabId={earnAndRedeemTab.id}
        panelId={earnAndRedeemTab.panelId}
      >
        {isOptedOut ? (
          <ScanAtCheckoutOptedOutContent
            isOptingIn={isOptingIn}
            handleOnOptIn={handleOnOptIn}
          />
        ) : (
          <ScanAtCheckoutOptedInContent
            loyaltyId={loyaltyId}
            availablePoints={availablePoints}
            isPremium={isPremium}
            handleSeeRewardOptionsPress={handleSeeRewardOptionsPress}
          />
        )}
      </Tabs.Panel>

      <Tabs.Panel
        isActive={activeTabItemId === payTab.id}
        tabId={payTab.id}
        panelId={payTab.panelId}
      >
        <ScanInStorePay />
      </Tabs.Panel>

      <ScanAtCheckout.RewardOptionsModal
        isVisible={isShowingRewardsModal}
        shouldShowCta={hasBenefits}
        onRequestClose={hideRewardsModal}
        onPressButton={navigateToLoyalty}
      >
        <Loyalty.InfoBenefits
          outerContainerStyle={styles.loyaltyInfoContainer}
          loyaltyInfoBenefits={pointsRewards}
          isLoading={isFetchingPointsRewards}
          disclaimer={benefitsDisclaimer}
          refetch={refetchPointsRewards}
        />
      </ScanAtCheckout.RewardOptionsModal>
    </Animated.ScrollView>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  header: {
    defaultMessage: 'Scan at checkout',
    description: 'Earn + Redeem | Loyalty Rewards | Header',
  },
  contactSupport: {
    defaultMessage: 'Contact support',
    description: 'Earn + Redeem | Loyalty Rewards | Footer CTA',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Earn + Redeem | Loyalty Rewards | Close',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  title: {
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['4'],
  },
  headerText: {
    textAlign: 'center',
  },
  loyaltyInfoContainer: {
    marginTop: 0,
    marginBottom: theme.spacing['6'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ScanScreenProps = {
  loyaltyId: string | null;
  availablePoints: number;
  isOptedOut: boolean;
  isOptingIn: boolean;
  hasGiftCardBalance: boolean;
  handleOnOptIn: () => void;
};
