import { useContentfulContentTypeEntriesFactory } from '@sg/shared-fe/integrations/contentful';

import { useContentfulClient } from '../useContentfulClient';
import { useIsPreviewModeEnabled } from '../useIsPreviewModeEnabled';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns Contentful entries for the provided content type.
 */
export const useContentfulContentTypeEntries = <ContentTypeFields>(
  params: UseContentfulContentTypeEntriesParams,
) => {
  const contentfulClient = useContentfulClient();
  const isPreviewModeEnabled = useIsPreviewModeEnabled();

  return useContentfulContentTypeEntriesFactory<ContentTypeFields>({
    contentfulClient,
    isPreviewModeEnabled,
    ...params,
  });
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseContentfulContentTypeEntriesParams = Omit<
  Parameters<typeof useContentfulContentTypeEntriesFactory>[0],
  'contentfulClient' | 'isPreviewModeEnabled'
>;
