import { LoyaltyHomeBody } from './LoyaltyHomeBody';
import { LoyaltyHomeChallenges } from './LoyaltyHomeChallenges';
import { LoyaltyHomeChallengesEmptyState } from './LoyaltyHomeChallengesEmptyState/LoyaltyHomeChallengesEmptyState';
import { LoyaltyHomeChallengesRewardsContainer } from './LoyaltyHomeChallengesRewardsContainer';
import { LoyaltyHomeContainer } from './LoyaltyHomeContainer';
import { LoyaltyHomeErrorState } from './LoyaltyHomeErrorState';
import { LoyaltyHomeFooter } from './LoyaltyHomeFooter';
import { LoyaltyHomeHeader } from './LoyaltyHomeHeader';
import { LoyaltyHomeHowItWorks } from './LoyaltyHomeHowItWorks';
import { LoyaltyHomeLegalDisclaimer } from './LoyaltyHomeLegalDisclaimer';
import { LoyaltyHomeProgressBar } from './LoyaltyHomeProgressBar';
import { LoyaltyHomeProgressBenefit } from './LoyaltyHomeProgressBenefit';
import { LoyaltyHomeRewards } from './LoyaltyHomeRewards';
import { LoyaltyHomeRewardsEmptyState } from './LoyaltyHomeRewardsEmptyState';

export const LoyaltyHome = {
  Container: LoyaltyHomeContainer,
  ChallengeRewardsContainer: LoyaltyHomeChallengesRewardsContainer,
  Header: LoyaltyHomeHeader,
  Body: LoyaltyHomeBody,
  ProgressBenefit: LoyaltyHomeProgressBenefit,
  ProgressBar: LoyaltyHomeProgressBar,
  Footer: LoyaltyHomeFooter,
  Challenges: LoyaltyHomeChallenges,
  ChallengesEmptyState: LoyaltyHomeChallengesEmptyState,
  Rewards: LoyaltyHomeRewards,
  RewardsEmptyState: LoyaltyHomeRewardsEmptyState,
  HowItWorks: LoyaltyHomeHowItWorks,
  ErrorState: LoyaltyHomeErrorState,
  LegalDisclaimer: LoyaltyHomeLegalDisclaimer,
};
