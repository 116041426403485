import React, { useCallback, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import {
  Form,
  HStack,
  LoadingPlaceholder,
  theme,
  useResponsive,
} from '@sg/garnish';

import {
  RequestAccountDeletionModal,
  RequestAccountDeletionTrigger,
  useRequestAccountDeletionModal,
} from '@order/components';
import { useCustomer } from '@order/Customer';
import { useFeatureFlag } from '@order/LaunchDarkly';
import type { TranslationKeys } from '@order/Localization';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { formatBirthdayForSubmit, getMonthNames } from '@order/utils';

import { US_PHONE_NUMBER_MAX_LENGTH } from './constants';
import type {
  CustomerProfileFieldsQuery,
  useUpdateCustomerProfileMutation,
} from './GraphQL/Profile.generated';
import type { ProfileFormData } from './useProfileForm';
import { useProfileForm } from './useProfileForm';

// ────────────────────────────────────────────────────────────────────────────────

export const ProfileForm = (props: ProfileFormProps) => {
  const { customer, submitForm, onFormReset, isLoading, isSubmitting } = props;
  const { t } = useLocalizationContext();
  const { signOut } = useCustomer();
  const { match } = useResponsive();

  const isZipCodeFieldEnabled = useFeatureFlag(
    'CELS-3186-enable-zip-code-field',
    { listenForChanges: true },
  );

  // ────────────────────────────────────────────────────────────────────

  const {
    isShowingRequestAccountDeletionModal,
    showRequestAccountDeletionModal,
    hideRequestAccountDeletionModal,
  } = useRequestAccountDeletionModal();

  // ────────────────────────────────────────────────────────────────────

  const { form, isSubmitAllowed, resetForm } = useProfileForm({
    customer,
    hasZipCode: isZipCodeFieldEnabled,
  });

  const { control, handleSubmit, formState, resetField } = form;
  const { errors, isSubmitSuccessful, dirtyFields } = formState;
  const monthNames = getMonthNames().map((key) => t(key as TranslationKeys));
  const { track } = useTelemetry();

  // ─── HELPERS ────────────────────────────────────────────────────────

  const onReset = useCallback(() => {
    resetForm();
    onFormReset?.();
  }, [onFormReset, resetForm]);

  const onSubmit = useCallback(
    async (formData: ProfileFormData) => {
      const {
        firstName,
        lastName,
        phoneNumber,
        confirmPassword,
        birthday,
        zipCode,
      } = formData;
      const changedFields = Object.keys(dirtyFields).filter(
        (key) => key !== 'confirmPassword',
      );

      track('account.profile_saved', { changedFields });

      await submitForm({
        input: {
          firstName,
          lastName,
          contactNumber: phoneNumber,
          birthday: formatBirthdayForSubmit(birthday),
          zipCode,

          // ─── Conditional fields ──────────────────────

          ...(confirmPassword ? { password: confirmPassword } : {}),
        },
      });
    },
    [dirtyFields, submitForm, track],
  );

  // ─── FORM EFFECTS ────────────────────────────────────────────────────────

  useEffect(() => {
    resetForm();
  }, [customer, resetForm]);

  useEffect(() => {
    if (isSubmitSuccessful && !isSubmitting) {
      resetField('confirmPassword');
      resetField('password');
    }
  }, [isSubmitSuccessful, isSubmitting, resetField]);

  // ────────────────────────────────────────────────────────────────────────────────

  if (isLoading) return <AccountScreenLoading />;

  return (
    <>
      <Form.Container
        contentStyle={match([
          styles.containerContentXS,
          styles.containerContentSM,
        ])}
        removeSpacing={true}
      >
        {/* ========= = first name ========== */}
        <Form.Group>
          <Form.TextField
            name="firstName"
            control={control}
            rules={{ required: true }}
            testID="account.profile.firstName"
            label={t('form.first-name')}
            hideSubmitButton
            autoComplete="name"
            notice={errors.firstName?.message}
            disabled={isSubmitting}
          />
        </Form.Group>

        {/* ========= = last name ========== */}
        <Form.Group>
          <Form.TextField
            name="lastName"
            control={control}
            rules={{ required: true }}
            testID="account.profile.lastName"
            label={t('form.last-name')}
            hideSubmitButton
            autoComplete="name"
            notice={errors.lastName?.message}
            disabled={isSubmitting}
          />
        </Form.Group>

        {/* ========= = email ========== */}
        <Form.Group>
          <Form.TextField
            name="email"
            control={control}
            rules={{ required: true }}
            keyboardType="email-address"
            testID="account.profile.email"
            label={t('form.email')}
            hideSubmitButton
            autoComplete="email"
            notice={errors.email?.message}
            disabled={true}
            accessibilityState={{ disabled: true }}
          />
        </Form.Group>

        {/* ========= = phone number ========== */}
        <Form.Group>
          <Form.TextFieldPhoneNumber
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            testID="account.profile.phoneNumber"
            label={t('form.phone-number')}
            maxLength={US_PHONE_NUMBER_MAX_LENGTH}
            hideSubmitButton
            notice={errors.phoneNumber?.message}
            disabled={isSubmitting}
          />
        </Form.Group>

        {/* ========= = zip code ========== */}
        {isZipCodeFieldEnabled ? (
          <Form.Group>
            <Form.TextFieldZipCode
              name="zipCode"
              control={control}
              rules={{ required: true }}
              testID="account.profile.zipCode"
              label={t('form.zip-code')}
              maxLength={8}
              hideSubmitButton
              notice={errors.zipCode?.message}
              disabled={isSubmitting}
            />
          </Form.Group>
        ) : null}

        {/* ========= = birthday ========== */}
        <Form.Group>
          <Form.BirthdayPicker
            name="birthday"
            control={control}
            label={t('form.birthday')}
            accessibilityLabel={t('form.birthday')}
            monthLabel={t('general.month')}
            dayLabel={t('general.day')}
            helperText={t('join.birthday-helper-text')}
            monthNames={monthNames}
          />
        </Form.Group>

        {/* ========= = ========== ========== */}
        <HStack itemsPerRow={2} style={styles.buttonContainer}>
          <Form.SubmitButton
            palette="secondary"
            onPress={onReset}
            disabled={isSubmitting}
            testID="account.profile.cancel-button"
            style={styles.button}
          >
            {t('account.profile.cancel')}
          </Form.SubmitButton>

          <Form.SubmitButton
            testID="account.profile.save-button"
            disabled={!isSubmitAllowed}
            isLoading={isSubmitting}
            onPress={handleSubmit(onSubmit)}
            accessibilityState={{ disabled: !isSubmitAllowed }}
            style={styles.button}
          >
            {t('account.profile.save')}
          </Form.SubmitButton>
        </HStack>

        <RequestAccountDeletionTrigger
          isDisabled={isSubmitting}
          onRequestAccountDeletion={showRequestAccountDeletionModal}
        />
      </Form.Container>

      <RequestAccountDeletionModal
        visible={isShowingRequestAccountDeletionModal}
        onSuccess={signOut}
        onClose={hideRequestAccountDeletionModal}
      />
    </>
  );
};

// ────────────────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerContentXS: {
    paddingTop: theme.spacing['2'],
    paddingBottom: 0,
  },
  containerContentSM: {
    paddingVertical: theme.spacing['8'],
    paddingHorizontal: theme.spacing['8'],
    borderColor: theme.colors.GRAY,
    borderRadius: theme.radius.large,
    borderWidth: 1,
  },
  buttonContainer: { paddingTop: theme.spacing['4'] },
  button: { paddingTop: 0 },
});

// ────────────────────────────────────────────────────────────────────────────────

type ProfileFormProps = Readonly<{
  customer: CustomerProfileFieldsQuery['currentCustomer'];
  isLoading: boolean;
  isSubmitting: boolean;
  onFormReset?: () => void;
  submitForm: UpdateCallback;
}>;

type UpdateCallback = ReturnType<typeof useUpdateCustomerProfileMutation>[1];

// ────────────────────────────────────────────────────────────────────────────────

const AccountScreenLoading = () => (
  <Form.Container>
    <LoadingPlaceholder
      rows={8}
      rowHeight={[100, 60, 70, 70, 70, 70, 70, 80]}
      palette="cream"
    />
  </Form.Container>
);
