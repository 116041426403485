/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoyaltyPointsRewardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type LoyaltyPointsRewardsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename?: 'LoyaltyProfile';
    readonly customerId: string;
    readonly pointsRewards: ReadonlyArray<{
      readonly __typename: 'PointsReward';
      readonly id: string;
      readonly points: number;
      readonly redeemable: boolean;
      readonly assetUrl: string | null;
      readonly name: string;
    }> | null;
  } | null;
};

export const LoyaltyPointsRewardsDocument = gql`
  query LoyaltyPointsRewards {
    loyaltyProfile {
      customerId
      pointsRewards {
        id
        __typename
        points
        name: displayName
        redeemable
        assetUrl
      }
    }
  }
`;

export function useLoyaltyPointsRewardsQuery(
  options?: Omit<
    Urql.UseQueryArgs<LoyaltyPointsRewardsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LoyaltyPointsRewardsQuery,
    LoyaltyPointsRewardsQueryVariables
  >({ query: LoyaltyPointsRewardsDocument, ...options });
}
