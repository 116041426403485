export const form = {
  // ─── FIELDS ──────────────────────────────────────────────────────────

  'form.first-name': 'First name',
  'form.last-name': 'Last name',
  'form.email': 'Email',
  'form.phone-number': 'Phone number',
  'form.zip-code': 'Zip code',
  'form.password': 'Password',
  'form.confirm-password': 'Confirm Password',
  'form.birthday': 'Birthday',
  'form.enter-code': 'Enter code',

  // ─── VALIDATION MESSAGES ────────────────────────────────────────────

  'form.validation-required': 'Field is required',
  'form.first-name-required': 'First name is required',
  'form.last-name-required': 'Last name is required',
  'form.max-name-length':
    '{fieldName} name should be no more than 50 characters',
  'form.invalid-characters': '{fieldName} name has invalid characters',
  'form.email-required': 'Email is required',
  'form.phone-number-required': 'Phone number is required',
  'form.zip-code-required': 'Zip code is required',
  'form.password-required': 'Password is required',
  'form.birthday-required': 'Birthday is required',
  'form.min-password-length': 'Password should be at least 8 characters',
  'form.confirm-password-match': 'Passwords must match',
  'form.validation-phone-number':
    'Please enter a valid phone number (10 digits)',
  'form.validation-checkbox': 'Field must be checked',
  'form.validation-email': 'Must be a valid email',
  'form.validation-length': 'Must be exactly {characters} characters',
  'form.validation-zip-code': 'Zip code is invalid',
};
