import { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';

import {
  getAppNavigationContainerRef,
  useActiveRouteName,
  useNavigateBack,
} from '../../navigation';

// Closes the bag if it's open.
export const useCloseBag = () => {
  const isBagOpen = useIsBagOpen();
  const { handleCloseBag } = useBagNavigation();

  return useCallback(() => {
    if (isBagOpen) handleCloseBag();
  }, [handleCloseBag, isBagOpen]);
};

// Returns whether the bag is opened by using the active route name.
export const useIsBagOpen = () => {
  return useActiveRouteName() === 'Bag';
};

// Returns whether the bag is opened by using the nav ref (works outside of navigation).
// It can either be { name: 'Modal', params: { screen: 'Bag'  } } or { name: 'Bag' }.
export const useIsBagOpenFromReference = () => {
  const [isBagOpen, setIsBagOpen] = useState(false);

  getAppNavigationContainerRef()?.current?.addListener('state', () => {
    const route = getAppNavigationContainerRef()?.current?.getCurrentRoute();
    const params = route?.params as Readonly<{ screen: string }>;

    setIsBagOpen(route?.name === 'Bag' || params?.screen === 'Bag');
  });

  return isBagOpen;
};

// ─── Helpers ────────────────────────────────────────────────────────────────

const useBagNavigation = () => {
  const navigation = useNavigation();

  // This just opens the bag screen on the modal stack.
  const handleOpenBag = useCallback(() => {
    navigation.navigate('Modal', { screen: 'Bag' });
  }, [navigation]);

  // This will go back on the parent (dismiss the modal stack),
  // It will go back in the modal stack if needed.
  const handleCloseBag = useNavigateBack();

  return {
    handleOpenBag,
    handleCloseBag,
  };
};
