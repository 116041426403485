import { useCallback } from 'react';
import { useClient } from 'urql';
import { TierName } from '@sg/graphql-schema';

import {
  BagAvailablePointsDocument,
  type BagAvailablePointsQuery,
} from '../../GraphQL/BagAvailablePoints.generated';

// ────────────────────────────────────────────────────────────────────────────

/**
 * A hook for fetching available points and opted out state.
 */
export const useBagLoyaltyData = (isLoyaltyV2Enabled: boolean) => {
  const client = useClient();

  // ─── Queries ──────────────────────────────────────────────────────────────

  const fetchLoyaltyData = useCallback(async () => {
    if (!isLoyaltyV2Enabled) {
      return { availablePoints: 0, isOptedOutFromLoyalty: false };
    }

    const queryRewards = client.query<BagAvailablePointsQuery>;

    const response = await queryRewards(
      BagAvailablePointsDocument,
      {},
      { requestPolicy: 'network-only' },
    ).toPromise();

    const loyaltyProfile = response.data?.loyaltyProfile;

    const customerTier = loyaltyProfile?.tier?.nameV2;
    const isOptedOut = customerTier === TierName.RewardsOptedOut;
    const availablePoints = loyaltyProfile?.points?.available ?? 0;

    return { availablePoints, isOptedOutFromLoyalty: isOptedOut };
  }, [isLoyaltyV2Enabled, client.query]);

  // ──────────────────────────────────────────────────────────────────────────

  return { fetchLoyaltyData };
};
