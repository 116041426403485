import React, { useMemo } from 'react';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { capitalizeFirstLetter, ProductCardV2 } from '@sg/garnish';

import { useDietaryRestrictions } from '@order/components';
import type { PartialOrderConflict } from '@order/graphql';
import { ConflictType } from '@order/graphql';

// ─────────────────────────────────────────────────────────────────────────────

export const ConflictCard = ({ conflict }: ConflictCardProps) => {
  const { formatMessage } = useIntl();
  const getRestrictionNotice = useDietaryRestrictions();

  // ─── Data ────────────────────────────────────────────────────────────

  const {
    asset,
    label,
    outOfStock,
    name: productName,
    description,
    dietaryProperties,
    calories,
    cost,
  } = conflict.product;

  const restrictionNotice = getRestrictionNotice(dietaryProperties);
  const costInDollars = cost === undefined ? null : cost / 100;

  // ─── Derived Data ────────────────────────────────────────────────────

  const unavailableNotice = useMemo(() => {
    const { ProductUnavailable, DeliveryIneligible, IngredientUnavailable } =
      ConflictType;

    const { type: conflictType, unavailableIngredients } = conflict;

    // ─── Unavailable Product ─────────────────────

    const isUnavailableProductConflict =
      conflictType === DeliveryIneligible ||
      conflictType === ProductUnavailable;

    if (isUnavailableProductConflict) {
      return formatMessage(messages.productUnavailable);
    }

    // ─── Unavailable Ingredients ─────────────────

    const hasUnavailableIngredients = conflictType === IngredientUnavailable;

    if (hasUnavailableIngredients && unavailableIngredients.length > 0) {
      const ingredients = capitalizeFirstLetter(
        unavailableIngredients
          .map(({ name }) => name)
          .join(', ')
          .toLowerCase(),
      );

      return formatMessage(messages.ingredientsUnavailable, { ingredients });
    }

    if (isUnavailableProductConflict) {
      return formatMessage(messages.someIngredientsUnavailable);
    }
  }, [conflict, formatMessage]);

  // ─── Flags ───────────────────────────────────────────────────────────

  const isProductAvailable = !outOfStock;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ProductCardV2.Container canHover={false}>
      {asset?.url ? (
        <ProductCardV2.Image
          source={asset.url}
          alt={productName ?? formatMessage(messages.productImageAlt)}
        />
      ) : null}

      <ProductCardV2.LabelContainer>
        {isProductAvailable && label?.name ? (
          <ProductCardV2.Label>{label.name}</ProductCardV2.Label>
        ) : null}

        {unavailableNotice ? (
          <ProductCardV2.UnavailableLabel>
            {unavailableNotice}
          </ProductCardV2.UnavailableLabel>
        ) : null}

        {productName ? (
          <ProductCardV2.Heading>{productName}</ProductCardV2.Heading>
        ) : null}

        {description ? (
          <ProductCardV2.Description>{description}</ProductCardV2.Description>
        ) : null}

        <ProductCardV2.Footer>
          <ProductCardV2.ProductDetailsContainer>
            {costInDollars ? (
              <ProductCardV2.ProductDetail palette="dark-kale">
                <FormattedNumber
                  value={costInDollars}
                  currency="USD"
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                />
              </ProductCardV2.ProductDetail>
            ) : null}

            {calories === undefined ? null : (
              <ProductCardV2.ProductDetail>
                <FormattedMessage
                  {...messages.caloriesText}
                  values={{ calories }}
                />
              </ProductCardV2.ProductDetail>
            )}
          </ProductCardV2.ProductDetailsContainer>

          {restrictionNotice ? (
            <ProductCardV2.AllergenNotice>
              {restrictionNotice}
            </ProductCardV2.AllergenNotice>
          ) : null}
        </ProductCardV2.Footer>
      </ProductCardV2.LabelContainer>
    </ProductCardV2.Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  productImageAlt: {
    defaultMessage: 'Product image',
    description:
      'Reorder | Conflict Review | Product card | Product image fallback alt',
  },
  caloriesText: {
    defaultMessage: '{calories} cals',
    description: 'Reorder | Conflict Review | Product card | Calories',
  },
  productUnavailable: {
    defaultMessage: 'Product unavailable',
    description:
      'Reorder | Conflict Review | Product card | Product unavailable',
  },
  someIngredientsUnavailable: {
    defaultMessage: 'Some ingredients are unavailable',
    description:
      'Reorder | Conflict Review | Product card | Some ingredients are unavailable',
  },
  ingredientsUnavailable: {
    defaultMessage: '{ingredients} unavailable',
    description:
      'Reorder | Conflict Review | Product card | Ingredients unavailable',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ConflictCardProps = Readonly<{ conflict: PartialOrderConflict }>;
