// https://sweetgreen.atlassian.net/wiki/spaces/CA/pages/4332126546/Order+Status+Specs+Replatform

export const orderStatusInfo = {
  'order-status-info.order-number': 'Order #{orderId}',
  'order-status-info.contact-us': 'Contact us',
  'order-status-info.title.completed': 'Order completed',
  'order-status-info.title.past': 'Order completed',
  'order-status-info.title.failed': 'Order canceled',
  'order-status-info.subtitle.completed':
    'Your order was completed {day} at {time}',
  'order-status-info.subtitle.past':
    'Your order was completed {day}, {date} at {time}',
  'order-status-info.subtitle.failed':
    "We're so sorry we had to cancel your order, there was a snag with it. We are processing a full refund, which will post in your account within 3-5 business days.",
  'order-status-info.reorder': 'Reorder',
  'order-status-info.reorder-total': 'Reorder | {total}',
  'order-status-info.rate-order': 'Rate order',
  'order-status-info.contact-us.heading': 'Need help with your order?',
  'order-status-info.contact-us.subheading': 'We want to hear from you.',
  'order-status-info.contact-us.cta': 'Contact us',
  'order-status-info.contact-us.cancel-order-cta': 'Cancel order',
  'order-status-info.contact-us-answer': 'at {formattedPhoneNumber}',
  'order-status-info.contact-us-answer-mobile-2':
    'Please text us at {formattedPhoneNumber}',
  'order-status-info.share-product': 'Share',
  'order-status-info.share-product-tooltip': 'Copied link',
  'order-status-info.courier': '{courierName} is on the way with your order.',
  'order-status-info.courier-cta': 'Contact courier',
  'order-status-info.sms-not-supported':
    'We are unable to contact this store at this time.',
  'order-status-info.cancellation-option': 'Need to cancel your order? ',
  'order-status-info.cancellation-highlight': 'Contact us',
  'order-status-info.in-app-cancellation-highlight': 'Cancel order',

  // Pickup Title
  'order-status-info.title.pickup.received': 'Order received',
  'order-status-info.title.pickup.preparing': 'Preparing order',
  'order-status-info.title.pickup.completed': 'Order completed',
  'order-status-info.title.pickup.past': 'Order completed',
  'order-status-info.title.pickup.preorder-failed': 'Order failed',
  'order-status-info.title.pickup.unknown': 'Pickup order',

  // Pickup Subtitle
  'order-status-info.subtitle.pickup.received':
    'Scheduled pickup time at {name} is {day} at {time}',
  'order-status-info.subtitle.pickup.preparing':
    'Scheduled pickup time at {name} is {day} at {time}',
  'order-status-info.subtitle.pickup.completed':
    'Your order was completed {day} at {time}',
  'order-status-info.subtitle.pickup.past':
    'Your order was completed {day}, {date} at {time}',
  'order-status-info.subtitle.pickup.preorder-failed':
    'Something went wrong with your pickup order',

  // Pickup Progress Bar
  'order-status-info.progress.pickup.received': 'Received',
  'order-status-info.progress.pickup.preparing': 'Preparing',
  'order-status-info.progress.pickup.completed': 'Complete',

  // Outpost Title
  'order-status-info.title.outpost.received': 'Order received',
  'order-status-info.title.outpost.delivering': 'Order out for delivery',
  'order-status-info.title.outpost.completed': 'Order completed',
  'order-status-info.title.outpost.preorder-failed': 'Order failed',
  'order-status-info.title.outpost.unknown': 'Outpost order',

  // Outpost Subtitle
  'order-status-info.subtitle.outpost.received':
    'Estimated delivery {day}, {date} between {startTime}-{endTime}',
  'order-status-info.subtitle.outpost.delivering':
    'Estimated delivery {day}, {date} between {startTime}-{endTime}',
  'order-status-info.subtitle.outpost.completed':
    'Your order was completed {day} at {time}',
  'order-status-info.subtitle.outpost.past':
    'Your order was completed {day}, {date} at {time}',
  'order-status-info.subtitle.outpost.late':
    'Your order was completed at {time} on {day}, {date}, {year}',
  'order-status-info.subtitle.outpost.preorder-failed':
    'Something went wrong with your outpost order',

  // Outpost ProgressBar
  'order-status-info.progress.outpost.received': 'Received',
  'order-status-info.progress.outpost.delivering': 'Delivering',
  'order-status-info.progress.outpost.completed': 'Complete',

  // Delivery Title
  'order-status-info.title.delivery.received': 'Order received',
  'order-status-info.title.delivery.preparing': 'Preparing order',
  'order-status-info.title.delivery.courier': 'Order ready for courier',
  'order-status-info.title.delivery.delivering': 'Order out for delivery',
  'order-status-info.title.delivery.completed': 'Order completed',
  'order-status-info.title.delivery.canceled': 'Order canceled',
  'order-status-info.title.delivery.failed': 'Delivery failed',
  'order-status-info.title.delivery.preorder-failed': 'Order failed',
  'order-status-info.title.delivery.unknown': 'Delivery order',

  // Delivery Subtitle
  'order-status-info.subtitle.delivery.received':
    'Scheduled delivery {startTime}-{endTime}',
  'order-status-info.subtitle.delivery.preparing':
    'Estimated delivery {startTime}-{endTime}',
  'order-status-info.subtitle.delivery.courier':
    'Estimated delivery {startTime}-{endTime}',
  'order-status-info.subtitle.delivery.route':
    'Estimated delivery {startTime}-{endTime}',
  'order-status-info.subtitle.delivery.completed':
    'Your order was completed {day} at {time}',
  'order-status-info.subtitle.delivery.past':
    'Your order was completed {day}, {date} at {time}',
  'order-status-info.subtitle.delivery.canceled':
    "We're so sorry we had to cancel your order, there was a snag with it. We are processing a full refund, which will post in your account within 3-5 business days.",
  'order-status-info.subtitle.delivery.failed':
    'Something went wrong with your delivery order',
  'order-status-info.subtitle.delivery.preorder-failed':
    'Something went wrong with your delivery order',

  // Delivery ProgressBar
  'order-status-info.progress.delivery.received': 'Received',
  'order-status-info.progress.delivery.preparing': 'Preparing',
  'order-status-info.progress.delivery.courier': 'Ready for courier',
  'order-status-info.progress.delivery.delivering': 'Delivering',

  // No Tracking Data
  'order-status-info.title.unavailable': `{orderType, select,
    pickup {Pickup order}
    outpost {Outpost order}
    delivery {Delivery order}
    other {}
  }`,
  'order-status-info.subtitle.unavailable': `{orderType, select,
    pickup {Your order will be ready {day}, {date} at {time} at {name}, {address}. Unfortunately, we're unable to provide real-time order status tracking at this time.}
    outpost {Your order will be ready {day}, {date}, between {startTime}-{endTime} at the {name}. Unfortunately, we're unable to provide real-time order status tracking at this time.}
    delivery {Your order will be arriving {day}, {date}, {time}. Unfortunately, we're unable to provide real-time order status tracking at this time. Please contact us if your order does not show up around the scheduled delivery time.}
    other {}
  }`,
};
