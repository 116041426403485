import React, { useCallback, useRef } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { usePressableState } from '../../../hooks';
import { BodyText } from '../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const ButtonToggle = (props: ButtonToggleProps) => {
  const { children, isActive = true, isDisabled, onChange } = props;

  const ref = useRef(null);
  const { isInteracting } = usePressableState(ref);

  // ─── Styles ──────────────────────────────────────────────────────────

  const interactiveStyle = isInteracting && !isDisabled ? styles.hover : null;

  const pressableStyle = getPressableStyle(isActive, isDisabled, isInteracting);
  const textColor = getTextColor(isActive);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnChange = useCallback(() => {
    onChange(!isActive);
  }, [isActive, onChange]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      ref={ref}
      accessibilityRole="button"
      accessibilityState={{ selected: isActive }}
      disabled={isDisabled}
      onPress={isDisabled ? undefined : handleOnChange}
      style={[styles.pressable, interactiveStyle, pressableStyle]}
    >
      <BodyText sizeMatch={['18']} style={textColor}>
        {children}
      </BodyText>
    </Pressable>
  );
};

// ─── Helpers ────────────────────────────────────────────────────────────────

function getPressableStyle(
  isActive: boolean,
  isDisabled: boolean,
  onHover: boolean,
) {
  if (isDisabled) return styles.disabled;
  if (isActive) return styles.active;
  if (onHover) return styles.hover;

  return styles.inactive;
}

function getTextColor(isActive: boolean) {
  return isActive ? styles.activeText : styles.inactiveText;
}

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    borderWidth: 1,
    borderColor: theme.colors.SPINACH,
    borderRadius: theme.radius.xxlarge,
    paddingHorizontal: theme.spacing['8'],
    height: theme.spacing['12'],
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: theme.colors.SPINACH,
  },
  inactive: {
    backgroundColor: theme.colors.OPACITY.TRANSPARENT,
  },
  activeText: {
    color: theme.colors.WHITE,
  },
  inactiveText: {
    color: theme.colors.KALE,
  },
  disabled: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
  hover: {
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type ButtonToggleProps = {
  isActive: boolean;
  isDisabled: boolean;
  onChange: (isChecked: boolean) => void;
  children: React.ReactNode;
};
