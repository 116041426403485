import {
  type RailItemsMeasurementsSV,
  type RailMeasurementsSV,
  type ScrollOffsetSV,
} from '../../../HorizontalScrollRail.types';

// ─────────────────────────────────────────────────────────────────────────────

export function findNextItemIndex(params: Params) {
  'worklet';

  const { railItemsMeasurementsSV, railMeasurementsSV, scrollOffsetSV } =
    params;

  return railItemsMeasurementsSV.value.findIndex((measurement) => {
    const { x = 0, width = 0 } = measurement ?? {};

    const railWidth = railMeasurementsSV.value?.width ?? 0;
    const totalWidth = scrollOffsetSV.value + railWidth;
    const itemEndPosition = x + width;

    return itemEndPosition > totalWidth;
  });
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Params = {
  railItemsMeasurementsSV: RailItemsMeasurementsSV;
  railMeasurementsSV: RailMeasurementsSV;
  scrollOffsetSV: ScrollOffsetSV;
};
