import React, { useRef } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { A } from '@expo/html-elements';
import {
  BodyText,
  FadeView,
  Image,
  LoadingDots,
  theme,
  usePressableState,
} from '@sg/garnish';

import {
  useContactUsHandler,
  type useOrganizationLinksFooterContentfulData,
} from '@order/hooks';
import { getLinkWebAttributes } from '@order/utils';

export const OrganizationLink = (props: OrganizationLinkProps) => {
  const { link } = props;

  const linkRef = useRef(null);
  const linkPressableState = usePressableState(linkRef);
  const linkHoverStyle = linkPressableState.isHovered
    ? styles.linkHover
    : undefined;

  return (
    <View style={styles.linkWrapper}>
      <A
        ref={linkRef}
        href={link.url}
        accessibilityRole="link"
        {...getLinkWebAttributes()}
      >
        <BodyText style={linkHoverStyle} sizeMatch={['16']}>
          {link.label}
        </BodyText>
      </A>

      {link.icon ? (
        <Image
          style={styles.linkIcon}
          aria-label={link.label}
          source={{ uri: link.icon }}
          contentFit="contain"
        />
      ) : null}
    </View>
  );
};

const OrganizationLinkKustomer = (props: OrganizationLinkProps) => {
  const { link } = props;

  const contactUsRef = useRef(null);
  const contactUsPressableState = usePressableState(contactUsRef);

  const { handlingContactUs, hasDynamicChat, handleContactUs } =
    useContactUsHandler('org-links');

  if (hasDynamicChat) {
    return (
      <Pressable
        accessibilityRole="button"
        ref={contactUsRef}
        style={styles.label}
        onPress={handleContactUs}
      >
        <BodyText underline={contactUsPressableState.isHovered}>
          {link.label}
        </BodyText>

        <FadeView show={handlingContactUs}>
          <LoadingDots />
        </FadeView>
      </Pressable>
    );
  }

  return <OrganizationLink link={link} />;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OrganizationLinkProps = Readonly<{
  link: ReturnType<
    typeof useOrganizationLinksFooterContentfulData
  >['sections'][0]['links'][0];
}>;

// ─── Styles ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  linkWrapper: {
    minHeight: theme.spacing['6'],
    gap: theme.spacing['2'],
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    flexDirection: 'row',
    marginBottom: theme.spacing['1'],
    gap: theme.spacing['2'],
  },
  linkHover: {
    textDecorationLine: 'underline',
  },
  linkIcon: { width: 32, height: 24 },
});

// ─── Compound Components ─────────────────────────────────────────────────────
// eslint-disable-next-line functional/immutable-data
OrganizationLink.Kustomer = OrganizationLinkKustomer;
