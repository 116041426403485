/* eslint-disable @typescript-eslint/consistent-type-assertions */

import { logger as LOG } from '@garnish/logger';
import { createModel } from 'xstate/lib/model';

import { DietaryPropertyKind } from '@order/graphql';
import { mergeKnownProperties } from '@order/utils';

// ─── Model ───────────────────────────────────────────────────────────────────

const initialDietaryRestrictions: DietaryRestrictionsTypes = {
  [DietaryPropertyKind.Dairy]: false,
  [DietaryPropertyKind.Meat]: false,
  [DietaryPropertyKind.Nuts]: false,
  [DietaryPropertyKind.Gluten]: false,
  [DietaryPropertyKind.Fish]: false,
  [DietaryPropertyKind.Soy]: false,
};

const modelInitialContext: DietaryRestrictionInitialContext = {
  restrictions: initialDietaryRestrictions,
  lastKnownRestrictions: initialDietaryRestrictions,
};

const modelCreators = {
  events: {
    TOGGLE_RESTRICTION: (restriction: DietaryPropertyKind) => ({
      restriction,
    }),
    SET_RESTRICTIONS: (restrictions: Partial<DietaryRestrictionsTypes>) => ({
      restrictions,
    }),
    LOAD_INITIAL_RESTRICTIONS: (
      restrictions: Partial<DietaryRestrictionsTypes>,
    ) => ({
      restrictions,
    }),
    REVERT_RESTRICTIONS: () => ({}),
    CLEAR_RESTRICTIONS: () => ({}),
    CONFIRM_RESTRICTIONS: () => ({}),
    UPDATE_REMOTE_RESTRICTIONS: () => ({}),
  },
  actions: {
    onConfirmRestrictions: () => ({}),
  },
  services: {
    async updateRemoteRestrictions() {
      logger.debug('Please provide `updateRemoteRestrictions` service.');

      return { success: false };
    },
  },
};

export const dietaryRestrictionsModel = createModel(
  modelInitialContext,
  modelCreators,
);

// ─── Actions ─────────────────────────────────────────────────────────────────

const toggleRestriction = dietaryRestrictionsModel.assign((ctx, event) => {
  const { restriction } = event;

  const noRestrictionFound = ctx.restrictions[restriction] === undefined;

  return {
    restrictions: noRestrictionFound
      ? ctx.restrictions
      : { ...ctx.restrictions, [restriction]: !ctx.restrictions[restriction] },
  };
}, 'TOGGLE_RESTRICTION');

const setRestriction = dietaryRestrictionsModel.assign((ctx, event) => {
  const { restrictions = {} } = event;
  const updatedRestrictions = mergeKnownProperties<
    DietaryRestrictionsTypes,
    Partial<DietaryRestrictionsTypes>
  >(ctx.restrictions, restrictions);

  return {
    restrictions: updatedRestrictions,
    lastKnownRestrictions: updatedRestrictions,
  };
}, 'SET_RESTRICTIONS');

const loadInitialRestrictions = dietaryRestrictionsModel.assign(
  (ctx, event) => {
    const { restrictions = {} } = event;
    const updatedRestrictions = mergeKnownProperties<
      DietaryRestrictionsTypes,
      Partial<DietaryRestrictionsTypes>
    >(ctx.restrictions, restrictions);

    return {
      restrictions: updatedRestrictions,
      lastKnownRestrictions: updatedRestrictions,
    };
  },
  'LOAD_INITIAL_RESTRICTIONS',
);

const revertRestrictions = dietaryRestrictionsModel.assign((ctx) => {
  return { restrictions: ctx.lastKnownRestrictions };
}, 'REVERT_RESTRICTIONS');

const clearRestrictions = dietaryRestrictionsModel.assign(
  () => ({ ...modelInitialContext }),
  'CLEAR_RESTRICTIONS',
);

const confirmRestrictions = dietaryRestrictionsModel.assign((ctx) => {
  return { lastKnownRestrictions: ctx.restrictions };
}, 'CONFIRM_RESTRICTIONS');

// ─── Machine ─────────────────────────────────────────────────────────────────

export const dietaryRestrictionsMachine =
  dietaryRestrictionsModel.createMachine({
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    id: 'sgDietaryPreferencesMachine',
    context: dietaryRestrictionsModel.initialContext,
    schema: {
      services: {} as MachineServices,
    },
    on: {
      TOGGLE_RESTRICTION: {
        actions: toggleRestriction,
      },
      SET_RESTRICTIONS: {
        actions: setRestriction,
      },
      REVERT_RESTRICTIONS: {
        actions: revertRestrictions,
      },
      CLEAR_RESTRICTIONS: {
        actions: clearRestrictions,
      },
      CONFIRM_RESTRICTIONS: {
        actions: [confirmRestrictions, 'onConfirmRestrictions'],
      },
    },
    initial: 'remoteRestrictions',
    states: {
      remoteRestrictions: {
        initial: 'initializing',
        states: {
          initializing: {
            description: 'Waiting for the initial restrictions to be set.',
            on: {
              LOAD_INITIAL_RESTRICTIONS: {
                actions: loadInitialRestrictions,
                target: 'ready',
              },
            },
          },
          ready: {
            description: 'Ready for requests to update remote restrictions.',
            on: {
              UPDATE_REMOTE_RESTRICTIONS: {
                target: 'updating',
              },
            },
          },
          updating: {
            description: 'Currently updating the remote restrictions.',
            invoke: {
              src: 'updateRemoteRestrictions',
              onDone: 'ready',
            },
          },
        },
      },
    },
  });

// ─── Types ───────────────────────────────────────────────────────────────────

export type DietaryRestrictionsTypes = Record<DietaryPropertyKind, boolean>;

type DietaryRestrictionInitialContext = Readonly<{
  restrictions: DietaryRestrictionsTypes;
  lastKnownRestrictions: DietaryRestrictionsTypes;
}>;

type MachineServices = {
  updateRemoteRestrictions: {
    data: Readonly<{
      success: boolean;
    }>;
  };
};

// ─── Logger ──────────────────────────────────────────────────────────────────

LOG.enable('DIETARY RESTRICTIONS STATE MACHINE');
const logger = LOG.extend('DIETARY RESTRICTIONS STATE MACHINE');
