import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { Button, Modal, ModalDialogue, theme } from '@sg/garnish';

import { useGlobalAppState } from '@order/GlobalAppState';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLiveActivity } from '@order/live-activities';
import { useTelemetry } from '@order/Telemetry';

import { useCancelOrder, useCancelOrderV2 } from './hooks';
import { orderCancellationMessages } from './order-cancellation-messages';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Order cancellation modal with built-in mutation handler
 *
 * Should be used together with the `useOrderCancellationModal` hook.
 *
 * @example
 *
 * import { OrderCancellationModal, useOrderCancellationModal } from '@order/components';
 *
 * const {
 *   isOrderCancellationModalVisible,
 *   showOrderCancellationModal,
 *   hideOrderCancellationModal,
 * } = useOrderCancellationModal();
 *
 * return (
 *   <>
 *    <Button onPress={showOrderCancellationModal}>Cancel Order</Button>
 *    <OrderCancellationModal isVisible={isOrderCancellationModalVisible} onRequestClose={hideOrderCancellationModal} ... />
 *   </>
 * )
 */
export const OrderCancellationModal = (props: OrderCancellationModalProps) => {
  const {
    isVisible,
    orderId,
    restaurantId,
    onSuccess,
    onAsyncCancelation,
    onRequestClose,
  } = props;

  const { track } = useTelemetry();
  const { formatMessage } = useIntl();

  const { dynamicFeatureFlags } = useGlobalAppState();
  const { isLiveActivitiesEnabled } = dynamicFeatureFlags;

  const { cancelLiveActivity } = useLiveActivity();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isOrderAsyncCancellationEnabled = useFeatureFlag(
    'CELS-2829-in-app-order-async-cancellation-enabled',
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * Some orders can be canceled asynchronously, which takes approximately
   * ~5 seconds.
   *
   * To handle these cases, we call the special `onAsyncCancelation` function
   * after 10 seconds to ensure that the pending cancelation is completed
   * or failed.
   */
  const handleAsyncCancelation = useCallback(() => {
    onAsyncCancelation?.(orderId);
    onRequestClose?.();

    if (isLiveActivitiesEnabled) {
      cancelLiveActivity?.(orderId);
    }
  }, [
    cancelLiveActivity,
    isLiveActivitiesEnabled,
    onAsyncCancelation,
    onRequestClose,
    orderId,
  ]);

  const handleSuccess = useCallback(() => {
    onSuccess?.(orderId);
    onRequestClose?.();

    if (isLiveActivitiesEnabled) {
      cancelLiveActivity?.(orderId);
    }
  }, [
    cancelLiveActivity,
    isLiveActivitiesEnabled,
    onRequestClose,
    onSuccess,
    orderId,
  ]);

  // ─── Remote Data ─────────────────────────────────────────────────────

  const cancelOrderLegacy = useCancelOrder({
    orderId,
    restaurantId,
    onSuccess: handleSuccess,
    onError: onRequestClose,
  });
  const cancelOrderV2 = useCancelOrderV2({
    orderId,
    restaurantId,
    onSuccess: handleSuccess,
    onAsyncCancelation: handleAsyncCancelation,
    onError: onRequestClose,
  });

  const { isCancellingOrder, cancelOrder } = isOrderAsyncCancellationEnabled
    ? cancelOrderV2
    : cancelOrderLegacy;

  // ─────────────────────────────────────────────────────────────────────

  const confirmOrderCancellation = useCallback(() => {
    track('order_cancel.confirmed');

    void cancelOrder();
  }, [cancelOrder, track]);

  const closeModal = useCallback(() => {
    if (isCancellingOrder) return;

    onRequestClose();
  }, [isCancellingOrder, onRequestClose]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <ModalDialogue
      visible={isVisible}
      size="small"
      headline={formatMessage(orderCancellationMessages.modalHeading)}
      body={formatMessage(orderCancellationMessages.modalBody)}
      accessibilityLabel={formatMessage(
        orderCancellationMessages.modalA11yLabel,
      )}
      onRequestClose={closeModal}
    >
      <Modal.Footer withoutTopBorder style={styles.footer}>
        <View style={styles.ctaContainer}>
          <Button
            size="large"
            palette="secondary"
            onPress={closeModal}
            disabled={isCancellingOrder}
          >
            <FormattedMessage
              {...orderCancellationMessages.modalGoBackCtaLabel}
            />
          </Button>
        </View>

        <View style={styles.ctaContainer}>
          <Button
            size="large"
            palette="caution"
            onPress={confirmOrderCancellation}
            isLoading={isCancellingOrder}
          >
            <FormattedMessage
              {...orderCancellationMessages.modalCancelOrderCtaLabel}
            />
          </Button>
        </View>
      </Modal.Footer>
    </ModalDialogue>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    gap: theme.spacing['4'],
  },
  ctaContainer: {
    flex: 1,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderCancellationModalProps = Readonly<{
  orderId: string;
  restaurantId: string;
  isVisible: boolean;
  onRequestClose: () => void;
  onSuccess?: (orderId: string) => void;

  // NOTE: Some orders (like IK orders) can be canceled only asynchronously.
  onAsyncCancelation?: (orderId: string) => void;
}>;
