/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { DeliveryOrderDetailFragmentDoc } from '../../../hooks/useCart/GraphQL/Cart.generated';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrdersQueryVariables = Types.Exact<{
  input: Types.OrdersInput;
}>;

export type OrdersQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename: 'Order';
          readonly id: string;
          readonly description: string;
          readonly orderType: Types.OrderType;
          readonly total: number | null;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly quantity: number;
            readonly customName: string | null;
            readonly isCustom: boolean;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly categoryName: string;
            };
          }>;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly slug: string;
          };
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const OrdersDocument = gql`
  query orders($input: OrdersInput!) {
    orders(input: $input) {
      __typename
      ... on OrdersResponseSuccess {
        orders {
          id
          __typename
          description
          orderType
          total
          asset {
            url
          }
          lineItems {
            id
            __typename
            quantity
            customName
            isCustom
            product {
              id
              __typename
              name
              isCustom
              categoryName
            }
          }
          restaurant {
            id
            __typename
            name
            city
            state
            address
            zipCode
            isOutpost
            slug
          }
          deliveryOrderDetail {
            ...DeliveryOrderDetail
          }
        }
      }
      ...ValidationErrorFields
    }
  }
  ${DeliveryOrderDetailFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useOrdersQuery(
  options: Omit<Urql.UseQueryArgs<OrdersQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OrdersQuery, OrdersQueryVariables>({
    query: OrdersDocument,
    ...options,
  });
}
