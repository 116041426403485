/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { CartDataFragmentDoc } from '../../../hooks/useCart/GraphQL/Cart.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderOrderMutationVariables = Types.Exact<{
  input: Types.ReorderInput;
}>;

export type ReorderOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly reorderOrder:
    | {
        readonly __typename: 'CanNotReorder';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderConflict';
        readonly isNothingAvailable: boolean;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderSuccess';
        readonly isMixingSupported: boolean;
        readonly cart: {
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type ReorderLineItemMutationVariables = Types.Exact<{
  input: Types.ReorderInput;
}>;

export type ReorderLineItemMutation = {
  readonly __typename?: 'Mutation';
  readonly reorderLineItem:
    | {
        readonly __typename: 'CanNotReorder';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderConflict';
        readonly isNothingAvailable: boolean;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ReorderSuccess';
        readonly isMixingSupported: boolean;
        readonly cart: {
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RestaurantMaxDeliveryQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'RestaurantMaxQuantityExceeded';
        readonly message: string;
        readonly conflicts: ReadonlyArray<{
          readonly __typename?: 'OrderConflict';
          readonly type: Types.ConflictType;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
          };
          readonly unavailableIngredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
          }>;
        }>;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type ReorderSuccessResponseFragment = {
  readonly __typename?: 'ReorderSuccess';
  readonly isMixingSupported: boolean;
  readonly cart: {
    readonly __typename: 'Order';
    readonly id: string;
    readonly orderType: Types.OrderType;
    readonly canTrackOrderStatus: boolean;
    readonly ledger: {
      readonly __typename?: 'Ledger';
      readonly tax: number;
      readonly subtotal: number;
      readonly feesTotal: number;
      readonly discountsTotal: number;
      readonly creditsTotal: number;
      readonly tip: number;
      readonly discounts: ReadonlyArray<{
        readonly __typename: 'LedgerItem';
        readonly id: string | null;
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly credits: ReadonlyArray<{
        readonly __typename: 'LedgerItem';
        readonly id: string | null;
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
      readonly fees: ReadonlyArray<{
        readonly __typename: 'LedgerItem';
        readonly id: string | null;
        readonly name: string;
        readonly amount: number;
        readonly description: string | null;
      }>;
    };
    readonly lineItems: ReadonlyArray<{
      readonly __typename: 'LineItem';
      readonly id: string;
      readonly slug: string;
      readonly quantity: number;
      readonly cost: number;
      readonly customName: string | null;
      readonly perItemCost: number;
      readonly favorited: boolean;
      readonly isCustom: boolean;
      readonly dressingMode: Types.DressingMode;
      readonly addedIngredients: ReadonlyArray<{
        readonly __typename: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly removedIngredients: ReadonlyArray<{
        readonly __typename: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      }>;
      readonly product: {
        readonly __typename: 'Product';
        readonly id: string;
        readonly name: string;
        readonly slug: string;
        readonly isCustom: boolean;
        readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
        readonly ingredients: ReadonlyArray<{
          readonly __typename: 'Ingredient';
          readonly id: string;
          readonly name: string;
          readonly kind: Types.IngredientKind | null;
        }>;
      };
      readonly mixedDressingDetails: ReadonlyArray<{
        readonly __typename?: 'MixedDressingDetails';
        readonly ingredientId: string;
        readonly weight: Types.DressingWeight;
      }>;
    }>;
    readonly restaurant: {
      readonly __typename: 'Restaurant';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly city: string;
      readonly state: string;
      readonly address: string;
      readonly zipCode: string;
      readonly isOutpost: boolean;
      readonly deliveryMinSubtotal: number;
      readonly showDeliveryFeeDisclosure: boolean;
      readonly deliveryFee: number;
      readonly availableDropOffLocations: ReadonlyArray<{
        readonly __typename: 'DropOffLocation';
        readonly id: string;
        readonly name: string;
      }>;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    };
    readonly availableWantedTimes: ReadonlyArray<{
      readonly __typename?: 'AvailableWantedTime';
      readonly time: string;
      readonly deliveryOffset: number;
    }>;
    readonly deliveryOrderDetail: {
      readonly __typename: 'DeliveryOrderDetail';
      readonly id: string;
      readonly tip: number;
      readonly deliveryFee: number;
      readonly vendor: string;
      readonly orderId: string;
      readonly vendorRestaurantId: string;
      readonly estimatedDeliveryTime: string | null;
      readonly address: {
        readonly __typename: 'Address';
        readonly id: string;
        readonly street: string;
        readonly secondaryStreet: string | null;
        readonly city: string;
        readonly state: string;
        readonly country: string;
        readonly zipCode: string;
        readonly deliveryPreference: Types.DeliveryPreferenceType;
        readonly googlePlaceId: string;
        readonly latitude: number;
        readonly longitude: number;
        readonly name: string | null;
        readonly notes: string | null;
      } | null;
    } | null;
  };
};

export type ConflictFragment = {
  readonly __typename?: 'OrderConflict';
  readonly type: Types.ConflictType;
  readonly product: {
    readonly __typename: 'Product';
    readonly id: string;
    readonly name: string;
    readonly calories: number;
    readonly cost: number;
    readonly description: string;
    readonly label: {
      readonly __typename: 'ProductLabel';
      readonly id: string;
      readonly name: string;
    } | null;
    readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
    readonly dietaryProperties: ReadonlyArray<{
      readonly __typename: 'DietaryProperty';
      readonly id: string;
      readonly name: Types.DietaryPropertyKind;
    }>;
  };
  readonly unavailableIngredients: ReadonlyArray<{
    readonly __typename: 'Ingredient';
    readonly id: string;
    readonly name: string;
  }>;
};

export type ReorderConflictResponseFragment = {
  readonly __typename?: 'ReorderConflict';
  readonly isNothingAvailable: boolean;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: Types.ConflictType;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename: 'ProductLabel';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};

export type RestaurantMaxDeliveryQuantityExceededResponseFragment = {
  readonly __typename?: 'RestaurantMaxDeliveryQuantityExceeded';
  readonly message: string;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: Types.ConflictType;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename: 'ProductLabel';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};

export type RestaurantMaxQuantityExceededResponseFragment = {
  readonly __typename?: 'RestaurantMaxQuantityExceeded';
  readonly message: string;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: Types.ConflictType;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename: 'ProductLabel';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};

export type CanNotReorderResponseFragment = {
  readonly __typename?: 'CanNotReorder';
  readonly message: string;
  readonly conflicts: ReadonlyArray<{
    readonly __typename?: 'OrderConflict';
    readonly type: Types.ConflictType;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly name: string;
      readonly calories: number;
      readonly cost: number;
      readonly description: string;
      readonly label: {
        readonly __typename: 'ProductLabel';
        readonly id: string;
        readonly name: string;
      } | null;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly dietaryProperties: ReadonlyArray<{
        readonly __typename: 'DietaryProperty';
        readonly id: string;
        readonly name: Types.DietaryPropertyKind;
      }>;
    };
    readonly unavailableIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};

export type ValidationErrorResponseFragment = {
  readonly __typename?: 'ValidationError';
  readonly message: string;
  readonly status: number;
  readonly fieldErrors: ReadonlyArray<{
    readonly __typename?: 'FieldValidationError';
    readonly field: string;
    readonly message: string;
  }>;
};

export const ReorderSuccessResponseFragmentDoc = gql`
  fragment ReorderSuccessResponse on ReorderSuccess {
    cart {
      ...CartData
    }
    isMixingSupported
  }
  ${CartDataFragmentDoc}
`;
export const ConflictFragmentDoc = gql`
  fragment Conflict on OrderConflict {
    type
    product {
      id
      __typename
      name
      calories
      cost
      label {
        id
        __typename
        name
      }
      description
      asset {
        url
      }
      dietaryProperties {
        id
        __typename
        name
      }
    }
    unavailableIngredients {
      id
      __typename
      name
    }
  }
`;
export const ReorderConflictResponseFragmentDoc = gql`
  fragment ReorderConflictResponse on ReorderConflict {
    isNothingAvailable
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const RestaurantMaxDeliveryQuantityExceededResponseFragmentDoc = gql`
  fragment RestaurantMaxDeliveryQuantityExceededResponse on RestaurantMaxDeliveryQuantityExceeded {
    message
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const RestaurantMaxQuantityExceededResponseFragmentDoc = gql`
  fragment RestaurantMaxQuantityExceededResponse on RestaurantMaxQuantityExceeded {
    message
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const CanNotReorderResponseFragmentDoc = gql`
  fragment CanNotReorderResponse on CanNotReorder {
    message
    conflicts {
      ...Conflict
    }
  }
  ${ConflictFragmentDoc}
`;
export const ValidationErrorResponseFragmentDoc = gql`
  fragment ValidationErrorResponse on ValidationError {
    message
    status
    fieldErrors {
      field
      message
    }
  }
`;
export const ReorderOrderDocument = gql`
  mutation reorderOrder($input: ReorderInput!) {
    reorderOrder(input: $input) {
      __typename
      ... on ReorderSuccess {
        ...ReorderSuccessResponse
      }
      ... on ReorderConflict {
        ...ReorderConflictResponse
      }
      ... on RestaurantMaxDeliveryQuantityExceeded {
        ...RestaurantMaxDeliveryQuantityExceededResponse
      }
      ... on RestaurantMaxQuantityExceeded {
        ...RestaurantMaxQuantityExceededResponse
      }
      ... on CanNotReorder {
        ...CanNotReorderResponse
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
    }
  }
  ${ReorderSuccessResponseFragmentDoc}
  ${ReorderConflictResponseFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededResponseFragmentDoc}
  ${RestaurantMaxQuantityExceededResponseFragmentDoc}
  ${CanNotReorderResponseFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useReorderOrderMutation() {
  return Urql.useMutation<ReorderOrderMutation, ReorderOrderMutationVariables>(
    ReorderOrderDocument,
  );
}
export const ReorderLineItemDocument = gql`
  mutation reorderLineItem($input: ReorderInput!) {
    reorderLineItem(input: $input) {
      __typename
      ... on ReorderSuccess {
        ...ReorderSuccessResponse
      }
      ... on ReorderConflict {
        ...ReorderConflictResponse
      }
      ... on RestaurantMaxDeliveryQuantityExceeded {
        ...RestaurantMaxDeliveryQuantityExceededResponse
      }
      ... on RestaurantMaxQuantityExceeded {
        ...RestaurantMaxQuantityExceededResponse
      }
      ... on CanNotReorder {
        ...CanNotReorderResponse
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
    }
  }
  ${ReorderSuccessResponseFragmentDoc}
  ${ReorderConflictResponseFragmentDoc}
  ${RestaurantMaxDeliveryQuantityExceededResponseFragmentDoc}
  ${RestaurantMaxQuantityExceededResponseFragmentDoc}
  ${CanNotReorderResponseFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useReorderLineItemMutation() {
  return Urql.useMutation<
    ReorderLineItemMutation,
    ReorderLineItemMutationVariables
  >(ReorderLineItemDocument);
}
