import { ContainerLayout } from './OrderStatusContainerLayout';
import { ContainerWrapperLayout } from './OrderStatusContainerWrapperLayout';
import { ContentLayout } from './OrderStatusContentLayout';
import { FooterLayout } from './OrderStatusFooterLayout';
import { InfoLayout } from './OrderStatusInfoLayout';
import { MapLayout } from './OrderStatusMapLayout';
import { SidePanelLayout } from './OrderStatusSidePanelLayout';

// ─── Compound Components ─────────────────────────────────────────────────────

export const Layout = {
  ContainerWrapper: ContainerWrapperLayout,
  Container: ContainerLayout,
  Map: MapLayout,
  SidePanel: SidePanelLayout,
  Info: InfoLayout,
  Content: ContentLayout,
  Footer: FooterLayout,
};
