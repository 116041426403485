import React, { type ComponentProps, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  interpolate,
  type SharedValue,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { FadeView } from '../../FadeView';
import { IconLink } from '../../Icon';
import { HStack } from '../../Stack';
import { HorizontalScrollRailCount } from './HorizontalScrollRailCount';

// ────────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRailNav = (props: HorizontalScrollNavProps) => {
  const {
    count,
    showNavControls = false,
    prevBtnA11yLabel = 'Previous item',
    nextBtnA11yLabel = 'Next item',
    headerPalette = 'dark',
    prevItemIndexSV,
    nextItemIndexSV,
    onPrevBtnPress,
    onNextBtnPress,
  } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const navContainerStyles = useAnimatedStyle(() => {
    const allItemsVisible =
      prevItemIndexSV.value === -1 && nextItemIndexSV.value === -1;

    return {
      opacity: allItemsVisible ? 0 : 1,
      pointerEvents: allItemsVisible ? 'none' : 'auto',
    };
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View style={styles.container}>
      <HorizontalScrollRailCount count={count} />

      <FadeView show={showNavControls}>
        {showNavControls ? (
          <Animated.View style={navContainerStyles}>
            <HStack gap={theme.spacing['4']}>
              <HorizontalScrollRailNavBtn
                name="IconArrowLeft"
                itemIndexSV={prevItemIndexSV}
                onNavButtonPress={onPrevBtnPress}
                palette={headerPalette}
                accessibilityLabel={prevBtnA11yLabel}
              />

              <HorizontalScrollRailNavBtn
                itemIndexSV={nextItemIndexSV}
                name="IconArrowRight"
                onNavButtonPress={onNextBtnPress}
                palette={headerPalette}
                accessibilityLabel={nextBtnA11yLabel}
              />
            </HStack>
          </Animated.View>
        ) : null}
      </FadeView>
    </View>
  );
};

// ─── Components ──────────────────────────────────────────────────────────────

const HorizontalScrollRailNavBtn = (props: HorizontalScrollRailNavBtnProps) => {
  const { palette, onNavButtonPress, itemIndexSV, ...iconLinkProps } = props;

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onPress = useCallback(() => {
    onNavButtonPress?.();
  }, [onNavButtonPress]);

  // ─── Styles ──────────────────────────────────────────────────────────

  const btnStateAnimation = useDerivedValue(() => {
    return withTiming(itemIndexSV.value === -1 ? 0 : 1, {
      duration: theme.transitions.short,
    });
  });

  const animatedStyles = useAnimatedStyle(() => {
    return {
      opacity: interpolate(btnStateAnimation.value, [0, 1], [0.2, 1]),
      pointerEvents: itemIndexSV.value === -1 ? 'none' : 'auto',
    };
  });

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={animatedStyles}>
      <IconLink
        color={HEADER_PALETTE_STYLES[palette].iconColor}
        width={22}
        height={22}
        accessibilityRole="button"
        onPress={onPress}
        {...iconLinkProps}
      />
    </Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const HEADER_PALETTE_STYLES = {
  light: {
    iconColor: theme.colors.WHITE,
  },
  dark: {
    iconColor: theme.colors.BLACK,
  },
} as const;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type HorizontalScrollNavProps = Readonly<{
  count: number;
  showNavControls?: boolean;
  onPrevBtnPress?: () => void;
  onNextBtnPress?: () => void;
  prevBtnA11yLabel?: string;
  nextBtnA11yLabel?: string;
  headerPalette?: 'dark' | 'light';
  prevItemIndexSV: SharedValue<number>;
  nextItemIndexSV: SharedValue<number>;
}>;

type HorizontalScrollRailNavBtnProps = {
  palette: 'dark' | 'light';
  itemIndexSV: SharedValue<number>;
  onNavButtonPress?: () => void;
} & Omit<ComponentProps<typeof IconLink>, 'onPress' | 'disabled'>;
