import {
  type RailItemsMeasurementsSV,
  type TargetItemIndexSV,
} from '../../../HorizontalScrollRail.types';

// ─────────────────────────────────────────────────────────────────────────────

export function getPreviousItemScrollOffset(params: Params) {
  'worklet';

  const { prevItemIndexSV, railItemsMeasurementsSV, outerOffset } = params;

  const targetItemX =
    railItemsMeasurementsSV.value?.[prevItemIndexSV.value]?.x ?? 0;

  return targetItemX + outerOffset;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Params = {
  prevItemIndexSV: TargetItemIndexSV;
  railItemsMeasurementsSV: RailItemsMeasurementsSV;
  outerOffset: number;
};
