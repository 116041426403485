import type { ContextFrom, EventFrom } from 'xstate';
import { actions, assign, send } from 'xstate';

import {
  initialContext,
  ProductModificationsModel,
} from './ProductModifications.model';
import {
  checkIfBreadIsAvailable,
  checkIfContainsOutOfStockIngredients,
  checkIfDressingModeShouldBeMixedIn,
  checkIfDressingPortionCannotBeIncreased,
  checkIfMaxKindLimitExceeded,
  checkIfMaxModificationLimitExceeded,
  checkIfMaxModificationLimitExceededOnChange,
  checkIfMinLimitExceeded,
  checkIfModificationIsInStock,
  checkIfMultipleQtyForKindExceeded,
  checkIfNoDressingsSelected,
  checkIfTooFewBasesSelected,
  generateInitialModifications,
  getIngredientModificationsLimitations,
  getNetPriceChange,
  handleBreadIngredientModificationChange,
  handleIngredientModificationChange,
  resetActiveDressingModifications,
  resetMixedDressingDetails,
  withChangedDressingPortions,
  withChangedDressingWeight,
  withDecreasedDressingPortionNumber,
  withDecreasedDressingWeight,
  withIncreasedDressingPortionNumber,
  withIncreasedDressingWeight,
  withMixedDressingDetails,
} from './utils';
import { calculateCalories } from './utils/calories';

const { raise } = actions;

// ─── ACTIONS ────────────────────────────────────────────────────────────────────

const setInitialContext = assign((context, event) => {
  const setupEvent = event as EventFrom<typeof ProductModificationsModel>;
  const { type } = setupEvent;

  const isValidSetupEvent = type === 'SETUP' || type === 'UPDATE';

  if (!isValidSetupEvent) return;

  const {
    ingredientsModifications,
    defaultIngredients,
    activeIngredients,
    maxModifications,
    mixedDressingDetails,
    isCustom,
    isModifiable,
    customName,
    calories: productCalories = 0,
  } = setupEvent;

  const { active, defaults, removals, substitutions, additions } =
    generateInitialModifications(
      ingredientsModifications,
      defaultIngredients,
      activeIngredients,
    );

  const { isSumCaloriesEnabled } = initialContext;

  return {
    active,
    defaults,
    isCustom,
    isModifiable,
    removals,
    additions,
    customName,
    substitutions,
    maxModifications,
    ingredientsModifications,
    mixedDressingDetails,
    calories: calculateCalories({
      ingredientsModifications: active,
      mixedDressingDetails,
      productCalories,
      isModifiable,
      isSumCaloriesEnabled,
    }),
    netPriceChange: getNetPriceChange(
      ingredientsModifications,
      additions,
      removals,
      substitutions,
    ),
    limitations: getIngredientModificationsLimitations(
      ingredientsModifications,
    ),
    lastKnownModifications: {
      active,
      additions,
      removals,
      substitutions,
      mixedDressingDetails,
    },
  };
});

const startCustomization = ProductModificationsModel.assign(
  {
    lastKnownModifications: (ctx) => ({
      active: ctx.active,
      additions: ctx.additions,
      removals: ctx.removals,
      substitutions: ctx.substitutions,
      mixedDressingDetails: ctx.mixedDressingDetails,
    }),
  },
  'START_CUSTOMIZATION',
);

const cancelCustomization = ProductModificationsModel.assign(
  {
    active: (ctx) => ctx.lastKnownModifications.active,
    additions: (ctx) => ctx.lastKnownModifications.additions,
    removals: (ctx) => ctx.lastKnownModifications.removals,
    substitutions: (ctx) => ctx.lastKnownModifications.substitutions,
    mixedDressingDetails: (ctx) =>
      ctx.lastKnownModifications.mixedDressingDetails,
  },
  'CANCEL_CUSTOMIZATION',
);

const finishCustomization = ProductModificationsModel.assign(
  {
    lastKnownModifications: (ctx) => ({
      active: ctx.active,
      additions: ctx.additions,
      removals: ctx.removals,
      substitutions: ctx.substitutions,
      mixedDressingDetails: ctx.mixedDressingDetails,
    }),
  },
  'FINISH_CUSTOMIZATION',
);

const resetAllModifications = ProductModificationsModel.assign(
  { ...initialContext },
  'RESET',
);

const addIngredientModification = ProductModificationsModel.assign(
  handleIngredientModificationChange('addition'),
  'ADD_INGREDIENT_MODIFICATION',
);

const removeIngredientModification = ProductModificationsModel.assign(
  handleIngredientModificationChange('removal'),
  'REMOVE_INGREDIENT_MODIFICATION',
);

const completelyRemoveIngredientModification = ProductModificationsModel.assign(
  handleIngredientModificationChange('removal-complete'),
  'REMOVE_INGREDIENT_MODIFICATION_COMPLETELY',
);

const toggleBreadIngredientModification = ProductModificationsModel.assign(
  handleBreadIngredientModificationChange,
  'TOGGLE_BREAD_INGREDIENT_MODIFICATION',
);

const recalculateCalories = ProductModificationsModel.assign((ctx) => {
  const {
    calories: productCalories,
    mixedDressingDetails,
    active,
    isModifiable,
  } = ctx;

  const { isSumCaloriesEnabled } = initialContext;

  return {
    calories: calculateCalories({
      ingredientsModifications: active,
      productCalories,
      mixedDressingDetails,
      isModifiable,
      isSumCaloriesEnabled,
    }),
  };
});

const customizeLineItemName = ProductModificationsModel.assign(
  (_, event) => ({
    customName: event.customName,
  }),
  'CUSTOMIZE_LINE_ITEM_NAME',
);

// ────────────────────────────────────────────────────────────────────────────────

export const ProductModificationsMachine =
  ProductModificationsModel.createMachine({
    predictableActionArguments: true, // https://xstate.js.org/docs/guides/actions.html
    id: 'ProductModifications-machine',
    context: ProductModificationsModel.initialContext,
    initial: 'waiting',
    states: {
      waiting: {
        on: {
          SETUP: [
            {
              target: 'setup',
              actions: setInitialContext,
            },
          ],
        },
      },
      setup: {
        always: [
          {
            target: 'active.modification.active',
            cond: 'check-if-should-start-customization-view',
          },
          {
            target: 'active.modification.idle',
          },
        ],
      },
      active: {
        type: 'parallel',
        entry: raise('OUT_OF_STOCK_INGREDIENTS'),
        states: {
          // ─── MODIFICATIONS ──────────────────────────────────────────────────

          modification: {
            id: 'modification',
            states: {
              idle: {
                on: {
                  START_CUSTOMIZATION: {
                    target: 'active',
                    actions: startCustomization,
                  },
                  TOGGLE_BREAD_INGREDIENT_MODIFICATION: {
                    actions: [
                      toggleBreadIngredientModification,
                      recalculateCalories,
                    ],
                    cond: checkIfBreadIsAvailable,
                  },
                },
              },
              active: {
                initial: 'inProgress',
                states: {
                  inProgress: {},
                  confirmTooFewBases: {
                    on: {
                      CONTINUE_CUSTOMIZATION: '#modification.active',
                      FINISH_CUSTOMIZATION: [
                        {
                          target: 'confirmNoDressing',
                          cond: checkIfNoDressingsSelected,
                        },
                        {
                          target: '#modification.idle',
                          actions: finishCustomization,
                        },
                      ],
                    },
                  },
                  confirmNoDressing: {
                    on: {
                      CONTINUE_CUSTOMIZATION: '#modification.active',
                      FINISH_CUSTOMIZATION: {
                        target: '#modification.idle',
                        actions: finishCustomization,
                      },
                    },
                  },
                },
                on: {
                  ADD_INGREDIENT_MODIFICATION: [
                    {
                      actions: 'onMaxModificationsExceeded',
                      cond: checkIfMaxModificationLimitExceededOnChange,
                    },
                    {
                      actions: 'onMaxKindQtyExceeded',
                      cond: checkIfMultipleQtyForKindExceeded,
                    },
                    {
                      actions: 'onMaxKindExceeded',
                      cond: checkIfMaxKindLimitExceeded,
                    },
                    {
                      actions: [
                        addIngredientModification,
                        send('GENERATE_MIXED_DRESSINGS_DETAILS'),
                        recalculateCalories,
                      ],
                      cond: checkIfModificationIsInStock,
                    },
                  ],
                  REMOVE_INGREDIENT_MODIFICATION: [
                    {
                      actions: 'onMaxModificationsExceeded',
                      cond: checkIfMaxModificationLimitExceededOnChange,
                    },
                    {
                      actions: [
                        removeIngredientModification,
                        send('GENERATE_MIXED_DRESSINGS_DETAILS'),
                        recalculateCalories,
                      ],
                    },
                  ],
                  REMOVE_INGREDIENT_MODIFICATION_COMPLETELY: [
                    {
                      actions: 'onMaxModificationsExceeded',
                      cond: checkIfMaxModificationLimitExceededOnChange,
                    },
                    {
                      actions: [
                        completelyRemoveIngredientModification,
                        send('GENERATE_MIXED_DRESSINGS_DETAILS'),
                        recalculateCalories,
                      ],
                    },
                  ],
                  CANCEL_CUSTOMIZATION: {
                    target: 'idle',
                    actions: [cancelCustomization, recalculateCalories],
                  },
                  FINISH_CUSTOMIZATION: [
                    {
                      actions: 'onMaxModificationsExceeded',
                      cond: checkIfMaxModificationLimitExceeded,
                    },
                    {
                      actions: 'onMinUnmet',
                      cond: checkIfMinLimitExceeded,
                    },
                    {
                      target: '.confirmTooFewBases',
                      cond: checkIfTooFewBasesSelected,
                    },
                    {
                      target: '.confirmNoDressing',
                      cond: checkIfNoDressingsSelected,
                    },
                    {
                      target: 'idle',
                      actions: finishCustomization,
                    },
                  ],
                },
              },
            },
          },

          // ─── DRESSING ───────────────────────────────────────────────────────

          dressing: {
            initial: 'onTheSide',
            entry: [
              raise('SET_INITIAL_DRESSING_MODE'),
              raise('GENERATE_MIXED_DRESSINGS_DETAILS'),
            ],
            states: {
              onTheSide: {
                id: 'dressing.onTheSide',
                on: {
                  TOGGLE_DRESSING_MODE: 'mixedIn',
                },
                entry: recalculateCalories,
                exit: resetActiveDressingModifications,
              },
              mixedIn: {
                id: 'dressing.mixedIn',
                on: {
                  TOGGLE_DRESSING_MODE: 'onTheSide',
                  GENERATE_MIXED_DRESSINGS_DETAILS: {
                    actions: [withMixedDressingDetails, recalculateCalories],
                  },
                },
                entry: [withMixedDressingDetails, recalculateCalories],
                exit: resetMixedDressingDetails,
              },
            },
            on: {
              SET_DRESSING_MODE_TO_ON_THE_SIDE: 'dressing.onTheSide',
              SET_DRESSING_MODE_TO_MIXED_IN: 'dressing.mixedIn',
              INCREASE_DRESSING_PORTION_NUMBER: [
                {
                  actions: 'onMaxModificationsExceeded',
                  cond: checkIfMaxModificationLimitExceededOnChange,
                },
                {
                  actions: 'onMaxDressingsPortionsExceeded',
                  cond: checkIfDressingPortionCannotBeIncreased,
                },
                {
                  actions: [
                    withIncreasedDressingPortionNumber,
                    recalculateCalories,
                  ],
                },
              ],
              DECREASE_DRESSING_PORTION_NUMBER: [
                {
                  actions: 'onMaxModificationsExceeded',
                  cond: checkIfMaxModificationLimitExceededOnChange,
                },
                {
                  actions: [
                    withDecreasedDressingPortionNumber,
                    recalculateCalories,
                  ],
                },
              ],
              CHANGE_DRESSING_PORTIONS: {
                actions: [withChangedDressingPortions, recalculateCalories],
              },
              INCREASE_DRESSING_WEIGHT: [
                {
                  actions: [withIncreasedDressingWeight, recalculateCalories],
                },
              ],
              DECREASE_DRESSING_WEIGHT: [
                {
                  actions: [withDecreasedDressingWeight, recalculateCalories],
                },
              ],
              CHANGE_DRESSING_WEIGHT: {
                actions: [withChangedDressingWeight, recalculateCalories],
              },
            },
          },
        },
        on: {
          CUSTOMIZE_LINE_ITEM_NAME: {
            actions: customizeLineItemName,
          },
          SET_INITIAL_DRESSING_MODE: [
            {
              target: '.dressing.mixedIn',
              cond: checkIfDressingModeShouldBeMixedIn,
            },
            '.dressing.onTheSide',
          ],
          OUT_OF_STOCK_INGREDIENTS: {
            actions: 'onOutOfStockIngredients',
            cond: checkIfContainsOutOfStockIngredients,
          },
          RESET: {
            target: 'waiting',
            actions: resetAllModifications,
          },
          UPDATE: {
            actions: setInitialContext,
          },
        },
      },
    },
  });

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type ProductModificationsModelContext = ContextFrom<
  typeof ProductModificationsModel
>;
export type ProductModificationsModelEvent = EventFrom<
  typeof ProductModificationsModel
>;
