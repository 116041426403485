import React, { useCallback, useEffect, useMemo } from 'react';
import type { UseFormSetValue } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import type { GooglePlacesAddress } from '@sg/garnish';
import {
  convertGooglePlacesAddress,
  formatGoogleAddress,
  theme,
  usePlacesAutocomplete,
} from '@sg/garnish';

import { getEnvVars } from '@order/utils';

import {
  AddressSearchField,
  AddressSearchResults,
  SEARCH_RESULT_HEIGHT,
} from '../../AddressSearch';
import type { AddressForm, AutoCompleteParams } from '../AddressForm.types';

export const AddressFormPredictions = ({
  placeId,
  address,
  visible,
  disabled,
  accessibilityLabel,
  setPlaceId,
  setValue,
}: AddressFormPredictionsProps) => {
  // ─── Auto Complete State ───────────────────────────────────

  const {
    loading,
    error,
    noResults,
    searchString,
    predictions,
    setSearchString,
    clearAutoCompleteResults,
  } = usePlacesAutocomplete({ apiKey: getEnvVars().GOOGLE_API_KEY });

  // ─── Memoized Results ──────────────────────────────────────

  const predictionsData = useMemo(() => {
    return { predictions, loading, error, noResults };
  }, [error, loading, noResults, predictions]);

  // ─── Load Initial Address ──────────────────────────────────

  useInitialAddress({
    visible,
    address,
    setSearchString,
    setPlaceId,
  });

  // ─── Load Results ──────────────────────────────────────────

  useGoogleAutocompleteResults({
    placeId,
    setValue,
    setSearchString,
    clearAutoCompleteResults,
  });

  // ─── Callbacks ─────────────────────────────────────────────

  const handleChangeText = useCallback(
    (input: string) => {
      setPlaceId('');
      setSearchString(input);
    },
    [setPlaceId, setSearchString],
  );

  // ─── Result Checks ─────────────────────────────────────────

  const hasPlaceId = Boolean(placeId);
  const hasEnteredMinChars = searchString.length >= 3;
  const isLoadingOrReady = loading || !noResults;
  const shouldShowPredictions =
    hasEnteredMinChars && !hasPlaceId && isLoadingOrReady;

  return (
    <>
      <AddressSearchField
        isRequired
        disabled={disabled}
        value={searchString}
        hasResults={!noResults}
        accessibilityLabel={accessibilityLabel}
        onChangeText={handleChangeText}
        onSubmit={setSearchString}
      />

      {shouldShowPredictions ? (
        <View
          testID="location.auto-complete-results.wrapper"
          style={styles.autoCompleteWrapper}
        >
          <AddressSearchResults
            style={styles.autoCompleteResultContent}
            predictionsData={predictionsData}
            onPress={setPlaceId}
          />
        </View>
      ) : null}
    </>
  );
};

// ─── Hooks ───────────────────────────────────────────────────

const useInitialAddress = ({
  visible,
  address,
  setSearchString,
  setPlaceId,
}: UseInitialAddressProps) => {
  useEffect(() => {
    if (!visible || !address) return;
    const searchString = formatGoogleAddress(address);

    setSearchString(searchString ?? '');
    setPlaceId(address.googlePlaceId);
  }, [address, visible, setSearchString, setPlaceId]);
};

const useGoogleAutocompleteResults = ({
  placeId,
  setSearchString,
  clearAutoCompleteResults,
  setValue,
}: AutoCompleteParams) => {
  useEffect(() => {
    if (!placeId) return;
    (async () => {
      const { formattedAddress, ...newAddress } =
        await convertGooglePlacesAddress({
          placeId,
          apiKey: getEnvVars().GOOGLE_API_KEY,
        });

      clearAutoCompleteResults();
      setSearchString(formattedAddress ?? '');
      setValue('latitude', newAddress.latitude);
      setValue('longitude', newAddress.longitude);
      setValue('street', newAddress.street);
      setValue('zipCode', newAddress.zipCode);
      setValue('city', newAddress.city);
      setValue('state', newAddress.state);
      setValue('country', newAddress.country);
      setValue('googlePlaceId', newAddress.googlePlaceId, {
        shouldDirty: true,
      });
    })();
  }, [placeId, setValue, clearAutoCompleteResults, setSearchString]);
};

// ─── Types ───────────────────────────────────────────────────

type AddressFormPredictionsProps = Readonly<{
  placeId: string;
  address: GooglePlacesAddress;
  visible?: boolean;
  disabled?: boolean;
  accessibilityLabel?: string;
  setPlaceId: (placeId: string) => void;
  setValue: UseFormSetValue<AddressForm>;
}>;

type UseInitialAddressProps = Readonly<{
  visible?: boolean;
  address?: GooglePlacesAddress;
  setSearchString: (searchString: string) => void;
  setPlaceId: (placeId: string) => void;
}>;

// ─── Styles ──────────────────────────────────────────────────

const styles = StyleSheet.create({
  autoCompleteWrapper: {
    position: 'absolute',
    top: theme.spacing['14'],
    left: 0,
    right: 0,
    minHeight: SEARCH_RESULT_HEIGHT * 3,
    backgroundColor: theme.colors.APP_BACKGROUND,
    borderBottomColor: theme.colors.NEUTRAL_4,
    borderBottomWidth: 1,
    zIndex: theme.zIndex.popover,
  },
  autoCompleteResultContent: {
    paddingHorizontal: theme.spacing['10'],
  },
});
