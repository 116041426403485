import React, { type ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { IconLink } from '../../../Icon';
import { Modal } from '../../../Modal';

// ─────────────────────────────────────────────────────────────────────────────

export const PinPromptModal = (props: ComponentProps<typeof Modal>) => {
  const { children, ...restProps } = props;

  return (
    <Modal size="small" animationType="none" fitHeight {...restProps}>
      <IconLink
        palette="muted"
        width={48}
        height={48}
        iconSize={40}
        name="IconClose"
        onPress={restProps.onRequestClose}
        accessibilityRole="imagebutton"
        style={styles.closeButton}
      />

      {children}
    </Modal>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  closeButton: {
    position: 'absolute',
    top: theme.spacing['3'],
    right: theme.spacing['3'],
    zIndex: 1,
  },
});
