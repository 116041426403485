import { useMemo } from 'react';

import { useFeatureFlag } from './useFeatureFlag';

/**
 * A hook with dynamic feature flags that listens for value changes.
 * Can be used at the global context level to access updated feature flag values
 * across the app.
 */
export const useDynamicFeatureFlags = () => {
  const isOneSignalLiveActivitiesEnabled = useFeatureFlag('CELS-997-live-activities-enabled', { listenForChanges: true }); // prettier-ignore
  const isBrazeLiveActivitiesEnabled = useFeatureFlag('CELS-3466-braze-live-activities-enabled', { listenForChanges: true }); // prettier-ignore

  return useMemo(
    () => ({
      isOneSignalLiveActivitiesEnabled,
      isBrazeLiveActivitiesEnabled,
    }),
    [isOneSignalLiveActivitiesEnabled, isBrazeLiveActivitiesEnabled],
  );
};
