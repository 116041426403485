import React, { useCallback, useState } from 'react';

import { useAzureAuthSignOut } from '@order/AzureAuth';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useLocalizationContext } from '@order/Localization';
import { useUrqlContext } from '@order/Urql';

import { AccountMenuPrimaryItem } from '../AccountMenuPrimaryItem';
import { AccountMenuSignOutItemEmbeddedModal } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const AccountMenuSignOutItem = (props: AccountMenuSignOutProps) => {
  const { isSigningOut, resetNavigation } = props;

  const { resetClient } = useUrqlContext();

  /**
   * Reset GraphQL client and navigation state.
   */
  const resetAppState = useCallback(async () => {
    await resetClient();
    resetNavigation();
  }, [resetClient, resetNavigation]);

  const signOutFromAzure = useAzureAuthSignOut({ resetAppState });

  // ─────────────────────────────────────────────────────────────────────

  const { t } = useLocalizationContext();

  const isAuthHybridFlowEnabled = useFeatureFlag('cels-2041-auth-hybrid-flow', {
    listenForChanges: true,
  });

  // ─── State ───────────────────────────────────────────────────────────

  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const openSignOutModal = useCallback(() => {
    setIsSignOutModalOpen(true);
  }, []);

  const closeSignOutModal = useCallback(() => {
    setIsSignOutModalOpen(false);
  }, []);

  const handleSignOut = useCallback(async () => {
    if (isAuthHybridFlowEnabled) {
      await signOutFromAzure();

      return;
    }

    openSignOutModal();
  }, [isAuthHybridFlowEnabled, openSignOutModal, signOutFromAzure]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <AccountMenuPrimaryItem
        testID="account.sidebar-tab.sign-out"
        label={t('account.sidebar.sign-out')}
        iconName="IconLogout"
        disabled={isSigningOut}
        onPress={handleSignOut}
      />

      {isAuthHybridFlowEnabled ? null : (
        <AccountMenuSignOutItemEmbeddedModal
          isVisible={isSignOutModalOpen}
          closeModal={closeSignOutModal}
          resetAppState={resetAppState}
        />
      )}
    </>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type AccountMenuSignOutProps = Readonly<{
  isSigningOut: boolean;
  resetNavigation: () => void;
}>;
