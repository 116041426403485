/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { AddressFragmentDoc } from '../../../graphql/fragments/CustomerData.generated';
import { ValidationErrorResponseFragmentDoc } from '../../../screens/ReorderingScreen/GraphQL/Reorder.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddAddressMutationVariables = Types.Exact<{
  input: Types.AddAddressInput;
}>;

export type AddAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly addAddress:
    | {
        readonly __typename: 'AddAddressSuccess';
        readonly address: {
          readonly __typename: 'Address';
          readonly id: string;
          readonly street: string;
          readonly secondaryStreet: string | null;
          readonly name: string | null;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly country: string;
          readonly deliveryPreference: Types.DeliveryPreferenceType;
          readonly googlePlaceId: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly notes: string | null;
        };
      }
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type UpdateAddressMutationVariables = Types.Exact<{
  input: Types.EditAddressInput;
}>;

export type UpdateAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAddress:
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'UpdateAddressSuccess';
        readonly address: {
          readonly __typename: 'Address';
          readonly id: string;
          readonly street: string;
          readonly secondaryStreet: string | null;
          readonly name: string | null;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly country: string;
          readonly deliveryPreference: Types.DeliveryPreferenceType;
          readonly googlePlaceId: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly notes: string | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type DeleteAddressMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type DeleteAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAddress:
    | { readonly __typename: 'DeleteAddressSuccess'; readonly success: boolean }
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const AddAddressDocument = gql`
  mutation addAddress($input: AddAddressInput!) {
    addAddress(input: $input) {
      __typename
      ... on AddAddressSuccess {
        address {
          id
          __typename
          ...address
        }
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${AddressFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useAddAddressMutation() {
  return Urql.useMutation<AddAddressMutation, AddAddressMutationVariables>(
    AddAddressDocument,
  );
}
export const UpdateAddressDocument = gql`
  mutation updateAddress($input: EditAddressInput!) {
    updateAddress(input: $input) {
      __typename
      ... on UpdateAddressSuccess {
        address {
          id
          __typename
          ...address
        }
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${AddressFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useUpdateAddressMutation() {
  return Urql.useMutation<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >(UpdateAddressDocument);
}
export const DeleteAddressDocument = gql`
  mutation deleteAddress($id: ID!) {
    deleteAddress(addressId: $id) {
      __typename
      ... on DeleteAddressSuccess {
        success
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${ValidationErrorResponseFragmentDoc}
`;

export function useDeleteAddressMutation() {
  return Urql.useMutation<
    DeleteAddressMutation,
    DeleteAddressMutationVariables
  >(DeleteAddressDocument);
}
