/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type OrderForLocationFragment = {
  readonly __typename: 'Order';
  readonly id: string;
  readonly orderType: Types.OrderType;
  readonly dropoffLocation: string | null;
  readonly deliveryOrderDetail: {
    readonly __typename: 'DeliveryOrderDetail';
    readonly id: string;
    readonly estimatedDeliveryTime: string | null;
    readonly vendor: string;
    readonly vendorRestaurantId: string;
    readonly address: {
      readonly __typename: 'Address';
      readonly id: string;
      readonly name: string | null;
      readonly street: string;
      readonly city: string;
      readonly state: string;
      readonly country: string;
      readonly zipCode: string;
      readonly googlePlaceId: string;
      readonly latitude: number;
      readonly longitude: number;
    } | null;
  } | null;
};

export type OrderForLocationRestaurantFragment = {
  readonly __typename: 'Restaurant';
  readonly id: string;
  readonly name: string;
  readonly isOutpost: boolean;
  readonly slug: string;
  readonly city: string;
  readonly state: string;
  readonly address: string;
  readonly zipCode: string;
  readonly phone: string;
  readonly flexMessage: string | null;
  readonly isAcceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string;
  readonly deliveryFee: number;
  readonly latitude: number;
  readonly longitude: number;
  readonly hours: {
    readonly __typename?: 'RestaurantHours';
    readonly formatted: string | null;
  };
  readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
};

export type OrdersV2ForLocationQueryVariables = Types.Exact<{
  input: Types.OrdersInputV2;
}>;

export type OrdersV2ForLocationQuery = {
  readonly __typename?: 'Query';
  readonly ordersV2:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly dropoffLocation: string | null;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
            readonly slug: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly phone: string;
            readonly flexMessage: string | null;
            readonly isAcceptingOrders: boolean;
            readonly notAcceptingOrdersReason: string;
            readonly deliveryFee: number;
            readonly latitude: number;
            readonly longitude: number;
            readonly hours: {
              readonly __typename?: 'RestaurantHours';
              readonly formatted: string | null;
            };
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly estimatedDeliveryTime: string | null;
            readonly vendor: string;
            readonly vendorRestaurantId: string;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly name: string | null;
              readonly street: string;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
            } | null;
          } | null;
        }>;
      }
    | { readonly __typename?: 'ValidationError' };
};

export type OrdersV2ForLocationWithDisclosureQueryVariables = Types.Exact<{
  input: Types.OrdersInputV2;
}>;

export type OrdersV2ForLocationWithDisclosureQuery = {
  readonly __typename?: 'Query';
  readonly ordersV2:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly dropoffLocation: string | null;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly showDeliveryPriceDifferenciationDisclosure: boolean;
            readonly showOutpostPriceDifferenciationDisclosure: boolean;
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
            readonly slug: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly phone: string;
            readonly flexMessage: string | null;
            readonly isAcceptingOrders: boolean;
            readonly notAcceptingOrdersReason: string;
            readonly deliveryFee: number;
            readonly latitude: number;
            readonly longitude: number;
            readonly hours: {
              readonly __typename?: 'RestaurantHours';
              readonly formatted: string | null;
            };
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly estimatedDeliveryTime: string | null;
            readonly vendor: string;
            readonly vendorRestaurantId: string;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly name: string | null;
              readonly street: string;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
            } | null;
          } | null;
        }>;
      }
    | { readonly __typename?: 'ValidationError' };
};

export const OrderForLocationFragmentDoc = gql`
  fragment OrderForLocation on Order {
    id
    __typename
    orderType
    deliveryOrderDetail {
      id
      __typename
      address {
        id
        __typename
        name
        street
        city
        state
        country
        zipCode
        googlePlaceId
        latitude
        longitude
      }
      estimatedDeliveryTime
      vendor
      vendorRestaurantId
    }
    dropoffLocation
  }
`;
export const OrderForLocationRestaurantFragmentDoc = gql`
  fragment OrderForLocationRestaurant on Restaurant {
    id
    __typename
    name
    isOutpost
    slug
    city
    state
    address
    zipCode
    phone
    hours {
      formatted
    }
    asset {
      url
    }
    flexMessage
    isAcceptingOrders
    notAcceptingOrdersReason
    deliveryFee
    latitude
    longitude
  }
`;
export const OrdersV2ForLocationDocument = gql`
  query ordersV2ForLocation($input: OrdersInputV2!) {
    ordersV2(input: $input) {
      ... on OrdersResponseSuccess {
        __typename
        orders {
          ...OrderForLocation
          restaurant {
            ...OrderForLocationRestaurant
          }
        }
      }
    }
  }
  ${OrderForLocationFragmentDoc}
  ${OrderForLocationRestaurantFragmentDoc}
`;

export function useOrdersV2ForLocationQuery(
  options: Omit<Urql.UseQueryArgs<OrdersV2ForLocationQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    OrdersV2ForLocationQuery,
    OrdersV2ForLocationQueryVariables
  >({ query: OrdersV2ForLocationDocument, ...options });
}
export const OrdersV2ForLocationWithDisclosureDocument = gql`
  query ordersV2ForLocationWithDisclosure($input: OrdersInputV2!) {
    ordersV2(input: $input) {
      ... on OrdersResponseSuccess {
        __typename
        orders {
          ...OrderForLocation
          restaurant {
            ...OrderForLocationRestaurant
            showDeliveryPriceDifferenciationDisclosure
            showOutpostPriceDifferenciationDisclosure
          }
        }
      }
    }
  }
  ${OrderForLocationFragmentDoc}
  ${OrderForLocationRestaurantFragmentDoc}
`;

export function useOrdersV2ForLocationWithDisclosureQuery(
  options: Omit<
    Urql.UseQueryArgs<OrdersV2ForLocationWithDisclosureQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    OrdersV2ForLocationWithDisclosureQuery,
    OrdersV2ForLocationWithDisclosureQueryVariables
  >({ query: OrdersV2ForLocationWithDisclosureDocument, ...options });
}
