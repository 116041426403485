import React, { type ComponentProps, useCallback } from 'react';
import { useToggleState } from '@sg/garnish';

import { ReorderConflictModal } from '@order/components';
import { OrderFavoriteLineItem } from '@order/features/orders';
import { useReorderOrderLineItem } from '@order/hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const ReorderOrderFavoriteLineItem = (
  props: ReorderOrderFavoriteLineItemProps,
) => {
  const {
    favoriteLineItem,
    mostRecentOrder,
    isTogglingFavoriteState,
    toggleFavoriteLineItem,
  } = props;

  // ─── State ───────────────────────────────────────────────────────────

  const {
    value: isConflictReviewModalVisible,
    toggleOn: showConflictReviewModal,
    toggleOff: hideConflictReviewModal,
  } = useToggleState(false);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const {
    reorderLineItem,
    isFetching: isReordering,
    unavailableIngredients,
    targetLocation,
  } = useReorderOrderLineItem({
    variation: 'favorite',
    mostRecentOrder,
    productName: favoriteLineItem.product.name,
    onUnavailableIngredientConflict: showConflictReviewModal,
    onSuccess: hideConflictReviewModal,
  });

  // ─── Derived Data ────────────────────────────────────────────────────

  const reorderLineItemForce = useCallback(async () => {
    await reorderLineItem(favoriteLineItem.id, true);
  }, [favoriteLineItem.id, reorderLineItem]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <>
      <OrderFavoriteLineItem
        favoriteLineItem={favoriteLineItem}
        isReordering={isReordering}
        isTogglingFavoriteState={isTogglingFavoriteState}
        onAddToBagButtonPress={reorderLineItem}
        onToggleFavoriteButtonPress={toggleFavoriteLineItem}
      />

      <ReorderConflictModal
        visible={isConflictReviewModalVisible}
        lineItem={favoriteLineItem}
        isReordering={isReordering}
        onRequestClose={hideConflictReviewModal}
        addToBagAnyway={reorderLineItemForce}
        targetLocation={targetLocation}
        unavailableIngredients={unavailableIngredients}
      />
    </>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type ReorderOrderFavoriteLineItemProps = {
  favoriteLineItem: FavoriteLineItem;
  mostRecentOrder: UseReorderOrderLineItemParams['mostRecentOrder'];
  isTogglingFavoriteState: boolean;
  toggleFavoriteLineItem: ToggleFavorite;
};

type UseReorderOrderLineItemParams = Parameters<
  typeof useReorderOrderLineItem
>[0];

type FavoriteLineItem = ComponentProps<
  typeof OrderFavoriteLineItem
>['favoriteLineItem'];

type ToggleFavorite = ComponentProps<
  typeof OrderFavoriteLineItem
>['onToggleFavoriteButtonPress'];
