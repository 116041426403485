/* eslint-disable react/no-array-index-key,@typescript-eslint/no-shadow,functional/immutable-data */

import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import {
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../hooks';
import { VStack } from '../Stack';
import { LoadingPlaceholderColumn, LoadingPlaceholderRow } from './components';

// ─────────────────────────────────────────────────────────────────────────────

export const LoadingPlaceholder = memo((props: LoadingPlaceholderProps) => {
  const {
    rows = 1,
    columns = 1,
    columnWidth,
    rowHeight,
    gridGap = theme.spacing['4'],
    gridGapY,
    gridGapX,
    speed = 600,
    borderRadius = theme.radius.medium,
    palette = 'gray',
    bgColor,
    fgColor,
    floating,
    style,
  } = props;

  const { match } = useResponsive();

  // ────────────────────────────────────────────────────────────────────

  const rowsNumber = Array.isArray(rows) ? match(rows) : rows;
  const columnsNumber = Array.isArray(columns) ? match(columns) : columns;

  const rowElements = useMemo(
    () => Array.from({ length: rowsNumber }),
    [rowsNumber],
  );
  const columnElements = useMemo(
    () => Array.from({ length: columnsNumber }),
    [columnsNumber],
  );

  const [paletteBgColor, paletteFgColor] = PALETTES_MAP[palette];
  const hasCustomWidth = columnWidth !== undefined;

  // ─────────────────────────────────────────────────────────────────────

  const transitionOddSV = useSharedValue(0);
  const transitionEvenSV = useSharedValue(0);

  const startAnimation = useCallback(() => {
    transitionOddSV.value = withRepeat(withTiming(1, { duration: speed }), -1, true); // prettier-ignore
    transitionEvenSV.value = withDelay(speed / 2, withRepeat(withTiming(1, { duration: speed }), -1, true)); // prettier-ignore
  }, [speed, transitionEvenSV, transitionOddSV]);

  const containerStyles = [
    floating ? styles.containerFloating : undefined,
    style,
  ];

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(startAnimation, [startAnimation]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View testID="sg-loading-placeholder-view" style={containerStyles}>
      <VStack gap={gridGapY ?? gridGap}>
        {rowElements.map((_, index) => (
          <LoadingPlaceholderRow
            key={index}
            gridGap={gridGapX ?? gridGap}
            index={index}
            columnsNumber={hasCustomWidth ? 'auto' : columnsNumber}
            rowHeight={rowHeight}
          >
            {columnElements.map((_, index) => {
              const isOdd = index % 2 === 0;

              return (
                <LoadingPlaceholderColumn
                  key={index}
                  columnWidth={columnWidth}
                  borderRadius={borderRadius}
                  backgroundColor={bgColor ?? paletteBgColor}
                  foregroundColor={fgColor ?? paletteFgColor}
                  transitionSV={isOdd ? transitionOddSV : transitionEvenSV}
                />
              );
            })}
          </LoadingPlaceholderRow>
        ))}
      </VStack>
    </View>
  );
});

// ─── Palettes ────────────────────────────────────────────────────────────────

const PALETTES_MAP: PalettesMap = {
  darkGray: ['#f2f1e5', '#e2e1d5'],
  gray: ['#f3f3f3', '#ecebeb'],
  cream: ['#f0eee2', '#e7e6dd'],
  quinoa: ['#e8dcc6', '#f1eadc'],
  darkGreen: ['#00473c', '#9cb9a1'],
  lightGreen: ['#92b19a', '#9cb9a1'],
  cucumber: ['#cddbcc', '#d5e1d3'],
  lightestGreen: ['#d0d7c9', '#c5d2c4'],
  lightGray: ['#00000000', '#0e150e3d'],
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerFloating: {
    ...StyleSheet.absoluteFillObject,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoadingPlaceholderProps = Readonly<{
  rows?: GridElementsNumber;
  columns?: GridElementsNumber;
  floating?: boolean;

  rowHeight?: RowHeight;
  columnWidth?: number;

  gridGap?: number;
  gridGapY?: number;
  gridGapX?: number;

  palette?: Palettes;
  bgColor?: string;
  fgColor?: string;
  style?: ViewProps['style'];

  borderRadius?: number;
  speed?: number;
}>;

type GridElementsNumber = number | readonly number[];

type RowHeight = number | number[];

type PalettesMap = Record<Palettes, ColorsTuple>;

type Palettes =
  | 'darkGray'
  | 'gray'
  | 'cream'
  | 'quinoa'
  | 'darkGreen'
  | 'lightGreen'
  | 'lightestGreen'
  | 'lightGray'
  | 'cucumber';

type ColorsTuple = readonly [string, string];
