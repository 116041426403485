import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsContainer = (props: PropsWithChildren) => {
  const { match } = useResponsive();
  const containerStyles = [styles.container, match([styles.containerXS])];

  return <View style={containerStyles}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: theme.spacing['6'],
    padding: theme.spacing['4'],
    marginBottom: theme.spacing['4'],
  },
  containerXS: {
    paddingBottom: theme.spacing['25'],
  },
});
