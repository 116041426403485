/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateAccountMutationVariables = Types.Exact<{
  input: Types.CreateAccountInput;
}>;

export type CreateAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly createAccount:
    | {
        readonly __typename: 'CreateAccountSuccess';
        readonly customer: {
          readonly __typename: 'Customer';
          readonly id: string;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const CreateAccountDocument = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      __typename
      ... on CreateAccountSuccess {
        customer {
          id
          __typename
        }
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
    }
  }
`;

export function useCreateAccountMutation() {
  return Urql.useMutation<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >(CreateAccountDocument);
}
