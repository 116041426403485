import type React from 'react';
import type { PressableProps, ViewProps } from 'react-native';
import type { NativeStackHeaderProps } from '@react-navigation/native-stack';
import type { IconName } from '@sg/garnish';

export type HeaderContentProps = Pick<ViewProps, 'testID'>;

export type HeaderProps = HeaderContentProps & NativeStackHeaderProps;

export type HeaderIconItemPressableProps = Pick<
  PressableProps,
  'testID' | 'accessibilityLabel' | 'accessibilityHint' | 'onPress'
>;

export type HeaderIconItemProps = Readonly<{
  key: string;
  ref?: React.ForwardedRef<unknown>;
  icon: IconName;
}> &
  HeaderIconItemPressableProps;

export type HeaderCloseIconItemProps = HeaderIconItemPressableProps;

export type HeaderIconItems = readonly HeaderIconItemProps[];
