import { useCallback } from 'react';
import { Platform } from 'react-native';

import { useGlobalAppState } from '@order/GlobalAppState';
import { useFeatureFlag } from '@order/LaunchDarkly';

export const useOnPostOrder = () => {
  const { postPurchaseModalMachineRef } = useGlobalAppState();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  // ─── Helpers ─────────────────────────────────────────────────────────

  const showPostPurchaseModal = useCallback(
    (_context: unknown, event: OrderSubmissionEvent) => {
      if (!isLoyaltyV2Enabled) return;

      const { order } = event.data;
      const { id: orderId, hasEarnedLoyaltyPoints } = order;

      if (!orderId || !hasEarnedLoyaltyPoints) return;

      // NOTE: We use a short delay on iOS because of a known issue during
      //       interactions between two modals.
      setTimeout(
        () => {
          postPurchaseModalMachineRef.send({ type: 'show', orderId });
        },
        Platform.select({ ios: 750, default: 0 }),
      );
    },
    [isLoyaltyV2Enabled, postPurchaseModalMachineRef],
  );

  // ─────────────────────────────────────────────────────────────────────

  return { onPostOrder: showPostPurchaseModal };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderSubmissionEvent = {
  data: {
    order: {
      id: string;
      hasEarnedLoyaltyPoints: boolean;
    };
  };
};
