import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { PortalProvider } from '@gorhom/portal';
import { theme } from '@sg/garnish';

import {
  OtaUpdateDialog,
  useResetDietaryRestrictionsOnLogout,
  useResetLoyaltyOffersOnLogout,
} from '@order/components';
import { useCustomerTelemetry } from '@order/Customer';
import {
  useAccessibilityTracking,
  useAppTrackingTransparency,
  useFetchUserCart,
  useLoyaltyPremiumStatus,
  useNetworkSubscription,
  usePushNotifications,
  useRegisterUrqlErrorTracker,
  useRegisterUrqlLogOutHandler,
  useSweetpassPlusPlans,
  useSweetpassTier,
  useSyncDietaryRestrictions,
} from '@order/hooks';
import { useTrackLastInteractedStore } from '@order/LastInteractedStore';
import { useResetPlacedOrdersCounterOnLogout } from '@order/PlacedOrdersCounter';

import { useFacebookPixel } from './integrations/facebook-pixel';
import { useKustomerToken } from './integrations/kustomer/hooks';
import {
  useInitializeLiveActivities,
  useLiveActivityTelemetry,
} from './integrations/live-activities';
import { AppNavigation } from './navigation/AppNavigation';

// ────────────────────────────────────────────────────────────────────────────────

export const AppContent = memo(() => {
  useCustomerTelemetry();
  useFetchUserCart();
  useNetworkSubscription();
  usePushNotifications();
  useAppTrackingTransparency();
  useFacebookPixel();
  useTrackLastInteractedStore();
  useAccessibilityTracking();
  useRegisterUrqlErrorTracker();
  useRegisterUrqlLogOutHandler();
  useLiveActivityTelemetry();
  useSyncDietaryRestrictions();
  useInitializeLiveActivities();

  // ─── App Load Graphql Updates ────────────────────────────────────────

  useLoyaltyPremiumStatus({ requestPolicy: 'cache-and-network' });
  useSweetpassDataCacheUpdate();
  useKustomerTokenCacheUpdate();

  // ─── Machine Resets ──────────────────────────────────────────────────

  useResetDietaryRestrictionsOnLogout();
  useResetPlacedOrdersCounterOnLogout();
  useResetLoyaltyOffersOnLogout();

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View testID="app.container" style={styles.appContainer}>
      <PortalProvider>
        <OtaUpdateDialog />
        <AppNavigation />
      </PortalProvider>
    </View>
  );
});

// ─── Helpers ────────────────────────────────────────────────────────────────

/**
 * We must fetch Sweetpass info at least once per session.
 * Otherwise customers will see stale data.
 */
const useSweetpassDataCacheUpdate = () => {
  useSweetpassTier('cache-and-network');
  useSweetpassPlusPlans('cache-and-network');
};

/**
 * We must fetch the Kustomer Token at least once per session.
 * The iat for a token must be within 15 minutes or the token will be invalid.
 * @see {@link https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/authenticate-chat-with-jwt-token}
 */
const useKustomerTokenCacheUpdate = () => {
  useKustomerToken('cache-and-network');
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  appContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
});
