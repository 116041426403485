/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ReorderFavoritesQueryVariables = Types.Exact<{
  input: Types.FavoritedLineItemsInput;
}>;

export type ReorderFavoritesQuery = {
  readonly __typename?: 'Query';
  readonly favoritedLineItems: ReadonlyArray<{
    readonly __typename: 'LineItem';
    readonly id: string;
    readonly customName: string | null;
    readonly quantity: number;
    readonly cost: number;
    readonly perItemCost: number;
    readonly favorited: boolean;
    readonly dressingMode: Types.DressingMode;
    readonly product: {
      readonly __typename: 'Product';
      readonly id: string;
      readonly slug: string;
      readonly name: string;
      readonly isCustom: boolean;
      readonly categoryName: string;
      readonly asset: { readonly __typename?: 'Asset'; readonly url: string };
      readonly ingredients: ReadonlyArray<{
        readonly __typename: 'Ingredient';
        readonly id: string;
        readonly name: string;
        readonly kind: Types.IngredientKind | null;
      }>;
    };
    readonly addedIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
    }>;
    readonly removedIngredients: ReadonlyArray<{
      readonly __typename: 'Ingredient';
      readonly id: string;
      readonly name: string;
      readonly kind: Types.IngredientKind | null;
    }>;
    readonly mixedDressingDetails: ReadonlyArray<{
      readonly __typename?: 'MixedDressingDetails';
      readonly ingredientId: string;
      readonly weight: Types.DressingWeight;
    }>;
  }>;
};

export type ToggleReorderLineItemFavoriteStateMutationVariables = Types.Exact<{
  input: Types.FavoriteLineItemInput;
}>;

export type ToggleReorderLineItemFavoriteStateMutation = {
  readonly __typename?: 'Mutation';
  readonly favoriteLineItem: {
    readonly __typename: 'LineItem';
    readonly id: string;
    readonly favorited: boolean;
  };
};

export const ReorderFavoritesDocument = gql`
  query ReorderFavorites($input: FavoritedLineItemsInput!) {
    favoritedLineItems(input: $input) {
      id
      __typename
      customName
      quantity
      cost
      perItemCost
      favorited
      product {
        id
        __typename
        slug
        name
        isCustom
        categoryName
        asset {
          url
        }
        ingredients {
          id
          __typename
          name
          kind
        }
      }
      addedIngredients {
        id
        __typename
        name
        kind
      }
      removedIngredients {
        id
        __typename
        name
        kind
      }
      mixedDressingDetails {
        ingredientId
        weight
      }
      dressingMode
    }
  }
`;

export function useReorderFavoritesQuery(
  options: Omit<Urql.UseQueryArgs<ReorderFavoritesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ReorderFavoritesQuery, ReorderFavoritesQueryVariables>({
    query: ReorderFavoritesDocument,
    ...options,
  });
}
export const ToggleReorderLineItemFavoriteStateDocument = gql`
  mutation ToggleReorderLineItemFavoriteState($input: FavoriteLineItemInput!) {
    favoriteLineItem(input: $input) {
      __typename
      id
      favorited
    }
  }
`;

export function useToggleReorderLineItemFavoriteStateMutation() {
  return Urql.useMutation<
    ToggleReorderLineItemFavoriteStateMutation,
    ToggleReorderLineItemFavoriteStateMutationVariables
  >(ToggleReorderLineItemFavoriteStateDocument);
}
