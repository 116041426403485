import { useCallback, useState } from 'react';

export const useAlertDialogPrompt = ({
  onSubmit,
  onCancel,
}: useAlertDialogPromptProps) => {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState<string | undefined>();

  const prompt = useCallback(() => {
    setVisible(true);
  }, []);

  const handleSubmit = useCallback(() => {
    setVisible(false);
    onSubmit?.(inputValue);
    setInputValue('');
  }, [inputValue, onSubmit]);

  const handleCancel = useCallback(() => {
    setVisible(false);
    onCancel?.();
    setInputValue('');
  }, [onCancel]);

  return {
    prompt,
    visible,
    handleCancel,
    handleSubmit,
    onInputChange: setInputValue,
    inputValue,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type useAlertDialogPromptProps = {
  onSubmit: (value: string | undefined) => void;
  onCancel?: () => void;
};
