import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, FadeView, LoadingDots, TextLink } from '@sg/garnish';

import { useContactUsHandler } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

/**
 * Need to cancel your order? Contact us ⋯
 */
export const OrderStatusCancellationOption = () => {
  const { t } = useLocalizationContext();
  const { handleContactUs, handlingContactUs } =
    useContactUsHandler('order-status');

  const label = t('order-status-info.cancellation-option');
  const highlight = t('order-status-info.cancellation-highlight');

  return (
    <View style={styles.optionContainer}>
      <BodyText size={3}>
        <Text>{label}</Text>
        <TextLink onPress={handleContactUs}>{highlight}</TextLink>
      </BodyText>

      <FadeView show={handlingContactUs} style={styles.loadingIcon}>
        <LoadingDots />
      </FadeView>
    </View>
  );
};

// ─── Styles ────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  optionContainer: {
    flexDirection: 'row',
    marginTop: theme.spacing['4'],
  },
  loadingIcon: {
    marginLeft: theme.spacing['2'],
  },
});
