import { useCallback, useMemo } from 'react';
import {
  HapticNotificationFeedbackStyle,
  triggerHapticNotificationFeedback,
} from '@sg/garnish';

import type {
  EditLineItemInCartInput,
  Ingredient,
  IngredientModificationInput,
  IngredientSubstitutionModificationInput,
  MixedDressingDetails,
  PartialProduct,
} from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';
import { useTelemetry } from '@order/Telemetry';
import { getUrqlError } from '@order/utils';

import { useNetworkErrorNotice } from '../../useNetworkErrorNotice';
import { useEditLineItemInCartMutation } from '../GraphQL/Cart.generated';
import { useCart } from '../useCart';
import {
  extractIngredientId,
  extractMixedDressingDetails,
  extractSubstitution,
  getLineItemCustomName,
} from '../useCart.utils';
import { useCartMutationErrorNotice } from '../useLineItemErrorResponse';

// ────────────────────────────────────────────────────────────────────────────────

export const useEditLineItemInCart = (props?: UseEditLineItemToCartProps) => {
  const { t } = useLocalizationContext();
  const { onSuccess } = props ?? {};
  const { executeCartMutation } = useCart();

  const [editLineItemResponse, editLineItem] = useEditLineItemInCartMutation();
  const { fetching, data } = editLineItemResponse;

  useCartMutationErrorNotice(data?.editLineItemInCart);
  useNetworkErrorNotice(editLineItemResponse);

  // ─── TELEMETRY ───────────────────────────────────────────────────

  const { track } = useTelemetry();

  const onAddToBagFailedTelemetry = useCallback(
    (userError?: string, systemError?: string) => {
      track('add_to_bag.failed', {
        userError: userError ?? t('general.error'),
        systemError,
      });
    },
    [t, track],
  );

  // ─────────────────────────────────────────────────────────────────

  const editLineItemInCart = useCallback(
    async (inputProps: EditLineItemInCartInputProps) => {
      const {
        product,
        lineItemId = '',
        quantity = 1,
        modifications,
        customName = null,
      } = inputProps;
      const { id: productId = '', name: productName = '' } = product;

      const {
        additions = [],
        removals = [],
        substitutions = [],
        mixedDressingDetails = [],
      } = modifications ?? {};

      const input: EditLineItemInCartInput = {
        productId,
        quantity,
        lineItemId,
        customName: getLineItemCustomName({ productName, customName }),
        additions: additions.map(extractIngredientId),
        removals: removals.map(extractIngredientId),
        substitutions: substitutions.map(extractSubstitution),
        mixedDressingDetails: mixedDressingDetails.map(
          extractMixedDressingDetails,
        ),
      };

      const executeMutation = async () => editLineItem({ input });
      const response = await executeCartMutation(
        executeMutation,
        'edit-line-item',
      );

      const maybeData = response.data?.editLineItemInCart;
      const possibleError = maybeData as { message?: string };
      const isSuccessfullyEdited = maybeData?.__typename === 'EditLineItemInCartSuccess'; // prettier-ignore

      if (isSuccessfullyEdited) {
        void triggerHapticNotificationFeedback(
          HapticNotificationFeedbackStyle.Success,
        );
        onSuccess?.();
      }

      if (!isSuccessfullyEdited) {
        onAddToBagFailedTelemetry(
          possibleError?.message,
          getUrqlError(response.error),
        );
      }

      return response;
    },
    [editLineItem, executeCartMutation, onAddToBagFailedTelemetry, onSuccess],
  );

  // ─────────────────────────────────────────────────────────────────

  return useMemo(
    () => ({ editLineItemInCart, fetching }),
    [editLineItemInCart, fetching],
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type UseEditLineItemToCartProps = Readonly<{
  onSuccess?: () => void;
}>;

type EditLineItemInCartInputProps = Omit<
  EditLineItemInCartInput,
  | 'additions'
  | 'removals'
  | 'substitutions'
  | 'mixedDressingDetails'
  | 'productId'
> &
  Readonly<{
    product: PartialProduct;
    modifications?: {
      additions: readonly IngredientWithId[];
      removals: readonly IngredientWithId[];
      substitutions?: readonly IngredientSubstitutionModificationInput[];
      mixedDressingDetails: readonly MixedDressingDetails[];
    };
  }>;

type IngredientWithId = Partial<
  Pick<IngredientModificationInput, 'ingredientId'> & Pick<Ingredient, 'id'>
>;
