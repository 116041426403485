/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SignInMutationVariables = Types.Exact<{ [key: string]: never }>;

export type SignInMutation = {
  readonly __typename?: 'Mutation';
  readonly signIn:
    | { readonly __typename: 'CustomerNotFound'; readonly errorMessage: string }
    | {
        readonly __typename: 'SignInFailed';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'SignInSuccess';
        readonly customer: {
          readonly __typename: 'Customer';
          readonly id: string;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const SignInDocument = gql`
  mutation SignIn {
    signIn {
      __typename
      ... on SignInSuccess {
        customer {
          id
          __typename
        }
      }
      ... on CustomerNotFound {
        errorMessage
      }
      ... on ValidationError {
        message
        status
        fieldErrors {
          field
          message
        }
      }
      ... on SignInFailed {
        message
        status
      }
    }
  }
`;

export function useSignInMutation() {
  return Urql.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
  );
}
