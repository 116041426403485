import React from 'react';
import { useIntl } from 'react-intl';
import { Pressable, type PressableProps } from 'react-native';
import { Icon, SweetgreenLogoSvg, theme, useResponsive } from '@sg/garnish';

import { messages } from '../messages';

export const NavbarHomeCta = (props: Props) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();
  const { minWidth } = useResponsive();

  return (
    <Pressable
      testID="nav-bar.home"
      accessibilityRole="button"
      aria-label={formatMessage(messages.homeA11y)}
      onPress={onPress}
    >
      {minWidth.isMD ? (
        <SweetgreenLogoSvg />
      ) : (
        <Icon
          name="IconSweetgreen"
          color={theme.colors.KALE}
          width={48}
          height={48}
        />
      )}
    </Pressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress'>;
