import { useCallback } from 'react';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { AddressType } from '@sg/garnish';

import type { DeliveryOrderDetailInput } from '../../graphql/types';
import type {
  AppStackParamList,
  MenuScreenReferrer,
} from '../../navigation/AppNavigation.props';
import { useMenuNavigation } from '../../screens/MenuScreen/MenuScreen.navigation';
import { useDeliveryOrderInFlight } from '../useDeliveryOrderInFlight';

export const useNavigateToMenu = (
  requestedNavigation?: NativeStackNavigationProp<AppStackParamList>,
) => {
  const { handleGoToMenu, handleGoToDeliveryMenu } =
    useMenuNavigation(requestedNavigation);

  // ─── In Flight Delivery Orders ───────────────────────────────────

  const { inFlightDeliveryOrderId, navigateToDeliveryOrderInFlightScreen } =
    useDeliveryOrderInFlight({ requestPolicy: 'cache-only' });

  return useCallback(
    ({
      orderType,
      restaurantSlug = '',
      deliveryOrderDetail = undefined,
      referrer,
    }: UseNavigateToMenuProps) => {
      if (['pickup', 'outpost'].includes(orderType)) {
        void handleGoToMenu(restaurantSlug, referrer);

        return;
      }

      // Block going to delivery menu when there is an in-flight delivery order.
      if (inFlightDeliveryOrderId) {
        navigateToDeliveryOrderInFlightScreen();

        return;
      }

      const deliveryType = deliveryOrderDetail as Readonly<{
        address: { id: string };
      }>;
      const addressId =
        deliveryOrderDetail?.addressId ?? deliveryType?.address?.id;

      if (orderType === 'delivery' && addressId) {
        void handleGoToDeliveryMenu(addressId, referrer);
      }
    },
    [
      inFlightDeliveryOrderId,
      handleGoToDeliveryMenu,
      handleGoToMenu,
      navigateToDeliveryOrderInFlightScreen,
    ],
  );
};

export type UseNavigateToMenuProps = Readonly<{
  orderType: AddressType;
  restaurantId?: string;
  restaurantSlug?: string;
  restaurantName?: string;
  deliveryAddressName?: string;
  deliveryOrderDetail?: Partial<DeliveryOrderDetailInput>;
  referrer?: MenuScreenReferrer;
}>;
