/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LastPlacedOrderDetailsQueryVariables = Types.Exact<{
  input: Types.OrdersInput;
}>;

export type LastPlacedOrderDetailsQuery = {
  readonly __typename?: 'Query';
  readonly orders:
    | {
        readonly __typename: 'OrdersResponseSuccess';
        readonly orders: ReadonlyArray<{
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly name: string;
            readonly isOutpost: boolean;
          };
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
          } | null;
        }>;
      }
    | { readonly __typename?: 'ValidationError' };
};

export const LastPlacedOrderDetailsDocument = gql`
  query LastPlacedOrderDetails($input: OrdersInput!) {
    orders(input: $input) {
      ... on OrdersResponseSuccess {
        __typename
        orders {
          id
          __typename
          orderType
          restaurant {
            id
            __typename
            name
            isOutpost
          }
          deliveryOrderDetail {
            id
            __typename
          }
        }
      }
    }
  }
`;

export function useLastPlacedOrderDetailsQuery(
  options: Omit<
    Urql.UseQueryArgs<LastPlacedOrderDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LastPlacedOrderDetailsQuery,
    LastPlacedOrderDetailsQueryVariables
  >({ query: LastPlacedOrderDetailsDocument, ...options });
}
