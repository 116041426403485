import * as React from 'react';
import type { ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  BoundScrollProvider,
  ModalProvider,
  NoticeBannersStackProvider,
} from '@sg/garnish';

import { BrazeProvider } from '@order/Braze';
import { ChallengesAndRewardsProvider } from '@order/ChallengesAndRewards';
import { CustomerProvider } from '@order/Customer';
import { GlobalAppState } from '@order/GlobalAppState';
import { LaunchDarklyProvider } from '@order/LaunchDarkly';
import { LocalizationProvider } from '@order/Localization';
import { OneSignalProvider } from '@order/OneSignal';
import { TelemetryProvider } from '@order/Telemetry';

export const Providers = (props: Pick<ViewProps, 'children'>) => (
  <SafeAreaProvider>
    <GestureHandlerRootView style={StyleSheet.absoluteFill}>
      <LocalizationProvider>
        <NoticeBannersStackProvider>
          <LaunchDarklyProvider>
            <TelemetryProvider>
              <GlobalAppState>
                <ModalProvider>
                  <CustomerProvider>
                    <BrazeProvider>
                      <OneSignalProvider>
                        <ChallengesAndRewardsProvider>
                          <BoundScrollProvider>
                            {props.children}
                          </BoundScrollProvider>
                        </ChallengesAndRewardsProvider>
                      </OneSignalProvider>
                    </BrazeProvider>
                  </CustomerProvider>
                </ModalProvider>
              </GlobalAppState>
            </TelemetryProvider>
          </LaunchDarklyProvider>
        </NoticeBannersStackProvider>
      </LocalizationProvider>
    </GestureHandlerRootView>
  </SafeAreaProvider>
);
