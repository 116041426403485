/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { LineItemNotFoundFieldsFragmentDoc } from '../../ProductDetailsScreen/GraphQL/LineItemDetailsScreen.getLineItem.generated';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetSharedLineItemQueryVariables = Types.Exact<{
  input: Types.LineItemBySlugInput;
}>;

export type GetSharedLineItemQuery = {
  readonly __typename?: 'Query';
  readonly lineItemBySlug:
    | {
        readonly __typename: 'LineItemNotFound';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'LineItemSuccess';
        readonly lineItem: {
          readonly __typename: 'LineItem';
          readonly id: string;
          readonly slug: string;
          readonly quantity: number;
          readonly customName: string | null;
          readonly customerName: string;
          readonly isCustom: boolean;
          readonly dressingMode: Types.DressingMode;
          readonly product: {
            readonly __typename: 'Product';
            readonly id: string;
            readonly name: string;
            readonly calories: number;
            readonly cost: number;
            readonly isCustom: boolean;
            readonly description: string;
            readonly label: {
              readonly __typename: 'ProductLabel';
              readonly id: string;
              readonly name: string;
            } | null;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
            readonly dietaryProperties: ReadonlyArray<{
              readonly __typename: 'DietaryProperty';
              readonly id: string;
              readonly name: Types.DietaryPropertyKind;
            }>;
            readonly restaurant: {
              readonly __typename: 'Restaurant';
              readonly id: string;
              readonly slug: string;
              readonly name: string;
            };
          };
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      }
    | null;
};

export const GetSharedLineItemDocument = gql`
  query GetSharedLineItem($input: LineItemBySlugInput!) {
    lineItemBySlug(input: $input) {
      __typename
      ... on LineItemSuccess {
        lineItem {
          id
          __typename
          slug
          quantity
          customName
          customerName
          isCustom
          product {
            id
            __typename
            name
            calories
            cost
            isCustom
            label {
              id
              __typename
              name
            }
            description
            asset {
              url
            }
            dietaryProperties {
              id
              __typename
              name
            }
            restaurant {
              id
              __typename
              slug
              name
            }
          }
          dressingMode
        }
      }
      ...LineItemNotFoundFields
      ...ValidationErrorFields
    }
  }
  ${LineItemNotFoundFieldsFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
`;

export function useGetSharedLineItemQuery(
  options: Omit<Urql.UseQueryArgs<GetSharedLineItemQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetSharedLineItemQuery, GetSharedLineItemQueryVariables>(
    { query: GetSharedLineItemDocument, ...options },
  );
}
