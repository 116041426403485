import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useFocusEffect } from '@react-navigation/native';
import { useNoticeBannersStackContext } from '@sg/garnish';
import type { DietaryPropertyKind } from '@sg/graphql-schema';

import {
  AccountScreenContainer,
  AccountScreenSection,
  AccountScreenTitle,
} from '@order/components';
import { MenuDietaryPreferences } from '@order/components/menu';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useDietaryRestrictionsMachine } from '../../components';

export const AccountDietaryRestrictionsScreen = () => {
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const { formatMessage } = useIntl();

  const {
    sortedRestrictionsNames,
    restrictions,
    isInitializingRestrictions,
    isUpdatingRestrictions,
    confirmRestrictions,
    toggleRestriction,
    revertRestrictions,
  } = useDietaryRestrictionsMachine();

  // ─── Flags ───────────────────────────────────────────────────────

  const isRemoteDietaryRestrictionsEnabled = useFeatureFlag(
    'CELS-3623-remote-dietary-restrictions-enabled',
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const confirmDietaryRestrictions = useCallback(() => {
    confirmRestrictions();

    // Do not preemptively show success banner when the restrictions are updated via network call.
    if (!isRemoteDietaryRestrictionsEnabled) {
      addNoticeBanner({
        text: formatMessage(messages.updateRestrictionsSuccess),
        palette: 'success',
      });
    }
  }, [
    isRemoteDietaryRestrictionsEnabled,
    confirmRestrictions,
    addNoticeBanner,
    formatMessage,
  ]);

  // revert unconfirmed dietary restrictions on screen blur
  useFocusEffect(useCallback(() => revertRestrictions, [revertRestrictions]));

  // ─────────────────────────────────────────────────────────────────

  return (
    <AccountScreenContainer screen="dietary-restrictions">
      <AccountScreenTitle title={formatMessage(messages.title)} />

      <AccountScreenSection>
        <MenuDietaryPreferences.Container withoutHorizontalSpacing>
          <MenuDietaryPreferences.Description />

          <MenuDietaryPreferences.ButtonsContainer>
            {sortedRestrictionsNames.map((restriction) => (
              <MenuDietaryPreferences.Button<DietaryPropertyKind>
                key={restriction}
                restriction={restriction}
                isLoading={isInitializingRestrictions}
                isChecked={restrictions[restriction]}
                isDisabled={isUpdatingRestrictions}
                toggleRestriction={toggleRestriction}
              />
            ))}
          </MenuDietaryPreferences.ButtonsContainer>

          <MenuDietaryPreferences.Notices />

          <MenuDietaryPreferences.SubmitButton
            isLoading={isUpdatingRestrictions}
            onPress={confirmDietaryRestrictions}
          />
        </MenuDietaryPreferences.Container>
      </AccountScreenSection>
    </AccountScreenContainer>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Dietary restrictions',
    description: 'Dietary Restrictions | Title',
  },
  updateRestrictionsSuccess: {
    defaultMessage: 'Your dietary restrictions are saved!',
    description: 'Dietary Restrictions | Update | Success',
  },
});
