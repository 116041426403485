/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type BagLoyaltyRewardsDeferredQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type BagLoyaltyRewardsDeferredQuery = {
  readonly __typename?: 'Query';
  readonly cart: {
    readonly __typename: 'Order';
    readonly id: string;
    readonly availableRewards: ReadonlyArray<{
      readonly __typename: 'Reward';
      readonly id: string;
      readonly name: string;
      readonly expirationDate: string | null;
      readonly rewardType: Types.RewardType;
      readonly redeemable: boolean;
      readonly redeemableAt: string | null;
      readonly redemptionChannel: Types.RedemptionChannel;
      readonly terms: string;
      readonly assetUrl: string | null;
    }> | null;
    readonly eligiblePointRewards: ReadonlyArray<{
      readonly __typename: 'PointsReward';
      readonly id: string;
      readonly points: number;
      readonly redeemable: boolean;
      readonly assetUrl: string | null;
      readonly name: string;
    }> | null;
  } | null;
};

export const BagLoyaltyRewardsDeferredDocument = gql`
  query BagLoyaltyRewardsDeferred {
    cart {
      id
      __typename
      availableRewards {
        id
        __typename
        name
        expirationDate
        rewardType
        redeemable
        redeemableAt
        redemptionChannel
        terms
        assetUrl
      }
      eligiblePointRewards {
        id
        __typename
        name: displayName
        points
        redeemable
        assetUrl
      }
    }
  }
`;

export function useBagLoyaltyRewardsDeferredQuery(
  options?: Omit<
    Urql.UseQueryArgs<BagLoyaltyRewardsDeferredQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    BagLoyaltyRewardsDeferredQuery,
    BagLoyaltyRewardsDeferredQueryVariables
  >({ query: BagLoyaltyRewardsDeferredDocument, ...options });
}
