import {
  PinPromptConfirmButton,
  PinPromptHeading,
  PinPromptInputDisplay,
  PinPromptKeypad,
  PinPromptModal,
} from './components';

export const PinPrompt = {
  Modal: PinPromptModal,
  Heading: PinPromptHeading,
  InputDisplay: PinPromptInputDisplay,
  Keypad: PinPromptKeypad,
  ConfirmButton: PinPromptConfirmButton,
};
