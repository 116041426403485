/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GiftCardOrderDetailQueryVariables = Types.Exact<{
  orderId: Types.Scalars['String']['input'];
}>;

export type GiftCardOrderDetailQuery = {
  readonly __typename?: 'Query';
  readonly giftCardOrderDetail:
    | {
        readonly __typename: 'GiftCardOrderDetail';
        readonly id: string;
        readonly deliveryDetail: {
          readonly __typename?: 'GiftCardDeliveryDetail';
          readonly deliveryDateTime: string | null;
        };
        readonly userGiftCards: ReadonlyArray<{
          readonly __typename: 'UserGiftCard';
          readonly id: string;
          readonly redemptionToken: string | null;
          readonly balance: number;
          readonly assetId: string | null;
          readonly recipientEmail: string | null;
          readonly recipientName: string;
          readonly message: string | null;
        }>;
      }
    | {
        readonly __typename: 'GiftCardOrderNotFoundError';
        readonly errorMessage: string;
      };
};

export const GiftCardOrderDetailDocument = gql`
  query GiftCardOrderDetail($orderId: String!) {
    giftCardOrderDetail(orderId: $orderId) {
      __typename
      ... on GiftCardOrderNotFoundError {
        errorMessage
      }
      ... on GiftCardOrderDetail {
        id
        deliveryDetail {
          deliveryDateTime
        }
        userGiftCards {
          id
          __typename
          redemptionToken
          balance
          assetId
          recipientEmail
          recipientName
          message
        }
      }
    }
  }
`;

export function useGiftCardOrderDetailQuery(
  options: Omit<Urql.UseQueryArgs<GiftCardOrderDetailQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    GiftCardOrderDetailQuery,
    GiftCardOrderDetailQueryVariables
  >({ query: GiftCardOrderDetailDocument, ...options });
}
