import React, { memo, useMemo } from 'react';
import { useInterpret } from '@xstate/react';
import { useActorRef } from '@xstate5/react';
import type { ActorRefFrom } from 'xstate';
import type { ActorRefFrom as ActorRefFromV5 } from 'xstate5';
import { networkMonitorMachine } from '@sg/garnish';

import {
  postPurchaseModalMachine,
  useInitLoyaltyOffersModalMachine,
} from '@order/features/loyalty';
import { useDynamicFeatureFlags } from '@order/LaunchDarkly';

import type { dietaryRestrictionsMachine } from '../../components/DietaryRestrictions/dietaryRestrictionsMachine/dietaryRestrictionsMachine';
import { useDietaryRestrictionsInterpret } from '../../components/DietaryRestrictions/dietaryRestrictionsMachine/useDietaryRestrictionsInterpret';
import { cartMachine } from '../../hooks/useCart/Cart.machine/Cart.machine';
import { reorderMachine } from '../../screens/ReorderingScreen/machines/Reorder.machine';
import type { AuthMachineRefType } from '../AuthMachine/useInitAuthMachine';
import { useInitAuthMachine } from '../AuthMachine/useInitAuthMachine';
import { useAzureAuthService } from '../AzureAuth';
import type { LastInteractedStoreMachineRefType } from '../LastInteractedStore/machine';
import { useInitLastInteractedStoreMachine } from '../LastInteractedStore/machine';
import { placedOrdersCounterMachine } from '../PlacedOrdersCounter/PlacedOrdersCounter.machine';

//
// ─── CONTEXT SETUP ──────────────────────────────────────────────────────────────
//

const INITIAL_CONTEXT_VALUE = undefined;

const GlobalAppStateContext = React.createContext<ContextValue>(
  INITIAL_CONTEXT_VALUE,
);

// TYPES
type ContextValue = typeof INITIAL_CONTEXT_VALUE | GlobalAppStateContextType;

type GlobalAppStateContextType = Readonly<{
  authMachineRef: AuthMachineRefType;
  loyaltyOffersModalMachineRef: ReturnType<
    typeof useInitLoyaltyOffersModalMachine
  >;
  lastInteractedStoreMachineRef: LastInteractedStoreMachineRefType;
  postPurchaseModalMachineRef: ActorRefFromV5<typeof postPurchaseModalMachine>;
  cartMachineRef: ActorRefFrom<typeof cartMachine>;
  networkMonitorMachineRef: ActorRefFrom<typeof networkMonitorMachine>;
  reorderMachineRef: ActorRefFrom<typeof reorderMachine>;
  placedOrderCounterMachineRef: ActorRefFrom<typeof placedOrdersCounterMachine>;
  dietaryRestrictionsMachineRef: ActorRefFrom<
    typeof dietaryRestrictionsMachine
  >;
  azureAuthMachineRef: ReturnType<typeof useAzureAuthService>;
  dynamicFeatureFlags: ReturnType<typeof useDynamicFeatureFlags>;
}>;

//
// ─── PROVIDER ────────────────────────────────────────────────────────────────
//

const useInterpretedMachineRefs = () => {
  return {
    authMachineRef: useInitAuthMachine(),
    lastInteractedStoreMachineRef: useInitLastInteractedStoreMachine(),
    dietaryRestrictionsMachineRef: useDietaryRestrictionsInterpret(),
    loyaltyOffersModalMachineRef: useInitLoyaltyOffersModalMachine(),
    cartMachineRef: useInterpret(cartMachine),
    networkMonitorMachineRef: useInterpret(networkMonitorMachine),
    reorderMachineRef: useInterpret(reorderMachine),
    placedOrderCounterMachineRef: useInterpret(placedOrdersCounterMachine),
    azureAuthMachineRef: useAzureAuthService(),
    postPurchaseModalMachineRef: useActorRef(postPurchaseModalMachine),
    dynamicFeatureFlags: useDynamicFeatureFlags(),
  };
};

export const GlobalAppState = memo((props: GlobalAppStateProps) => {
  const machineRefs = useInterpretedMachineRefs();
  const contextValue = useMemo(() => machineRefs, [machineRefs]);

  return (
    <GlobalAppStateContext.Provider value={contextValue}>
      {props.children}
    </GlobalAppStateContext.Provider>
  );
});

type GlobalAppStateProps = Readonly<{
  children: React.ReactNode;
}>;

//
// ─── HOOK ────────────────────────────────────────────────────────────────────
//

/**
 * CAUTION: Prefer more targeted hooks.
 * @see {@link: https://twitter.com/kentcdodds/status/1121440768277663745?lang=en}
 * >I never expose my context object to other modules, instead I create a custom
 *  Provider component and a custom hook consumer and expose those.
 *
 * NOTE: This is only intended to be used by other useContext-derived hooks eg useBagMachine
 */
export const useGlobalAppState = () => {
  const context = React.useContext(GlobalAppStateContext);

  if (!context) {
    throw new Error('cannot be rendered outside the <GlobalAppState />');
  }

  return context;
};
