import React from 'react';
import { ModalBackdrop } from '@sg/garnish';

import { NotFoundView, SierraHostedFrame } from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useNavigateBack } from '../../navigation';

export const ChatScreen = () => {
  const isSierraEnabled = useFeatureFlag('permanent-sierra-enabled');

  if (!isSierraEnabled) return <NotFoundView />;

  return <SierraChatScreen />;
};

const SierraChatScreen = () => {
  const navigateBack = useNavigateBack();

  return (
    <>
      <SierraHostedFrame onClose={navigateBack} />
      <ModalBackdrop onPress={navigateBack} />
    </>
  );
};
