import { useEffect } from 'react';

import { type BagReward } from '../../machines/ordering-machine.types';
import { useBagLoyaltyRewardsDeferredQuery } from './graphql/BagLoyaltyRewardsDeferred.generated';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Fetches available loyalty rewards and synchronizes results.
 *
 * NOTE: There is an issue with the urql core's `.toPromise` method which
 *       prevents it from correctly handling scenarios where many requests for
 *       the same typename are being made in parallel.
 *
 *       URQL just cancels one of the requests, which can lead to unexpected results.
 *
 *       This hook makes use of the standard `useQuery` hook, which correctly
 *       handles multiple requests at once.
 *
 *       Instead of using a service approach, we simply notify the state machine
 *       of new data or a failure and repeat the same behavior as in a service.
 */
export const useBagLoyaltyRewardsDeferred = (
  params: UseBagLoyaltyRewardsDeferredParams,
) => {
  const { shouldFetchRewards, onSuccess, onFailure } = params;

  // ─── Remote Data ───────────────────────────────────────────────────────────

  const [response, refetch] = useBagLoyaltyRewardsDeferredQuery({
    requestPolicy: 'network-only',
    pause: true,
  });
  const { fetching, stale, data, error } = response;

  // ─── Flags ───────────────────────────────────────────────────────────

  const hasFinishedFetching = !fetching && !stale;
  const hasResolvedData = hasFinishedFetching && error === undefined && data !== undefined; // prettier-ignore
  const hasFailedToResolvedData = hasFinishedFetching && error !== undefined;

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!shouldFetchRewards) return;

    refetch();
  }, [shouldFetchRewards, refetch]);

  useEffect(() => {
    if (!hasResolvedData) return;

    const hasNoData = data?.cart === undefined;

    if (hasNoData) {
      onFailure();

      return;
    }

    const nonPointsRewards = data?.cart?.availableRewards ?? [];
    const pointsRewards = data?.cart?.eligiblePointRewards ?? [];
    const rewards = [...nonPointsRewards, ...pointsRewards] as BagReward[];

    onSuccess(rewards);
  }, [data?.cart, hasResolvedData, onFailure, onSuccess]);

  useEffect(() => {
    if (!hasFailedToResolvedData) return;

    onFailure();
  }, [hasFailedToResolvedData, onFailure]);
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseBagLoyaltyRewardsDeferredParams = {
  shouldFetchRewards: boolean;
  onSuccess: (rewards: BagReward[]) => void;
  onFailure: () => void;
};
