import type { ComponentProps } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';

import { HorizontalScrollRailHeader } from '../../HorizontalScrollRail/subcomponents';
import { Image } from '../../Image';
import { BodyText } from '../../Text';

export const LoyaltyRewardsRailEmptyState = ({
  image,
  description,
  headerText,
  headerVariation,
  subtitle,
}: LoyaltyRewardsRailEmptyStateProps) => {
  const nextItemIndexSV = useSharedValue(-1);
  const prevItemIndexSV = useSharedValue(-1);

  return (
    <View>
      <HorizontalScrollRailHeader
        count={0}
        heading={headerText}
        headerVariation={headerVariation}
        subtitle={subtitle}
        nextItemIndexSV={nextItemIndexSV}
        prevItemIndexSV={prevItemIndexSV}
      />
      <View style={styles.wrapper}>
        <Image style={styles.image} source={image} contentFit="contain" />
        <BodyText size={3}>{description}</BodyText>
      </View>
    </View>
  );
};

// ─── STYLES ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 220,
    height: 130,
  },
});

// ─── TYPES ─────────────────────────────────────────────────────────────────

type LoyaltyRewardsRailEmptyStateProps = Readonly<{
  image: ComponentProps<typeof Image>['source'];
  description: string;
  headerText: string;
  headerVariation: ComponentProps<
    typeof HorizontalScrollRailHeader
  >['headerVariation'];
  subtitle?: string;
}>;
