import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import {
  BodyText,
  Form,
  TEST_GRECAPTCHA,
  TextLink,
  theme,
  useNoticeBannersStackContext,
  useReCaptcha,
} from '@sg/garnish';

import { PageWrapper } from '@order/components';
import { useFeatureFlag } from '@order/LaunchDarkly';
import type { TranslationKeys } from '@order/Localization';
import { useLocalizationContext } from '@order/Localization';
import {
  checkIfRecaptchaEnabled,
  formatBirthdayForSubmit,
  getMonthNames,
} from '@order/utils';

import { useJoinForm } from '../../hooks';
import type { JoinFormData } from '../../utils';
import { TermsAndConditions } from '../TermsAndConditions';

export const JoinForm = (props: JoinFormProps) => {
  const {
    predefinedEmail,
    isBirthdayRequired = false,
    hasPassword = false,
    shouldUseRecaptcha = false,
    isLoading,
    onPressJoin,
    onPressSignIn,
    onPressUseDifferentAccount,
  } = props;

  const { t } = useLocalizationContext();
  const { push: addNoticeBanner } = useNoticeBannersStackContext();
  const isZipCodeFieldEnabled = useFeatureFlag(
    'CELS-3186-enable-zip-code-field',
    { listenForChanges: true },
  );

  // ─── ReCaptcha ───────────────────────────────────────────────────

  const isRecaptchaEnabled = checkIfRecaptchaEnabled() && shouldUseRecaptcha;
  const { reCaptchaMachine, reCaptchaRef } = useReCaptcha({
    isEnabled: isRecaptchaEnabled,
  });

  const [reCaptchaState] = reCaptchaMachine;
  const isLoadingRecaptcha = reCaptchaState.matches('loading');
  const isExecutingRecaptcha = reCaptchaState.matches('executing');

  // ────────────────────────────────────────────────────────────────────

  const { form } = useJoinForm({
    email: predefinedEmail,
    isBirthdayRequired,
    hasPassword,
    hasZipCode: isZipCodeFieldEnabled,
  });
  const { control, formState, handleSubmit } = form;
  const { errors, isValid, isSubmitting } = formState;

  // ─── Helpers ────────────────────────────────────────────────────────

  const signUpUser = useCallback(
    async (signUpFormData: JoinFormData) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        terms,
        birthday = '',
        grecaptcha = TEST_GRECAPTCHA,
        ...formData
      } = signUpFormData;

      const formattedBirthday = formatBirthdayForSubmit(birthday);

      await onPressJoin({
        ...formData,
        ...(formattedBirthday ? { birthday: formattedBirthday } : {}),
        terms: true,
        grecaptcha,
      });

      // reset recaptcha after request
      reCaptchaRef.current?.resetRecaptcha();
    },
    [onPressJoin, reCaptchaRef],
  );

  const onRecaptchaVerify = useCallback(
    async (grecaptcha: string) => {
      if (!grecaptcha) return;

      const formValues = form.getValues();

      await signUpUser({ ...formValues, grecaptcha });
    },
    [form, signUpUser],
  );

  const onRecaptchaError = useCallback(() => {
    addNoticeBanner({ text: t('general.error'), palette: 'caution' });
  }, [addNoticeBanner, t]);

  const submitForm = useMemo(() => {
    return handleSubmit(async (formData) => {
      await (isRecaptchaEnabled
        ? reCaptchaRef.current?.executeRecaptcha()
        : signUpUser(formData));
    });
  }, [handleSubmit, isRecaptchaEnabled, reCaptchaRef, signUpUser]);

  // ────────────────────────────────────────────────────────────────────

  const monthNames = getMonthNames().map((key) => t(key as TranslationKeys));

  // ────────────────────────────────────────────────────────────────────

  const shouldShowLoadingSubmitBtn =
    isSubmitting || isLoading || isExecutingRecaptcha;
  const shouldDisableSubmitBtn = isLoadingRecaptcha || !isValid;

  // ─────────────────────────────────────────────────────────────────

  return (
    <PageWrapper>
      <Form.Container
        reCaptchaRef={isRecaptchaEnabled ? reCaptchaRef : undefined}
        reCaptchaMachine={isRecaptchaEnabled ? reCaptchaMachine : undefined}
        onRecaptchaVerify={isRecaptchaEnabled ? onRecaptchaVerify : undefined}
        onRecaptchaError={isRecaptchaEnabled ? onRecaptchaError : undefined}
      >
        <Form.Title>{t('join.title')}</Form.Title>

        {onPressSignIn ? (
          <Form.Subtitle direction="row">
            <BodyText size={3}>{t('join.subtitle')} </BodyText>

            <TextLink
              accessibilityLabel={t('login.title')}
              onPress={onPressSignIn}
            >
              {t('login.title')}
            </TextLink>
          </Form.Subtitle>
        ) : (
          <View style={styles.titleSpacingPlaceholder} />
        )}

        {/* ========== first name ========== */}

        <Form.Group>
          <Form.TextField
            name="firstName"
            control={control}
            rules={{ required: true }}
            label={t('form.first-name')}
            hideSubmitButton
            onSubmit={submitForm}
            autoComplete="name-given"
            autoCapitalize="words"
            clearButtonType="icon"
            notice={errors.firstName?.message}
            disabled={isLoading}
            testID="join-form.first-name"
          />
        </Form.Group>

        {/* ========== last name ========== */}

        <Form.Group>
          <Form.TextField
            name="lastName"
            control={control}
            rules={{ required: true }}
            label={t('form.last-name')}
            hideSubmitButton
            onSubmit={submitForm}
            autoComplete="name-family"
            autoCapitalize="words"
            clearButtonType="icon"
            notice={errors.lastName?.message}
            disabled={isLoading}
            testID="join-form.last-name"
          />
        </Form.Group>

        {/* ========== email ========== */}

        <Form.Group>
          <Form.TextField
            name="email"
            control={control}
            rules={{ required: true }}
            keyboardType="email-address"
            label={t('form.email')}
            hideSubmitButton
            onSubmit={submitForm}
            autoComplete="email"
            clearButtonType="icon"
            notice={errors.email?.message}
            disabled={Boolean(predefinedEmail) || isLoading}
          />
        </Form.Group>

        {/* ========== phone number ========== */}

        <Form.Group>
          <Form.TextFieldPhoneNumber
            name="phoneNumber"
            control={control}
            rules={{ required: true }}
            label={t('form.phone-number')}
            clearButtonType="icon"
            maxLength={10}
            hideSubmitButton
            onSubmit={submitForm}
            notice={errors.phoneNumber?.message}
            disabled={isLoading}
            helperText={t('join.phone-number-helper-text')}
            testID="join-form.phone-number"
          />
        </Form.Group>

        {/* ========== zip code ========== */}

        {isZipCodeFieldEnabled ? (
          <Form.Group>
            <Form.TextFieldZipCode
              name="zipCode"
              control={control}
              rules={{ required: true }}
              label={t('form.zip-code')}
              clearButtonType="icon"
              maxLength={8}
              hideSubmitButton
              onSubmit={submitForm}
              notice={errors.zipCode?.message}
              disabled={isLoading}
              helperText={t('join.zip-code-helper-text')}
            />
          </Form.Group>
        ) : null}

        {/* ========== password ========== */}

        {hasPassword ? (
          <Form.Group>
            <Form.TextFieldPassword
              name="password"
              control={control}
              rules={{ required: true }}
              label={t('form.password')}
              clearButtonType="icon"
              hideSubmitButton
              onSubmit={submitForm}
              autoComplete="password-new"
              notice={errors.password?.message}
              disabled={isLoading}
            />
          </Form.Group>
        ) : null}

        {/* ========== birthday ========== */}

        <Form.Group>
          <Form.BirthdayPicker
            name="birthday"
            control={control}
            label={t('form.birthday')}
            accessibilityLabel={t('form.birthday')}
            monthLabel={t('general.month')}
            dayLabel={t('general.day')}
            helperText={t(
              isBirthdayRequired
                ? 'join.birthday-helper-text-required'
                : 'join.birthday-helper-text',
            )}
            monthNames={monthNames}
          />
        </Form.Group>

        {/* ========== terms and conditions ========== */}

        <TermsAndConditions
          control={control}
          errorMessage={errors.terms?.message}
        />

        {/* ========== ========== ========== */}

        <Form.SubmitButton
          isLoading={shouldShowLoadingSubmitBtn}
          disabled={shouldDisableSubmitBtn}
          accessibilityLabel={t('join.create-account')}
          onPress={submitForm}
        >
          {t('join.create-account')}
        </Form.SubmitButton>

        {onPressUseDifferentAccount ? (
          <BodyText sizeMatch={['14']} style={styles.differentAccountContainer}>
            <FormattedMessage
              description="Join Form | Already have an account"
              defaultMessage="Already have an account?"
            />{' '}
            <TextLink
              sizeMatch={['14']}
              onPress={onPressUseDifferentAccount}
              disabled={shouldShowLoadingSubmitBtn}
            >
              <FormattedMessage
                description="Join Form | Sign in with a different email"
                defaultMessage="Sign in with a different email"
              />
            </TextLink>
          </BodyText>
        ) : null}
      </Form.Container>
    </PageWrapper>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  titleSpacingPlaceholder: {
    height: theme.spacing['8'],
  },
  differentAccountContainer: {
    marginTop: theme.spacing['10'],
    marginBottom: theme.spacing['6'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────────

type JoinFormProps = Readonly<{
  predefinedEmail?: string;
  hasZipCode?: boolean; // To be removed together with CELS-3186-enable-zip-code-field.
  isLoading?: boolean;

  onPressJoin:
    | ((payload: JoinFormData) => Promise<void>)
    | ((payload: JoinFormData) => void);
  onPressSignIn?: () => void;
  onPressUseDifferentAccount?: () => void;
}>;
