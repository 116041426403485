/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LocationDetailsFragment = {
  readonly __typename: 'StoreLocation';
  readonly id: string;
  readonly name: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly slug: string;
  readonly address: string;
  readonly city: string;
  readonly state: string;
  readonly zipCode: string;
  readonly isOutpost: boolean | null;
  readonly phone: string | null;
  readonly storeHours: string | null;
  readonly flexMessage: string | null;
  readonly enabled: boolean;
  readonly acceptingOrders: boolean;
  readonly notAcceptingOrdersReason: string | null;
  readonly imageUrl: string;
  readonly hidden: boolean;
  readonly showWarningDialog: boolean;
  readonly warningDialogDescription: string | null;
  readonly warningDialogTimeout: number | null;
  readonly warningDialogTitle: string | null;
};

export type LocationsSearchBySearchStringQueryVariables = Types.Exact<{
  searchString: Types.Scalars['String']['input'];
  showHidden?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type LocationsSearchBySearchStringQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByString: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly score: number;
    readonly location: {
      readonly __typename: 'StoreLocation';
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export type LocationsSearchBySearchStringWithDisclosureFieldsQueryVariables =
  Types.Exact<{
    searchString: Types.Scalars['String']['input'];
    showHidden?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  }>;

export type LocationsSearchBySearchStringWithDisclosureFieldsQuery = {
  readonly __typename?: 'Query';
  readonly searchLocationsByString: ReadonlyArray<{
    readonly __typename?: 'LocationSearchResult';
    readonly score: number;
    readonly location: {
      readonly __typename: 'StoreLocation';
      readonly outpostPriceDifferentiationEnabled: boolean | null;
      readonly id: string;
      readonly name: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly slug: string;
      readonly address: string;
      readonly city: string;
      readonly state: string;
      readonly zipCode: string;
      readonly isOutpost: boolean | null;
      readonly phone: string | null;
      readonly storeHours: string | null;
      readonly flexMessage: string | null;
      readonly enabled: boolean;
      readonly acceptingOrders: boolean;
      readonly notAcceptingOrdersReason: string | null;
      readonly imageUrl: string;
      readonly hidden: boolean;
      readonly showWarningDialog: boolean;
      readonly warningDialogDescription: string | null;
      readonly warningDialogTimeout: number | null;
      readonly warningDialogTitle: string | null;
    };
  } | null> | null;
};

export const LocationDetailsFragmentDoc = gql`
  fragment LocationDetails on StoreLocation {
    id
    __typename
    name
    latitude
    longitude
    slug
    address
    city
    state
    zipCode
    isOutpost
    phone
    storeHours
    flexMessage
    enabled
    acceptingOrders
    notAcceptingOrdersReason
    imageUrl
    hidden
    showWarningDialog
    warningDialogDescription
    warningDialogTimeout
    warningDialogTitle
  }
`;
export const LocationsSearchBySearchStringDocument = gql`
  query LocationsSearchBySearchString(
    $searchString: String!
    $showHidden: Boolean
  ) {
    searchLocationsByString(
      searchString: $searchString
      showHidden: $showHidden
    ) {
      score
      location {
        ...LocationDetails
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchBySearchStringQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchBySearchStringQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchBySearchStringQuery,
    LocationsSearchBySearchStringQueryVariables
  >({ query: LocationsSearchBySearchStringDocument, ...options });
}
export const LocationsSearchBySearchStringWithDisclosureFieldsDocument = gql`
  query LocationsSearchBySearchStringWithDisclosureFields(
    $searchString: String!
    $showHidden: Boolean
  ) {
    searchLocationsByString(
      searchString: $searchString
      showHidden: $showHidden
    ) {
      score
      location {
        ...LocationDetails
        outpostPriceDifferentiationEnabled
      }
    }
  }
  ${LocationDetailsFragmentDoc}
`;

export function useLocationsSearchBySearchStringWithDisclosureFieldsQuery(
  options: Omit<
    Urql.UseQueryArgs<LocationsSearchBySearchStringWithDisclosureFieldsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    LocationsSearchBySearchStringWithDisclosureFieldsQuery,
    LocationsSearchBySearchStringWithDisclosureFieldsQueryVariables
  >({
    query: LocationsSearchBySearchStringWithDisclosureFieldsDocument,
    ...options,
  });
}
