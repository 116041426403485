import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { type Entry } from 'contentful';
import { theme } from '@sg/garnish';

import { SG_REWARDS_TERMS_AND_CONDITIONS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';

export const useLoyaltyHowItWorksContent = () => {
  const { formatMessage } = useIntl();

  const content = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  const fallbackData = useMemo(
    () => [
      {
        id: 'How it works | Earn',
        title: formatMessage(fallbacks.earnTitle),
        description: formatMessage(fallbacks.earnDescription),
        backgroundColor: theme.colors.GREEN_2,
        textColor: theme.colors.NEUTRAL_6,
      },
      {
        id: 'How it works | Redeem',
        title: formatMessage(fallbacks.redeemTitle),
        description: formatMessage(fallbacks.redeemDescription),
        backgroundColor: theme.colors.SWEET_CORN,
        textColor: theme.colors.GREEN_2,
      },
      {
        id: 'How it works | Get more',
        title: formatMessage(fallbacks.getMoreTitle),
        description: formatMessage(fallbacks.getMoreDescription),
        backgroundColor: '#C0CB81',
        textColor: theme.colors.GREEN_2,
      },
    ],
    [formatMessage],
  );

  const contentfulData = content.data?.fields;

  return useMemo(() => {
    const howItWorks =
      contentfulData?.homeHowItWorksXS?.map((item) => item.fields) ??
      fallbackData;

    const termsAndConditionsUrl =
      contentfulData?.termsAndConditionsUrl ?? SG_REWARDS_TERMS_AND_CONDITIONS;

    const disclaimer = contentfulData?.homeHowItWorksDisclaimer;

    return { howItWorks, termsAndConditionsUrl, disclaimer };
  }, [contentfulData, fallbackData]);
};

// ─── Fallbacks ──────────────────────────────────────────────────────────────

const fallbacks = defineMessages({
  earnDescription: {
    defaultMessage:
      'Scan in store or order ahead to get 10 points for every $1 spent',
    description: 'Loyalty | How it works | Earn description',
  },
  redeemDescription: {
    defaultMessage: 'Use points to unlock rewards and free Sweetgreen',
    description: 'Loyalty | How it works | Rewards description',
  },
  getMoreDescription: {
    defaultMessage:
      'Look out for offers to earn more points and special rewards',
    description: 'Loyalty | How it works | Get more description',
  },
  earnTitle: {
    defaultMessage: 'Earn',
    description: 'Loyalty | How it works | Earn title',
  },
  redeemTitle: {
    defaultMessage: 'Redeem',
    description: 'Loyalty | How it works | Rewards title',
  },
  getMoreTitle: {
    defaultMessage: 'Get more',
    description: 'Loyalty | How it works | Get more title',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  homeHowItWorksXS: Entry<LoyaltyHowItWorksItem>[];
  termsAndConditionsUrl: string;
  homeHowItWorksDisclaimer: string;
};

type LoyaltyHowItWorksItem = {
  id: string;
  description: string;
  title: string;
  backgroundColor: string;
  textColor: string;
};
