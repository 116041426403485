/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SweetpassTierQueryVariables = Types.Exact<{ [key: string]: never }>;

export type SweetpassTierQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyProfile: {
    readonly __typename: 'LoyaltyProfile';
    readonly customerId: string;
    readonly loyaltyId: string | null;
    readonly joinDate: string;
    readonly tier: {
      readonly __typename: 'Tier';
      readonly id: string;
      readonly name: Types.TierName;
      readonly nextTier: {
        readonly __typename: 'Tier';
        readonly id: string;
        readonly name: Types.TierName;
      } | null;
    } | null;
    readonly tierStatus: {
      readonly __typename: 'TierStatus';
      readonly id: string;
      readonly name: Types.TierStatusName;
    } | null;
  } | null;
};

export const SweetpassTierDocument = gql`
  query SweetpassTier {
    loyaltyProfile {
      __typename
      customerId
      loyaltyId
      joinDate
      tier {
        id
        __typename
        name
        nextTier {
          id
          __typename
          name
        }
      }
      tierStatus {
        id
        __typename
        name
      }
    }
  }
`;

export function useSweetpassTierQuery(
  options?: Omit<Urql.UseQueryArgs<SweetpassTierQueryVariables>, 'query'>,
) {
  return Urql.useQuery<SweetpassTierQuery, SweetpassTierQueryVariables>({
    query: SweetpassTierDocument,
    ...options,
  });
}
