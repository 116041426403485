import { useCallback, useState } from 'react';

export const usePinPromptModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const showPinPromptModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hidePinPromptModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  return {
    showPinPromptModal,
    hidePinPromptModal,
    isPinPromptModalVisible: isVisible,
  };
};
