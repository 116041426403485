import { useMemo } from 'react';
import { type RequestPolicy } from 'urql';
import { usePrevious } from '@sg/garnish';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useLoyaltyPremiumStatusQuery } from './GraphQL/LoyaltyPremiumStatus.generated';

/**
 * Returns the loyalty premium status for this customer.
 * Should be called with `cache-and-network` on app load.
 * Should be called without params or with `cache-only` everywhere else.
 * Also checks the `CELS-3031-sg-rewards-premium-enabled` feature flag.
 */
export const useLoyaltyPremiumStatus = (
  params: UseLoyaltyPremiumStatusParams = {},
) => {
  const { requestPolicy = 'cache-only' } = params;

  const isLoggedIn = useIsLoggedIn();
  const isPremiumStatusEnabled = useFeatureFlag(
    'CELS-3031-sg-rewards-premium-enabled',
  );

  const [response] = useLoyaltyPremiumStatusQuery({
    requestPolicy,
    pause: !isLoggedIn || !isPremiumStatusEnabled,
  });

  const isFetchingPremiumStatus = response.fetching;
  const isPremiumStatusStale = response.stale;

  const prevIsPremiumStatusStale = usePrevious(isPremiumStatusStale);
  const prevIsFetchingPremiumStatus = usePrevious(isFetchingPremiumStatus);

  // This flag indicates that the latest date has been resolved.
  // Useful for sending dynamic events based on the current state.
  const hasResolvedPremiumStatus =
    (prevIsPremiumStatusStale && !isPremiumStatusStale) ||
    (prevIsFetchingPremiumStatus && !isFetchingPremiumStatus);

  const status = response.data?.loyaltyProfile?.rewardsPremiumStatus;

  const isPremium = Boolean(isPremiumStatusEnabled && status?.isActive);
  const premiumExpiration = isPremium ? status?.expirationDate : undefined;

  return useMemo(
    () => ({
      isFetchingPremiumStatus,
      hasResolvedPremiumStatus,
      isPremium,
      premiumExpiration,
    }),
    [
      hasResolvedPremiumStatus,
      isFetchingPremiumStatus,
      isPremium,
      premiumExpiration,
    ],
  );
};

// ─── Types ──────────────────────────────────────────────────────────────────

type UseLoyaltyPremiumStatusParams = {
  requestPolicy?: RequestPolicy;
};
