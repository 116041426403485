export const navbar = {
  'nav-bar.a11y.label': 'navigation bar for {routeName} page',
  // Menu
  'nav-bar.menu.title': 'Menu',
  'nav-bar.menu.title-named': 'Menu: {name}',
  // Sweetpass
  'nav-bar.sweetpass.title': 'sweetpass',
  // Loyalty
  'nav-bar.loyalty.title': 'Loyalty',
  // Scan
  'nav-bar.scan.title': 'Scan',
  // Home / Sweetgreen Logo
  'nav-bar.home.title': 'Home',
  'nav-bar.home.accessibility-label': 'Home',
  // Account
  'nav-bar.account.title': 'Account',
  // Bag
  'nav-bar.bag.accessibility-label': 'Header | Bag',
  // Dietary Restrictions
  'nav-bar.dietary-restrictions.accessibility-label': 'Dietary Restrictions',
  'nav-bar.dietary-restrictions.accessibility-hint':
    'Opens dietary preferences',

  'nav-bar.gift-cards.title': 'Gift',
};
