import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive, webOnlyStyles } from '@sg/garnish';

export const SidePanelLayout = (props: PropsWithChildren) => {
  const { match } = useResponsive();

  const style = [
    styles.sidePanel,
    match([null, styles.sidePanelWidthSM, styles.sidePanelWidthLG]),
    webOnlyStyles({ userSelect: 'auto' }),
  ];

  return <View style={style}>{props.children}</View>;
};
// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  sidePanel: {
    borderLeftColor: theme.colors.BLACK,
    borderLeftWidth: 1,
  },
  sidePanelWidthSM: {
    width: 376,
  },
  sidePanelWidthLG: {
    width: 478,
  },
});
