import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LabelText } from '../../Text';

export const HorizontalScrollRailCount = ({
  count,
}: HorizontalScrollRailCountProps) => {
  if (count === 0) return null;

  return (
    <View style={styles.countContainer}>
      <LabelText size={1}>{count}</LabelText>
    </View>
  );
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type HorizontalScrollRailCountProps = Readonly<{
  count: number;
}>;

// ─── STYLES ──────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  countContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 28,
    borderWidth: 1,
    width: 28,
    height: 28,
    borderColor: theme.colors.BLACK,
  },
});
