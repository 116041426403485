import { type ComponentProps, useCallback } from 'react';
import { useSelector } from '@xstate/react';

import { type JoinOrSignInForm } from '../../JoinOrSignInForm';
import { useJoinOrSignInFrameMessages } from '../hooks';
import { useJoinOrSignInService } from './useJoinOrSignInService';

// ─────────────────────────────────────────────────────────────────────────────

export const useJoinOrSignIn = (params: UseJoinOrSignInParams) => {
  const { onFrameHeightChange } = params;

  const joinOrSignInService = useJoinOrSignInService();

  const handleIncomingMessage = useJoinOrSignInFrameMessages({
    joinOrSignInService,
    onFrameHeightChange,
  });

  const states = useSelector(joinOrSignInService, ({ matches }) => {
    return {
      isLoadingEmailAddress: matches('waiting'),
      isEmailAddressView:
        matches('entering-email-address') ||
        matches('waiting') ||
        matches('verification-code-loading'),
      isLoadingVerificationCode:
        matches('verification-code-loading') || matches('verification'),
      isVerificationCodeView:
        matches('entering-verification-code') || matches('verification'),
      isFillAccountDataView:
        matches('filling-account-data') ||
        matches('refreshing-access-tokens') ||
        matches('creating-account'),
      isSigningIn: matches('signing-in'),
      isCreatingAccount:
        matches('refreshing-access-tokens') || matches('creating-account'),
      isAuthenticationFailed: matches('authentication-failed'),
      shouldRedirect: matches('redirect'),
    };
  });

  const context = useSelector(joinOrSignInService, (state) => state.context);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const submitAccountData = useCallback<
    ComponentProps<typeof JoinOrSignInForm>['onSubmit']
  >(
    (payload) => {
      joinOrSignInService.send('SUBMIT_ACCOUNT_DATA', {
        input: {
          firstName: payload.firstName,
          lastName: payload.lastName,
          phoneNumber: payload.phoneNumber,
          birthday: payload.birthday,
          zipCode: payload.zipCode,
        },
      });
    },
    [joinOrSignInService],
  );

  const restartAuthenticationFlow = useCallback(() => {
    joinOrSignInService.send('RESTART');
  }, [joinOrSignInService]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    states,
    context,
    submitAccountData,
    handleIncomingMessage,
    restartAuthenticationFlow,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type UseJoinOrSignInParams = {
  onFrameHeightChange: (height: number) => void;
};
