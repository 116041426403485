import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  ScrollView,
  StyleSheet,
  type TextStyle,
  type ViewProps,
} from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { useStyle } from 'react-native-style-utilities';
import {
  CustomNavigationHeader,
  DisplayText,
  theme,
  useResponsive,
} from '@sg/garnish';

export const LoyaltyHomeContainer = (props: LoyaltyHomeContainerProps) => {
  const { children, style } = props;
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return <LoyaltyHomeContainerXs {...props} />;
  }

  return (
    <ScrollView style={style}>
      <SafeAreaView edges={['top']}>{children}</SafeAreaView>
    </ScrollView>
  );
};

const LoyaltyHomeContainerXs = (props: LoyaltyHomeContainerProps) => {
  const { availablePoints, style, palette, children } = props;

  const { formatNumber } = useIntl();
  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffsetSV = useScrollViewOffset(animatedRef);

  const { top } = useSafeAreaInsets();
  const topInset = useStyle(() => ({ paddingTop: top }), [top]);
  const floatingHeader = useStyle(
    () => ({
      marginBottom: -(theme.spacing['18'] + top),
    }),
    [top],
  );

  const headerStyle = [styles.header, floatingHeader];

  return (
    <Animated.ScrollView
      ref={animatedRef}
      style={style}
      contentContainerStyle={styles.container}
      stickyHeaderIndices={[0]}
    >
      <CustomNavigationHeader.Container
        safeAreaEdges={[]}
        style={headerStyle}
        scrollOffsetSV={scrollOffsetSV}
        palette={palette}
      >
        <CustomNavigationHeader.Content
          style={topInset}
          scrollOffsetSV={scrollOffsetSV}
        >
          <DisplayText
            style={[styles.headerText, textPalettes[palette]]}
            sizeMatch={['24']}
          >
            <FormattedMessage
              {...messages.header}
              values={{ available_points: formatNumber(availablePoints) }}
            />
          </DisplayText>
        </CustomNavigationHeader.Content>
      </CustomNavigationHeader.Container>

      <SafeAreaView edges={['top']}>{children}</SafeAreaView>
    </Animated.ScrollView>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  header: {
    defaultMessage: '{available_points} points',
    description: 'Loyalty | Home | Container | Header',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  header: {
    marginHorizontal: -theme.spacing['14'],
  },
  headerText: {
    paddingVertical: theme.spacing['4'],
    marginBottom: -theme.spacing['1'],
  },
  darkKaleText: {
    color: theme.colors.DARK_KALE,
  },
  creamText: {
    color: theme.colors.CREAM,
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const textPalettes: Record<LoyaltyHomeContainerProps['palette'], TextStyle> = {
  'dark-kale': styles.creamText,
  cream: styles.darkKaleText,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeContainerProps = ViewProps & {
  availablePoints: number;
  palette: 'cream' | 'dark-kale';
};
