import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const InfoLayout = (props: PropsWithChildren) => {
  const { match } = useResponsive();
  const style = match([styles.infoContainerXS, styles.infoContainerSM]);

  return <View style={style}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  infoContainerXS: {
    flex: 1,
    paddingTop: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    paddingBottom: theme.spacing['10'],
  },
  infoContainerSM: {
    padding: theme.spacing['10'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
  },
});
