import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { theme, useResponsive, webOnlyStyles } from '@sg/garnish';

export const ContentLayout = (props: PropsWithChildren) => {
  const { match } = useResponsive();

  const style = [
    match([styles.contentXS, styles.contentSM]),
    webOnlyStyles({ userSelect: 'auto' }),
  ];

  const Wrapper = match([View, ScrollView]);

  return <Wrapper style={style}>{props.children}</Wrapper>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  contentXS: {
    borderTopColor: theme.colors.DARK_KALE,
    paddingHorizontal: theme.spacing['4'],
  },
  contentSM: {
    padding: theme.spacing['10'],
  },
});
