import React from 'react';
import { StyleSheet } from 'react-native';

import { useResponsive } from '../../../../../hooks';
import { IconLink } from '../../../../Icon';
import {
  ICON_SIZE_SM,
  ICON_SIZE_XS,
  QUANTITY_STEPPER_SIZE_SM,
  QUANTITY_STEPPER_SIZE_XS,
} from '../constants';

export const IngredientCardQuantityStepperButton = (
  props: IngredientCardQuantityStepperButtonProps,
) => {
  const { variation, onPress, accessibilityLabel } = props;

  const { match } = useResponsive();

  return (
    <IconLink
      style={match([styles.iconXS, styles.iconSM])}
      name={variation === 'increase' ? 'IconPlus2' : 'IconMinus2'}
      palette="dark-kale"
      width={match([ICON_SIZE_XS, ICON_SIZE_SM])}
      height={match([ICON_SIZE_XS, ICON_SIZE_SM])}
      accessibilityLabel={accessibilityLabel}
      onPress={onPress}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  iconXS: {
    width: QUANTITY_STEPPER_SIZE_XS,
    height: QUANTITY_STEPPER_SIZE_XS,
  },
  iconSM: {
    width: QUANTITY_STEPPER_SIZE_SM,
    height: QUANTITY_STEPPER_SIZE_SM,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardQuantityStepperButtonProps = {
  onPress: () => void;
  accessibilityLabel: string;
  variation: 'increase' | 'decrease';
};
