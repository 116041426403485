import { useFocusEffect, useRoute } from '@react-navigation/native';
import type { AddressType } from '@sg/garnish';
import { type Order } from '@sg/graphql-schema';

import { getAddressTypeFromAddress } from '@order/components';
import { FlattenedOrderStatuses, OrderType } from '@order/graphql';

import { useOrderStatusQuery } from '../../graphql/OrderStatus.generated';
import { useCanCancelOrder } from '../useCanCancelOrder';

export function useOrderStatusRequest() {
  // ─── Request ─────────────────────────────────────────────────────────

  const { params } = useRoute();
  const { orderId } = params as Readonly<{ orderId: string }>;

  const [{ data, error, fetching, stale }, fetchOrderStatus] =
    useOrderStatusQuery({
      variables: { id: orderId },
      requestPolicy: 'cache-and-network',
      pause: true, // NOTE: Fetching is controlled by `useFocusEffect`
    });

  // ─── Validation ──────────────────────────────────────────────────────

  const isInvalidOrder = data?.orderStatus?.__typename === 'InvalidOrder';
  const response = isInvalidOrder ? undefined : data?.orderStatus;
  const hasNoData = data === undefined && !error;
  const isFetching = hasNoData || fetching || stale;
  const isMissingStatus = !response?.flattenedOrderStatus;
  const isInvalidResponse = !isFetching && (isInvalidOrder || isMissingStatus);
  const isInvalidOrderStatus = Boolean(error) || isInvalidResponse;

  // ─── Query On Focus ──────────────────────────────────────────────────

  useFocusEffect(fetchOrderStatus);

  // ─── Parsing Response ────────────────────────────────────────────────

  const order = response?.order as Partial<Order>;
  const courierDetails = response?.courierDetails ?? undefined;
  const orderStatus =
    response?.flattenedOrderStatus ?? FlattenedOrderStatuses.Failed;

  const restaurant = order?.restaurant;
  const deliveryDetails = order?.deliveryOrderDetail;
  const deliveryAddress = deliveryDetails?.address;
  const isRestaurantOutpost = restaurant?.isOutpost;
  const hasSeveralDropoffLocation =
    (restaurant?.availableDropOffLocations?.length ?? 0) > 1;
  const dropoffLocation =
    isRestaurantOutpost && hasSeveralDropoffLocation
      ? order?.dropoffLocation ?? undefined
      : undefined;
  const orderType = getOrderType(order?.orderType, isRestaurantOutpost);

  const restaurantId = restaurant?.id;

  const orderToCancel: Parameters<typeof useCanCancelOrder>[0] =
    order?.id && order?.restaurant?.id && order?.wantedTime
      ? {
          id: order.id,
          canCancel: Boolean(restaurant),
          restaurant: {
            id: order.restaurant.id,
          },
          wantedTime: order.wantedTime,
        }
      : undefined;

  const { canCancel, refetchCanCancelStatus } =
    useCanCancelOrder(orderToCancel);

  // ─────────────────────────────────────────────────────────────

  return {
    isFetching,
    isInvalidOrderStatus,

    order,
    courierDetails,
    orderStatus,

    deliveryAddressName: deliveryAddress?.name ?? undefined,
    deliveryAddressStreet: deliveryAddress?.street,
    deliveryAddressSecondaryStreet:
      deliveryAddress?.secondaryStreet ?? undefined,
    deliveryAddressCity: deliveryAddress?.city,
    deliveryAddressState: deliveryAddress?.state,
    deliveryAddressZipCode: deliveryAddress?.zipCode,
    deliveryAddressNotes: deliveryAddress?.notes ?? undefined,
    deliveryAddressLatitude: deliveryAddress?.latitude,
    deliveryAddressLongitude: deliveryAddress?.longitude,
    deliveryAddressType: getAddressTypeFromAddress(deliveryAddress),
    deliveryPreference: deliveryAddress?.deliveryPreference,
    deliveryEstimatedTime: deliveryDetails?.estimatedDeliveryTime ?? undefined,

    locationName: order?.restaurant?.name,
    locationAddress: order?.restaurant?.address,
    locationCity: order?.restaurant?.city,
    locationState: order?.restaurant?.state,
    locationZipCode: order?.restaurant?.zipCode,
    locationLatitude: order?.restaurant?.latitude,
    locationLongitude: order?.restaurant?.longitude,
    dropoffLocation,

    orderId,
    orderType,
    orderTotal: order?.total ?? undefined,
    orderWantedTime: order?.wantedTime,
    orderLedger: order?.ledger ?? {},
    orderLineItems: order?.lineItems ?? [],
    vendorId: order?.vendorId,
    restaurantId,
    canCancel,
    refetchCanCancelStatus,

    isPendingFeedback: order?.isPendingFeedback,
    isTrackable: order?.canTrackOrderStatus,
  };
}

// ─── Helpers ───────────────────────────────────────────────────────────

const getOrderType = (
  orderType?: OrderType,
  isRestaurantOutpost?: boolean,
): AddressType => {
  if (isRestaurantOutpost) return 'outpost';

  return orderType === OrderType.Delivery ? 'delivery' : 'pickup';
};
