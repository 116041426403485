import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { Button } from '../../../Button';
import { Modal } from '../../../Modal';
import { type ModalSize } from '../../../Modal/Modal.sizes';
import { HStack } from '../../../Stack/HStack';
import { TextField } from '../../../TextField';

/**
 * Small modal popup (akin to an iOS alert) that prompts input from the user
 * and returns the value on close.
 */
export const AlertDialogPrompt = ({
  size = 'small',
  title = 'Input Required',
  visible,
  onInputChange,
  inputValue,
  placeholder = 'Input...',
  handleSubmit,
  handleCancel,
  cancelButtonTitle = 'Cancel',
  submitButtonTitle = 'Confirm',
}: AlertDialogPromptProps) => {
  return (
    <Modal
      size={size}
      visible={visible}
      animationType="none"
      onRequestClose={handleCancel}
      testID="alert-dialog-prompt"
    >
      <Modal.Header onClose={handleCancel}>{title}</Modal.Header>

      <View style={styles.container}>
        <TextField
          placeholder={placeholder}
          value={inputValue}
          onChangeText={onInputChange}
          testID="alert-dialog-prompt-text-field"
        />
      </View>

      <Modal.Footer withoutTopBorder>
        <HStack itemsPerRow={2}>
          <Button
            onPress={handleCancel}
            palette="secondary"
            size="large-wide"
            testID="alert-dialog-prompt-cancel"
          >
            {cancelButtonTitle}
          </Button>
          <Button
            onPress={handleSubmit}
            size="large-wide"
            testID="alert-dialog-prompt-submit"
          >
            {submitButtonTitle}
          </Button>
        </HStack>
      </Modal.Footer>
    </Modal>
  );
};

//  ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    paddingBottom: theme.spacing['6'],
  },
});

//  ─── Types ──────────────────────────────────────────────────────────────────

type AlertDialogPromptProps = {
  size?: ModalSize;
  title?: string;
  visible: boolean;
  onInputChange: (newValue: string) => void;
  inputValue: string | undefined;
  placeholder?: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  cancelButtonTitle?: string;
  submitButtonTitle?: string;
};
