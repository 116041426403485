import { useEffect, useMemo, useState } from 'react';
import type {
  Asset,
  ContentfulClientApi,
  CreateClientParams,
  Entry,
  TagLink,
} from 'contentful';
import { createContentfulClient } from '@sg/shared-fe/integrations/contentful';

import { useFeatureFlag } from '@order/LaunchDarkly';
import { getEnvVars } from '@order/utils';

import { useIsPreviewModeEnabled } from './useIsPreviewModeEnabled';

export const useContentfulClient = () => {
  const isContentfulEnabled = useFeatureFlag('OPS-contentful-enabled');

  // Feature-flag based configuration for preview / environments.
  const contentfulParams = useContentfulParams();

  // Create contentful client instance based on feature flag.
  const [contentfulClient, setContentfulClient] = useState<
    ContentfulClientApi | undefined
  >(() =>
    isContentfulEnabled ? createContentfulClient(contentfulParams) : undefined,
  ); // NOTE: the function approach is used to ensure that the initial state is set only once

  // Attempt to initialize contentful client in once feature flag is enabled.
  useEffect(() => {
    if (!isContentfulEnabled || contentfulClient !== undefined) return;

    setContentfulClient(createContentfulClient(contentfulParams));
  }, [contentfulClient, contentfulParams, isContentfulEnabled]);

  return isContentfulEnabled ? contentfulClient : undefined;
};

// ─── Utils ───────────────────────────────────────────────────────────────────

const {
  CONTENTFUL_SWEETGREEN_SPACE_ID,
  CONTENTFUL_CONTENT_DELIVERY_API_ACCESS_TOKEN,
  CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN,
  CONTENTFUL_ENVIRONMENT,
} = getEnvVars();

const DEFAULT_CREATE_CONTENTFUL_CLIENT_PARAMS: CreateClientParams = {
  space: CONTENTFUL_SWEETGREEN_SPACE_ID,
  accessToken: CONTENTFUL_CONTENT_DELIVERY_API_ACCESS_TOKEN,
};

const PREVIEW_CREATE_CONTENTFUL_CLIENT_PARAMS: CreateClientParams = {
  host: 'preview.contentful.com',
  space: CONTENTFUL_SWEETGREEN_SPACE_ID,
  accessToken: CONTENTFUL_CONTENT_PREVIEW_API_ACCESS_TOKEN,
};

/**
 * Feature Flag / Environment based configuration for preview / environments.
 * Selects preview / non-preview based on permanent-contentful-preview-enabled.
 * Selects environment based on the CONTENTFUL_ENVIRONMENT env var.
 * Defaults to non-preview master.
 */
const useContentfulParams = () => {
  const isPreviewEnabled = useIsPreviewModeEnabled();

  return useMemo(
    () => ({
      ...(isPreviewEnabled
        ? PREVIEW_CREATE_CONTENTFUL_CLIENT_PARAMS
        : DEFAULT_CREATE_CONTENTFUL_CLIENT_PARAMS),
      environment: CONTENTFUL_ENVIRONMENT ?? 'development',
    }),
    [isPreviewEnabled],
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

export type ContentfulAsset = Asset;
export type ContentfulEntry<T> = Entry<T>;
export type ContentfulTag = TagLink;
