import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';

// ─── Types ───────────────────────────────────────────────────────────────────

const RadioSizes = {
  sm: 'sm',
  md: 'md',
};

export type RadioSize = keyof typeof RadioSizes;

// ─── Styles ──────────────────────────────────────────────────────────────────

export const sizeStyles: Record<
  RadioSize,
  StyleProp<ViewStyle>
> = StyleSheet.create({
  sm: {
    minHeight: theme.spacing['10'],
    padding: theme.spacing['2'],
  },
  md: {
    minHeight: theme.spacing['18'],
  },
});
