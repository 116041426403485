import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { StyleSheet, type TextStyle, View } from 'react-native';
import { theme } from '@garnish/constants';
import { BodyText, Collapsible, DisplayText, VStack } from '@sg/garnish';

export const LoyaltyFAQ = (props: LoyaltyFAQProps) => {
  const { entries, palette = 'dark-kale', onChange } = props;

  return (
    <View style={styles.container}>
      <DisplayText style={palettes[palette]} sizeMatch={['32']}>
        <FormattedMessage {...messages.title} />
      </DisplayText>

      <VStack hasDivider gap={0}>
        {entries.map((entry) => (
          <LoyaltyFAQEntry
            key={entry.title}
            entry={entry}
            palette={palette}
            onChange={onChange}
          />
        ))}
      </VStack>
    </View>
  );
};

export const LoyaltyFAQEntry = (props: LoyaltyFAQEntryProps) => {
  const { entry, palette = 'dark-kale', onChange } = props;
  const iconColor = iconPaletteColors[palette];

  return (
    <Collapsible style={styles.entry} onChange={onChange}>
      <Collapsible.Summary.Container style={styles.summaryContainer}>
        <BodyText
          style={[palettes[palette], styles.collapsibleText]}
          sizeMatch={['18']}
        >
          {entry.title}
        </BodyText>

        <Collapsible.Icon
          iconName="IconCaretDown"
          iconColor={iconColor}
          iconStrokeWidth={1.5}
          style={styles.downCaret}
        />
      </Collapsible.Summary.Container>

      <Collapsible.Details>
        <BodyText
          style={[descriptionPalettes[palette], styles.description]}
          sizeMatch={['12']}
        >
          {entry.description}
        </BodyText>
      </Collapsible.Details>
    </Collapsible>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'FAQ',
    description: 'Loyalty | FAQ | Title',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['4'],
  },
  summaryContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  description: {
    marginTop: theme.spacing['2'],
  },
  entry: {
    paddingVertical: theme.spacing['6'],
  },
  darkKaleText: {
    color: theme.colors.DARK_KALE,
  },
  kaleText: {
    color: theme.colors.KALE,
  },
  creamText: {
    color: theme.colors.CREAM,
  },
  blackText: {
    color: theme.colors.BLACK,
  },
  collapsibleText: {
    flex: 1,
  },
  downCaret: {
    alignSelf: 'center',
  },
});

// ─── Palettes ───────────────────────────────────────────────────────────────

const palettes: Record<FAQPalette, TextStyle> = {
  'dark-kale': styles.darkKaleText,
  kale: styles.kaleText,
  cream: styles.creamText,
};

const iconPaletteColors: Record<FAQPalette, string> = {
  'dark-kale': theme.colors.DARK_KALE,
  kale: theme.colors.KALE,
  cream: theme.colors.CREAM,
};

const descriptionPalettes: Record<FAQPalette, TextStyle> = {
  'dark-kale': styles.blackText,
  kale: styles.blackText,
  cream: styles.creamText,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type FAQEntry = {
  title: string;
  description: string;
};

type FAQPalette = 'dark-kale' | 'kale' | 'cream';

type LoyaltyFAQProps = {
  entries: FAQEntry[];
  palette?: FAQPalette;
  onChange?: () => void;
};

type LoyaltyFAQEntryProps = {
  entry: FAQEntry;
  palette?: FAQPalette;
  onChange?: () => void;
};
