import {
  IngredientCardQuantityStepperButton,
  IngredientCardQuantityStepperContainer,
  IngredientCardQuantityStepperLabel,
} from './subcomponents';

export const IngredientCardQuantityStepper = {
  StepperButton: IngredientCardQuantityStepperButton,
  Label: IngredientCardQuantityStepperLabel,
  Container: IngredientCardQuantityStepperContainer,
};
