import React, { type PropsWithChildren } from 'react';
import { StyleSheet } from 'react-native';
import { theme } from '@garnish/constants';

import { HStack } from '../../../../../Stack';

// ─────────────────────────────────────────────────────────────────────────────

export const PinPromptKeypadContainer = (props: PropsWithChildren) => {
  return (
    <HStack itemsPerRow={3} gap={6} style={styles.container}>
      {props.children}
    </HStack>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    padding: theme.spacing['2'],
  },
});
