import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const MapLayout = (props: PropsWithChildren) => {
  const { match } = useResponsive();
  const style = match([styles.mapContainerXS, styles.mapContainerSM]);

  return <View style={style}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  mapContainerSM: {
    position: 'relative',
    flex: 1,
  },
  mapContainerXS: {
    position: 'relative',
    height: 300,
    borderTopColor: theme.colors.LIGHT,
    borderBottomColor: theme.colors.LIGHT,
    marginBottom: theme.spacing['4'],
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
});
