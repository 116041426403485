import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Pressable, StyleSheet, useWindowDimensions, View } from 'react-native';
import { FallbackImage, Icon, theme, TitleText } from '@sg/garnish';

import { IllusLoyaltySignedOut } from '../../../assets';

/**
 * Signed out state for loyalty in bag.
 */
export const BagLoyaltySignedOutCta = (props: BagLoyaltySignedOutCtaProps) => {
  const { message, imageUrl = '', onPress } = props;
  const { formatMessage } = useIntl();
  const { width } = useWindowDimensions();

  // ─── Flags ───────────────────────────────────────────────────────────

  const isSmallerScreen = width <= 375;

  return (
    <Pressable
      style={styles.container}
      accessibilityRole="link"
      accessibilityLabel={formatMessage(messages.accessibilityLabel)}
      onPress={onPress}
    >
      <FallbackImage
        contentFit="cover"
        style={styles.image}
        defaultImage={IllusLoyaltySignedOut}
        baseUrl={imageUrl}
        contentfulOptions={contentfulOptions}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <View style={styles.floatingContent}>
        <View style={styles.contentColumn}>
          <TitleText
            maxFontSizeMultiplier={1}
            numberOfLines={4}
            sizeMatch={isSmallerScreen ? ['18'] : ['24']}
            style={styles.message}
          >
            {message}
          </TitleText>
        </View>

        <View style={styles.sgRewardColumn}>
          <Icon name="IconSgRewardWithText" width={100} height={30} />
        </View>
      </View>
    </Pressable>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const HEIGHT = 150;
const contentfulOptions = {
  fm: 'webp' as const,
  q: 90,
  h: HEIGHT,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  rewards: {
    defaultMessage: 'rewards',
    description: 'Bag | Loyalty | Rewards',
  },
  accessibilityLabel: {
    defaultMessage: 'Create account to earn rewards',
    description: 'Bag | Loyalty | Signed Out A11y',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    height: HEIGHT,
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.DARK,
  },
  image: {
    width: '100%',
    height: HEIGHT,
    borderRadius: theme.radius.large,
  },
  floatingContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: HEIGHT,
    padding: theme.spacing['4'],
  },
  contentColumn: {
    flex: 2,
  },
  sgRewardColumn: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    gap: theme.spacing['1'],
  },
  message: {
    color: theme.colors.WHITE,
    fontFamily: 'GrenettePro-Regular',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type BagLoyaltySignedOutCtaProps = {
  message: string;
  imageUrl?: string;
  onPress: () => void;
};
