import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';
import { theme } from '@garnish/constants';
import {
  BodyText,
  Button,
  type CloudinaryTransformConfig,
  FallbackImage,
  IllusEmpty_2,
  LoadingAnimation,
  useCallbackWithLimit,
} from '@sg/garnish';

/**
 * Info regarding loyalty rewards availability.
 */
export const LoyaltyInfoBenefits = (props: LoyaltyInfoBenefitsProps) => {
  const {
    availablePoints,
    palette = 'cream',
    loyaltyInfoBenefits,
    style,
    outerContainerStyle,
    isLoading,
    hasError,
    disclaimer,
    disclaimerPosition = 'outside',
    refetch,
  } = props;

  const paletteStyle = palette ? paletteStyles[palette] : {};

  const { callbackWithLimit: refresh, hasReachedLimit } = useCallbackWithLimit({
    callback: refetch,
    limit: 5,
  });

  if (hasError || (!isLoading && loyaltyInfoBenefits.length === 0)) {
    return (
      <View style={styles.errorContainer}>
        <BodyText sizeMatch={['22']}>
          <FormattedMessage {...messages.errorState} />
        </BodyText>

        {hasReachedLimit && !isLoading ? null : (
          <Button
            size="large-wide"
            palette="spinachMuted"
            isLoading={isLoading}
            onPress={refresh}
          >
            <FormattedMessage {...messages.refresh} />
          </Button>
        )}
      </View>
    );
  }

  return (
    <View style={[styles.outerContainer, outerContainerStyle]}>
      <View style={[styles.container, paletteStyle, style]}>
        {isLoading && loyaltyInfoBenefits.length === 0 ? (
          <LoadingAnimation />
        ) : null}

        {loyaltyInfoBenefits.map((loyaltyInfoBenefit) => (
          <LoyaltyInfoBenefitsRow
            availablePoints={availablePoints}
            key={loyaltyInfoBenefit.id}
            loyaltyInfoBenefit={loyaltyInfoBenefit}
          />
        ))}

        {disclaimerPosition === 'inside' && (
          <BodyText
            sizeMatch={['14']}
            style={[styles.legalDisclaimer, styles.legalDisclaimerInside]}
          >
            {disclaimer}
          </BodyText>
        )}
      </View>

      {disclaimerPosition === 'outside' && (
        <BodyText sizeMatch={['14']} style={styles.legalDisclaimer}>
          {disclaimer}
        </BodyText>
      )}
    </View>
  );
};

const LoyaltyInfoBenefitsRow = (props: LoyaltyInfoBenefitsRowProps) => {
  const {
    availablePoints,
    loyaltyInfoBenefit: { name, points, assetUrl },
  } = props;

  const hasEnoughPoints = availablePoints && availablePoints >= points;

  const shouldShowAvailablePointStyling = availablePoints !== undefined;

  const wrapperStyle = [
    styles.pointsNoWrapper,
    shouldShowAvailablePointStyling ? styles.pointsWrapper : null,
    !shouldShowAvailablePointStyling || hasEnoughPoints
      ? null
      : styles.pointsWrapperUnusable,
  ];

  const pointsStyle = [
    styles.points,
    shouldShowAvailablePointStyling ? styles.pillPoints : null,
    !shouldShowAvailablePointStyling || hasEnoughPoints
      ? null
      : styles.lockedPoints,
  ];

  const imageStyle = [
    styles.image,
    !shouldShowAvailablePointStyling || hasEnoughPoints
      ? null
      : styles.lockedImage,
  ];

  const titleStyle = [
    styles.title,
    !shouldShowAvailablePointStyling || hasEnoughPoints
      ? null
      : styles.lockedTitle,
  ];

  const { formatMessage } = useIntl();

  return (
    <View style={styles.reward}>
      <View style={wrapperStyle}>
        <BodyText bold sizeMatch={['12']} style={pointsStyle}>
          {points}
        </BodyText>
      </View>

      <FallbackImage
        contentFit="contain"
        style={imageStyle}
        defaultImage={IllusEmpty_2}
        baseUrl={assetUrl}
        cloudinaryConfig={cloudinaryConfig}
        aria-label={formatMessage(messages.accessibilityLabel)}
      />

      <BodyText style={titleStyle} sizeMatch={['16']}>
        {name}
      </BodyText>
    </View>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  accessibilityLabel: {
    defaultMessage: 'Loyalty reward',
    description: 'Loyalty Info | Accessibility Label',
  },
  errorState: {
    defaultMessage:
      'Points are taking longer than expected to load. Please try again in a few moments.',
    description: 'Loyalty Info | Error State',
  },
  refresh: {
    defaultMessage: 'Refresh',
    description: 'Loyalty Info | Refresh',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const IMAGE_SIZE = 60;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  outerContainer: {
    marginTop: theme.spacing['18'],
  },
  container: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.KALE,
    borderRadius: theme.radius.medium,
    borderWidth: 1,
    marginHorizontal: theme.spacing['6'],
    minHeight: theme.spacing['24'],
  },
  errorContainer: {
    alignItems: 'center',
    margin: theme.spacing['4'],
    marginTop: theme.spacing['16'],
    paddingBottom: theme.spacing['6'],
    gap: theme.spacing['10'],
  },
  reward: {
    flexDirection: 'row',
    gap: theme.spacing['2'],
    paddingHorizontal: theme.spacing['4'],
    alignItems: 'center',
  },
  pointsNoWrapper: {
    height: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.CREAM,
  },
  pointsWrapper: {
    height: theme.spacing['6'],
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing['2'],
    borderRadius: theme.radius.xxxlarge,
    backgroundColor: theme.colors.BASIL,
  },
  pointsWrapperUnusable: {
    backgroundColor: theme.colors.CREAM,
    borderColor: theme.colors.BASIL,
    borderWidth: 1,
  },
  points: {
    marginTop: 2,
    textAlign: 'center',
    minWidth: 50,
    color: theme.colors.DARK_KALE,
  },
  pillPoints: {
    color: theme.colors.CREAM,
  },
  lockedPoints: {
    color: theme.colors.BASIL,
  },
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  },
  lockedImage: {
    opacity: 0.8,
  },
  title: {
    flex: 1,
    color: theme.colors.BLACK,
  },
  lockedTitle: {
    color: theme.colors.CHARCOAL,
  },
  cream: {
    backgroundColor: theme.colors.CREAM,
  },
  oatmeal: {
    backgroundColor: theme.colors.OATMEAL,
  },
  legalDisclaimer: {
    color: theme.colors.OPACITY.DARK_KALE.DARKER,
    alignSelf: 'center',
    textAlign: 'center',
    paddingHorizontal: theme.spacing['4'],
    paddingTop: theme.spacing['6'],
  },
  legalDisclaimerInside: {
    textAlign: 'center',
    paddingTop: theme.spacing['4'],
  },
});

const cloudinaryConfig: CloudinaryTransformConfig = [
  { crop: 'crop', height: 0.6, width: 0.6 },
  { width: IMAGE_SIZE },
];

const paletteStyles = {
  cream: styles.cream,
  oatmeal: styles.oatmeal,
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyInfoBenefitsProps = {
  availablePoints?: number;
  loyaltyInfoBenefits: readonly LoyaltyInfoBenefit[];
  isLoading: boolean;
  hasError?: boolean;
  palette?: 'cream' | 'oatmeal';
  style?: StyleProp<ViewStyle>;
  outerContainerStyle?: StyleProp<ViewStyle>;
  disclaimer?: string;
  disclaimerPosition?: 'inside' | 'outside';
  refetch: () => void;
};

type LoyaltyInfoBenefitsRowProps = {
  availablePoints?: number;
  loyaltyInfoBenefit: LoyaltyInfoBenefit;
};

type LoyaltyInfoBenefit = {
  id: string;
  name: string;
  points: number;
  assetUrl?: string | null;
};
