/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CustomerCreditsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type CustomerCreditsQuery = {
  readonly __typename?: 'Query';
  readonly currentCustomer: {
    readonly __typename: 'Customer';
    readonly id: string;
    readonly availableCredit: number;
    readonly useCredit: boolean;
    readonly credits: ReadonlyArray<{
      readonly __typename: 'Credit';
      readonly id: string;
      readonly title: string;
      readonly remainingAmount: number;
      readonly expiresAt: string | null;
      readonly storefrontEligibilityTitle: string;
      readonly creditType: Types.CreditType;
      readonly createdAt: string;
    }>;
  } | null;
};

export const CustomerCreditsDocument = gql`
  query CustomerCredits {
    currentCustomer {
      id
      __typename
      availableCredit
      useCredit
      credits {
        id
        __typename
        title
        remainingAmount
        expiresAt
        storefrontEligibilityTitle
        creditType
        createdAt
      }
    }
  }
`;

export function useCustomerCreditsQuery(
  options?: Omit<Urql.UseQueryArgs<CustomerCreditsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CustomerCreditsQuery, CustomerCreditsQueryVariables>({
    query: CustomerCreditsDocument,
    ...options,
  });
}
