import React, { type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';
import { useResponsive } from '@sg/garnish';

export const LoyaltyHomeBody = (props: LoyaltyHomeBodyProps) => {
  const { children, isPremium } = props;

  const { match } = useResponsive();
  const style = [
    match([styles.containerXS, styles.containerSM]),
    match([undefined, isPremium ? styles.premiumBorder : undefined]),
  ];

  return <View style={style}>{children}</View>;
};

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeBodyProps = {
  isPremium: boolean;
  children: PropsWithChildren['children'];
};

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['3'],
    padding: theme.spacing['6'],
  },
  containerSM: {
    alignItems: 'center',
    gap: theme.spacing['6'],
    paddingTop: theme.spacing['10'],
    paddingBottom: theme.spacing['16'],
  },
  premiumBorder: {
    borderColor: theme.colors.CREAM,
    borderBottomWidth: 1,
  },
});
