/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SubmitGiftCardOrderUsingPaymentMethodMutationVariables =
  Types.Exact<{
    input: Types.SubmitGiftCardOrderUsingPaymentMethodInput;
  }>;

export type SubmitGiftCardOrderUsingPaymentMethodMutation = {
  readonly __typename?: 'Mutation';
  readonly submitGiftCardOrderUsingPaymentMethod:
    | {
        readonly __typename: 'GiftCardOrderDetail';
        readonly id: string;
        readonly purchasedAt: string;
        readonly deliveryDetail: {
          readonly __typename?: 'GiftCardDeliveryDetail';
          readonly deliveryMethods: ReadonlyArray<string>;
          readonly isAsap: boolean;
          readonly deliveryDateTime: string | null;
        };
        readonly userGiftCards: ReadonlyArray<{
          readonly __typename: 'UserGiftCard';
          readonly id: string;
          readonly balance: number;
          readonly assetId: string | null;
          readonly recipientEmail: string | null;
          readonly recipientName: string;
          readonly message: string | null;
        }>;
      }
    | {
        readonly __typename: 'SubmitGiftCardOrderError';
        readonly errorMessage: string;
      };
};

export type GiftCardPurchasePaymentProfileQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GiftCardPurchasePaymentProfileQuery = {
  readonly __typename?: 'Query';
  readonly paymentProfile: {
    readonly __typename: 'PaymentProfile';
    readonly id: string;
    readonly defaultCardId: string | null;
  } | null;
};

export type GiftCardOrderDetailFragment = {
  readonly __typename: 'GiftCardOrderDetail';
  readonly id: string;
  readonly purchasedAt: string;
  readonly deliveryDetail: {
    readonly __typename?: 'GiftCardDeliveryDetail';
    readonly deliveryMethods: ReadonlyArray<string>;
    readonly isAsap: boolean;
    readonly deliveryDateTime: string | null;
  };
  readonly userGiftCards: ReadonlyArray<{
    readonly __typename: 'UserGiftCard';
    readonly id: string;
    readonly balance: number;
    readonly assetId: string | null;
    readonly recipientEmail: string | null;
    readonly recipientName: string;
    readonly message: string | null;
  }>;
};

export type SubmitGiftCardOrderErrorFragment = {
  readonly __typename?: 'SubmitGiftCardOrderError';
  readonly errorMessage: string;
};

export const GiftCardOrderDetailFragmentDoc = gql`
  fragment GiftCardOrderDetail on GiftCardOrderDetail {
    id
    __typename
    purchasedAt
    deliveryDetail {
      deliveryMethods
      isAsap
      deliveryDateTime
    }
    userGiftCards {
      id
      __typename
      balance
      assetId
      recipientEmail
      recipientName
      message
    }
  }
`;
export const SubmitGiftCardOrderErrorFragmentDoc = gql`
  fragment SubmitGiftCardOrderError on SubmitGiftCardOrderError {
    errorMessage
  }
`;
export const SubmitGiftCardOrderUsingPaymentMethodDocument = gql`
  mutation SubmitGiftCardOrderUsingPaymentMethod(
    $input: SubmitGiftCardOrderUsingPaymentMethodInput!
  ) {
    submitGiftCardOrderUsingPaymentMethod(input: $input) {
      __typename
      ...GiftCardOrderDetail
      ...SubmitGiftCardOrderError
    }
  }
  ${GiftCardOrderDetailFragmentDoc}
  ${SubmitGiftCardOrderErrorFragmentDoc}
`;

export function useSubmitGiftCardOrderUsingPaymentMethodMutation() {
  return Urql.useMutation<
    SubmitGiftCardOrderUsingPaymentMethodMutation,
    SubmitGiftCardOrderUsingPaymentMethodMutationVariables
  >(SubmitGiftCardOrderUsingPaymentMethodDocument);
}
export const GiftCardPurchasePaymentProfileDocument = gql`
  query GiftCardPurchasePaymentProfile {
    paymentProfile {
      id
      __typename
      defaultCardId
    }
  }
`;

export function useGiftCardPurchasePaymentProfileQuery(
  options?: Omit<
    Urql.UseQueryArgs<GiftCardPurchasePaymentProfileQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GiftCardPurchasePaymentProfileQuery,
    GiftCardPurchasePaymentProfileQueryVariables
  >({ query: GiftCardPurchasePaymentProfileDocument, ...options });
}
