import { MAX_DPR_SETTING } from './ContentfulImage.constants';
import type { ContentfulImageTransformOptions } from './ContentfulImageTransformOptions';

/**
 * Returns a Contentful image URL with the specified transform options applied.
 *
 * @param baseUrl The base image URL returned from a Contentful query.
 * @param options The image transform options.
 * @param scale The factor by which to multiply `w`, `h`, and `r` options.
 * @returns A Contentful image URL with the specified transform options appended as a query string.
 */
export const getContentfulImageUrl = (
  baseUrl: string,
  options?: Partial<ContentfulImageTransformOptions>,
  scale?: number,
) => {
  /// For some reason, the query returns image assets whose `url` property is missing a protocol.
  const url = baseUrl.startsWith('//') ? `https:${baseUrl}` : baseUrl;

  if (!options) {
    return url;
  }

  const scaleWithLimit = Math.min(scale ?? 1, MAX_DPR_SETTING);

  /// Generate the transform query string.
  const query = Object.entries(options)
    .map(
      ([key, value]) =>
        `${key}=${key === 'bg' ? 'rgb:' : ''}${
          (key === 'w' || key === 'h' || key === 'r') &&
          typeof value === 'number'
            ? Math.round(value * scaleWithLimit)
            : value
        }`,
    )
    .join('&');

  const transformedUrl = query.length === 0 ? url : `${url}?${query}`;

  return transformedUrl;
};
