/* eslint-disable react/no-array-index-key */

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { LoadingDot } from './components';
import { useDotAnimatedValues } from './hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoadingDots = (props: LoadingDotsProps) => {
  const { color = DEFAULT_COLOR } = props;

  const animatedValues = useDotAnimatedValues();

  return (
    <View style={styles.container}>
      {animatedValues.map((animatedValue, index) => (
        <LoadingDot
          key={`loading-dot-${index}`}
          animatedValue={animatedValue}
          color={color}
        />
      ))}
    </View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const DEFAULT_COLOR = theme.colors.DARK_KALE;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoadingDotsProps = {
  color?: string;
};
