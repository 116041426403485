import { useMemo } from 'react';
import type { Asset, Entry } from 'contentful';

import { SG_REWARDS_TERMS_AND_CONDITIONS } from '@order/constants';
import { useContentfulContentTypeEntry } from '@order/Contentful';

import { useLoyaltyPremiumStatus } from '../useLoyaltyPremiumStatus';
import { useLoyaltyContentFallbacks } from './useLoyaltyContentFallbacks';

/**
 * Returns the contentful data for the bag.
 */
export const useLoyaltyContent = (params?: UseLoyaltyContentParams) => {
  const { availablePoints = 0 } = params ?? {};
  const { isPremium } = useLoyaltyPremiumStatus();

  const fallbacks = useLoyaltyContentFallbacks(availablePoints);
  const contentfulResponse = useContentfulContentTypeEntry<LoyaltyContainer>({
    contentType: 'loyalty',
  });

  return useMemo(() => {
    const content = contentfulResponse.data?.fields;

    // ─── FAQ ────────────────────────────────────────────────────────────────

    const faqLoyaltyHomeContentfulData = isPremium
      ? content?.faqSgRewardsPremiumLoyaltyHome
      : content?.faqLoyaltyHome;

    const faqLoyaltyHome = (faqLoyaltyHomeContentfulData ?? EMPTY_FAQ)
      ?.map(extractLoyaltyFAQ)
      ?.filter(filterOutInvalidLoyaltyFAQ);

    const faqPointHistory = (content?.faqPointHistory ?? EMPTY_FAQ)
      ?.map(extractLoyaltyFAQ)
      ?.filter(filterOutInvalidLoyaltyFAQ);

    // ─── Bag ────────────────────────────────────────────────────────────────

    const bagHeaderLoggedOut =
      content?.bagHeaderLoggedOut ?? fallbacks?.bagHeaderLoggedOut;
    const bagHeaderNewUser =
      content?.bagHeaderNewUser ?? fallbacks?.bagHeaderNewUser;
    const bagHeaderRecurringUser =
      content?.bagHeaderRecurringUser ?? fallbacks?.bagHeaderRecurringUser;
    const bagHeaderSubtitle =
      content?.bagHeaderSubtitle ?? fallbacks?.bagHeaderSubtitle;
    const bagHeaderLoggedOutImageMessage =
      content?.bagLoggedOutImageMessage ??
      fallbacks?.bagHeaderLoggedOutImageMessage;
    const bagHeaderLoggedOutImage =
      content?.bagHeaderLoggedOutImage?.fields?.file?.url;
    const bagDiscountBody =
      content?.bagDiscountBody ?? fallbacks?.bagDiscountBody;

    // ─── Home Empty State ───────────────────────────────────────────────────

    const homeChallengesEmptyStateTitle =
      content?.homeChallengesEmptyStateTitle ??
      fallbacks?.homeChallengesEmptyStateTitle;

    const homeChallengesEmptyStateBody =
      content?.homeChallengesEmptyStateBody ??
      fallbacks?.homeChallengesEmptyStateBody;

    const homeChallengesEmptyStateCtaXS =
      content?.homeChallengesEmptyStateCtaXS ??
      fallbacks?.homeChallengesEmptyStateCtaXS;

    const homeChallengesEmptyStateCtaSM =
      content?.homeChallengesEmptyStateCtaSM ??
      fallbacks?.homeChallengesEmptyStateCtaSM;

    const homeRewardsEmptyStateTitle =
      content?.homeRewardsEmptyStateTitle ??
      fallbacks?.homeRewardsEmptyStateTitle;

    const homeRewardsEmptyStateBody =
      content?.homeRewardsEmptyStateBody ??
      fallbacks?.homeRewardsEmptyStateBody;

    // ─── Progress Benefits ──────────────────────────────────────────────────

    const homeProgressBenefits = (
      content?.homeProgressBenefits ?? EMPTY_HOME_PROGRESS_BENEFITS
    )
      .map(extractHomeProgressBenefit)
      .filter(filterOutInvalidHomeProgressBenefit);

    const homeProgressBenefitsDisclaimer =
      content?.homeProgressBenefitsDisclaimer;

    // ─── How It Works ───────────────────────────────────────────────────────

    const homeHowItWorksSMData = (
      content?.homeHowItWorksSM ?? EMPTY_HOW_IT_WORKS
    ).map(extractLoyaltyHowItWorks);

    const homeHowItWorksSM = (
      homeHowItWorksSMData.length > 0
        ? homeHowItWorksSMData
        : [
            {
              title: fallbacks.earnPointsTitle,
              description: fallbacks.earnPointsDescription,
            },
            {
              title: fallbacks.redeemRewardsTitle,
              description: fallbacks.redeemRewardsDescription,
            },
            {
              title: fallbacks.lookOutForOffersTitle,
              description: fallbacks.lookOutForOffersDescription,
            },
          ]
    ).filter(filterOutInvalidLoyaltyHowItWorks);

    const homeHowItWorksDisclaimer = content?.homeHowItWorksDisclaimer;

    // ─── Terms And Conditions ───────────────────────────────────────────────

    const termsAndConditionsUrl =
      content?.termsAndConditionsUrl ?? SG_REWARDS_TERMS_AND_CONDITIONS;

    return {
      faqLoyaltyHome,
      faqPointHistory,
      bagHeaderLoggedOut,
      bagHeaderNewUser,
      bagHeaderRecurringUser,
      bagHeaderSubtitle,
      bagHeaderLoggedOutImageMessage,
      bagHeaderLoggedOutImage,
      bagDiscountBody,
      homeProgressBenefits,
      homeProgressBenefitsDisclaimer,
      homeHowItWorksSM,
      homeHowItWorksDisclaimer,
      homeChallengesEmptyStateTitle,
      homeChallengesEmptyStateBody,
      homeChallengesEmptyStateCtaXS,
      homeChallengesEmptyStateCtaSM,
      homeRewardsEmptyStateTitle,
      homeRewardsEmptyStateBody,
      termsAndConditionsUrl,
    };
  }, [contentfulResponse, fallbacks, isPremium]);
};

// ─── Container ──────────────────────────────────────────────────────────────

type LoyaltyContainer = {
  faqLoyaltyHome: Entry<LoyaltyFAQ>[];
  faqSgRewardsPremiumLoyaltyHome: Entry<LoyaltyFAQ>[];
  faqPointHistory: Entry<LoyaltyFAQ>[];

  bagHeaderLoggedOut?: string;
  bagHeaderNewUser?: string;
  bagHeaderRecurringUser?: string;
  bagHeaderSubtitle?: string;
  bagLoggedOutImageMessage?: string;
  bagHeaderLoggedOutImage?: Asset;
  bagDiscountBody?: string;

  homeProgressBenefits: Entry<ProgressBenefit>[];
  homeProgressBenefitsDisclaimer: string;

  homeHowItWorksSM: Entry<LoyaltyHowItWorks>[];
  homeHowItWorksDisclaimer: string;

  homeChallengesEmptyStateTitle: string;
  homeChallengesEmptyStateBody: string;
  homeChallengesEmptyStateCtaXS: string;
  homeChallengesEmptyStateCtaSM: string;

  homeRewardsEmptyStateTitle: string;
  homeRewardsEmptyStateBody: string;

  termsAndConditionsUrl?: string;
};

// ─── Loyalty FAQ ────────────────────────────────────────────────────────────

type LoyaltyFAQ = {
  title?: string;
  description?: string;
};

const extractLoyaltyFAQ = (loyaltyFAQ?: Entry<LoyaltyFAQ>) => {
  return {
    title: loyaltyFAQ?.fields?.title,
    description: loyaltyFAQ?.fields?.description,
  };
};

const filterOutInvalidLoyaltyFAQ = (
  entry: Partial<LoyaltyFAQ>,
): entry is Required<LoyaltyFAQ> => {
  return Boolean(entry.title && entry.description);
};

const EMPTY_FAQ: Entry<LoyaltyFAQ>[] = [];

// ─── Loyalty How It Works ───────────────────────────────────────────────────

type LoyaltyHowItWorks = {
  title?: string;
  description?: string;
};

type LoyaltyHowItWorksPostExtraction = {
  title?: string;
  description?: string;
};

const extractLoyaltyHowItWorks = (
  loyaltyHowItWorks?: Entry<LoyaltyHowItWorks>,
): LoyaltyHowItWorksPostExtraction => {
  return {
    title: loyaltyHowItWorks?.fields?.title,
    description: loyaltyHowItWorks?.fields?.description,
  };
};

const filterOutInvalidLoyaltyHowItWorks = (
  entry: Partial<LoyaltyHowItWorksPostExtraction>,
): entry is Required<LoyaltyHowItWorksPostExtraction> => {
  return Boolean(entry.title && entry.description);
};

const EMPTY_HOW_IT_WORKS: Entry<LoyaltyHowItWorks>[] = [];

// ─── Progress Benefits ──────────────────────────────────────────────────────

type ProgressBenefit = {
  title?: string;
  description?: string;
};

export type MappedProgressBenefit = {
  title: string;
  maxPoints: number;
  isPendingBenefit: boolean;
};

const extractHomeProgressBenefit = (
  benefit?: Entry<ProgressBenefit>,
): Partial<MappedProgressBenefit> => {
  return {
    title: benefit?.fields?.title,
    maxPoints: Number(benefit?.fields?.description),
    isPendingBenefit: benefit?.fields?.description === 'Pending',
  };
};

const filterOutInvalidHomeProgressBenefit = (
  entry: Partial<MappedProgressBenefit>,
): entry is Required<MappedProgressBenefit> => {
  return (
    entry.title !== undefined &&
    (Number.isInteger(entry.maxPoints) || entry.isPendingBenefit === true) &&
    entry.isPendingBenefit !== undefined
  );
};

const EMPTY_HOME_PROGRESS_BENEFITS: Entry<ProgressBenefit>[] = [];

// ─── Params ─────────────────────────────────────────────────────────────────

type UseLoyaltyContentParams = {
  availablePoints: number | undefined;
};
