/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { AddressFragmentDoc } from '../../../graphql/fragments/CustomerData.generated';
import { ValidationErrorResponseFragmentDoc } from '../../ReorderingScreen/GraphQL/Reorder.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateBagDeliveryPreferencesMutationVariables = Types.Exact<{
  input: Types.EditAddressInput;
}>;

export type UpdateBagDeliveryPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAddress:
    | {
        readonly __typename: 'InvalidAddress';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'UpdateAddressSuccess';
        readonly address: {
          readonly __typename: 'Address';
          readonly id: string;
          readonly street: string;
          readonly secondaryStreet: string | null;
          readonly name: string | null;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly country: string;
          readonly deliveryPreference: Types.DeliveryPreferenceType;
          readonly googlePlaceId: string;
          readonly latitude: number;
          readonly longitude: number;
          readonly notes: string | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const UpdateBagDeliveryPreferencesDocument = gql`
  mutation updateBagDeliveryPreferences($input: EditAddressInput!) {
    updateAddress(input: $input) {
      __typename
      ... on UpdateAddressSuccess {
        address {
          id
          ...address
        }
      }
      ... on ValidationError {
        ...ValidationErrorResponse
      }
      ... on InvalidAddress {
        message
        status
      }
    }
  }
  ${AddressFragmentDoc}
  ${ValidationErrorResponseFragmentDoc}
`;

export function useUpdateBagDeliveryPreferencesMutation() {
  return Urql.useMutation<
    UpdateBagDeliveryPreferencesMutation,
    UpdateBagDeliveryPreferencesMutationVariables
  >(UpdateBagDeliveryPreferencesDocument);
}
