import { useCallback, useMemo } from 'react';
import { useIsFocused } from '@react-navigation/native';

import { useIsLoggedIn } from '@order/AuthMachine';
import {
  type PaymentSubscription,
  PaymentSubscriptionStatus,
  TierName,
  TierStatusName,
} from '@order/graphql';
import {
  getPreferredSubscription,
  useSweetpassPlan,
  useSweetpassPlusPlans,
} from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { useSweetpassMembershipQuery } from '../../GraphQL/SweetpassMembership.generated';
import { useCancelSweetpassMembership } from '../useCancelSweetpassMembership';
import { useReactivateSweetpassMembership } from '../useReactivateSweetpassMembership';

/**
 * The sweetpass membership data is re-fetched on focus to ensure it is in sync after tier operations.
 * The plan data depends on the current tier, so it will also be automatically re-fetched through dependencies.
 * The membership query uses `network-only` so we don't show outdated information.
 */
export const useSweetpassMembershipData = () => {
  const isLoggedIn = useIsLoggedIn();
  const isScreenFocused = useIsFocused();
  const { t } = useLocalizationContext();

  const [response, fetchMembershipState] = useSweetpassMembershipQuery({
    requestPolicy: 'network-only',
    pause: !isLoggedIn || !isScreenFocused,
  });

  const fetchMembership = useCallback(() => {
    if (!isLoggedIn) {
      return;
    }

    fetchMembershipState();
  }, [fetchMembershipState, isLoggedIn]);

  const {
    data: sweetpassMembershipData,
    error: loyaltyProfileError,
    fetching: isLoadingMembership,
  } = response;

  // ─── Loyalty Profile Data ────────────────────────────────────────────

  const loyaltyProfile = sweetpassMembershipData?.loyaltyProfile;
  const joinDate = loyaltyProfile?.joinDate;
  const tierStatus = loyaltyProfile?.tierStatus?.name;
  const tier = loyaltyProfile?.tier;
  const nextTier = loyaltyProfile?.tier?.nextTier;
  const hasPaidTierHistory = Boolean(loyaltyProfile?.hasPaidTierHistory);
  const isEligibleToFreeTrial = false;

  // ─── Subscriptions Data ──────────────────────────────────────────────

  const subscriptions: ReadonlyArray<Partial<PaymentSubscription>> =
    sweetpassMembershipData?.getSubscriptions ?? [];
  const activeSubscription = getPreferredSubscription(subscriptions);
  const subscriptionId = activeSubscription?.id;
  const nextBillingDate = activeSubscription?.nextBillingDate;
  const subscriptionPlanId = activeSubscription?.planId;

  // ─── Payment Methods Data ────────────────────────────────────────────

  const paymentMethods =
    sweetpassMembershipData?.paymentSubscriptionProfile?.creditCards;
  const activePaymentMethodId = paymentMethods?.find(
    (paymentMethod) => paymentMethod.isActive,
  )?.id;

  // ─── Plan Data ───────────────────────────────────────────────────────

  const { plans, isLoadingPlans } = useSweetpassPlusPlans();
  const freeTrialPlanId = plans?.annual?.id ?? plans?.monthly?.id;
  const { planPrice, billingFrequencyUnit, isLoadingPlan } = useSweetpassPlan({
    requestedTierId: nextTier?.id,
    pause: !nextTier?.id,
  });

  // ─── Subscription Recurrence Data ────────────────────────────────────

  const isSubscriptionAnnual = plans?.annual?.id === subscriptionPlanId;
  const pendingSubscription = subscriptions.find(
    ({ status }) => status === PaymentSubscriptionStatus.Pending,
  );

  const hasPendingSubscription = Boolean(pendingSubscription);

  const switchSubscriptionPrice = isSubscriptionAnnual
    ? plans?.monthly?.price
    : plans?.annual?.price;
  const planIdAfterSwitch = isSubscriptionAnnual
    ? plans?.monthly?.id
    : plans?.annual?.id;
  const savingAmount = plans.annual?.savingAmount;

  // ─── Plan Switching ──────────────────────────────────────────────────

  const changePlanForwardsLabel = isSubscriptionAnnual
    ? t('account.sweetpass-membership.membership-level.monthly')
    : t('account.sweetpass-membership.membership-level.annual');

  const changePlanBackwardsLabel = isSubscriptionAnnual
    ? t('account.sweetpass-membership.membership-level.annual')
    : t('account.sweetpass-membership.membership-level.monthly');

  // ─── Loading State ───────────────────────────────────────────────────

  const isSweetpassMembershipLoading =
    isLoadingMembership || isLoadingPlans || isLoadingPlan;

  // ─── Error State ─────────────────────────────────────────────────────
  // This only considers errors if the tier is missing or if the plan is required but is missing.

  const isMissingTier = !tier;
  const isPlanRequired = tier?.name === TierName.Sweetpass;
  const isMissingPlan = !planPrice || !billingFrequencyUnit;
  const isMissingRequiredPlan = isPlanRequired && isMissingPlan;
  const hasMembershipError = isMissingTier || isMissingRequiredPlan;

  // ─── Callbacks ───────────────────────────────────────────────────────

  const { cancelSubscriptionAtCycleEnd } = useCancelSweetpassMembership({
    subscriptionId,
    nextBillingDate,
    tierName: tier?.name,
    isFreeTrial: tierStatus === TierStatusName.FreeTrial,
    isPastDue: tierStatus === TierStatusName.PastDue,
    refetchMembership: fetchMembership,
  });

  const { reactivateSubscription } = useReactivateSweetpassMembership({
    subscriptionId,
    refetchMembership: fetchMembership,
  });

  return useMemo(
    () => ({
      isSweetpassMembershipLoading,
      tierStatus,
      tier,
      nextTier,
      freeTrialPlanId,
      subscriptionPlanId,
      nextBillingDate,
      planPrice,
      savingAmount,
      changePlanForwardsLabel,
      changePlanBackwardsLabel,
      billingFrequencyUnit,
      hasPaidTierHistory,
      switchSubscriptionPrice,
      planIdAfterSwitch,
      isSubscriptionAnnual,
      hasPendingSubscription,
      isEligibleToFreeTrial,
      joinDate,
      activeSubscription,
      pendingSubscription,
      paymentMethods,
      activePaymentMethodId,
      loyaltyProfileError,
      hasMembershipError,
      cancelSubscriptionAtCycleEnd,
      reactivateSubscription,
      refetchMembership: fetchMembership,
    }),
    [
      isSweetpassMembershipLoading,
      tierStatus,
      tier,
      nextTier,
      freeTrialPlanId,
      subscriptionPlanId,
      nextBillingDate,
      planPrice,
      savingAmount,
      changePlanForwardsLabel,
      changePlanBackwardsLabel,
      billingFrequencyUnit,
      hasPaidTierHistory,
      switchSubscriptionPrice,
      planIdAfterSwitch,
      isSubscriptionAnnual,
      hasPendingSubscription,
      isEligibleToFreeTrial,
      joinDate,
      activeSubscription,
      pendingSubscription,
      paymentMethods,
      activePaymentMethodId,
      loyaltyProfileError,
      hasMembershipError,
      cancelSubscriptionAtCycleEnd,
      reactivateSubscription,
      fetchMembership,
    ],
  );
};
