import {
  type RailItemsMeasurementsSV,
  type ScrollOffsetSV,
} from '../../../HorizontalScrollRail.types';

// ─────────────────────────────────────────────────────────────────────────────

export function findPreviousItemIndex(params: Params) {
  'worklet';

  const { railItemsMeasurementsSV, scrollOffsetSV } = params;

  // NOTE: At the moment of writing, the `lastIndexOf` method is not widely available
  const itemsMeasurementsReversed = [...railItemsMeasurementsSV.value,].reverse(); // prettier-ignore

  const itemsLength = itemsMeasurementsReversed.length - 1;
  const targetItemIndex = itemsMeasurementsReversed.findIndex((measurement) => {
    const x = measurement?.x ?? 0;

    return x < scrollOffsetSV.value;
  });

  return targetItemIndex === -1 ? -1 : itemsLength - targetItemIndex;
}

// ─── Types ───────────────────────────────────────────────────────────────────

type Params = {
  railItemsMeasurementsSV: RailItemsMeasurementsSV;
  scrollOffsetSV: ScrollOffsetSV;
};
