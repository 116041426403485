import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Animated, Platform } from 'react-native';

// ─────────────────────────────────────────────────────────────────────────────

export const useDotAnimatedValues = (): DotInterpolationsArray => {
  //
  // ─── Animations ──────────────────────────────────────────────────────

  const animationValuesRef = useRef([
    new Animated.Value(0),
    new Animated.Value(0),
    new Animated.Value(0),
  ]);

  const createAnimation = useCallback(() => {
    const animatedValues = animationValuesRef.current;

    return Animated.stagger(
      INTERVAL_BETWEEN_ANIMATIONS,
      animatedValues.map(animateValue),
    );
  }, []);

  const compositeAnimationRef = useRef(createAnimation());

  // ─── Helpers ─────────────────────────────────────────────────────────

  const resetAnimatedValues = useCallback(() => {
    // eslint-disable-next-line unicorn/no-array-for-each
    animationValuesRef.current?.forEach(resetAnimatedValue);
  }, []);

  const restartAnimation = useCallback<Animated.EndCallback>(
    (state) => {
      if (!state?.finished) return;

      resetAnimatedValues();

      compositeAnimationRef.current?.reset?.();
      compositeAnimationRef.current?.start?.(restartAnimation);
    },
    [resetAnimatedValues],
  );

  // ─── Effects ─────────────────────────────────────────────────────────

  /**
   * Starts the animation on mount and resets it on unmount.
   */
  useEffect(() => {
    const pendingAnimation = compositeAnimationRef.current;

    compositeAnimationRef.current?.start?.(restartAnimation);

    return () => {
      pendingAnimation?.reset?.();
    };
  }, [restartAnimation]);

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(() => animationValuesRef.current.map(interpolateValue), []);
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

function animateValue(value: Animated.Value) {
  return Animated.timing(value, TIMING_CONFIG);
}

function resetAnimatedValue(value: Animated.Value) {
  value.setValue(0);
}

function interpolateValue(value: Animated.Value) {
  return value.interpolate(INTERPOLATION_CONFIG);
}

// ─── Constants ───────────────────────────────────────────────────────────────

const TIMING_CONFIG = {
  toValue: 1,
  duration: 750,
  useNativeDriver: Platform.OS !== 'web',
};

const INTERVAL_BETWEEN_ANIMATIONS = 150;

const INTERPOLATION_CONFIG = {
  inputRange: [0, 0.3, 0.6, 1],
  outputRange: [0, -7, 0, 0],
};

// ─── Types ───────────────────────────────────────────────────────────────────

type DotInterpolationsArray = Animated.AnimatedInterpolation<number>[];
