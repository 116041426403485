/* eslint-disable functional/immutable-data,react/no-array-index-key */

import React, { useCallback, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withSequence,
  withTiming,
} from 'react-native-reanimated';
import { type WithTimingConfig } from 'react-native-reanimated/src/animation/timing';
import { theme } from '@garnish/constants';

import { DisplayText } from '../../../Text';

// ─────────────────────────────────────────────────────────────────────────────

export const PinPromptInputDisplay = (props: PinPromptInputDisplayProps) => {
  const { value, maxLength, isInvalid } = props;

  // ─── Animations ──────────────────────────────────────────────────────

  const transformSV = useSharedValue(0);

  const shake = useCallback(() => {
    'worklet';

    transformSV.value = withSequence(
      withTiming(SHAKE_OFFSET, SHAKE_TIMING_CONFIG),
      withTiming(-SHAKE_OFFSET, SHAKE_TIMING_CONFIG),
      withTiming(SHAKE_OFFSET, SHAKE_TIMING_CONFIG),
      withTiming(0, SHAKE_TIMING_CONFIG),
    );
  }, [transformSV]);

  const containerAnimatedStyles = useAnimatedStyle(() => {
    return { transform: [{ translateX: transformSV.value }] };
  });

  const containerStyles = [styles.container, containerAnimatedStyles];

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(() => {
    if (!isInvalid) return;

    shake();
  }, [isInvalid, shake]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Animated.View style={containerStyles}>
      {Array.from({ length: maxLength }).map((_, index) => (
        <View key={index} style={styles.valueContainer}>
          <DisplayText
            sizeMatch={['48']}
            style={styles.displayInput}
            maxFontSizeMultiplier={1}
          >
            {value[index]}
          </DisplayText>
        </View>
      ))}
    </Animated.View>
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const SHAKE_OFFSET = 15;
const SHAKE_TIMING_CONFIG: WithTimingConfig = { duration: 60, easing: Easing.bounce }; // prettier-ignore

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing['2'],
    paddingVertical: theme.spacing['6'],
  },
  valueContainer: {
    width: 60,
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing['2'],
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.LIGHT,
  },
  displayInput: {
    marginTop: theme.spacing['2'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type PinPromptInputDisplayProps = {
  value: string;
  maxLength: number;
  isInvalid: boolean;
};
