import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useResponsive } from '@sg/garnish';

import { AppFooter } from '@order/components';
import { Loyalty } from '@order/features/loyalty';
import { useOptInUserV2Mutation } from '@order/graphql';
import { useTelemetry } from '@order/Telemetry';

import { useJoinSgRewardsContent } from './hooks';

export const JoinSgRewardsScreen = () => {
  const { formatMessage } = useIntl();
  const { track } = useTelemetry();
  const { currentBreakpoint, minWidth } = useResponsive();

  const { heroSection, lineItems, termsAndConditionsUrl } =
    useJoinSgRewardsContent();

  const [optInMutationResponse, optInMutation] = useOptInUserV2Mutation();

  const onPressCta = useCallback(async () => {
    track('sgrewards.optedout.join.cta_tapped');
    await optInMutation({});
  }, [optInMutation, track]);

  const handleOnLinkPress = useCallback(() => {
    track('reward.terms.cta_tapped');
  }, [track]);

  return (
    <Loyalty.JoinSgRewards.Container>
      {heroSection?.heroImage?.image && heroSection?.heroLogo?.image ? (
        <Loyalty.JoinSgRewards.Hero
          heroImage={heroSection.heroImage.image}
          logoImage={heroSection.heroLogo.image}
        >
          {heroSection?.heroImage?.title ? (
            <Loyalty.JoinSgRewards.Title>
              {heroSection.heroImage.title}
            </Loyalty.JoinSgRewards.Title>
          ) : null}

          {currentBreakpoint.isXS ? null : (
            <Loyalty.JoinSgRewards.Cta
              isLoading={optInMutationResponse.fetching}
              text={formatMessage(messages.buttonCta)}
              onPress={onPressCta}
            />
          )}
        </Loyalty.JoinSgRewards.Hero>
      ) : null}

      <Loyalty.JoinSgRewards.BodyContainer>
        <Loyalty.JoinSgRewards.ItemContainer>
          {lineItems.map((item) => (
            <Loyalty.JoinSgRewards.RowItem
              key={item.title}
              title={item.title}
              description={item.description}
              image={item.image}
            />
          ))}
        </Loyalty.JoinSgRewards.ItemContainer>

        <Loyalty.JoinSgRewards.Terms
          termsAndConditionsUrl={termsAndConditionsUrl}
          onLinkPress={handleOnLinkPress}
        />

        {currentBreakpoint.isXS ? (
          <Loyalty.JoinSgRewards.Cta
            isLoading={optInMutationResponse.fetching}
            text={formatMessage(messages.buttonCta)}
            onPress={onPressCta}
          />
        ) : null}
      </Loyalty.JoinSgRewards.BodyContainer>

      {minWidth.isSM ? <AppFooter /> : null}
    </Loyalty.JoinSgRewards.Container>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  buttonCta: {
    defaultMessage: 'Join SG Rewards',
    description: 'Join SG Rewards | Loyalty | Button CTA',
  },
});
