import React from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';
import { theme } from '@garnish/constants';
import { Icon, type IconName, useResponsive } from '@sg/garnish';

import { LoyaltyTerms } from '../../LoyaltyTerms';

export const LoyaltyHomeFooter = (props: LoyaltyHomeFooterProps) => {
  const { onLinkPress, termsAndConditionsUrl, style, isPremium } = props;

  const { match } = useResponsive();
  const containerStyle = [
    match([styles.containerXS, styles.containerSM]),
    style,
  ];

  const logoWidth = match([
    isPremium ? LOGO_WIDTH_XS_PREMIUM : LOGO_WIDTH_XS,
    LOGO_WIDTH_SM,
  ]);

  const logoIcon: IconName = isPremium
    ? 'IconSgRewardWithGoat'
    : 'IconSgRewardWithText';

  return (
    <View style={containerStyle}>
      <View style={match([styles.wrapperXS, styles.wrapperSM])}>
        <Icon name={logoIcon} width={logoWidth} />

        <LoyaltyTerms
          palette="light"
          termsAndConditionsUrl={termsAndConditionsUrl}
          onLinkPress={onLinkPress}
        />
      </View>
    </View>
  );
};

// ─── Constants ──────────────────────────────────────────────────────────────

const LOGO_WIDTH_XS = 230;
const LOGO_WIDTH_XS_PREMIUM = 300;
const LOGO_WIDTH_SM = 360;

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerXS: {
    flexGrow: 1,
    backgroundColor: theme.colors.FOREST,
    gap: theme.spacing['10'],
    paddingTop: theme.spacing['4'],
    paddingBottom: theme.spacing['25'],
    paddingHorizontal: theme.spacing['10'],
  },
  containerSM: {
    flexGrow: 1,
    backgroundColor: theme.colors.FOREST,
    gap: theme.spacing['10'],
    paddingTop: theme.spacing['16'],
    paddingBottom: theme.spacing['25'],
    paddingHorizontal: theme.spacing['30'],
  },
  wrapperXS: {
    marginTop: 'auto',
    alignItems: 'center',
    gap: theme.spacing['6'],
  },
  wrapperSM: {
    marginTop: 'auto',
    alignItems: 'center',
    gap: theme.spacing['12'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoyaltyHomeFooterProps = {
  onLinkPress?: () => void;
  termsAndConditionsUrl: string;
  isPremium: boolean;
  style: ViewProps['style'];
};
