import { logger as LOG } from '@garnish/logger';

import { getAppVersion, getEnvVars } from '@order/utils';

import { ORDER_APP_VERSION_SEARCH_PARAM } from '../../config';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Returns the Azure AD embedded frame URL with the required search parameters.
 */
export function getAzureAuthEmbeddedFrameUrl(): string {
  const { AZURE_AD_EMBEDDED_AUTH_URL } = getEnvVars();

  if (typeof AZURE_AD_EMBEDDED_AUTH_URL !== 'string') {
    log.error('`AZURE_AD_EMBEDDED_AUTH_URL` is not provided');

    return '';
  }

  return appendOrderAppVersionToUrl(AZURE_AD_EMBEDDED_AUTH_URL);
}

/**
 * Returns the Azure AD embedded frame logout URL with the required search parameters.
 */
export function getAzureAuthEmbeddedFrameLogoutUrl(): string {
  const { AZURE_AD_EMBEDDED_AUTH_LOGOUT_URL } = getEnvVars();

  if (typeof AZURE_AD_EMBEDDED_AUTH_LOGOUT_URL !== 'string') {
    log.error('`AZURE_AD_EMBEDDED_AUTH_LOGOUT_URL` is not provided');

    return '';
  }

  return appendOrderAppVersionToUrl(AZURE_AD_EMBEDDED_AUTH_LOGOUT_URL);
}

// ─────────────────────────────────────────────────────────────────────────────

function appendOrderAppVersionToUrl(url: string) {
  const appVersion = getAppVersion();

  return appendSearchParam(url, ORDER_APP_VERSION_SEARCH_PARAM, appVersion);
}

function appendSearchParam(url: string, searchParam: string, value: string) {
  const urlInstance = new URL(url);

  urlInstance.searchParams.append(searchParam, value);

  const updatedUrl = urlInstance.toString();

  // The Hermes engine can add extra '/' characters before search parameters;
  // therefore, we need to format the final output to avoid invalid characters
  // in the URL.
  return updatedUrl.replace('/?', '?').replaceAll('/&', '&');
}

// ─────────────────────────────────────────────────────────────────────────────

LOG.enable('GET_AZURE_AUTH_FRAME_URL');
const log = LOG.extend('GET_AZURE_AUTH_FRAME_URL');
