import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { BodyText, ButtonToggle, DisplayText, theme } from '@sg/garnish';

// ─────────────────────────────────────────────────────────────────────────────

export const CustomizationDetailsBreadToggle = (
  props: CustomizationDetailsBreadToggleProps,
) => {
  const { breadCalories, isActive, isDisabled, onChange } = props;

  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.separator} />

      <View style={styles.innerContainer}>
        <View style={styles.descriptionContainer}>
          <DisplayText sizeMatch={['24']}>
            {formatMessage(messages.description)}
          </DisplayText>
          <BodyText sizeMatch={['14']}>
            {formatMessage(messages.calories, {
              bread_calories: breadCalories,
            })}
          </BodyText>
        </View>

        <ButtonToggle
          isActive={isActive}
          isDisabled={isDisabled}
          onChange={onChange}
        >
          {isActive
            ? formatMessage(messages.buttonLabelActive)
            : formatMessage(messages.buttonLabel)}
        </ButtonToggle>
      </View>
    </View>
  );
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  description: {
    defaultMessage: 'Add a slice of free bread?',
    description: 'Customization | Dressing details | Add bread description',
  },
  buttonLabel: {
    defaultMessage: 'Add',
    description: 'Customization | Dressing details | Add bread button label',
  },
  buttonLabelA11y: {
    defaultMessage: 'Add slice of free bread',
    description: 'Customization | Dressing details | Add bread button label',
  },
  buttonLabelActive: {
    defaultMessage: 'Added',
    description: 'Customization | Dressing details | Remove bread button label',
  },
  buttonLabelActiveA11y: {
    defaultMessage: 'Added slice of free bread',
    description: 'Customization | Dressing details | Remove bread button label',
  },
  calories: {
    defaultMessage: '{bread_calories} cal',
    description: 'Customization | Dressing details | Bread calories',
  },
});

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -theme.spacing['6'],
  },
  descriptionContainer: {
    flex: 1,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: theme.spacing['6'],
    gap: theme.spacing['6'],
  },
  separator: {
    marginVertical: theme.spacing['4'],
    height: 8,
    backgroundColor: theme.colors.OPACITY.DARK_KALE.ALMOST_TRANSPARENT,
    borderTopWidth: 1,
    borderTopColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.OPACITY.DARK_KALE.LIGHTEST,
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type CustomizationDetailsBreadToggleProps = {
  isActive: boolean;
  isDisabled: boolean;
  onChange: () => void;
  breadCalories: number | undefined;
};
