import type { ComponentProps } from 'react';
import React, { useCallback } from 'react';
import type { AccessibilityProps, ViewProps } from 'react-native';
import { StyleSheet, View } from 'react-native';
import { theme } from '@garnish/constants';

import { slugify } from '../../utils';
import { ProductIngredientsList } from '../ProductIngredientsList';
import {
  LineItemDescription,
  LineItemFooter,
  LineItemHeader,
  LineItemImage,
  LineItemPressableHeader,
  LineItemPrice,
} from './subcomponents';

// ────────────────────────────────────────────────────────────────────────────────

export const ProductLineItemCard = (props: ProductLineItemCardProps) => {
  const {
    cloudinaryImageUrl = '',
    imageSize = 'regular',
    imagePalette,
    productName,
    description = '',
    price,
    addedIngredients = [],
    addedIngredientsLabel = 'Add:',
    removedIngredients = [],
    removedIngredientsLabel = 'Remove:',
    isDisabled,
    shareUrl = '',
    accessibilityLabel,
    onPress,
    onShare,
  } = props;

  const productSlug = slugify(productName);
  const shouldShowProductImage = Boolean(cloudinaryImageUrl);

  // ─────────────────────────────────────────────────────────────────

  const handleShare = useCallback(() => {
    onShare?.(shareUrl, productName);
  }, [onShare, shareUrl, productName]);

  // ─────────────────────────────────────────────────────────────────

  return (
    <View testID={`sg-line-item-card-${productSlug}`} style={styles.card}>
      {shouldShowProductImage ? (
        <View style={styles.imgWrapper}>
          <LineItemImage
            cloudinaryImageUrl={cloudinaryImageUrl}
            size={imageSize}
            onPress={onPress}
            isDisabled={isDisabled}
            palette={imagePalette}
            accessibilityLabel={accessibilityLabel}
          />
        </View>
      ) : null}

      <ContentWrapper>
        <DetailsWrapper>
          <View style={styles.detailsColumn}>
            {onPress ? (
              <LineItemPressableHeader
                productName={productName}
                productSlug={productSlug}
                onPress={onPress}
                disabled={isDisabled}
                accessibilityLabel={accessibilityLabel}
                accessibilityState={{ disabled: isDisabled }}
              />
            ) : (
              <LineItemHeader
                productName={productName}
                productSlug={productSlug}
              />
            )}

            <ProductIngredientsList
              addedIngredientsLabel={addedIngredientsLabel}
              addedIngredients={addedIngredients}
              addedIngredientsListTestID={`sg-line-item-card-added-ingredients-${productSlug}`}
              removedIngredientsLabel={removedIngredientsLabel}
              removedIngredients={removedIngredients}
              removedIngredientsListTestID={`sg-line-item-card-removed-ingredients-${productSlug}`}
            />

            {description ? (
              <LineItemDescription
                selectable={!onPress}
                productSlug={productSlug}
              >
                {description}
              </LineItemDescription>
            ) : null}
          </View>

          {price ? <LineItemPrice price={price} /> : null}
        </DetailsWrapper>

        <LineItemFooter
          {...props}
          productSlug={productSlug}
          onShare={onShare ? handleShare : undefined}
        />
      </ContentWrapper>
    </View>
  );
};

// ─── SUBCOMPONENTS ──────────────────────────────────────────────────────────────

const ContentWrapper = (props: Pick<ViewProps, 'children'>) => {
  return <View style={styles.contentWrapper}>{props.children}</View>;
};

const DetailsWrapper = (props: Pick<ViewProps, 'children'>) => {
  return <View style={styles.detailsWrapper}>{props.children}</View>;
};

// ─── STYLES ─────────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  card: {
    paddingVertical: theme.spacing['6'],
    flexDirection: 'row',
  },
  contentWrapper: {
    flex: 1,
    alignSelf: 'center',
  },
  imgWrapper: {
    paddingRight: theme.spacing['2'],
  },
  detailsWrapper: {
    flexDirection: 'row',
  },
  detailsColumn: {
    flex: 1,
  },
});

// ─── TYPES ──────────────────────────────────────────────────────────────────────

type ProductLineItemCardProps = Readonly<{
  productId: string;
  productName: string;
  description?: string;
  cloudinaryImageUrl?: string;
  imageSize?: 'regular' | 'large';
  imagePalette?: ComponentProps<typeof LineItemImage>['palette'];
  price?: string;
  quantity?: number;
  removeBtnLabel?: string;
  shareTooltipLabel?: string;
  shareBtnLabel?: string;
  shareUrl?: string;
  isFetchingFavorite?: boolean;
  isFavorite?: boolean;
  isDisabled?: boolean;
  onPress?: () => void;
  onRemove?: (productId: string) => void;
  onShare?: (productSlug: string, productName: string) => void;
  onFavorite?: () => void;
  onQuantityChange?:
    | ((selectedQuantity: number) => void)
    | ((selectedQuantity: number) => Promise<void>);
}> &
  Pick<
    ComponentProps<typeof ProductIngredientsList>,
    | 'addedIngredientsLabel'
    | 'addedIngredients'
    | 'removedIngredientsLabel'
    | 'removedIngredients'
  > &
  Pick<AccessibilityProps, 'accessibilityLabel'>;
