/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MenuContentRestaurantQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  costChannel?: Types.InputMaybe<Types.CostChannel>;
}>;

export type MenuContentRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly restaurant: {
    readonly __typename: 'Restaurant';
    readonly id: string;
    readonly slug: string;
    readonly name: string;
    readonly city: string;
    readonly state: string;
    readonly zipCode: string;
    readonly address: string;
    readonly phone: string;
    readonly isOutpost: boolean;
    readonly showDeliveryFeeDisclosure: boolean;
    readonly isAcceptingOrders: boolean;
    readonly notAcceptingOrdersReason: string;
    readonly deliveryFee: number;
    readonly flexMessage: string | null;
    readonly hours: {
      readonly __typename?: 'RestaurantHours';
      readonly formatted: string | null;
      readonly permanent: ReadonlyArray<{
        readonly __typename?: 'RestaurantStoreHours';
        readonly start: string;
        readonly end: string;
        readonly day: string;
      }>;
      readonly store: ReadonlyArray<{
        readonly __typename?: 'RestaurantStoreHours';
        readonly start: string;
        readonly end: string;
        readonly day: string;
      }>;
    };
    readonly menu: {
      readonly __typename: 'Menu';
      readonly id: string;
      readonly categories: ReadonlyArray<{
        readonly __typename: 'MenuCategory';
        readonly id: string;
        readonly name: string;
        readonly isCustom: boolean;
        readonly description: string | null;
        readonly products: ReadonlyArray<{
          readonly __typename: 'Product';
          readonly id: string;
          readonly calories: number;
          readonly categoryId: string;
          readonly description: string;
          readonly isModifiable: boolean;
          readonly isCustom: boolean;
          readonly customType: Types.CustomProductType | null;
          readonly isSalad: boolean;
          readonly name: string;
          readonly outOfStock: boolean;
          readonly restaurantId: string;
          readonly slug: string;
          readonly throttleItem: boolean;
          readonly cost: number;
          readonly asset: {
            readonly __typename?: 'Asset';
            readonly url: string;
          };
          readonly ingredients: ReadonlyArray<{
            readonly __typename: 'Ingredient';
            readonly id: string;
            readonly name: string;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          }>;
          readonly label: {
            readonly __typename: 'ProductLabel';
            readonly id: string;
            readonly name: string;
          } | null;
          readonly dietaryProperties: ReadonlyArray<{
            readonly __typename: 'DietaryProperty';
            readonly id: string;
            readonly name: Types.DietaryPropertyKind;
          }>;
        }>;
      }>;
    };
  } | null;
};

export const MenuContentRestaurantDocument = gql`
  query MenuContentRestaurant($id: ID!, $costChannel: CostChannel) {
    restaurant(id: $id) {
      id
      __typename
      slug
      name
      city
      state
      zipCode
      address
      phone
      isOutpost
      showDeliveryFeeDisclosure
      hours {
        formatted
        permanent {
          start
          end
          day
        }
        store {
          start
          end
          day
        }
      }
      isAcceptingOrders
      notAcceptingOrdersReason
      deliveryFee
      flexMessage
      menu {
        id
        __typename
        categories {
          id
          __typename
          name
          isCustom
          description
          products {
            id
            __typename
            asset {
              url
            }
            calories
            categoryId
            cost: channelCost(costChannel: $costChannel)
            description
            ingredients {
              id
              __typename
              name
              asset {
                url
              }
            }
            isModifiable
            isCustom
            customType
            isSalad
            label {
              id
              __typename
              name
            }
            dietaryProperties {
              id
              __typename
              name
            }
            name
            outOfStock
            restaurantId
            slug
            throttleItem
          }
        }
      }
    }
  }
`;

export function useMenuContentRestaurantQuery(
  options: Omit<
    Urql.UseQueryArgs<MenuContentRestaurantQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MenuContentRestaurantQuery,
    MenuContentRestaurantQueryVariables
  >({ query: MenuContentRestaurantDocument, ...options });
}
