import React, { type ComponentProps, useCallback, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { logger as LOG } from '@garnish/logger';
import { BodyText, theme, useToggleState, webOnlyStyles } from '@sg/garnish';

import { useCustomer } from '@order/Customer';
import { useFeatureFlag, useLaunchDarkly } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';
import { getAppVersion } from '@order/utils';

import { DebugMenuModal } from './DebugMenuModal';

LOG.enable('DEBUG_MENU');
const logger = LOG.extend('DEBUG_MENU');

export const DebugMenuTrigger = () => {
  const { overrides } = useLaunchDarkly();
  const isDebugMenuEnabled = useFeatureFlag('permanent-debug-menu-enabled');

  // ─── State ───────────────────────────────────────────────────────────

  const {
    value: isShowingDebugModal,
    toggleOn: showDebugModal,
    toggleOff: hideDebugModal,
  } = useToggleState();

  const [triggerCount, setTriggerCount] = useState(1);
  const hasOverrides = Object.keys(overrides).length > 0;

  const isPressable = triggerCount < COUNT_TO_TRIGGER;

  // ─── Telemetry ───────────────────────────────────────────────────────

  const { track } = useTelemetry();
  const { customer, isCustomerLoaded } = useCustomer();
  const email = customer?.email ?? '';
  const isLoggedIn = isCustomerLoaded;

  const handleOpenDebugMenu = useCallback(() => {
    logger.warn('DEBUG_MENU_OPENED', { email, isLoggedIn });
    track('debug-menu.view', { email, isLoggedIn });
    showDebugModal();
  }, [email, isLoggedIn, track, showDebugModal]);

  // ─── Callbacks ───────────────────────────────────────────────────────

  const handleOnPress = useCallback(() => {
    setTriggerCount((count) => {
      if (count >= COUNT_TO_TRIGGER) handleOpenDebugMenu();

      return count + 1;
    });
  }, [handleOpenDebugMenu]);

  if (!isDebugMenuEnabled) return <AppVersion />;

  return (
    <>
      <TouchableOpacity
        onPress={handleOnPress}
        accessibilityRole="button"
        accessibilityLabel="Debug Menu"
        disabled={isShowingDebugModal}
        style={isPressable ? disabledTriggerStyle : styles.pressableContainer}
      >
        <AppVersion bold={hasOverrides} />
      </TouchableOpacity>

      <DebugMenuModal
        visible={isShowingDebugModal}
        onRequestClose={hideDebugModal}
      />
    </>
  );
};

const AppVersion = (props: Partial<ComponentProps<typeof BodyText>>) => {
  return <BodyText {...props}>v{getAppVersion()}</BodyText>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressableContainer: {
    alignSelf: 'flex-start',
    backgroundColor: theme.colors.SWEET_CORN,
    padding: theme.spacing['1'],
    borderRadius: theme.radius.small,
  },
});

const disabledTriggerStyle = webOnlyStyles({
  userSelect: 'none',
  cursor: 'default',
});

const COUNT_TO_TRIGGER = 5;
