import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Modal, theme } from '@sg/garnish';

export const FooterLayout = (props: PropsWithChildren) => {
  return <Modal.Footer style={styles.footer}>{props.children}</Modal.Footer>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    borderTopColor: theme.colors.DARK_KALE,
    paddingHorizontal: theme.spacing['4'],
  },
});
