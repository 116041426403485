import React from 'react';
import { StyleSheet, View } from 'react-native';
import type { AddressType } from '@sg/garnish';
import {
  AddressOrder,
  AddressTypes,
  BodyText,
  Ledger as LedgerComponent,
  SectionHeader,
  theme,
  VStack,
} from '@sg/garnish';

import { usePreferencesText } from '@order/components';
import type { CourierDetails } from '@order/graphql';
import type { Ledger, PartialLineItem } from '@order/graphql';
import { DeliveryPreferenceType, FlattenedOrderStatuses } from '@order/graphql';
import { useLocalizationContext } from '@order/Localization';

import { useLedgerLineItems } from './hooks';
import { CourierInfo } from './OrderStatusCourierInfo';
import { OrderStatusLineItems } from './OrderStatusLineItems';

export const OrderStatusDetails = (props: OrderStatusDetailsProps) => {
  const showCourierInfoForVStackSpacing =
    props.orderType === 'delivery' &&
    props.orderStatus === FlattenedOrderStatuses.Delivering &&
    props.courierName &&
    props.courierPhone; /* note: VStack adds spacing for null inner children. */

  return (
    <VStack gap={theme.spacing['10']}>
      {showCourierInfoForVStackSpacing ? <CourierInfo {...props} /> : null}

      <AddressSection {...props} />

      <OrderSection {...props} />
    </VStack>
  );
};

export const AddressSection = (props: OrderStatusDetailsProps) => {
  const {
    orderType,
    deliveryAddressName,
    deliveryAddressStreet,
    deliveryAddressSecondaryStreet,
    deliveryAddressCity,
    deliveryAddressState,
    deliveryAddressZipCode,
    deliveryAddressNotes,
    deliveryPreference,
    locationName,
    locationAddress,
    locationCity,
    locationState,
    locationZipCode,
    dropoffLocation,
  } = props;

  const { t } = useLocalizationContext();

  const deliveryPreferenceText = usePreferencesText(
    deliveryPreference ?? DeliveryPreferenceType.LeaveAtDoor,
  );
  const isDelivery = orderType === 'delivery';

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <View dataSet={{ ddPrivacy: isDelivery ? 'mask' : 'mask-user-input' }}>
      <SectionHeader
        style={styles.addressHeader}
        heading={
          // eslint-disable-next-line no-nested-ternary -- Nx + ESLint Update 2023-12-10
          orderType === AddressTypes.delivery
            ? t('general.delivery-location')
            : orderType === AddressTypes.pickup
              ? t('general.pickup-location')
              : t('general.outpost-location')
        }
      />

      <AddressOrder
        addressType={orderType}
        name={isDelivery ? deliveryAddressName : locationName}
        street={isDelivery ? deliveryAddressStreet : locationAddress}
        city={isDelivery ? deliveryAddressCity : locationCity}
        state={isDelivery ? deliveryAddressState : locationState}
        zipCode={isDelivery ? deliveryAddressZipCode : locationZipCode}
        secondaryStreet={deliveryAddressSecondaryStreet}
        hideIcon
      />

      {dropoffLocation ? (
        <View style={styles.preferences}>
          <BodyText size={4}>{dropoffLocation}</BodyText>
        </View>
      ) : null}

      {isDelivery ? (
        <View style={styles.preferences}>
          <BodyText size={4}>{deliveryPreferenceText}</BodyText>

          <BodyText size={4}>{deliveryAddressNotes}</BodyText>
        </View>
      ) : null}
    </View>
  );
};

export const OrderSection = (props: OrderStatusDetailsProps) => {
  const { lineItems, ledger } = props;
  const { t } = useLocalizationContext();

  const ledgerTip = ledger.tip ?? 0;
  const ledgerItems = useLedgerLineItems({
    ledger,
    showTip: ledgerTip > 0,
    showTotal: true,
    showTaxes: true,
    showReward: true,
  });

  return (
    <View>
      <View>
        <SectionHeader heading={t('general.your-order')} />

        <OrderStatusLineItems lineItems={lineItems} />
      </View>

      <LedgerComponent
        style={styles.ledger}
        testID="order-status-details-ledger"
        hasTopBorder={true}
        items={ledgerItems}
      />
    </View>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  addressHeader: {
    marginBottom: theme.spacing['6'],
  },
  ledger: {
    paddingBottom: 0,
  },
  preferences: {
    marginTop: theme.spacing['4'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderStatusDetailsProps = Readonly<{
  orderType: AddressType;
  orderStatus?: FlattenedOrderStatuses;
  deliveryAddressName?: string;
  deliveryAddressStreet?: string;
  deliveryAddressSecondaryStreet?: string;
  deliveryAddressCity?: string;
  deliveryAddressState?: string;
  deliveryAddressZipCode?: string;
  deliveryAddressNotes?: string;
  deliveryPreference?: DeliveryPreferenceType;
  locationName?: string;
  locationAddress?: string;
  locationCity?: string;
  locationState?: string;
  locationZipCode?: string;
  lineItems: readonly PartialLineItem[];
  dropoffLocation?: string;
  ledger: Partial<Ledger>;
  courierName?: CourierDetails['name'];
  courierPhone?: CourierDetails['phone'];
}>;
