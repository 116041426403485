import React, {
  Children,
  type ComponentProps,
  forwardRef,
  type ReactNode,
  useCallback,
} from 'react';
import type { ScrollViewProps } from 'react-native';
import { type ScrollView, View } from 'react-native';
import Animated, { useAnimatedRef } from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';
import useMergedRef from '@react-hook/merged-ref';
import { theme } from '@garnish/constants';

import { useRailItemsRef, useRailNavigationState } from './hooks';
import {
  HorizontalScrollRailFooterText,
  HorizontalScrollRailHeader,
  HorizontalScrollRailItem,
} from './subcomponents';

// ─────────────────────────────────────────────────────────────────────────────

export const HorizontalScrollRail = forwardRef<
  ScrollView,
  HorizontalScrollRailProps
>((props, ref) => {
  const {
    children: railItems,
    heading = '',
    headingIcon,
    headerPalette = 'dark',
    headerBottomSpacing,
    subtitle = '',
    footerText = '',
    titleStyle,
    headerVariation,
    gap = RAIL_DEFAULT_GAP,
    outerOffset = RAIL_DEFAULT_OUTER_OFFSET,
    showNavControls = true,
    withoutHeaderBorder,
    count = 0,
    countPosition,
    onScroll,
    onLayout,
    ...scrollViewProps
  } = props;

  const itemsCount = Children.count(railItems);

  // ─── Refs ────────────────────────────────────────────────────────────

  const railRef = useAnimatedRef<Animated.ScrollView>();
  const railItemsRef = useRailItemsRef({ railItemsCount: itemsCount });

  // ─── Navigation ──────────────────────────────────────────────────────

  const {
    scrollToNextItem,
    scrollToPreviousItem,
    setViewableState,
    setViewableStateOnLayout,
    prevItemIndexSV,
    nextItemIndexSV,
  } = useRailNavigationState({ railRef, railItemsRef, outerOffset });

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleOnLayout = useCallback<OnLayout>(
    (...args) => {
      setViewableStateOnLayout();
      onLayout?.(...args);
    },
    [setViewableStateOnLayout, onLayout],
  );

  const handleOnScroll = useCallback<OnScroll>(
    (...args) => {
      onScroll?.(...args);
      setViewableState();
    },
    [onScroll, setViewableState],
  );

  // ─── Styles ──────────────────────────────────────────────────────────

  const contentContainerStyles = useStyle(
    () => ({ paddingHorizontal: Math.abs(outerOffset), columnGap: gap }),
    [outerOffset, gap],
  );
  const railStyle = useStyle(
    () => ({ marginHorizontal: outerOffset }),
    [outerOffset],
  );

  // ─── Flags ───────────────────────────────────────────────────────────

  const shouldRenderHeader = heading || showNavControls;

  // ─────────────────────────────────────────────────────────────────────

  return (
    <View>
      {shouldRenderHeader ? (
        <HorizontalScrollRailHeader
          heading={heading}
          headingIcon={headingIcon}
          headerPalette={headerPalette}
          headerBottomSpacing={headerBottomSpacing}
          titleStyle={titleStyle}
          subtitle={subtitle}
          headerVariation={headerVariation}
          showNavControls={showNavControls}
          prevItemIndexSV={prevItemIndexSV}
          nextItemIndexSV={nextItemIndexSV}
          withoutHeaderBorder={withoutHeaderBorder}
          countPosition={countPosition}
          count={count}
          onPrevBtnPress={scrollToPreviousItem}
          onNextBtnPress={scrollToNextItem}
        />
      ) : null}

      <Animated.ScrollView
        ref={useMergedRef(railRef, ref)}
        style={railStyle}
        contentContainerStyle={contentContainerStyles}
        scrollEventThrottle={16}
        horizontal
        showsHorizontalScrollIndicator={false}
        onLayout={handleOnLayout}
        onScroll={handleOnScroll}
        {...scrollViewProps}
      >
        {Children.map(railItems, (item, index) => (
          <HorizontalScrollRailItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            railItemsRef={railItemsRef}
          >
            {item}
          </HorizontalScrollRailItem>
        ))}
      </Animated.ScrollView>

      {footerText ? (
        <HorizontalScrollRailFooterText>
          {footerText}
        </HorizontalScrollRailFooterText>
      ) : null}
    </View>
  );
});

// ─── Constants ───────────────────────────────────────────────────────────────

const RAIL_DEFAULT_GAP = theme.spacing['4'];
const RAIL_DEFAULT_OUTER_OFFSET = 0;

// ─── Types ───────────────────────────────────────────────────────────────────

type HorizontalScrollRailProps = Readonly<{
  children: readonly ReactNode[];
  gap?: number;
  outerOffset?: number;
  footerText?: string;
  count?: number;
  countPosition?: ComponentProps<typeof HorizontalScrollRailHeader>['countPosition']; // prettier-ignore
  showNavControls?: boolean;
  itemVisiblePercentThreshold?: number;
  onContentSizeChange?: (width: number, height: number) => void;
  headerPalette?: 'dark' | 'light';
  shouldResetOffset?: boolean;
}> &
  Omit<
    ComponentProps<typeof HorizontalScrollRailHeader>,
    | 'headerPalette'
    | 'disablePrevBtn'
    | 'disableNextBtn'
    | 'count'
    | 'countPosition'
    | 'onNextBtnPress'
    | 'onPrevBtnPress'
    | 'prevItemIndexSV'
    | 'nextItemIndexSV'
  > &
  Omit<ScrollViewProps, 'ref' | 'horizontal'>;

type OnLayout = NonNullable<ScrollViewProps['onLayout']>;
type OnScroll = NonNullable<ScrollViewProps['onScroll']>;
