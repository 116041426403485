/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { RestaurantMenuFragmentDoc } from '../../MenuScreen/GraphQL/Restaurant.query.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AddressQueryVariables = Types.Exact<{
  addressId: Types.Scalars['ID']['input'];
}>;

export type AddressQuery = {
  readonly __typename?: 'Query';
  readonly address: {
    readonly __typename: 'Address';
    readonly id: string;
    readonly street: string;
    readonly secondaryStreet: string | null;
    readonly city: string;
    readonly state: string;
    readonly country: string;
    readonly zipCode: string;
    readonly deliveryPreference: Types.DeliveryPreferenceType;
    readonly googlePlaceId: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly name: string | null;
    readonly notes: string | null;
  } | null;
};

export type DeliveryRestaurantQueryVariables = Types.Exact<{
  input: Types.LocationByAddressInput;
  costChannel?: Types.InputMaybe<Types.CostChannel>;
}>;

export type DeliveryRestaurantQuery = {
  readonly __typename?: 'Query';
  readonly locationByAddress:
    | { readonly __typename: 'InvalidInput' }
    | {
        readonly __typename: 'Location';
        readonly status: Types.LocationStatus;
        readonly restaurant: {
          readonly __typename: 'Restaurant';
          readonly id: string;
          readonly slug: string;
          readonly name: string;
          readonly city: string;
          readonly state: string;
          readonly zipCode: string;
          readonly address: string;
          readonly phone: string;
          readonly isOutpost: boolean;
          readonly deliveryFee: number;
          readonly showDeliveryFeeDisclosure: boolean;
          readonly isAcceptingOrders: boolean;
          readonly notAcceptingOrdersReason: string;
          readonly flexMessage: string | null;
          readonly hours: {
            readonly __typename?: 'RestaurantHours';
            readonly formatted: string | null;
          };
          readonly menu: {
            readonly __typename: 'Menu';
            readonly id: string;
            readonly categories: ReadonlyArray<{
              readonly __typename: 'MenuCategory';
              readonly id: string;
              readonly name: string;
              readonly isCustom: boolean;
              readonly products: ReadonlyArray<{
                readonly __typename: 'Product';
                readonly id: string;
                readonly calories: number;
                readonly categoryId: string;
                readonly description: string;
                readonly isModifiable: boolean;
                readonly isCustom: boolean;
                readonly customType: Types.CustomProductType | null;
                readonly isSalad: boolean;
                readonly name: string;
                readonly outOfStock: boolean;
                readonly restaurantId: string;
                readonly slug: string;
                readonly throttleItem: boolean;
                readonly cost: number;
                readonly asset: {
                  readonly __typename?: 'Asset';
                  readonly url: string;
                };
                readonly ingredients: ReadonlyArray<{
                  readonly __typename: 'Ingredient';
                  readonly id: string;
                  readonly name: string;
                  readonly asset: {
                    readonly __typename?: 'Asset';
                    readonly url: string;
                  };
                }>;
                readonly label: {
                  readonly __typename: 'ProductLabel';
                  readonly id: string;
                  readonly name: string;
                } | null;
                readonly dietaryProperties: ReadonlyArray<{
                  readonly __typename: 'DietaryProperty';
                  readonly id: string;
                  readonly name: Types.DietaryPropertyKind;
                }>;
              }>;
            }>;
          };
        };
        readonly estimateRange: {
          readonly __typename?: 'EstimateRange';
          readonly start: number;
          readonly end: number;
        };
        readonly vendor: {
          readonly __typename?: 'Vendor';
          readonly name: string;
          readonly restaurantId: string;
        };
      }
    | { readonly __typename: 'NoValidRestaurants' }
    | { readonly __typename: 'ValidationError' };
};

export const AddressDocument = gql`
  query Address($addressId: ID!) {
    address(addressId: $addressId) {
      id
      __typename
      street
      secondaryStreet
      city
      state
      country
      zipCode
      deliveryPreference
      googlePlaceId
      latitude
      longitude
      name
      notes
    }
  }
`;

export function useAddressQuery(
  options: Omit<Urql.UseQueryArgs<AddressQueryVariables>, 'query'>,
) {
  return Urql.useQuery<AddressQuery, AddressQueryVariables>({
    query: AddressDocument,
    ...options,
  });
}
export const DeliveryRestaurantDocument = gql`
  query DeliveryRestaurant(
    $input: LocationByAddressInput!
    $costChannel: CostChannel
  ) {
    locationByAddress(input: $input) {
      __typename
      ... on Location {
        restaurant {
          ...RestaurantMenu
        }
        estimateRange {
          start
          end
        }
        vendor {
          name
          restaurantId
        }
        status
      }
    }
  }
  ${RestaurantMenuFragmentDoc}
`;

export function useDeliveryRestaurantQuery(
  options: Omit<Urql.UseQueryArgs<DeliveryRestaurantQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    DeliveryRestaurantQuery,
    DeliveryRestaurantQueryVariables
  >({ query: DeliveryRestaurantDocument, ...options });
}
