import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Platform, StyleSheet } from 'react-native';
import Animated, {
  useAnimatedRef,
  useScrollViewOffset,
} from 'react-native-reanimated';
import {
  BodyText,
  CustomNavigationHeader,
  getIosModalPresentationConfig,
  LoadingDots,
  Modal,
  theme,
  useResponsive,
} from '@sg/garnish';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyContent } from '@order/hooks';
import { useTelemetry } from '@order/Telemetry';

import { useLoyaltyPointHistory } from '../../hooks';

export const LoyaltyPointHistoryModal = (
  props: LoyaltyPointHistoryModalProps,
) => {
  const { visible, availablePoints, startOrder, onRequestClose } = props;
  const { match } = useResponsive();

  // ─── Query ────────────────────────────────────────────────────────────────

  const {
    pointHistory,
    pointExpirationEntries,
    canLoadMorePointHistory,
    isLoadingPointHistory,
    hasFailedToLoad,
    handleLoadMorePointHistory,
    refetchPointHistory,
  } = useLoyaltyPointHistory();

  // ──────────────────────────────────────────────────────────────────────────

  return (
    <Modal
      fitHeight={match([true, false])}
      size={match(['full', 'medium'])}
      visible={visible}
      contentContainerStyle={styles.headerStyle}
      onRequestClose={onRequestClose}
      {...getIosModalPresentationConfig()}
    >
      {hasFailedToLoad ? (
        <Loyalty.PointsHistory.ErrorState
          isLoading={isLoadingPointHistory}
          refetch={refetchPointHistory}
          onRequestClose={onRequestClose}
        />
      ) : (
        <ModalContent
          availablePoints={availablePoints}
          pointHistory={pointHistory}
          pointExpirationEntries={pointExpirationEntries}
          canLoadMorePointHistory={canLoadMorePointHistory}
          isLoadingPointHistory={isLoadingPointHistory}
          startOrder={startOrder}
          onRequestClose={onRequestClose}
          handleLoadMorePointHistory={handleLoadMorePointHistory}
        />
      )}
    </Modal>
  );
};

const ModalContent = (props: LoyaltyPointHistoryContentProps) => {
  const {
    availablePoints,
    pointHistory,
    pointExpirationEntries,
    canLoadMorePointHistory,
    isLoadingPointHistory,
    handleLoadMorePointHistory,
    startOrder,
    onRequestClose,
  } = props;

  const { formatMessage } = useIntl();
  const { match } = useResponsive();

  const hasPointsHistory = pointHistory.length > 0;

  // ─── Custom Navigation Header ─────────────────────────────────────────────

  const animatedRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffsetSV = useScrollViewOffset(animatedRef);

  // ─── Faq ──────────────────────────────────────────────────────────────────

  const { faqPointHistory } = useLoyaltyContent({ availablePoints: undefined });

  // ──────────────────────────────────────────────────────────────────────────

  // ─── Telemetry ───────────────────────────────────────────────────────

  const { track } = useTelemetry();

  const handleOnPressSeeMore = useCallback(() => {
    track('loyalty.pointhistory.viewmore.cta_tapped');
    handleLoadMorePointHistory();
  }, [track, handleLoadMorePointHistory]);

  const handleOnChangeAccordion = useCallback(() => {
    track('loyalty.pointhistory.faq_tapped');
  }, [track]);

  return (
    <Animated.ScrollView
      stickyHeaderIndices={[0]}
      ref={animatedRef}
      contentContainerStyle={styles.container}
    >
      <CustomNavigationHeader.Container
        palette="cream"
        safeAreaEdges={Platform.OS === 'android' ? ['top'] : []}
        scrollOffsetSV={scrollOffsetSV}
      >
        <CustomNavigationHeader.Content
          transition={match([null, 'opacity'])}
          scrollOffsetSV={scrollOffsetSV}
        >
          <BodyText style={styles.headerText} sizeMatch={['16']}>
            {formatMessage(messages.title)}
          </BodyText>
        </CustomNavigationHeader.Content>

        <CustomNavigationHeader.Button.Right
          name="IconClose"
          accessibilityLabel={formatMessage(messages.close)}
          onPress={onRequestClose}
        />
      </CustomNavigationHeader.Container>

      <Loyalty.PointsHistory.Header availablePoints={availablePoints} />

      {isLoadingPointHistory && !hasPointsHistory ? <LoadingDots /> : null}

      {!isLoadingPointHistory && !hasPointsHistory ? (
        <Loyalty.PointsHistory.EmptyState onPress={startOrder} />
      ) : null}

      {pointExpirationEntries?.map((pointExpirationEntry) => (
        <Loyalty.PointsHistory.ExpirationRow
          key={pointExpirationEntry.id}
          {...pointExpirationEntry}
        />
      ))}

      {pointHistory.map((pointHistoryEntry) => (
        <Loyalty.PointsHistory.Row
          key={pointHistoryEntry.id}
          {...pointHistoryEntry}
        />
      ))}

      {canLoadMorePointHistory && hasPointsHistory ? (
        <Loyalty.PointsHistory.SeeMoreCta
          isLoading={isLoadingPointHistory}
          onPress={handleOnPressSeeMore}
        />
      ) : null}

      {faqPointHistory.length > 0 ? (
        <Loyalty.FAQ
          entries={faqPointHistory}
          onChange={handleOnChangeAccordion}
        />
      ) : null}
    </Animated.ScrollView>
  );
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  title: {
    defaultMessage: 'Points History + FAQ',
    description: 'Loyalty | Points History | Title',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Loyalty | Points History | Close',
  },
});

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingBottom: theme.spacing['10'],
    gap: theme.spacing['6'],
    backgroundColor: theme.colors.CREAM,
  },
  headerStyle: {
    borderTopLeftRadius: theme.radius.large,
    borderTopRightRadius: theme.radius.large,
  },
  headerText: {
    textAlign: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyPointHistoryModalProps = {
  availablePoints: number;
  visible: boolean;
  startOrder: () => void;
  onRequestClose: () => void;
};

type LoyaltyPointHistory = ReturnType<typeof useLoyaltyPointHistory>;

type LoyaltyPointHistoryContentProps = {
  availablePoints: number;
  pointHistory: LoyaltyPointHistory['pointHistory'];
  pointExpirationEntries: LoyaltyPointHistory['pointExpirationEntries'];
  canLoadMorePointHistory: LoyaltyPointHistory['canLoadMorePointHistory'];
  isLoadingPointHistory: LoyaltyPointHistory['isLoadingPointHistory'];
  handleLoadMorePointHistory: LoyaltyPointHistory['handleLoadMorePointHistory'];
  startOrder: () => void;
  onRequestClose: () => void;
};
