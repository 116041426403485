export const account = {
  'account.go-back': 'Back to account options',

  // ─── Logged Out State ────────────────────────────────────────────────

  'account.logged-out.title': 'Welcome to the sweet life',
  'account.logged-out.sign-in': 'Sign in',
  'account.logged-out.not-member': "Don't have an account?",
  'account.logged-out.join-now': 'Join now',

  // ─── Account Sidebar ─────────────────────────────────────────────────

  'account.sidebar.sweetpass-membership': 'Sweetpass membership',
  'account.sidebar.sweetpass-join': 'Unlock benefits',
  'account.sidebar.sweetpass-join.tag': 'Explore',
  'account.sidebar.account-info': 'Account info',
  'account.sidebar.credit-and-promo-codes': 'Credit & promo codes',
  'account.sidebar.payment-and-gift-cards': 'Payment & gift cards',
  'account.sidebar.payments': 'Payments',
  'account.sidebar.addresses': 'Addresses',
  'account.sidebar.orders': 'Orders',
  'account.sidebar.favorites': 'Favorites',
  'account.sidebar.refer-friend': 'Refer a friend',
  'account.sidebar.dietary-restrictions': 'Dietary restrictions',
  'account.sidebar.contact-us': 'Contact us',
  'account.sidebar.terms': 'Terms',
  'account.sidebar.privacy-policy': 'Privacy policy',
  'account.sidebar.do-not-sell': 'Do not sell my personal information',
  'account.sidebar.instagram': 'Instagram',
  'account.sidebar.twitter': 'Twitter',
  'account.sidebar.tiktok': 'TikTok',
  'account.sidebar.spotify': 'Spotify',
  'account.sidebar.facebook': 'Facebook',
  'account.sidebar.sign-out': 'Sign out',
  'account.sidebar.js-crash': 'Javascript Crash',
  'account.sidebar.native-crash': 'Native Crash',

  // ─── Account Profile ─────────────────────────────────────────────────

  'account.profile.title': 'Account info',
  'account.profile.save': 'Save',
  'account.profile.cancel': 'Cancel',

  // ─── Account Payments ────────────────────────────────────────────────

  'account.payment.title': 'Payment methods',
  'account.payment.default': 'Default',
  'account.payment.expired': 'Card expired',
  'account.payment.expiring': 'Card will expire soon',
  'account.payment.new': 'Add credit or debit card',

  // ─── Account Credit and Promo Code ───────────────────────────────────

  'account.credit-and-promo-codes.title': 'Credit & promo codes',

  // ─── Payment and Gift Cards ──────────────────────────────────────────

  'account.payment-and-gift-cards.title': 'Payment & gift cards',
  'account.payments.title': 'Payments',

  'account.gift-cards.title': 'Gift card',
  'account.gift-cards.gift-card-total': 'Gift card total',
  'account.gift-cards.add-gift-card': 'Add gift card',

  // ─── Account Credit ──────────────────────────────────────────────────

  'account.credit.label': 'Total credit',
  'account.credit.mobile-label': 'Available credit',
  'account.credit.view-details': 'View details',
  'account.credit.close-details': 'Close details',
  'account.credit.expires': 'Expires {date}',
  'account.credit.field-placeholder': 'Add promo or gift code',
  'account.credit.field-add-promo-code-placeholder': 'Add promo code',
  'account.credit.field-promo-code-placeholder': 'Promo code',
  'account.credit.a11y-label': 'Promo or gift code',
  'account.credit.promo-code-a11y-label': 'Promo code',
  'account.credit-promo.title': 'Credit & promo codes',
  'account.credit-promo.helper-text': `If entering gift card number, your gift card amount will be transferred to your online credit card and you will no longer be able to use your physical gift card.

Some promo codes will generate new rewards that can only be applied to an active order in your bag.`,
  'account.credit.apply-promo-code-btn-a11y-label': 'Apply promo code',
  'account.credit-promo.helper-text-promo-code':
    'Some promo codes will generate new rewards that can only be applied to an active order in your bag.',
  'account.credit.list-item.label.online-only': 'Online only',
  'account.credit.list-item.label.in-store-only': 'In-store only',
  'account.credit.apply': 'Apply',
  'account.scan-to-pay.header.title': 'Scan to pay',
  'account.scan-to-pay.header.subtitle':
    'To pay in store, please scan this code at checkout',
  'account.scan-to-pay.payments': 'Payments',
  'account.scan-to-pay.enter-promo-code': 'Enter promo code',
  'account.scan-to-pay.form-subtitle':
    'To use scan to pay please add a credit or debit card to your account.',
  'account.order-card.title': '{wantedTime, date, short} · {orderType}',
  'account.shared-by': 'By {name}',
  'account.notice.credit-applied': 'Credit added successfully',
  'account.notice.credit-invalid': 'This code or giftcard is not valid.',
  'account.notice.credit-promo-code-invalid': 'This code is not valid.',
  'account.notice.credit-already-applied':
    'This code or giftcard has already been applied',
  'account.notice.credit-promo-code-already-applied':
    'This code has already been applied',
  'account.notice.promo-applied':
    'Your promo has been added as an reward. Apply it to an active order in your bag.',
  'account.scan-to-pay.scan-gift-code-message':
    'Align QR code in the box above & hold steady.',
  'account.scan-to-pay.scan-gift-code-camera-permission-message':
    'Refer to your device’s system settings to enable camera permissions.',

  // ─── Account Addresses ───────────────────────────────────────────────

  'account.addresses.title': 'Addresses',

  // ─── Account Orders ──────────────────────────────────────────────────

  'account.orders.title': 'Orders',
  'account.orders.list': 'Orders list',
  'account.orders.load-more': 'See more orders',
  'account.orders.empty':
    'Once you place an order your history will appear here',
  'account.orders.empty.illustration-alt': 'A person holding a Sweetgreen bag',
  'account.orders.cancel': 'Cancel order',
  'account.orders.canceled': 'Canceled ·',

  // ─── Account Favorites ───────────────────────────────────────────────

  'account.favorites.title': 'Favorites',
  'account.favorites.empty':
    'Once you favorite a product your favorites will appear here',
  'account.favorites.illustration.alt': 'Sweetgreen customer with a bag',
  'account.favorites.load-more': 'See more favorites',
  'account.favorites.quantity': 'Quantity: {quantity}',
  'account.favorites.add-to-bag': 'Add to bag',

  // ─── Account Refer Friend ────────────────────────────────────────────

  'account.refer-friend.title': 'Refer a friend',
  'account.refer-friend.subtitle': 'Give $7 + get $7',
  'account.refer-friend.description':
    "Invite your friends to download our app, and they'll each get $7 in credit to spend at sweetgreen. Once they spend their $7, you'll score $7 too!",
  'account.refer-friend.share.hint': 'Share via:',
  'account.refer-friend.share.title': "Here's $7 to spend @ sweetgreen",
  'account.refer-friend.share.body':
    'Hey! Download the sweetgreen app using my invite link and get $7 off your first order.%0D%0A{shareLink}',
  'account.refer-friend.share.content':
    "Here's $7 to spend @ sweetgreen\n\nHey! Download the sweetgreen app using my invite link and get $7 off your first order.\n\n{shareLink}",
  'account.refer-friend.share.email': 'Email',
  'account.refer-friend.share.facebook': 'Facebook',
  'account.refer-friend.share.twitter': 'Twitter',
  'account.refer-friend.share.whatsapp': 'Whatsapp',
  'account.refer-friend.share.instagram': 'Instagram',
  'account.refer-friend.share.tiktok': 'Tiktok',
  'account.refer-friend.share.more': 'More',
  'account.refer-friend.copy.hint': 'Or copy the link:',

  // ─── Account Delete ──────────────────────────────────────────────────

  'account.delete.headline': 'Delete account',
  'account.delete.subhead.step1': 'Step 1 of 2',
  'account.delete.subhead.step2': 'Step 2 of 2',
  'account.delete.cancelSubscription': 'Cancel Sweetpass+',
  'account.delete.trigger': 'Delete account',
  'account.delete.confirm': 'Delete your data & account',
  'account.delete.success':
    'You have successfully requested to delete your account.',
  'account.delete.step1': `You must cancel your Sweetpass+ membership in order to delete your sweetgreen account.

By clicking "Cancel Sweetpass+" below, your Sweetpass+ membership will end on {nextBillingDate}. Keep your membership active to keep all-access to your delicious benefits and keep saving.`,
  'account.delete.step2': `Click “Delete your data & account” in order to submit your request. A confirmation email will be sent to you after submitting your request. You must select "Confirm Email" to continue your account deletion. You will generally hear back from us about your request within 45 days, but in some cases it may take up to 90 days, in which case we will notify you prior to 45 days that an extension is required.

Please also note that if you request that sweetgreen delete your data, sweetgreen will need to delete your sweetgreen account entirely and that we will be unable to provide any online or digital services to you or support for past products or services that you have purchased.

If you have any other questions about the rights to your sweetgreen data, please email us at privacy@sweetgreen.com or visit our privacy policy at https://www.sweetgreen.com/privacy-policy.`,

  // ─── Account Errors ──────────────────────────────────────────────────

  'account.sign-out.error':
    'Something went wrong, try again in a few moments...',
  'account.profile.error':
    'Oops! We were unable to save your changes. Try again in a bit.',
  'account.cancel-subscription.error':
    'Something went wrong with cancelling your subscription.',
  'account.cancel-sweetpass-plus-subscription.success':
    'We’re sorry to see you go. Your Sweetpass+ membership has been canceled.',
  'account.delete.error': 'Something went wrong with deleting your account.',
};
