import React from 'react';
import { useResponsive } from '@sg/garnish';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import {
  AppFooter,
  ChallengeCompletionModal,
  LoadingAnimation,
  NotFoundView,
  SignedOutView,
} from '@order/components';
import { OrderStatus } from '@order/features/order-status';
import { useDeliveryOrderInFlight } from '@order/hooks';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { ignoreTimezone } from '@order/utils';

import {
  OrderStatusContactUs,
  OrderStatusHealthKit,
  OrderStatusLoyaltyPointsBanner,
} from './components';
import { useLateOrderNoticeBanner, useOrderStatusData } from './hooks';
import { OrderStatusCTAs } from './OrderStatusCTAs';
import { OrderStatusDetails } from './OrderStatusDetails';
import { OrderStatusInfo } from './OrderStatusInfo';
import { OrderStatusMap } from './OrderStatusMap';

export const OrderStatusScreen = () => {
  const isLoggedIn = useIsLoggedIn();
  const isLoggingIn = useIsLoggingIn();

  // ─────────────────────────────────────────────────────────────────

  if (isLoggingIn) return <LoadingAnimation />;

  if (!isLoggedIn) return <SignedOutView />;

  return <OrderStatusScreenSignedIn />;
};

const OrderStatusScreenSignedIn = () => {
  const data = useOrderStatusData();

  useDeliveryOrderInFlight(); // results later used from cache.

  const { isRenderedOnScreen, isFetching, isInvalidOrderStatus, celebration } =
    data;

  if (isInvalidOrderStatus) return <NotFoundView />;

  if (isFetching) return <LoadingAnimation />;

  return (
    <>
      <OrderStatusScreenReady {...data} />

      {celebration && isRenderedOnScreen ? (
        <ChallengeCompletionModal challengeCompletionDetails={celebration} />
      ) : null}
    </>
  );
};

const OrderStatusScreenReady = (
  props: ReturnType<typeof useOrderStatusData>,
) => {
  const {
    deliveryAddressName,
    deliveryAddressStreet,
    deliveryAddressSecondaryStreet,
    deliveryAddressCity,
    deliveryAddressState,
    deliveryAddressZipCode,
    deliveryAddressNotes,
    deliveryAddressType,
    deliveryAddressLatitude,
    deliveryAddressLongitude,
    deliveryEstimatedTime,
    deliveryPreference,
    locationName,
    locationAddress,
    locationCity,
    locationState,
    locationZipCode,
    locationLatitude,
    locationLongitude,
    dropoffLocation,
    orderId,
    orderType,
    orderStatus,
    orderTotal,
    orderWantedTime,
    orderLedger,
    orderLineItems,
    isLate,
    isPast,
    isPendingFeedback = false,
    isTrackable = false,
    orderToHealthKitLineItems,
    isOrderToHealthKitEnabled,
    vendorId,
    canCancel,
    customerName,
    customerLatitude,
    customerLongitude,
    courierName,
    courierPhone,
    courierLatitude,
    courierLongitude,
    restaurantId,
    onReorder,
    refetchOrderStatus,
  } = props;

  const { currentBreakpoint } = useResponsive();

  const isOrderCancelled = orderStatus === 'failed';
  const canReorder = isOrderCancelled || isPast;
  const shouldRenderOrderToHealthKit =
    isOrderToHealthKitEnabled &&
    orderToHealthKitLineItems?.length !== undefined &&
    orderWantedTime !== undefined;

  const isInAppOrderCancellationEnabled = useFeatureFlag(
    'CELS-1476-in-app-order-cancellation',
  );

  useLateOrderNoticeBanner({ isLate });

  if (currentBreakpoint.isXS) {
    return (
      <OrderStatus.Layout.ContainerWrapper>
        <OrderStatus.Layout.Container>
          <OrderStatus.Layout.Info>
            <OrderStatusInfo
              orderId={orderId}
              vendorId={vendorId}
              orderType={orderType}
              orderStatus={orderStatus}
              wantedTime={orderWantedTime}
              estimatedDeliveryTime={deliveryEstimatedTime}
              orderTotal={orderTotal}
              locationName={locationName}
              locationAddress={locationAddress}
              isTrackable={isTrackable}
              isPendingFeedback={isPendingFeedback}
              isPast={isPast}
              onReorder={onReorder}
            />
          </OrderStatus.Layout.Info>

          <OrderStatus.Layout.Map>
            <OrderStatusMap
              orderType={orderType}
              orderStatus={orderStatus}
              locationName={locationName}
              locationLatitude={locationLatitude}
              locationLongitude={locationLongitude}
              customerName={customerName}
              customerLatitude={customerLatitude}
              customerLongitude={customerLongitude}
              deliveryAddressName={deliveryAddressName}
              deliveryAddressType={deliveryAddressType}
              deliveryAddressLatitude={deliveryAddressLatitude}
              deliveryAddressLongitude={deliveryAddressLongitude}
              courierName={courierName}
              courierLatitude={courierLatitude}
              courierLongitude={courierLongitude}
            />
          </OrderStatus.Layout.Map>

          <OrderStatus.Layout.Content>
            <OrderStatusLoyaltyPointsBanner orderId={orderId} />

            <OrderStatusDetails
              orderType={orderType}
              orderStatus={orderStatus}
              deliveryAddressName={deliveryAddressName}
              deliveryAddressStreet={deliveryAddressStreet}
              deliveryAddressSecondaryStreet={deliveryAddressSecondaryStreet}
              deliveryAddressCity={deliveryAddressCity}
              deliveryAddressState={deliveryAddressState}
              deliveryAddressZipCode={deliveryAddressZipCode}
              deliveryAddressNotes={deliveryAddressNotes}
              deliveryPreference={deliveryPreference}
              locationName={locationName}
              locationAddress={locationAddress}
              locationCity={locationCity}
              locationState={locationState}
              locationZipCode={locationZipCode}
              lineItems={orderLineItems}
              dropoffLocation={dropoffLocation}
              ledger={orderLedger}
              courierName={courierName}
              courierPhone={courierPhone}
            />

            {shouldRenderOrderToHealthKit ? (
              <OrderStatusHealthKit
                lineItems={orderToHealthKitLineItems}
                orderWantedTime={ignoreTimezone(orderWantedTime)}
              />
            ) : null}

            <OrderStatusContactUs
              orderId={orderId}
              canCancel={canCancel}
              restaurantId={restaurantId}
              refetchOrderStatus={refetchOrderStatus}
            />
          </OrderStatus.Layout.Content>

          <AppFooter />
        </OrderStatus.Layout.Container>

        {canReorder ? (
          <OrderStatus.Layout.Footer>
            <OrderStatusCTAs
              orderId={orderId}
              orderTotal={orderTotal}
              isPendingFeedback={isPendingFeedback}
              onReorder={onReorder}
            />
          </OrderStatus.Layout.Footer>
        ) : null}
      </OrderStatus.Layout.ContainerWrapper>
    );
  }

  return (
    <OrderStatus.Layout.ContainerWrapper>
      <OrderStatus.Layout.Container>
        <OrderStatus.Layout.Map>
          <OrderStatusMap
            orderType={orderType}
            orderStatus={orderStatus}
            locationName={locationName}
            locationLatitude={locationLatitude}
            locationLongitude={locationLongitude}
            customerName={customerName}
            customerLatitude={customerLatitude}
            customerLongitude={customerLongitude}
            deliveryAddressName={deliveryAddressName}
            deliveryAddressType={deliveryAddressType}
            deliveryAddressLatitude={deliveryAddressLatitude}
            deliveryAddressLongitude={deliveryAddressLongitude}
            courierName={courierName}
            courierLatitude={courierLatitude}
            courierLongitude={courierLongitude}
          />
        </OrderStatus.Layout.Map>

        <OrderStatus.Layout.SidePanel>
          <OrderStatus.Layout.Info>
            <OrderStatusInfo
              orderId={orderId}
              vendorId={vendorId}
              orderType={orderType}
              orderStatus={orderStatus}
              wantedTime={orderWantedTime}
              estimatedDeliveryTime={deliveryEstimatedTime}
              orderTotal={orderTotal}
              locationName={locationName}
              locationAddress={locationAddress}
              isTrackable={isTrackable}
              isPendingFeedback={isPendingFeedback}
              isPast={isPast}
              onReorder={onReorder}
            />
          </OrderStatus.Layout.Info>

          <OrderStatus.Layout.Content>
            <OrderStatusLoyaltyPointsBanner orderId={orderId} />

            <OrderStatusDetails
              orderType={orderType}
              orderStatus={orderStatus}
              deliveryAddressName={deliveryAddressName}
              deliveryAddressStreet={deliveryAddressStreet}
              deliveryAddressSecondaryStreet={deliveryAddressSecondaryStreet}
              deliveryAddressCity={deliveryAddressCity}
              deliveryAddressState={deliveryAddressState}
              deliveryAddressZipCode={deliveryAddressZipCode}
              deliveryAddressNotes={deliveryAddressNotes}
              deliveryPreference={deliveryPreference}
              locationName={locationName}
              locationAddress={locationAddress}
              locationCity={locationCity}
              locationState={locationState}
              locationZipCode={locationZipCode}
              lineItems={orderLineItems}
              dropoffLocation={dropoffLocation}
              ledger={orderLedger}
              courierName={courierName}
              courierPhone={courierPhone}
            />

            {/* NOTE: The contact us card with in-app order cancellation should be rendered inside the scrollable content. */}
            {isInAppOrderCancellationEnabled ? (
              <OrderStatusContactUs
                orderId={orderId}
                canCancel={canCancel}
                restaurantId={restaurantId}
                refetchOrderStatus={refetchOrderStatus}
              />
            ) : null}
          </OrderStatus.Layout.Content>

          {/* NOTE: The contact us floating bar with Kustomer order cancellation should be rendered outside the scrollable content. */}
          {isInAppOrderCancellationEnabled ? null : (
            <OrderStatusContactUs
              orderId={orderId}
              canCancel={canCancel}
              restaurantId={restaurantId}
              refetchOrderStatus={refetchOrderStatus}
            />
          )}
        </OrderStatus.Layout.SidePanel>
      </OrderStatus.Layout.Container>

      <AppFooter />
    </OrderStatus.Layout.ContainerWrapper>
  );
};
