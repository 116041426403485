import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Pressable, type PressableProps } from 'react-native';
import { Icon, usePressableState } from '@sg/garnish';

import { messages } from '../messages';

export const NavbarAccountCta = (props: Props) => {
  const { onPress } = props;

  const ref = useRef(null);
  const { isHovered } = usePressableState(ref);
  const { formatMessage } = useIntl();

  return (
    <Pressable
      testID="nav-bar.account"
      ref={ref}
      accessibilityRole="button"
      aria-label={formatMessage(messages.accountA11y)}
      onPress={onPress}
    >
      <Icon
        width={24}
        height={24}
        name={isHovered ? 'IconAccountHover' : 'IconAccount'}
      />
    </Pressable>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress'>;
