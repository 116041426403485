import { useCallback, useMemo } from 'react';
import { useActor, useSelector } from '@xstate/react';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useGlobalAppState } from '@order/GlobalAppState';
import type { DietaryPropertyKind } from '@order/graphql';
import { useFeatureFlag } from '@order/LaunchDarkly';
import { useTelemetry } from '@order/Telemetry';
import { checkIfShallowlyEqual } from '@order/utils';

import { useActiveRouteName } from '../../../navigation';
import type { DietaryRestrictionsTypes } from './dietaryRestrictionsMachine';
import { dietaryRestrictionsModel } from './dietaryRestrictionsMachine';

// ────────────────────────────────────────────────────────────────────────────────

export const useDietaryRestrictionsMachine = () => {
  const { dietaryRestrictionsMachineRef } = useGlobalAppState();
  const activeRouteName = useActiveRouteName();
  const { track } = useTelemetry();

  const isLoggedIn = useIsLoggedIn();

  const actor = useActor(dietaryRestrictionsMachineRef);
  const [state, send] = actor;

  const { restrictions, lastKnownRestrictions } = state.context;
  const isModified = !checkIfShallowlyEqual(
    restrictions,
    lastKnownRestrictions,
  );

  const sortedRestrictionsNames = useMemo(() => {
    const restrictionsNames = Object.keys(restrictions);
    const compareAlphabetically = (
      restriction: string,
      nextRestriction: string,
    ) => restriction.localeCompare(nextRestriction);

    return [...restrictionsNames].sort(
      compareAlphabetically,
    ) as readonly DietaryPropertyKind[];
  }, [restrictions]);

  // ─── Remote Flags ────────────────────────────────────────────────────

  const isRemoteDietaryRestrictionsEnabled = useFeatureFlag(
    'CELS-3623-remote-dietary-restrictions-enabled',
  );

  // ─── State ───────────────────────────────────────────────────────────

  const isInitializingRestrictions = useSelector(
    dietaryRestrictionsMachineRef,
    (currentState) => {
      const { matches } = currentState;

      return matches('remoteRestrictions.initializing');
    },
  );

  const isUpdatingRestrictions = useSelector(
    dietaryRestrictionsMachineRef,
    (currentState) => {
      const { matches } = currentState;

      return matches('remoteRestrictions.updating');
    },
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const toggleRestriction = useCallback(
    (restriction: DietaryPropertyKind) => {
      const selected = !restrictions[restriction];

      track('dietary-restrictions.select', {
        route: activeRouteName,
        restriction,
        selected,
      });

      send(dietaryRestrictionsModel.events.TOGGLE_RESTRICTION(restriction));
    },
    [activeRouteName, restrictions, send, track],
  );

  const setRestrictions = useCallback(
    (updatedRestrictions: Partial<DietaryRestrictionsTypes>) => {
      send(
        dietaryRestrictionsModel.events.SET_RESTRICTIONS(updatedRestrictions),
      );
    },
    [send],
  );

  const revertRestrictions = useCallback(() => {
    send(dietaryRestrictionsModel.events.REVERT_RESTRICTIONS());
  }, [send]);

  const confirmRestrictions = useCallback(() => {
    send(dietaryRestrictionsModel.events.CONFIRM_RESTRICTIONS());

    if (isRemoteDietaryRestrictionsEnabled && isLoggedIn) {
      send(dietaryRestrictionsModel.events.UPDATE_REMOTE_RESTRICTIONS());
    }
  }, [isRemoteDietaryRestrictionsEnabled, isLoggedIn, send]);

  // ────────────────────────────────────────────────────────────────────

  return {
    restrictions,
    sortedRestrictionsNames,
    isModified,
    isInitializingRestrictions:
      isRemoteDietaryRestrictionsEnabled &&
      isLoggedIn &&
      isInitializingRestrictions,
    isUpdatingRestrictions:
      isRemoteDietaryRestrictionsEnabled &&
      isLoggedIn &&
      isUpdatingRestrictions,
    toggleRestriction,
    setRestrictions,
    revertRestrictions,
    confirmRestrictions,
  };
};
