import { useBagCountQuery } from './GraphQL/BagCount.generated';

/**
 * Returns the current bag count from the cache.
 */
export const useBagCount = () => {
  const [response] = useBagCountQuery({ requestPolicy: 'cache-only' });

  const bagCount = response.data?.cart?.lineItems?.length ?? 0;

  return { bagCount };
};
