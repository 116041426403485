/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ChallengeRewardFieldsFragment = {
  readonly __typename: 'ChallengeReward';
  readonly id: string;
  readonly name: string;
  readonly expiresAt: string | null;
  readonly terms: string;
  readonly rewardType: Types.RewardType;
  readonly redeemable: boolean | null;
  readonly redeemableAt: string | null;
};

export type ChallengeRewardsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ChallengeRewardsQuery = {
  readonly __typename?: 'Query';
  readonly challengeRewards: ReadonlyArray<{
    readonly __typename: 'ChallengeReward';
    readonly id: string;
    readonly name: string;
    readonly expiresAt: string | null;
    readonly terms: string;
    readonly rewardType: Types.RewardType;
    readonly redeemable: boolean | null;
    readonly redeemableAt: string | null;
  }>;
};

export type ChallengeRewardsWithRedemptionChannelQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ChallengeRewardsWithRedemptionChannelQuery = {
  readonly __typename?: 'Query';
  readonly challengeRewards: ReadonlyArray<{
    readonly __typename: 'ChallengeReward';
    readonly redemptionChannel: Types.RedemptionChannel;
    readonly id: string;
    readonly name: string;
    readonly expiresAt: string | null;
    readonly terms: string;
    readonly rewardType: Types.RewardType;
    readonly redeemable: boolean | null;
    readonly redeemableAt: string | null;
  }>;
};

export const ChallengeRewardFieldsFragmentDoc = gql`
  fragment ChallengeRewardFields on ChallengeReward {
    id
    __typename
    name
    expiresAt
    terms
    rewardType
    redeemable
    redeemableAt
  }
`;
export const ChallengeRewardsDocument = gql`
  query ChallengeRewards {
    challengeRewards {
      ...ChallengeRewardFields
    }
  }
  ${ChallengeRewardFieldsFragmentDoc}
`;

export function useChallengeRewardsQuery(
  options?: Omit<Urql.UseQueryArgs<ChallengeRewardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ChallengeRewardsQuery, ChallengeRewardsQueryVariables>({
    query: ChallengeRewardsDocument,
    ...options,
  });
}
export const ChallengeRewardsWithRedemptionChannelDocument = gql`
  query ChallengeRewardsWithRedemptionChannel {
    challengeRewards {
      ...ChallengeRewardFields
      redemptionChannel
    }
  }
  ${ChallengeRewardFieldsFragmentDoc}
`;

export function useChallengeRewardsWithRedemptionChannelQuery(
  options?: Omit<
    Urql.UseQueryArgs<ChallengeRewardsWithRedemptionChannelQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    ChallengeRewardsWithRedemptionChannelQuery,
    ChallengeRewardsWithRedemptionChannelQueryVariables
  >({ query: ChallengeRewardsWithRedemptionChannelDocument, ...options });
}
