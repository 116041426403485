import React, { type ComponentProps, useCallback } from 'react';
import {
  Pressable,
  type PressableStateCallbackType,
  type StyleProp,
  StyleSheet,
  type ViewStyle,
} from 'react-native';
import { theme } from '@garnish/constants';

import { webOnlyStyles } from '../../../../utils';

// ─────────────────────────────────────────────────────────────────────────────

export const IngredientCardPressable = (
  props: IngredientCardPressableProps,
) => {
  const { style, disabled, accessibilityLabel, ...restProps } = props;

  // ─── Styles ──────────────────────────────────────────────────────────

  const pressableStyles = useCallback(
    (state: PressableStateCallbackType): StyleProp<ViewStyle> => {
      const { pressed, hovered } = state as InteractionState;

      return [
        styles.pressable,
        pressableWebStyles,
        hovered && !disabled ? styles.pressableHover : undefined,
        pressed && !disabled ? styles.pressablePressed : undefined,
        style,
      ];
    },
    [disabled, style],
  );

  // ─────────────────────────────────────────────────────────────────────

  return (
    <Pressable
      accessibilityLabel={accessibilityLabel}
      role="button"
      style={pressableStyles}
      disabled={disabled}
      {...restProps}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  pressable: {
    gap: theme.spacing['1'],
  },
  pressableHover: {
    opacity: 0.8,
  },
  pressablePressed: {
    opacity: 0.6,
  },
});

const pressableWebStyles = webOnlyStyles({
  outlineColor: theme.colors.GRAY,
  outlineOffset: theme.spacing['1'],
  outlineWidth: 2,
  transition: theme.transitions.presets.colorsAndOpacity,
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardPressableProps = {
  style?: ViewStyle;
  accessibilityLabel?: string;
} & Omit<ComponentProps<typeof Pressable>, 'style'>;

type InteractionState = {
  hovered: boolean;
} & PressableStateCallbackType;
