/* eslint-disable functional/immutable-data */

import { useCallback, useMemo } from 'react';
import type Animated from 'react-native-reanimated';
import { useScrollViewOffset } from 'react-native-reanimated';
import { measure } from 'react-native-reanimated';
import {
  type AnimatedRef,
  runOnJS,
  runOnUI,
  useSharedValue,
} from 'react-native-reanimated';
import throttle from 'lodash.throttle';

import { useReduceMotionStatus } from '../../../../hooks';
import {
  type RailItemsMeasurements,
  type RailItemsRefsMapRef,
  type RailMeasurements,
  type TargetItemIndexSV,
} from '../../HorizontalScrollRail.types';
import {
  findNextItemIndex,
  findPreviousItemIndex,
  getNextItemScrollOffset,
  getPreviousItemScrollOffset,
} from './helpers';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * Manages and provides state and functionality related to rail navigation.
 *
 * This hook handles navigation between items in a rail, allowing users to
 * scroll to the previous or next item + maintaining shared values for scroll
 * offset and items measurements.
 */
export const useRailNavigationState = (params: Params) => {
  const { outerOffset, railRef, railItemsRef } = params;

  // ─────────────────────────────────────────────────────────────────────

  const reduceMotionStatus = useReduceMotionStatus();
  const shouldAnimateScrolling = reduceMotionStatus !== 'enabled';

  // ─── Shared Values ───────────────────────────────────────────────────

  const scrollOffsetSV = useScrollViewOffset(railRef);

  const railMeasurementsSV = useSharedValue<RailMeasurements>(null);
  const railItemsMeasurementsSV = useSharedValue<RailItemsMeasurements[]>([]);

  const prevItemIndexSV: TargetItemIndexSV = useSharedValue(-1);
  const nextItemIndexSV: TargetItemIndexSV = useSharedValue(-1);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const setViewableState = useCallback(() => {
    runOnUI(() => {
      prevItemIndexSV.value = findPreviousItemIndex({
        scrollOffsetSV,
        railItemsMeasurementsSV,
      });
      nextItemIndexSV.value = findNextItemIndex({
        scrollOffsetSV,
        railMeasurementsSV,
        railItemsMeasurementsSV,
      });
    })();
  }, [
    nextItemIndexSV,
    prevItemIndexSV,
    railItemsMeasurementsSV,
    railMeasurementsSV,
    scrollOffsetSV,
  ]);

  const setViewableStateOnLayout = useCallback(() => {
    const items = [...railItemsRef.current.values()];

    runOnUI((currentItems: AnimatedRef<Animated.View>[]) => {
      railItemsMeasurementsSV.value = currentItems.map(measure);
      railMeasurementsSV.value = measure(railRef);

      runOnJS(setViewableState)();
    })(items);
  }, [
    railItemsMeasurementsSV,
    railItemsRef,
    railMeasurementsSV,
    railRef,
    setViewableState,
  ]);

  const setViewableStateThrottled = useMemo(
    () => throttle(setViewableState, 150),
    [setViewableState],
  );

  const setViewableStateOnLayoutThrottled = useMemo(
    () => throttle(setViewableStateOnLayout, 150),
    [setViewableStateOnLayout],
  );

  // ─── "Scroll to" helpers ─────────────────────────────────────────────

  const scrollToTarget = useCallback(
    (targetOffset: number) => {
      const scrollTo = railRef?.current?.scrollTo;

      if (!scrollTo) return;

      scrollTo({ x: targetOffset, animated: shouldAnimateScrolling });
    },
    [railRef, shouldAnimateScrolling],
  );

  const scrollToPreviousItem = useCallback(() => {
    runOnUI(() => {
      const targetOffset = getPreviousItemScrollOffset({
        prevItemIndexSV,
        outerOffset,
        railItemsMeasurementsSV,
      });

      runOnJS(scrollToTarget)(targetOffset);
    })();
  }, [outerOffset, prevItemIndexSV, railItemsMeasurementsSV, scrollToTarget]);

  const scrollToNextItem = useCallback(() => {
    runOnUI(() => {
      const targetOffset = getNextItemScrollOffset({
        nextItemIndexSV,
        outerOffset,
        railItemsMeasurementsSV,
        railMeasurementsSV,
      });

      if (targetOffset === undefined) return;

      runOnJS(scrollToTarget)(targetOffset);
    })();
  }, [
    nextItemIndexSV,
    outerOffset,
    railItemsMeasurementsSV,
    railMeasurementsSV,
    scrollToTarget,
  ]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    scrollToPreviousItem,
    scrollToNextItem,
    prevItemIndexSV,
    nextItemIndexSV,
    setViewableState: setViewableStateThrottled,
    setViewableStateOnLayout: setViewableStateOnLayoutThrottled,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Params = {
  railRef: AnimatedRef<Animated.ScrollView>;
  railItemsRef: RailItemsRefsMapRef;
  outerOffset: number;
};
