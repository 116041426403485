import React from 'react';
import { StyleSheet, View } from 'react-native';
import { type ViewStyle } from '@expo/html-elements/build/primitives/View';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../../../hooks';

export const IngredientCardQuantityStepperContainer = (
  props: IngredientCardQuantityStepperContainerProps,
) => {
  const { children, style } = props;

  const { match } = useResponsive();

  return (
    <View style={[match([styles.wrapper]), style]} pointerEvents="box-none">
      <View style={styles.innerContainer} pointerEvents="auto">
        {children}
      </View>
    </View>
  );
};

// ─── Styles ───────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  wrapper: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing['3'],
    backgroundColor: theme.colors.CREAM,
    borderRadius: theme.radius.xxlarge,
    padding: theme.spacing['1'],
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type IngredientCardQuantityStepperContainerProps = {
  children: React.ReactNode;
  style?: ViewStyle;
};
