/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FetchPlansByTierIdQueryVariables = Types.Exact<{
  tierId: Types.Scalars['ID']['input'];
}>;

export type FetchPlansByTierIdQuery = {
  readonly __typename?: 'Query';
  readonly fetchPlansByTierId: ReadonlyArray<{
    readonly __typename?: 'PaymentPlan';
    readonly braintreePlanId: string;
  }> | null;
};

export type OptInUserMutationVariables = Types.Exact<{ [key: string]: never }>;

export type OptInUserMutation = {
  readonly __typename?: 'Mutation';
  readonly optInFreeTierV2: {
    readonly __typename: 'LoyaltyProfile';
    readonly customerId: string;
    readonly loyaltyId: string | null;
    readonly joinDate: string;
    readonly tier: {
      readonly __typename: 'Tier';
      readonly id: string;
      readonly name: Types.TierName;
      readonly nextTier: {
        readonly __typename: 'Tier';
        readonly id: string;
        readonly name: Types.TierName;
      } | null;
    } | null;
    readonly tierStatus: {
      readonly __typename: 'TierStatus';
      readonly id: string;
      readonly name: Types.TierStatusName;
    } | null;
  } | null;
};

export const FetchPlansByTierIdDocument = gql`
  query FetchPlansByTierId($tierId: ID!) {
    fetchPlansByTierId(tierId: $tierId) {
      braintreePlanId
    }
  }
`;

export function useFetchPlansByTierIdQuery(
  options: Omit<Urql.UseQueryArgs<FetchPlansByTierIdQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    FetchPlansByTierIdQuery,
    FetchPlansByTierIdQueryVariables
  >({ query: FetchPlansByTierIdDocument, ...options });
}
export const OptInUserDocument = gql`
  mutation OptInUser {
    optInFreeTierV2 {
      __typename
      customerId
      loyaltyId
      joinDate
      tier {
        id
        __typename
        name
        nextTier {
          id
          __typename
          name
        }
      }
      tierStatus {
        id
        __typename
        name
      }
    }
  }
`;

export function useOptInUserMutation() {
  return Urql.useMutation<OptInUserMutation, OptInUserMutationVariables>(
    OptInUserDocument,
  );
}
