import React, { type ComponentProps, type PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import { Nav } from '@expo/html-elements';
import { Container, theme } from '@sg/garnish';

import { NAVBAR_HEADER_HEIGHT } from '../constants';

const NavbarMainContent = (props: ComponentProps<typeof Nav>) => {
  const { children, style, ...rest } = props;

  return (
    <Nav {...rest} style={[styles.container, style]} testID="nav-bar">
      <Container style={styles.contentMain}>{children}</Container>
    </Nav>
  );
};

const NavbarLeftContent = (props: PropsWithChildren) => {
  return <View style={styles.contentLeft}>{props.children}</View>;
};

const NavbarRightContent = (props: PropsWithChildren) => {
  return <View style={styles.contentRight}>{props.children}</View>;
};

export const Containers = {
  Main: NavbarMainContent,
  Left: NavbarLeftContent,
  Right: NavbarRightContent,
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: NAVBAR_HEADER_HEIGHT,
    backgroundColor: theme.colors.CREAM,
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.DARK_KALE,
  },
  contentMain: {
    height: NAVBAR_HEADER_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing['4'],
    paddingHorizontal: theme.spacing['10'],
  },
  contentLeft: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing['10'],
  },
  contentRight: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing['6'],
  },
});
