import { type PointsReward } from '@sg/graphql-schema';

import { useIsLoggedIn } from '@order/AuthMachine';
import { useFeatureFlag } from '@order/LaunchDarkly';

import { useLoyaltyPointsRewardsQuery } from './GraphQL/LoyaltyPointsRewards.generated';

export const useLoyaltyPointsRewards = () => {
  const isLoggedIn = useIsLoggedIn();
  const isLoyaltyV2Enabled = useFeatureFlag('CELS-2685-loyalty-v2-enabled');

  const [response, refetchPointsRewards] = useLoyaltyPointsRewardsQuery({
    pause: !isLoggedIn || !isLoyaltyV2Enabled,
    requestPolicy: 'cache-and-network',
  });

  return {
    pointsRewards:
      response.data?.loyaltyProfile?.pointsRewards ?? emptyPointsRewards,
    isFetchingPointsRewards: response.fetching,
    refetchPointsRewards,
  };
};

const emptyPointsRewards: readonly PointsReward[] = [];
