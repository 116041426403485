import AsyncStorage from '@react-native-async-storage/async-storage';
import { addMinutes, formatISO } from 'date-fns';

import { type WantedTime } from '@order/features/ordering';
import { ignoreTimezone } from '@order/utils';

/**
 * The wanted time shown to the customer needs to be adjusted by its delivery offset.
 * However, the wanted time sent to the submit order request must not be adjusted.
 * This helper is used to adjust it on the components.
 */
export const adjustWantedTime = (wantedTime?: WantedTime) => {
  if (!wantedTime) return undefined;

  const { time, offset } = wantedTime;

  const validTime = ignoreTimezone(time);

  if (!validTime) return undefined;

  return formatISO(addMinutes(validTime, offset));
};

/**
 * Returns the initial wanted time to be used in the bag.
 * If there is a still valid stored wanted time for this order id, use it.
 * Otherwise use the first available wanted time.
 */
export const getInitialWantedTime = async (
  orderId: string | undefined,
  wantedTimes: WantedTime[],
): Promise<WantedTime> => {
  if (!orderId) return wantedTimes[0];

  const storedWantedTime = await getStoredWantedTime(orderId);

  if (!storedWantedTime) return wantedTimes[0];

  const previousWantedTimeObject = wantedTimes.find(
    (wantedTime) => wantedTime.time === storedWantedTime,
  );

  if (!previousWantedTimeObject) return wantedTimes[0];

  return previousWantedTimeObject;
};

// ─── Async Storage ───────────────────────────────────────────────────────────
// These store and retrieve the previously selected wanted time and its order id.
// The purpose is to persist the wanted time for a given order in between bag usages.

export const storeWantedTime = async (orderId: string, time: string) => {
  await AsyncStorage.setItem(
    PREVIOUS_WANTED_TIME,
    JSON.stringify({
      orderId,
      time,
    }),
  );
};

const getStoredWantedTime = async (orderId: string) => {
  try {
    const storedValue = await AsyncStorage.getItem(PREVIOUS_WANTED_TIME);

    if (!storedValue) return null;

    const storedWantedTime = JSON.parse(storedValue) as StoredWantedTime;

    if (!storedWantedTime?.time) return null;

    if (storedWantedTime?.orderId !== orderId) return null;

    return storedWantedTime.time;
  } catch {
    return null;
  }
};

// ─── Constants ───────────────────────────────────────────────────────────────

const PREVIOUS_WANTED_TIME = 'previous_wanted_time';

// ─── Types ───────────────────────────────────────────────────────────────────

type StoredWantedTime = {
  orderId: string;
  time: string;
};
