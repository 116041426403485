import AsyncStorage from '@react-native-async-storage/async-storage';
import { type DietaryRestriction } from '@sg/graphql-schema';

import { DIETARY_RESTRICTIONS_STORAGE_KEY } from '@order/constants';

/**
 * This returns the local map of dietary restrictions as a list of restrictions that are enabled.
 * Will return an empty list if the map doesn't exist or if no restrictions are enabled.
 */
export async function getLocalDietaryRestrictions(): Promise<Restrictions> {
  try {
    const persistedState = await AsyncStorage.getItem(
      DIETARY_RESTRICTIONS_STORAGE_KEY,
    );

    if (!persistedState) return [];

    const restrictionMap = JSON.parse(persistedState) as RestrictionsMap;
    const restrictions = Object.keys(restrictionMap) as Restrictions;

    if (restrictions.length === 0) return [];

    return restrictions.filter((key) => restrictionMap[key]);
  } catch {
    return [];
  }
}

type Restrictions = DietaryRestriction[];
type RestrictionsMap = Record<DietaryRestriction, boolean>;
