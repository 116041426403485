import { useCallback } from 'react';
import { useActor, useSelector } from '@xstate/react';

import { useGlobalAppState } from '@order/GlobalAppState';

import { type AzureAuthStateMachineEvents } from '../../machine/azure-auth-machine';

// ─────────────────────────────────────────────────────────────────────────────

/**
 * A simple hook for interacting with the Azure authentication machine using friendly abstractions.
 */
export const useAzureAuth = () => {
  const { azureAuthMachineRef } = useGlobalAppState();

  // ─── Service ─────────────────────────────────────────────────────────

  const [, send] = useActor(azureAuthMachineRef);

  // ─── Flags ───────────────────────────────────────────────────────────

  const {
    isFillAccountDataStep,
    isCreatingNewAccount,
    isAuthenticationFailed,
    shouldRedirect,
  } = useSelector(azureAuthMachineRef, ({ matches }) => {
    return {
      isFillAccountDataStep:
        matches('filling-account-data') ||
        matches('refreshing-access-tokens') ||
        matches('creating-account'),
      isCreatingNewAccount:
        matches('refreshing-access-tokens') || matches('creating-account'),
      isAuthenticationFailed:
        matches('authentication-failed') || matches('ad-authentication-failed'),
      shouldRedirect: matches('redirect'),
    };
  });

  const context = useSelector(azureAuthMachineRef, (state) => state.context);

  // ─── Helpers ─────────────────────────────────────────────────────────

  const submitAccountData = useCallback(
    (input: SubmitAccountDataInput) => {
      send({
        type: 'SUBMIT_ACCOUNT_DATA',
        input: {
          firstName: input.firstName,
          lastName: input.lastName,
          phoneNumber: input.phoneNumber,
          zipCode: input.zipCode,
          birthday: input.birthday,
        },
      });
    },
    [send],
  );

  const initializeAuthenticationFlow = useCallback(() => {
    send('INITIALIZE');
  }, [send]);

  const resetAuthenticationFlow = useCallback(() => {
    send('RESET');
  }, [send]);

  const restartAuthenticationFlow = useCallback(() => {
    send('RESTART');
    send('INITIALIZE');
  }, [send]);

  const completeAuthenticationFlow = useCallback(() => {
    send('COMPLETE');
  }, [send]);

  // ─────────────────────────────────────────────────────────────────────

  return {
    context,
    state: {
      isFillAccountDataStep,
      isCreatingNewAccount,
      isAuthenticationFailed,
      shouldRedirect,
    },
    initializeAuthenticationFlow,
    resetAuthenticationFlow,
    restartAuthenticationFlow,
    completeAuthenticationFlow,
    submitAccountData,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type SubmitAccountDataInput = Extract<
  AzureAuthStateMachineEvents,
  { type: 'SUBMIT_ACCOUNT_DATA' }
>['input'];
