import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { type PressableProps, StyleSheet } from 'react-native';
import { Button } from '@sg/garnish';

import { messages } from '../messages';

export const NavbarOrderCta = (props: Props) => {
  const { onPress } = props;

  const { formatMessage } = useIntl();

  return (
    <Button
      testID="nav-bar.order"
      style={styles.container}
      palette="secondary"
      accessibilityRole="button"
      aria-label={formatMessage(messages.orderA11y)}
      onPress={onPress}
    >
      <FormattedMessage {...messages.order} />
    </Button>
  );
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Props = Pick<PressableProps, 'onPress'>;

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  container: {
    minWidth: 120,
    height: 32,
  },
});
