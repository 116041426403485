import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';
import { IconLink, theme } from '@sg/garnish';

import {
  useLineItemDescription,
  useLineItemIngredients,
  useLineItemName,
} from '@order/shared/hooks';

import {
  OrderLineItemBagIconLink,
  OrderLineItemContainer,
  OrderLineItemDetails,
  OrderLineItemImage,
  OrderLineItemPressable,
} from './components';
import {
  ORDER_LINE_ITEM_CONTAINER_VERTICAL,
  ORDER_LINE_ITEM_IMAGE,
} from './OrderLineItem.constants';
import { orderLineItemMessages as messages } from './OrderLineItem.messages';
import {
  type OrderLineItemBaseProps,
  type OrderLineItemFavoriteBaseProps,
  type ToggleFavoriteButtonPressParams,
} from './OrderLineItem.types';

// ─────────────────────────────────────────────────────────────────────────────

export const OrderLineItem = (props: OrderLineItemProps) => {
  const {
    variation = 'horizontal',
    lineItem,
    orderDetails,
    isReordering,
    onLineItemCardPress,
    onAddToBagButtonPress,
  } = props;

  const { restaurant, deliveryOrderDetail } = orderDetails;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────────────

  const { product, id: lineItemId, customName } = lineItem;
  const productAssetUrl = product?.asset?.url;

  // ─── Formatted Data ──────────────────────────────────────────────────

  const lineItemName = useLineItemName(product, lineItem);
  const lineItemDescription = useLineItemDescription(lineItem);
  const { addedIngredientsText, removedIngredientsText } =
    useLineItemIngredients(lineItem);

  const openProductDetailsButtonA11yLabel = formatMessage(
    messages.openProductDetailsButtonA11yLabel,
    { line_item_name: lineItemName },
  );
  const addToBagButtonA11yLabel = formatMessage(
    messages.addToBagButtonA11yLabel,
    { line_item_name: lineItemName },
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const handleAddToBag = useCallback(() => {
    onAddToBagButtonPress(lineItemId);
  }, [lineItemId, onAddToBagButtonPress]);

  const handleLineItemCardPress = useCallback(() => {
    onLineItemCardPress({
      product,
      lineItemId,
      customName: customName ?? undefined,
      restaurantSlug: restaurant.slug,
      addressId: deliveryOrderDetail?.address?.id,
    });
  }, [
    customName,
    deliveryOrderDetail?.address?.id,
    lineItemId,
    onLineItemCardPress,
    product,
    restaurant.slug,
  ]);

  // ─── "Vertical" variation ────────────────────────────────────────────

  if (variation === 'vertical') {
    return (
      <OrderLineItemContainer variation="vertical">
        <OrderLineItemPressable
          variation="vertical"
          aria-label={openProductDetailsButtonA11yLabel}
          onPress={handleLineItemCardPress}
        >
          {productAssetUrl ? (
            <View style={styles.imageContainer}>
              <OrderLineItemImage
                size="md"
                assetUrl={productAssetUrl}
                aria-label={product.name}
              />
            </View>
          ) : null}

          <OrderLineItemDetails
            lineItemName={lineItemName}
            lineItemDescription={lineItemDescription}
            addedIngredientsText={addedIngredientsText}
            removedIngredientsText={removedIngredientsText}
            textAlignment="center"
          />
        </OrderLineItemPressable>

        <View
          style={styles.bagButtonVerticalContainer}
          pointerEvents="box-none"
        >
          <OrderLineItemBagIconLink
            variation="floating"
            isLoading={isReordering}
            aria-label={addToBagButtonA11yLabel}
            onPress={handleAddToBag}
          />
        </View>
      </OrderLineItemContainer>
    );
  }

  // ─── "Horizontal" variation ──────────────────────────────────────────

  return (
    <OrderLineItemContainer>
      <OrderLineItemPressable
        aria-label={openProductDetailsButtonA11yLabel}
        onPress={handleLineItemCardPress}
      >
        {productAssetUrl ? (
          <OrderLineItemImage
            size="sm"
            assetUrl={productAssetUrl}
            aria-label={product.name}
          />
        ) : null}

        <OrderLineItemDetails
          lineItemName={lineItemName}
          lineItemDescription={lineItemDescription}
          addedIngredientsText={addedIngredientsText}
          removedIngredientsText={removedIngredientsText}
        />
      </OrderLineItemPressable>

      <OrderLineItemBagIconLink
        isLoading={isReordering}
        aria-label={addToBagButtonA11yLabel}
        onPress={handleAddToBag}
      />
    </OrderLineItemContainer>
  );
};

export const OrderFavoriteLineItem = (props: OrderFavoriteLineItemProps) => {
  const {
    favoriteLineItem,
    isReordering,
    isTogglingFavoriteState,
    onAddToBagButtonPress,
    onToggleFavoriteButtonPress,
  } = props;

  const { formatMessage } = useIntl();

  // ─────────────────────────────────────────────────────────────────────

  const { product, id, favorited: isFavorite } = favoriteLineItem;

  const productId = product.id;
  const productAssetUrl = product?.asset?.url;

  // ─── Formatted Data ──────────────────────────────────────────────────

  const lineItemName = useLineItemName(product, favoriteLineItem);
  const lineItemDescription = useLineItemDescription(favoriteLineItem);
  const { addedIngredientsText, removedIngredientsText } =
    useLineItemIngredients(favoriteLineItem);

  const addToBagButtonA11yLabel = formatMessage(
    messages.addToBagButtonA11yLabel,
    { line_item_name: lineItemName },
  );
  const favoriteLineItemButtonA11yLabel = formatMessage(
    messages.favoriteLineItemButtonA11yLabel,
    { line_item_name: lineItemName },
  );
  const unFavoriteLineItemButtonA11yLabel = formatMessage(
    messages.unFavoriteLineItemButtonA11yLabel,
    { line_item_name: lineItemName },
  );

  // ─── Helpers ─────────────────────────────────────────────────────────

  const onAddToBag = useCallback(() => {
    onAddToBagButtonPress(id);
  }, [id, onAddToBagButtonPress]);

  const onToggleFavorite = useCallback(() => {
    if (!productId) return;

    onToggleFavoriteButtonPress({ id, productId, isFavorite: !isFavorite });
  }, [id, isFavorite, onToggleFavoriteButtonPress, productId]);

  // ─────────────────────────────────────────────────────────────────────

  return (
    <OrderLineItemContainer>
      <View style={styles.favoriteLineItemInnerContainer}>
        {productAssetUrl ? (
          <OrderLineItemImage
            size="sm"
            assetUrl={productAssetUrl}
            aria-label={product.name}
          />
        ) : null}

        <OrderLineItemDetails
          lineItemName={lineItemName}
          lineItemDescription={lineItemDescription}
          addedIngredientsText={addedIngredientsText}
          removedIngredientsText={removedIngredientsText}
        />
      </View>

      <View style={styles.favoriteLineItemControlsContainer}>
        <OrderLineItemBagIconLink
          isLoading={isReordering}
          aria-label={addToBagButtonA11yLabel}
          onPress={onAddToBag}
        />

        <IconLink
          width={24}
          height={24}
          color={theme.colors.KALE}
          accessibilityLabel={
            isFavorite
              ? unFavoriteLineItemButtonA11yLabel
              : favoriteLineItemButtonA11yLabel
          }
          name={isFavorite ? 'IconHeartFill' : 'IconHeartStroke'}
          disabled={isTogglingFavoriteState}
          onPress={onToggleFavorite}
        />
      </View>
    </OrderLineItemContainer>
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  imageContainer: {
    marginBottom: theme.spacing['4'],
  },
  favoriteLineItemInnerContainer: {
    flex: 1,
    alignItems: 'center',
    gap: theme.spacing['2'],
    flexDirection: 'row',
  },
  favoriteLineItemControlsContainer: {
    alignItems: 'center',
    rowGap: theme.spacing['3'],
  },
  bagButtonVerticalContainer: {
    position: 'absolute',
    top: ORDER_LINE_ITEM_CONTAINER_VERTICAL.PADDING,
    right: 0,
    left: 0,
    width: ORDER_LINE_ITEM_IMAGE.SIZE.MD,
    height: ORDER_LINE_ITEM_IMAGE.SIZE.MD,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginHorizontal: 'auto',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type OrderLineItemProps = OrderLineItemBaseProps & {
  isReordering: boolean;
  onLineItemCardPress: (params: onLineItemCardPressParams) => void;
  onAddToBagButtonPress: (lineItemId: string) => void;
  variation?: 'vertical' | 'horizontal';
};

type OrderFavoriteLineItemProps = OrderLineItemFavoriteBaseProps & {
  isReordering: boolean;
  isTogglingFavoriteState: boolean;
  onAddToBagButtonPress: (lineItemId: string) => void;
  onToggleFavoriteButtonPress: (
    params: ToggleFavoriteButtonPressParams,
  ) => void;
};

type onLineItemCardPressParams = {
  lineItemId: string;
  product: { slug?: string };
  restaurantSlug: string;
  addressId?: string;
  customName?: string;
};
