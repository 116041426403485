import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useContentfulContentTypeEntry } from '@order/Contentful';

export const useHomeBannerContent = () => {
  const { data, fetching } = useContentfulContentTypeEntry<HomeBannerContent>({
    contentType: HOME_BANNER_CONTENT_TYPE,
  });

  const defaults = useDefaultHomeBannerContents();

  return {
    ...defaults,

    bannerPromoMessage: data?.fields?.message,
    bannerPromoCta: data?.fields?.cta,
    bannerPromoDeepLink: data?.fields?.deepLink,
    isFetchingPromoBanner: fetching,
  };
};

// ─── Types ───────────────────────────────────────────────────────────────────

type HomeBannerContent = {
  message: string;
  cta: string;
  deepLink: string;
};

// ─── Messages ────────────────────────────────────────────────────────────────

const messages = defineMessages({
  bannerSignUpMessage: {
    defaultMessage: `Sign up for sweet perks`,
    description: `Home | Banner | Explaining benefits of signing up`,
  },
  bannerSignUpCta: {
    defaultMessage: `Log in or join`,
    description: `Home | Banner | Requesting the customer to sign up`,
  },

  bannerOrderNotTrackableMessage: {
    defaultMessage: `Order ETA #eta`,
    description: `Home | Banner | Informing the order ETA`,
  },
  bannerOrderReceivedMessage: {
    defaultMessage: `ETA #eta • Order received`,
    description: `Home | Banner | Informing order was received`,
  },
  bannerOrderPreparingMessage: {
    defaultMessage: `ETA #eta • Preparing order`,
    description: `Home | Banner | Informing order is being prepared`,
  },
  bannerOrderReadyForCourierMessage: {
    defaultMessage: `ETA #eta • Ready for courier`,
    description: `Home | Banner | Informing order is ready for the courier to pick it up`,
  },
  bannerOrderDeliveringMessage: {
    defaultMessage: `ETA #eta • Delivering order`,
    description: `Home | Banner | Informing order is being delivered`,
  },
  bannerOrderCompletedMessage: {
    defaultMessage: `ETA #eta • Order complete`,
    description: `Home | Banner | Informing order is completed`,
  },
  bannerOrderLateMessage: {
    defaultMessage: `Sorry, your order is running late`,
    description: `Home | Banner | Informing order is late`,
  },
  bannerOrderCancelledMessage: {
    defaultMessage: `Sorry, your order was cancelled`,
    description: `Home | Banner | Informing order was cancelled`,
  },
  bannerOrderCta: {
    defaultMessage: `View order`,
    description: `Home | Banner | Allows customer to view their order`,
  },

  bannerRateMessage: {
    defaultMessage: `Let us know how we did`,
    description: `Home | Banner | Asking customer for feedback`,
  },
  bannerRateCta: {
    defaultMessage: `Rate order`,
    description: `Home | Banner | Allows customer to rate their order`,
  },
});

const useDefaultHomeBannerContents = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      bannerSignUpMessage: formatMessage(messages.bannerSignUpMessage),
      bannerSignUpCta: formatMessage(messages.bannerSignUpCta),

      bannerOrderNotTrackableMessage: formatMessage(
        messages.bannerOrderNotTrackableMessage,
      ),
      bannerOrderReceivedMessage: formatMessage(
        messages.bannerOrderReceivedMessage,
      ),
      bannerOrderReadyForCourierMessage: formatMessage(
        messages.bannerOrderReadyForCourierMessage,
      ),
      bannerOrderDeliveringMessage: formatMessage(
        messages.bannerOrderDeliveringMessage,
      ),
      bannerOrderPreparingMessage: formatMessage(
        messages.bannerOrderPreparingMessage,
      ),
      bannerOrderCompletedMessage: formatMessage(
        messages.bannerOrderCompletedMessage,
      ),
      bannerOrderLateMessage: formatMessage(messages.bannerOrderLateMessage),
      bannerOrderCancelledMessage: formatMessage(
        messages.bannerOrderCancelledMessage,
      ),

      bannerOrderCta: formatMessage(messages.bannerOrderCta),

      bannerRateMessage: formatMessage(messages.bannerRateMessage),
      bannerRateCta: formatMessage(messages.bannerRateCta),
    }),
    [formatMessage],
  );
};

// ─── Constants ───────────────────────────────────────────────────────────────

const HOME_BANNER_CONTENT_TYPE = 'homeBanner';
