import React from 'react';
import { type RouteProp, useRoute } from '@react-navigation/native';

import { MenuContent, useMenuContentData } from '@order/components';

import type { MenuTabStackParamList } from '../../navigation/AppNavigation.props';

// ─────────────────────────────────────────────────────────────────────────────

export const MenuScreen = () => {
  //
  // ─── Navigation ──────────────────────────────────────────────────────

  const { params } = useRoute<RouteProp<MenuTabStackParamList, 'Menu'>>();

  const { restaurantSlug } = params ?? {};

  // ─── Remote Data ─────────────────────────────────────────────────────

  const { restaurantData, isFetching, hasError, hasNoProducts } =
    useMenuContentData({ restaurantSlug });

  // ─────────────────────────────────────────────────────────────────────

  const shouldRenderErrorView = hasError || !restaurantData || hasNoProducts;

  if (isFetching) {
    return <MenuContent.Loading />;
  }

  if (shouldRenderErrorView) {
    return <MenuContent.Error />;
  }

  // ─────────────────────────────────────────────────────────────────────

  const orderChannel = restaurantData.isOutpost ? 'outpost' : 'pickup';
  const locationName = restaurantData.name;

  return (
    <MenuContent.Ready
      restaurantData={restaurantData}
      orderChannel={orderChannel}
      locationName={locationName}
    />
  );
};
