import type { PropsWithChildren } from 'react';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { theme, useResponsive } from '@sg/garnish';

export const ContainerLayout = (props: PropsWithChildren) => {
  const { match } = useResponsive();
  const style = match([styles.containerXS, styles.containerSM]);

  return <View style={style}>{props.children}</View>;
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  containerSM: {
    flexDirection: 'row',
    height: '100%',
    backgroundColor: theme.colors.APP_BACKGROUND,
  },
  containerXS: {
    overflow: 'hidden',
    backgroundColor: theme.colors.APP_BACKGROUND,
    marginBottom: theme.spacing['4'],
  },
});
