import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import Animated, {
  interpolateColor,
  type SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';
import { useStyle } from 'react-native-style-utilities';

// ─────────────────────────────────────────────────────────────────────────────

export const LoadingPlaceholderColumn = memo(
  (props: LoadingPlaceholderColumnProps) => {
    const {
      transitionSV,
      columnWidth,
      backgroundColor,
      foregroundColor,
      borderRadius,
    } = props;

    // ─── Styles ──────────────────────────────────────────────────────────

    const dynamicStyles = useStyle(() => {
      return { borderRadius, width: columnWidth };
    }, [borderRadius]);

    const animatedStyles = useAnimatedStyle(() => {
      return {
        backgroundColor: interpolateColor(
          transitionSV.value,
          [0, 1],
          [backgroundColor, foregroundColor],
        ),
      };
    });

    const columnStyles = [
      styles.columnContainer,
      dynamicStyles,
      animatedStyles,
    ];

    // ─────────────────────────────────────────────────────────────────────

    return <Animated.View style={columnStyles} />;
  },
);

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  columnContainer: {
    width: '100%',
    height: '100%',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type LoadingPlaceholderColumnProps = {
  transitionSV: SharedValue<number>;
  columnWidth: number | undefined;
  backgroundColor: string;
  foregroundColor: string;
  borderRadius: number;
};
