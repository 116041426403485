import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  type Challenge,
  type PointsReward,
  type Reward,
  TierName,
} from '@sg/graphql-schema';

import { useLoyaltyInfoQuery } from '../../GraphQL/LoyaltyInfo.generated';

export const useLoyaltyInfo = (props: UseLoyaltyInfoProps) => {
  const { pause } = props;

  // ─── Remote Data ─────────────────────────────────────────────────────

  const [response, refetch] = useLoyaltyInfoQuery({
    requestPolicy: 'cache-and-network',
    pause,
  });

  // ─────────────────────────────────────────────────────────────────────

  const { formatMessage } = useIntl();
  const guest = formatMessage(messages.guest);

  // ─────────────────────────────────────────────────────────────────────

  return useMemo(() => {
    const customer = response.data?.currentCustomer;
    const loyaltyProfile = response.data?.loyaltyProfile;

    const customerTier = loyaltyProfile?.tier?.nameV2;
    const isOptedOut = customerTier === TierName.RewardsOptedOut;

    const firstName = customer?.firstName ?? guest;
    const availablePoints = loyaltyProfile?.points?.available ?? 0;
    const pendingPoints = loyaltyProfile?.points?.pending ?? 0;
    const totalPoints = loyaltyProfile?.points?.total ?? 0;

    const challenges = response.data?.challenges ?? emptyChallenges;
    const rewards = loyaltyProfile?.rewards ?? emptyRewards;
    const pointsRewards = loyaltyProfile?.pointsRewards ?? emptyPointsRewards;

    const isLoading = response.fetching;
    const isStale = response.stale;
    const hasFailedToLoad = Boolean(response.error);
    const hasNoPointsRewards =
      !isLoading && !isStale && pointsRewards.length === 0;

    return {
      isLoading,
      isStale,
      hasFailedToLoad,
      hasNoPointsRewards,
      firstName,
      availablePoints,
      pendingPoints,
      totalPoints,
      challenges,
      rewards,
      pointsRewards,
      customerTier,
      isOptedOut,
      refetch,
    };
  }, [response, guest, refetch]);
};

// ─── Constants ──────────────────────────────────────────────────────────────

const emptyChallenges: Challenge[] = [];
const emptyRewards: Reward[] = [];
const emptyPointsRewards: readonly PointsReward[] = [];

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  guest: {
    defaultMessage: 'Guest',
    description: 'Loyalty | Customer Name | Fallback',
  },
});

// ─── Types ───────────────────────────────────────────────────────────────────

type UseLoyaltyInfoProps = {
  pause: boolean;
};
