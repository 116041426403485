import React, { type ComponentProps } from 'react';
import { StatusBar } from 'react-native';

export const OrderStatusBar = (props: ComponentProps<typeof StatusBar>) => {
  return (
    <StatusBar
      translucent
      backgroundColor="transparent"
      barStyle="dark-content"
      {...props}
    />
  );
};
