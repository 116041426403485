import { PixelRatio, Platform } from 'react-native';
import type {
  CloudinaryConfiguration,
  ImageTransform,
  ImageTransformOptions,
} from 'cloudinary-tiny-js';
import cloudinary from 'cloudinary-tiny-js';

/**
 * Processes template image uri and cloudinary transform config into new cloudinary URL.
 */
export function getCloudinaryUrl(
  templateImage: string,
  transformConfig: ImageTransform | readonly ImageTransform[],
) {
  const shouldUseRemoteImageConfig =
    !checkIfCloudinaryUrl(templateImage) && checkIfRemoteUrl(templateImage);

  const config = shouldUseRemoteImageConfig
    ? CLOUDINARY_CONFIG_FOR_REMOTE_IMAGES
    : CLOUDINARY_BASE_CONFIG;

  const cl = cloudinary(config);

  if (!transformConfig) {
    return templateImage;
  }

  const templateImagePublicId = extractPublicId(templateImage);

  // we set DPR setting inline to support dynamic pixel ratio change and inline mocking
  const pixelRatioSetting = {
    dpr: Math.min(PixelRatio.get(), MAX_DPR_SETTING),
  };

  const transformOptions = {
    transformations: Array.isArray(transformConfig)
      ? amendWithDefaultTransforms([pixelRatioSetting, ...transformConfig])
      : { ...pixelRatioSetting, ...transformConfig },
  };

  return cl(templateImagePublicId, transformOptions as ImageTransformOptions);
}

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * Conditionally extracts Cloudinary asset Public ID from the absolute URL
 */
function extractPublicId(imageURL: string) {
  const isAbsoluteCloudinaryAsset = checkIfCloudinaryUrl(imageURL);
  const isRemoteAsset = checkIfRemoteUrl(imageURL);

  // Ignore other assets
  if (!isAbsoluteCloudinaryAsset && isRemoteAsset) {
    return imageURL;
  }

  const publicId = isAbsoluteCloudinaryAsset
    ? imageURL.split('/upload/')[1]
    : imageURL;

  // Returns the public ID without a possible file extension
  return withoutFileExtension(publicId);
}

/**
 * Returns whether the provided URL is from Cloudinary
 */
function checkIfCloudinaryUrl(imageURL: string) {
  return imageURL.startsWith(CLOUDINARY_BASE_URL);
}

/**
 * Returns whether the provided URL is from Cloudinary
 */
function checkIfRemoteUrl(imageURL: string) {
  return (
    imageURL.startsWith('http://') ||
    imageURL.startsWith('https://') ||
    imageURL.startsWith('//')
  );
}

/**
 * Returns the provided URL without a possible file extension.
 * @param imageURL
 */
function withoutFileExtension(imageURL: string) {
  return imageURL.replace(/\.[^./]+$/, '');
}

/**
 * This is intended to support `imageTransformDefaults` for array configs,
 * currently library do not support configs in array
 * @see {@link https://github.com/marnusw/cloudinary-tiny-js/issues/14}
 */
function amendWithDefaultTransforms(
  transformations: readonly ImageTransform[],
): readonly ImageTransform[] {
  return [DEFAULT_TRANSFORMS, ...transformations];
}

export function getImageFormat() {
  if (Platform.OS !== 'web') return 'webp';

  const canvas = document.createElement('canvas');

  // https://medium.com/@JackPu/how-to-detect-browser-support-webp-446b8f91504
  const isWebpSupported =
    canvas?.getContext?.('2d') &&
    canvas?.toDataURL?.('image/webp')?.startsWith('data:image/webp');

  if (isWebpSupported) {
    return 'webp';
  }

  return 'auto';
}

// ─── Constants ───────────────────────────────────────────────────────────────

const DEFAULT_TRANSFORMS: ImageTransform = {
  // Since native is not considered browser, Cloudinary doesn't send back Webp
  // format back. We need to explicitly tell it to send the Webp format back.
  format: getImageFormat(),
  quality: 'auto:good',
};

const CLOUDINARY_BASE_CONFIG: CloudinaryConfiguration = {
  /** The name of your Cloudinary account, a unique public identifier for URL
   * building and API access. */
  cloudName: 'sweetgreen',

  /**
   * The type of asset to deliver, `image` by default. Valid values: `image`,
   * `raw`, or `video`. Only the `image` type currently supports transforms.
   */
  assetType: 'image',

  /** Use https instead of http */
  secure: true,

  /** Transforms applied to all images, any transforms passed later will extend
   * these defaults. */
  imageTransformDefaults: DEFAULT_TRANSFORMS,
};

const CLOUDINARY_CONFIG_FOR_REMOTE_IMAGES: CloudinaryConfiguration = {
  ...CLOUDINARY_BASE_CONFIG,

  /**
   * Delivery type
   * @see {@link https://cloudinary.com/documentation/fetch_remote_images#comparing_fetch_to_auto_upload}
   */
  deliveryType: 'fetch',
};

const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com';

// NOTE: We limit the `dpr` modification to avoid fetching too large images.
const MAX_DPR_SETTING = 2;
