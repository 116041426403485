import type { ComponentProps } from 'react';
import React from 'react';
import { theme } from '@garnish/constants';

import { useResponsive } from '../../../hooks';
import { useContainerSpacing } from '../../Container';
import { HorizontalScrollRail } from '../../HorizontalScrollRail';

// ────────────────────────────────────────────────────────────────────

export const LoyaltyChallengeCardsRailBase = (
  props: LoyaltyChallengeCardsRailBaseProps,
) => {
  const { headerText, footerText, children, ...restProps } = props;

  const { minWidth } = useResponsive();
  const containerSpacing = useContainerSpacing();

  return (
    <HorizontalScrollRail
      heading={headerText}
      footerText={footerText}
      showNavControls={minWidth.isSM}
      outerOffset={-containerSpacing}
      gap={theme.spacing['6']}
      countPosition={minWidth.isSM ? 'left' : 'right'}
      {...restProps}
    >
      {children as readonly React.ReactNode[]}
    </HorizontalScrollRail>
  );
};

// ─── TYPES ─────────────────────────────────────────────────────────────────

export type LoyaltyChallengeCardsRailBaseProps = Readonly<{
  headerText: string;
  footerText?: string;
  count?: number;
}> &
  Partial<ComponentProps<typeof HorizontalScrollRail>>;
