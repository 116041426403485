import React from 'react';
import { type StyleProp, StyleSheet, type ViewStyle } from 'react-native';
import { useResponsive } from '@sg/garnish';
import { RedemptionChannel } from '@sg/graphql-schema';

import { Loyalty } from '@order/features/loyalty';
import { useLoyaltyContent } from '@order/hooks';

import { type useLoyaltyInfo, type useTermsBeingShown } from '../../hooks';

// ─────────────────────────────────────────────────────────────────────────────

export const LoyaltyHomeRewards = (props: LoyaltyHomeRewardsProps) => {
  const { style, hasRewards, rewards, showTerms, startOrder } = props;

  const { match } = useResponsive();
  const rewardContainer = match([styles.rewardContainerXS, undefined]);

  const { homeRewardsEmptyStateTitle, homeRewardsEmptyStateBody } =
    useLoyaltyContent();

  if (hasRewards) {
    return (
      <Loyalty.Home.Rewards.Container style={style}>
        <Loyalty.Home.Rewards.Rail>
          {rewards.map((reward) => {
            const isInStoreOnly =
              reward.redemptionChannel === RedemptionChannel.InStore;
            const onShowTerms = reward.terms ? showTerms(reward) : undefined;
            const onStartOrder = isInStoreOnly ? undefined : startOrder;

            return (
              <Loyalty.Reward
                key={reward.id}
                id={reward.id}
                title={reward.name}
                imageUrl={reward.assetUrl}
                expirationDate={reward.expirationDate}
                redeemableAt={reward.redeemableAt}
                isInStoreOnly={isInStoreOnly}
                style={rewardContainer}
                onShowTerms={onShowTerms}
                onStartOrder={onStartOrder}
              />
            );
          })}
        </Loyalty.Home.Rewards.Rail>
      </Loyalty.Home.Rewards.Container>
    );
  }

  return (
    <Loyalty.Home.RewardsEmptyState
      title={homeRewardsEmptyStateTitle}
      body={homeRewardsEmptyStateBody}
      style={style}
    />
  );
};

// ─── Styles ──────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  rewardContainerXS: {
    width: '100%',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type LoyaltyHomeRewardsProps = {
  style: StyleProp<ViewStyle>;
  hasRewards: boolean;
  rewards: ReturnType<typeof useLoyaltyInfo>['rewards'];
  showTerms: ReturnType<typeof useTermsBeingShown>['showTerms'];
  startOrder: () => void;
};
