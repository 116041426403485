/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * See codegen config in .graphqlrc.yml for details
 */

import type * as Types from '@sg/graphql-schema';

import gql from 'graphql-tag';
import { CartDataFragmentDoc } from '../../../hooks/useCart/GraphQL/Cart.generated';
import { ValidationErrorFieldsFragmentDoc } from '../../../graphql/fragments/ValidationErrorFields.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RewardsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type RewardsQuery = {
  readonly __typename?: 'Query';
  readonly rewards: ReadonlyArray<{
    readonly __typename: 'Reward';
    readonly id: string;
    readonly name: string;
    readonly expirationDate: string | null;
    readonly rewardType: Types.RewardType;
    readonly redeemable: boolean;
    readonly redeemableAt: string | null;
  }>;
};

export type RewardNotAppliedFieldsFragment = {
  readonly __typename?: 'RewardNotApplied';
  readonly message: string;
  readonly status: number;
  readonly failureCode: Types.ApplyRewardFailureCode;
  readonly failureReasons: ReadonlyArray<string>;
  readonly failureMetadata: {
    readonly __typename?: 'ApplyRewardFailureMetadata';
    readonly requiredChannel: string | null;
    readonly requiredDays: ReadonlyArray<number> | null;
  };
};

export type RewardNotRemovedFieldsFragment = {
  readonly __typename?: 'RewardNotRemoved';
  readonly message: string;
  readonly status: number;
};

export type ApplyRewardMutationVariables = Types.Exact<{
  input: Types.ApplyRewardInput;
}>;

export type ApplyRewardMutation = {
  readonly __typename?: 'Mutation';
  readonly applyReward:
    | {
        readonly __typename: 'ApplyRewardSuccess';
        readonly order: {
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RewardNotApplied';
        readonly message: string;
        readonly status: number;
        readonly failureCode: Types.ApplyRewardFailureCode;
        readonly failureReasons: ReadonlyArray<string>;
        readonly failureMetadata: {
          readonly __typename?: 'ApplyRewardFailureMetadata';
          readonly requiredChannel: string | null;
          readonly requiredDays: ReadonlyArray<number> | null;
        };
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export type RemoveRewardMutationVariables = Types.Exact<{
  input: Types.RemoveRewardInput;
}>;

export type RemoveRewardMutation = {
  readonly __typename?: 'Mutation';
  readonly removeReward:
    | {
        readonly __typename: 'RemoveRewardSuccess';
        readonly order: {
          readonly __typename: 'Order';
          readonly id: string;
          readonly orderType: Types.OrderType;
          readonly canTrackOrderStatus: boolean;
          readonly ledger: {
            readonly __typename?: 'Ledger';
            readonly tax: number;
            readonly subtotal: number;
            readonly feesTotal: number;
            readonly discountsTotal: number;
            readonly creditsTotal: number;
            readonly tip: number;
            readonly discounts: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly credits: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
            readonly fees: ReadonlyArray<{
              readonly __typename: 'LedgerItem';
              readonly id: string | null;
              readonly name: string;
              readonly amount: number;
              readonly description: string | null;
            }>;
          };
          readonly lineItems: ReadonlyArray<{
            readonly __typename: 'LineItem';
            readonly id: string;
            readonly slug: string;
            readonly quantity: number;
            readonly cost: number;
            readonly customName: string | null;
            readonly perItemCost: number;
            readonly favorited: boolean;
            readonly isCustom: boolean;
            readonly dressingMode: Types.DressingMode;
            readonly addedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly removedIngredients: ReadonlyArray<{
              readonly __typename: 'Ingredient';
              readonly id: string;
              readonly name: string;
              readonly kind: Types.IngredientKind | null;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
            }>;
            readonly product: {
              readonly __typename: 'Product';
              readonly id: string;
              readonly name: string;
              readonly slug: string;
              readonly isCustom: boolean;
              readonly asset: {
                readonly __typename?: 'Asset';
                readonly url: string;
              };
              readonly ingredients: ReadonlyArray<{
                readonly __typename: 'Ingredient';
                readonly id: string;
                readonly name: string;
                readonly kind: Types.IngredientKind | null;
              }>;
            };
            readonly mixedDressingDetails: ReadonlyArray<{
              readonly __typename?: 'MixedDressingDetails';
              readonly ingredientId: string;
              readonly weight: Types.DressingWeight;
            }>;
          }>;
          readonly restaurant: {
            readonly __typename: 'Restaurant';
            readonly id: string;
            readonly slug: string;
            readonly name: string;
            readonly city: string;
            readonly state: string;
            readonly address: string;
            readonly zipCode: string;
            readonly isOutpost: boolean;
            readonly deliveryMinSubtotal: number;
            readonly showDeliveryFeeDisclosure: boolean;
            readonly deliveryFee: number;
            readonly availableDropOffLocations: ReadonlyArray<{
              readonly __typename: 'DropOffLocation';
              readonly id: string;
              readonly name: string;
            }>;
            readonly asset: {
              readonly __typename?: 'Asset';
              readonly url: string;
            };
          };
          readonly availableWantedTimes: ReadonlyArray<{
            readonly __typename?: 'AvailableWantedTime';
            readonly time: string;
            readonly deliveryOffset: number;
          }>;
          readonly deliveryOrderDetail: {
            readonly __typename: 'DeliveryOrderDetail';
            readonly id: string;
            readonly tip: number;
            readonly deliveryFee: number;
            readonly vendor: string;
            readonly orderId: string;
            readonly vendorRestaurantId: string;
            readonly estimatedDeliveryTime: string | null;
            readonly address: {
              readonly __typename: 'Address';
              readonly id: string;
              readonly street: string;
              readonly secondaryStreet: string | null;
              readonly city: string;
              readonly state: string;
              readonly country: string;
              readonly zipCode: string;
              readonly deliveryPreference: Types.DeliveryPreferenceType;
              readonly googlePlaceId: string;
              readonly latitude: number;
              readonly longitude: number;
              readonly name: string | null;
              readonly notes: string | null;
            } | null;
          } | null;
        };
      }
    | {
        readonly __typename: 'RewardNotRemoved';
        readonly message: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'ValidationError';
        readonly message: string;
        readonly status: number;
        readonly fieldErrors: ReadonlyArray<{
          readonly __typename?: 'FieldValidationError';
          readonly field: string;
          readonly message: string;
        }>;
      };
};

export const RewardNotAppliedFieldsFragmentDoc = gql`
  fragment RewardNotAppliedFields on RewardNotApplied {
    message
    status
    failureCode
    failureReasons
    failureMetadata {
      requiredChannel
      requiredDays
    }
  }
`;
export const RewardNotRemovedFieldsFragmentDoc = gql`
  fragment RewardNotRemovedFields on RewardNotRemoved {
    message
    status
  }
`;
export const RewardsDocument = gql`
  query Rewards {
    rewards {
      id
      __typename
      name
      expirationDate
      rewardType
      redeemable
      redeemableAt
    }
  }
`;

export function useRewardsQuery(
  options?: Omit<Urql.UseQueryArgs<RewardsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<RewardsQuery, RewardsQueryVariables>({
    query: RewardsDocument,
    ...options,
  });
}
export const ApplyRewardDocument = gql`
  mutation applyReward($input: ApplyRewardInput!) {
    applyReward(input: $input) {
      __typename
      ... on ApplyRewardSuccess {
        order {
          ...CartData
        }
      }
      ...ValidationErrorFields
      ...RewardNotAppliedFields
    }
  }
  ${CartDataFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotAppliedFieldsFragmentDoc}
`;

export function useApplyRewardMutation() {
  return Urql.useMutation<ApplyRewardMutation, ApplyRewardMutationVariables>(
    ApplyRewardDocument,
  );
}
export const RemoveRewardDocument = gql`
  mutation removeReward($input: RemoveRewardInput!) {
    removeReward(input: $input) {
      __typename
      ... on RemoveRewardSuccess {
        order {
          ...CartData
        }
      }
      ...ValidationErrorFields
      ...RewardNotRemovedFields
    }
  }
  ${CartDataFragmentDoc}
  ${ValidationErrorFieldsFragmentDoc}
  ${RewardNotRemovedFieldsFragmentDoc}
`;

export function useRemoveRewardMutation() {
  return Urql.useMutation<RemoveRewardMutation, RemoveRewardMutationVariables>(
    RemoveRewardDocument,
  );
}
