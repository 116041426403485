import React from 'react';
import { StyleSheet, View } from 'react-native';
import { LoadingPlaceholder, theme } from '@sg/garnish';

export const LoadingResults = () => (
  <View style={styles.wrapper} testID="location-loading-results">
    <LoadingPlaceholder
      rows={5}
      rowHeight={44}
      gridGapY={theme.spacing['2']}
      palette="cream"
    />
  </View>
);

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing['4'],
  },
});
