// ─── TYPES ──────────────────────────────────────────────────────────────────────

import type {
  DressingWeight,
  IngredientModification,
  IngredientModificationInput,
  IngredientModifications,
  IngredientSubstitutionModificationInput,
  MixedDressingDetails,
} from '../../../graphql/types';
import type {
  FilteredIngredientModificationsKind,
  IngredientModificationWithQuantity,
} from '../types';

export type IngredientsModificationsWithQuantities =
  readonly IngredientModificationWithQuantity[];

export type ProductIngredientModifications = {
  readonly defaults: DefaultIngredientModificationMap;
  readonly ingredientsModifications: IngredientModifications | null;
  readonly limitations: IngredientModificationLimitations;
  readonly active: IngredientsModificationsWithQuantities;
  readonly additions: IngredientsModificationsAdditions;
  readonly removals: IngredientsModificationsRemovals;
  readonly substitutions: IngredientsModificationsSubstitutions;
  readonly calories: number;
  readonly netPriceChange: number;
  readonly maxModifications: number;
  readonly isCustom: boolean;
  readonly isModifiable: boolean;
  readonly customName: string;
  readonly mixedDressingDetails: readonly MixedDressingDetails[];
  readonly lastKnownModifications: Readonly<{
    active: IngredientsModificationsWithQuantities;
    additions: IngredientsModificationsAdditions;
    removals: IngredientsModificationsRemovals;
    substitutions: IngredientsModificationsSubstitutions;
    mixedDressingDetails: readonly MixedDressingDetails[];
  }>;

  isSumCaloriesEnabled: boolean;
};

export type ModificationLimitation = Readonly<{
  min: number;
  max: number;
}>;

export type IngredientModificationLimitations = Record<
  string,
  ModificationLimitation
>;

export type ProductIngredientComputedModifications = Pick<
  ProductIngredientModifications,
  'additions' | 'removals' | 'substitutions'
>;

export type IngredientsModificationsAdditions =
  readonly IngredientModificationChange[];

export type IngredientsModificationsRemovals =
  readonly IngredientModificationChange[];

export type IngredientsModificationsSubstitution =
  IngredientSubstitutionModificationInput &
    Readonly<{
      subKind: IngredientModificationsSubKind | null;
      substitutionCost: number;
      removalCost: number;
    }>;

export type IngredientsModificationsSubstitutions =
  readonly IngredientsModificationsSubstitution[];

export type IngredientModificationChange = Readonly<{
  kind: FilteredIngredientModificationsKind | null;
  subKind: IngredientModificationsSubKind | null;
  additionCost: number;
  removalCost: number;
  substitutionCost: number;
}> &
  IngredientModificationInput;

export type IngredientModificationsSubKind =
  | FilteredIngredientModificationsKind
  | 'superpremiums';

export type DefaultIngredientModificationMap = Map<
  IngredientModification['ingredient']['id'],
  IngredientModificationWithQuantity
>;

// ─── DRESSINGS ──────────────────────────────────────────────────────────────────

export type DressingsDetails = readonly DressingDetails[];

export type DressingDetails = Readonly<{
  id: IngredientModification['ingredient']['id'];
  name: IngredientModification['ingredient']['name'];
  portionsNumber: number;
  weight?: DressingWeight;
  ingredientModification: IngredientModificationWithQuantity;
}>;
