import React, { type ComponentProps } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { theme, TYPE_CONFIG } from '@garnish/constants';
import {
  HorizontalScrollRail,
  useContainerSpacing,
  useResponsive,
  VStack,
} from '@sg/garnish';

import { LoyaltyHomeChallengesRewardsContainer } from '../LoyaltyHomeChallengesRewardsContainer';

const Rail = (props: RailProps) => {
  const { match } = useResponsive();
  const { formatMessage } = useIntl();
  const containerSpacing = useContainerSpacing();
  const { currentBreakpoint } = useResponsive();

  if (currentBreakpoint.isXS) {
    return <VStack>{props.children}</VStack>;
  }

  return (
    <HorizontalScrollRail
      showNavControls={true}
      withoutHeaderBorder
      gap={theme.spacing['4']}
      heading={formatMessage(messages.rewards)}
      headerBottomSpacing={theme.spacing['6']}
      headerVariation="display"
      outerOffset={
        -match([
          containerSpacing,
          containerSpacing,
          containerSpacing,
          RAIL_SPACING_LG,
        ])
      }
      headerPalette="light"
      titleStyle={styles.title}
    >
      {props.children}
    </HorizontalScrollRail>
  );
};

export const LoyaltyHomeRewards = {
  Container: LoyaltyHomeChallengesRewardsContainer,
  Rail,
};

// ─── Messages ───────────────────────────────────────────────────────────────

const messages = defineMessages({
  rewards: {
    defaultMessage: 'Rewards',
    description: 'Loyalty Home | Rewards | Title',
  },
});

// ─── Constants ───────────────────────────────────────────────────────────────

const RAIL_SPACING_LG = theme.spacing['30'];

// ─── Styles ─────────────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  title: {
    ...TYPE_CONFIG.DISPLAY['24'],
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type RailProps = Pick<ComponentProps<typeof HorizontalScrollRail>, 'children'>;
