import React from 'react';
import { StyleSheet } from 'react-native';
import { Footer } from '@expo/html-elements';
import { BodyText, theme } from '@sg/garnish';

import { LoyaltyTerms } from '../../../LoyaltyTerms';

export const HowItWorksFooter = (props: HowItWorksFooterProps) => {
  return (
    <Footer style={styles.footer}>
      {props.disclaimer ? (
        <BodyText sizeMatch={['12']} style={styles.disclaimer}>
          {props.disclaimer}
        </BodyText>
      ) : null}

      <LoyaltyTerms
        palette="dark"
        termsAndConditionsUrl={props.termsAndConditionsUrl}
      />
    </Footer>
  );
};

// ─── Styles ─────────────────────────────────────────────────────────

const styles = StyleSheet.create({
  footer: {
    gap: theme.spacing['2'],
    paddingHorizontal: theme.spacing['2'],
    paddingVertical: theme.spacing['4'],
    alignItems: 'center',
  },
  disclaimer: {
    color: theme.colors.CHARCOAL,
    alignSelf: 'center',
    textAlign: 'center',
  },
});

// ─── Types ──────────────────────────────────────────────────────────────────

type HowItWorksFooterProps = {
  termsAndConditionsUrl: string;
  disclaimer?: string;
};
