import { useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { datadog } from '@sg/expo-datadog-client';

import { useIsLoggedIn, useIsLoggingIn } from '@order/AuthMachine';
import { useTelemetry } from '@order/Telemetry';

import { useCustomerData } from './useCustomerData';

/**
 * Handles telemetry events related to the customer and session on app start.
 */
export const useCustomerTelemetry = () => {
  const { track } = useTelemetry();
  const { customer } = useCustomerData();
  const customerId = customer?.id ?? '';

  const isLoggingIn = useIsLoggingIn();
  const isLoggedIn = useIsLoggedIn();
  const isLoadingCustomer = isLoggingIn || (isLoggedIn && !customerId);

  useEffect(() => {
    if (isLoadingCustomer) return;

    datadog.setUser(customerId);
    void handleFirstOpen(customerId, track);
    void handleAppSession(customerId, track);
  }, [isLoadingCustomer, customerId, track]);
};

// ─── Helpers ─────────────────────────────────────────────────────────────────

/**
 * When the app is opened for the first time, send the "app.first_open" event.
 * Afterwards, set the "first_open" async storage key to the current date.
 * The next time the app is opened, it won't trigger the event because the key already exists.
 */
const handleFirstOpen = async (customerId: string, track: Track) => {
  const firstOpen = await AsyncStorage.getItem(FIRST_OPEN);

  if (!firstOpen) {
    track('app.first_open', { userId: customerId ?? '' });
    await AsyncStorage.setItem(FIRST_OPEN, new Date().toISOString());
  }
};

/**
 * Whenever the app is opened, send a "app_session" event.
 * Additionally, also store the time the app was opened as a "last_session" key in async storage.
 * The next time the app is opened, it will include this last session on the event.
 */
const handleAppSession = async (customerId: string, track: Track) => {
  const currentSession = new Date().toISOString();
  const lastSession = (await AsyncStorage.getItem(LAST_SESSION)) ?? '';

  track('app.session', { userId: customerId, lastSession });
  await AsyncStorage.setItem(LAST_SESSION, currentSession);
};

// ─── Constants ───────────────────────────────────────────────────────────────

const FIRST_OPEN = 'first_open';
const LAST_SESSION = 'last_session';

// ─── Types ───────────────────────────────────────────────────────────────────

type Track = ReturnType<typeof useTelemetry>['track'];
