import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as DateFns from 'date-fns';

import type { CustomerProfileFieldsQuery } from './GraphQL/Profile.generated';
import { useFormValidation } from './useFormValidation';

export const useProfileForm = (props: ProfileFormHook) => {
  const { customer, hasZipCode } = props;
  const validationSchema = useFormValidation(hasZipCode);

  // ──── Init Form ────────────────────────────────────────────────────────────

  const form = useForm<ProfileFormData>({
    defaultValues: {
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
      email: customer?.email ?? '',
      phoneNumber: customer?.phoneNumber ?? '',
      zipCode: customer?.zipCode ?? '',
      birthday: convertRemoteBirthday(customer?.birthday),
      password: '',
      hasPasswordChanged: false,
      confirmPassword: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  // ──── Helpers ────────────────────────────────────────────────────────────────────

  const resetForm = React.useCallback(() => {
    form.reset({
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
      email: customer?.email ?? '',
      phoneNumber: customer?.phoneNumber ?? '',
      zipCode: customer?.zipCode ?? '',
      birthday: convertRemoteBirthday(customer?.birthday),
      password: '',
      hasPasswordChanged: false,
      confirmPassword: '',
    });
  }, [form, customer]);

  // ────────────────────────────────────────────────────────────────────

  return {
    form,
    resetForm,
    isSubmitAllowed: form.formState.isDirty,
  };
};

// Remote birthday is in the format of YYYY-MM-DD
// The birthday picker component uses MM/DD.
const convertRemoteBirthday = (remoteBirthday?: string | null | undefined) => {
  if (!remoteBirthday || !DateFns.isValid(new Date(remoteBirthday))) return '';

  const [, month, day] = remoteBirthday.split('-');

  return `${month}/${day}`;
};

// ─── TYPES ──────────────────────────────────────────────────────────────────────

export type ProfileFormData = Readonly<{
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  birthday: string;
  hasPasswordChanged: boolean;
  password: string;
  confirmPassword: string;
}>;

type ProfileFormHook = Readonly<{
  customer: CustomerProfileFieldsQuery['currentCustomer'];
  hasZipCode: boolean;
}>;
