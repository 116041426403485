import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FadeView, Icon, LoadingDots, theme } from '@sg/garnish';

import { useContactUsHandler, useIsChatAppEnabled } from '@order/hooks';
import { useLocalizationContext } from '@order/Localization';

import { AccountMenuPrimaryItem } from '../AccountMenuPrimaryItem';

export const AccountContactUsOption = () => {
  const { t } = useLocalizationContext();

  const isChatAppEnabled = useIsChatAppEnabled();

  const { handleContactUs, handlingContactUs } = useContactUsHandler('account');

  return (
    <AccountMenuPrimaryItem
      testID="account.sidebar-tab.contact-us"
      label={t('account.sidebar.contact-us')}
      iconName="IconText"
      onPress={handleContactUs}
    >
      {isChatAppEnabled ? null : (
        <View style={styles.chatAppIcon}>
          <Icon name="IconShare2" />
        </View>
      )}

      <FadeView show={handlingContactUs} style={styles.chatAppIcon}>
        <LoadingDots />
      </FadeView>
    </AccountMenuPrimaryItem>
  );
};

const styles = StyleSheet.create({
  chatAppIcon: {
    marginLeft: theme.spacing['2'],
  },
});
