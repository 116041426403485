/* eslint-disable unicorn/no-array-for-each */

import { useCallback, useEffect, useRef } from 'react';

import { type RailItemsRefsMap } from '../../HorizontalScrollRail.types';

// ─────────────────────────────────────────────────────────────────────────────

export const useRailItemsRef = (params: Params) => {
  const { railItemsCount } = params;

  // ─── Refs ────────────────────────────────────────────────────────────

  const railItemsRef = useRef<RailItemsRefsMap>(new Map());

  // ─── Helpers ─────────────────────────────────────────────────────────

  /**
   * A helper function that removes extra items from the items refs map.
   *
   * It iterates through the current references in the `railItemsRef` and removes
   * any items that are unmounted.
   */
  const removeExtraItems = useCallback(() => {
    const currentRefs = [...railItemsRef.current];

    currentRefs.forEach((_, index) => {
      const isUnmountedItem = index >= railItemsCount;

      if (!isUnmountedItem) return;

      railItemsRef.current.delete(index);
    });
  }, [railItemsCount]);

  // ─── Effects ─────────────────────────────────────────────────────────

  useEffect(removeExtraItems, [removeExtraItems]);

  // ─────────────────────────────────────────────────────────────────────

  return railItemsRef;
};

// ─── Types ───────────────────────────────────────────────────────────────────

type Params = {
  railItemsCount: number;
};
