//

// ─── Context ────────────────────────────────────────────────────────────────

export type OrderingMachineContext<LineItem, DeliveryOrderDetail> = Readonly<{
  isInitialDataReady: boolean;
  cart: OrderingCart<LineItem, DeliveryOrderDetail>;
  rewards: readonly BagReward[];
  rewardError: string | undefined;
  isRewardMissingSku: boolean;
  availablePoints: number;
  appliedRewardId: string | undefined;
  paymentMethods: readonly PaymentMethod[];
  defaultPaymentMethodId: string | undefined;
  hasDefaultPaymentMethodCard: boolean;
  availableCredit: number;
  giftCardBalance: number;

  expressPayments: {
    isExpressPaymentsEnabled: boolean;
    isStripeReady: boolean;
    canApplePay: boolean;
    canGooglePay: boolean;
  };

  customer: {
    customerId: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isNewCustomer: boolean;
    isOptedOutFromLoyalty: boolean;
  };

  wantedTime: WantedTime | undefined;
  isWantedTimeAsap: boolean;
  hasShownFutureDateWarning: boolean;
  dropoffLocation: string | undefined;
  paymentMethodId: string | undefined;
  shouldUseCredit: boolean;
  shouldIncludeUtensils: boolean;
  selectedTip: number;

  lineItemIdsBeingUpdated: string[];
  rewardIdBeingUpdated: string | undefined;
}>;

// ─── Events ─────────────────────────────────────────────────────────────────

export type OrderingMachineEvents =
  | StartParams
  | InitialDataParams
  | CancelRequestParams
  | RequestChannelLocationChangeParams
  | RequestTimeChangeParams
  | RequestDeliveryPreferencesChangeParams
  | RequestDropoffLocationChangeParams
  | RequestCheckoutLedgerParams
  | RequestGiftCardFormParams
  | RequestPaymentMethodChangeParams
  | RequestPaymentMethodAddParams
  | AddUpsellToBagParams
  | StartChangingLineItemQuantityParams
  | StopChangingLineItemQuantityParams
  | ChangeLineItemQuantityParams
  | ChangeUtensilsUsageParams
  | DeliveryPreferencesParams
  | ShowLoyaltyInfoParams
  | ApplyRewardParams
  | RemoveRewardParams
  | RefetchRewardsParams
  | AssignRewardsParams
  | RewardsFetchErrorParams
  | ApplyPromoCodeParams
  | ApplyGiftCardParams
  | AddPaymentMethodParams
  | SetDefaultPaymentMethodParams
  | SetAvailableExpressPaymentsParams
  | ChangePaymentMethodParams
  | ChangeCreditUsageParams
  | ChangeWantedTimeParams
  | ChangeTippingParams
  | ChangeDropoffLocationParams
  | SubmitOrderParams;

// ─── Services ───────────────────────────────────────────────────────────────

export type OrderingMachineServices<LineItem, DeliveryOrderDetail> = Readonly<{
  fetchInitialData: {
    data: {
      cart: Readonly<OrderingCart<LineItem, DeliveryOrderDetail>>;
      paymentMethods: readonly PaymentMethod[];
      defaultPaymentMethodId: string | undefined;
      availablePoints: number;
      availableCredit: number;
      giftCardBalance: number;
      customerId: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      isNewCustomer: boolean;
      isOptedOutFromLoyalty: boolean;
    };
  };
  getInitialWantedTime: {
    data: {
      wantedTime: WantedTime;
    };
  };
  fetchRewards: {
    data: {
      rewards: readonly BagReward[];
    };
  };
  fetchWantedTimes: {
    data: {
      wantedTimes: WantedTime[];
    };
  };
  addUpsellToBag: {
    data:
      | Readonly<{ cart: OrderingCart<LineItem, DeliveryOrderDetail> }>
      | { error: string };
  };
  updateLineItem: {
    data: Readonly<OrderingCart<LineItem, DeliveryOrderDetail> | undefined>;
  };
  applyReward: {
    data:
      | Readonly<{ cart: OrderingCart<LineItem, DeliveryOrderDetail> }>
      | { error: string; isRewardMissingSku: boolean };
  };
  removeReward: {
    data:
      | Readonly<{ cart: OrderingCart<LineItem, DeliveryOrderDetail> }>
      | { error: string };
  };
  applyPromoCode: {
    data: Readonly<{
      rewards: readonly BagReward[];
      success: boolean;
      error: string | undefined;
    }>;
  };
  applyGiftCard: {
    data: { giftCardBalance: number; availableCredit: number } | null;
  };
  addPaymentMethod: {
    data: PaymentMethod | null;
  };
  setDefaultPaymentMethod: {
    data: string | undefined;
  };
  submitOrder: {
    data: {
      success: boolean;
      isTimeslotUnavailable: boolean;
      error: string | undefined;
      order?: {
        id: string;
        hasEarnedLoyaltyPoints?: boolean;
      };
    };
  };
}>;

// ─── Actions ────────────────────────────────────────────────────────────────

export type OrderingMachineActions<LineItem, DeliveryOrderDetail> =
  | Readonly<{
      type: 'changeChannelOrLocation';
      changeChannelOrLocation: (
        context: OrderingMachineContext<LineItem, DeliveryOrderDetail>,
        event: OrderingMachineEvents,
      ) => void;
    }>
  | Readonly<{
      type: 'setLineItemQuantity';
      setLineItemQuantity: (
        context: OrderingMachineContext<LineItem, DeliveryOrderDetail>,
        event: OrderingMachineEvents,
      ) => void;
    }>;

// ─── Cart ───────────────────────────────────────────────────────────────────

export type OrderingCart<LineItem, DeliveryOrderDetail> = {
  orderId: string | undefined;
  orderChannel: 'pickup' | 'delivery' | 'outpost' | undefined;
  wantedTimes: WantedTime[];
  dropoffLocations: readonly OrderingDropoffLocation[];
  locationName: string | undefined;
  restaurantId: string | undefined;
  restaurantSlug: string | undefined;
  addressId: string | undefined;
  deliveryOrderDetail: DeliveryOrderDetail;
  deliveryPreferences: { type: string; notes: string };
  deliveryMinSubtotal: number | undefined;
  canTrackOrderStatus: boolean;
  lineItems: readonly LineItem[];
  ledger: OrderingLedger | undefined;
  appliedRewardId?: string;
};

// ─── Ledger ─────────────────────────────────────────────────────────────────

export type OrderingLedger = {
  subtotal: number;
  feesTotal: number;
  discountsTotal: number;
  creditsTotal: number;
  tax: number;
  tip: number;
  discounts: ReadonlyArray<{
    name: string;
    amount: number;
    description: string | null;
  }>;
  credits: ReadonlyArray<{
    name: string;
    amount: number;
    description: string | null;
  }>;
  fees: ReadonlyArray<{
    name: string;
    amount: number;
    description: string | null;
  }>;
};

// ─── Setup ──────────────────────────────────────────────────────────────────

export type StartParams = { type: 'START' };
export type InitialDataParams = { type: 'FETCH_INITIAL_DATA' };
export type CancelRequestParams = { type: 'CANCEL_REQUEST' };

// ─── Channel Location ───────────────────────────────────────────────────────

export type RequestChannelLocationChangeParams = {
  type: 'REQUEST_CHANNEL_LOCATION_CHANGE';
};

export type RequestDeliveryPreferencesChangeParams = {
  type: 'REQUEST_DELIVERY_PREFERENCES_CHANGE';
};

// ─── Upsells ────────────────────────────────────────────────────────────────

export type AddUpsellToBagParams = {
  type: 'ADD_UPSELL_TO_BAG';
  params: {
    productId: string;
    productName: string;
    deliveryDetails?: {
      addressId: string;
      deliveryFee: number;
      tip: number;
      vendor: string;
      vendorRestaurantId: string;
    };
  };
};

// ─── Line Items ─────────────────────────────────────────────────────────────

export type StartChangingLineItemQuantityParams = {
  type: 'START_CHANGING_LINE_ITEM_QUANTITY';
};

export type StopChangingLineItemQuantityParams = {
  type: 'STOP_CHANGING_LINE_ITEM_QUANTITY';
};

export type ChangeLineItemQuantityParams = {
  type: 'CHANGE_LINE_ITEM_QUANTITY';
  params: {
    lineItemId: string;
    productId: string;
    quantity: number;
  };
};

// ─── Wanted Time ────────────────────────────────────────────────────────────

export type WantedTime = {
  time: string;
  offset: number;
};

export type RequestTimeChangeParams = {
  type: 'REQUEST_TIME_CHANGE';
};

export type ChangeWantedTimeParams = {
  type: 'CHANGE_TIME';
  params: {
    wantedTime: WantedTime;
    isFutureDate: boolean;
  };
};

// ─── Dropoff Location ───────────────────────────────────────────────────────

export type RequestDropoffLocationChangeParams = {
  type: 'REQUEST_DROPOFF_LOCATION_CHANGE';
};

export type OrderingDropoffLocation = {
  value: string;
  label: string;
};

export type ChangeDropoffLocationParams = {
  type: 'CHANGE_DROPOFF_LOCATION';
  params: {
    dropoffLocation: string;
  };
};

// ─── Tipping ────────────────────────────────────────────────────────────────

export type ChangeTippingParams = {
  type: 'CHANGE_TIP';
  params: {
    tip: number;
  };
};

// ─── Utensils ───────────────────────────────────────────────────────────────

export type ChangeUtensilsUsageParams = {
  type: 'CHANGE_UTENSILS_USAGE';
  params: { shouldIncludeUtensils: boolean };
};

// ─── Delivery Preferences ───────────────────────────────────────────────────

export type DeliveryPreferencesParams = {
  type: 'CHANGE_DELIVERY_PREFERENCES';
  params: {
    type: string;
    notes: string;
  };
};

// ─── Order Submission ───────────────────────────────────────────────────────

export type RequestCheckoutLedgerParams = {
  type: 'REQUEST_CHECKOUT_LEDGER';
};

export type SubmitOrderParams = {
  type: 'SUBMIT_ORDER';
  params: { paymentMethodId?: string };
};

// ─── Rewards ────────────────────────────────────────────────────────────────

export type ShowLoyaltyInfoParams = Readonly<{
  type: 'SHOW_LOYALTY_INFO';
}>;

export enum RewardType {
  Premium = 'PREMIUM',
  NonPremium = 'NON_PREMIUM',
}

export enum RedemptionChannel {
  All = 'All',
  Delivery = 'Delivery',
  InStore = 'InStore',
  Outpost = 'Outpost',
  Pickup = 'Pickup',
}

export type BagReward = Readonly<{
  id: string;
  name: string;
  assetUrl?: string | null;
  points?: number | null;
  expirationDate?: string | null;
  rewardType?: RewardType | null;
  redemptionChannel?: RedemptionChannel | null;
  redeemable?: boolean | null;
  redeemableAt?: string | null;
}>;

export type ApplyRewardParams = Readonly<{
  type: 'APPLY_REWARD';
  params: {
    orderId: string;
    rewardId: string;
  };
}>;

export type RemoveRewardParams = Readonly<{
  type: 'REMOVE_REWARD';
  params: {
    orderId: string;
    rewardId: string;
  };
}>;

export type RefetchRewardsParams = Readonly<{
  type: 'REFETCH_REWARDS';
}>;

export type AssignRewardsParams = Readonly<{
  type: 'ASSIGN_REWARDS';
  data: {
    rewards: BagReward[];
  };
}>;

export type RewardsFetchErrorParams = Readonly<{
  type: 'REWARDS_FETCH_ERROR';
}>;

// ─── Payment Methods ────────────────────────────────────────────────────────

export type PaymentMethod = {
  id: string;
  description: string | null;
  nickname: string | null;
  isDefault: boolean;
  cardType: PaymentMethodType;
  expirationState: PaymentMethodExpirationState;
};

export enum PaymentMethodType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  MasterCard = 'MASTER_CARD',
  Unsupported = 'UNSUPPORTED',
  Visa = 'VISA',
}

export enum PaymentMethodExpirationState {
  Expired = 'EXPIRED',
  Nearing = 'NEARING',
  Ok = 'OK',
}

export type AddPaymentMethodParams = Readonly<{
  type: 'ADD_PAYMENT_METHOD';
  params: {
    id: string;
    nickname: string;
    isDefault: boolean;
  };
}>;

export type ChangePaymentMethodParams = Readonly<{
  type: 'CHANGE_PAYMENT_METHOD';
  params: {
    paymentMethodId: string;
  };
}>;

export type RequestPaymentMethodChangeParams = {
  type: 'REQUEST_PAYMENT_METHOD_CHANGE';
};

export type RequestPaymentMethodAddParams = {
  type: 'REQUEST_PAYMENT_METHOD_ADD';
};

// ─── Credit Usage ───────────────────────────────────────────────────────────

export type ChangeCreditUsageParams = Readonly<{
  type: 'CHANGE_CREDIT_USAGE';
  params: {
    shouldUseCredit: boolean;
  };
}>;

// ─── Promo Codes ────────────────────────────────────────────────────────────

export type ApplyPromoCodeParams = Readonly<{
  type: 'APPLY_PROMO_CODE';
  params: {
    code: string;
  };
}>;

// ─── Gift Cards ─────────────────────────────────────────────────────────────

export type RequestGiftCardFormParams = {
  type: 'REQUEST_GIFT_CARD_FORM';
};

export type ApplyGiftCardParams = {
  type: 'APPLY_GIFT_CARD';
  params: {
    code: string;
    regCode: string;
  };
};

// ─── Available Express Payments ─────────────────────────────────────────────

export type SetAvailableExpressPaymentsParams = {
  type: 'SET_AVAILABLE_EXPRESS_PAYMENTS';
  params: { canApplePay: boolean; canGooglePay: boolean };
};

// ─── Default Payment Method ─────────────────────────────────────────────────

export type SetDefaultPaymentMethodParams = {
  type: 'SET_DEFAULT_PAYMENT_METHOD';
  params: { paymentMethodId: string };
};
